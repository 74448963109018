import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

export function getTaxAmount(totalPrice) {
  return (totalPrice - totalPrice / 1.05).toFixed(2);
}
export const numberWithCommas = (x) => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return 0;
};

export const getDiscountAmount = (
  price,
  qty,
  listing,
  discountPerUnit,
  coupon
) => {
  let discountAmount = 0;
  if (
    coupon?.parameters?.label === 'Category' &&
    coupon?.parameters?.value?.includes(listing.category)
  ) {
    discountAmount = discountPerUnit * price * qty;
  }
  if (
    coupon?.parameters?.label === 'Brand' &&
    coupon?.parameters?.value?.includes(listing.partBrand)
  ) {
    discountAmount = discountPerUnit * price * qty;
  }
  if (
    coupon?.parameters?.label === 'Sub-Category' &&
    coupon?.parameters?.value?.includes(listing.subCategory)
  ) {
    discountAmount = discountPerUnit * price * qty;
  }
  if (
    coupon?.parameters?.label === 'SKU' &&
    !coupon?.parameters?.selectedAll &&
    coupon?.parameters?.value?.includes(listing._id)
  ) {
    discountAmount = discountPerUnit * price * qty;
  }
  if (coupon?.parameters?.label === 'SKU' && coupon?.parameters?.selectedAll) {
    discountAmount = discountPerUnit * price * qty;
  }
  if (
    coupon?.parameters?.label === 'Seller' &&
    coupon?.parameters?.value?.includes(listing?.user?._id || listing?.user)
  ) {
    discountAmount = discountPerUnit * price * qty;
  }
  return discountAmount;
};

export const calcOrderSummary = ({
  orderDetail: order,
  returnProduct,
  currentUser: { _id, role },
}) => {
  //variables
  let generalRoles = ['admin', 'logistics_admin', 'finance', 'BUYER'];
  let listings = order?.products || [];
  const TAX = 1.05;
  let coupon = order?.coupon || {};
  let shippingPrice = generalRoles?.includes(role)
    ? order?.shipping_price || 0
    : 0;
  let shippingPriceWithoutTax = shippingPrice / TAX;
  let productsSum = listings.reduce((a, b) => {
    return a + b.delivery_option?.price * b.quantity;
  }, 0);
  let returnProductsSum = returnProduct.reduce((a, b) => {
    if (b.status === 'quality_check_pass') {
      return a + b?.list?.price * b?.quantity;
    }
    return a;
  }, 0);
  let cancelledProductsSum = listings?.reduce((a, b) => {
    if (b?.status === 'cancelled') {
      return a + b?.delivery_option?.price * b?.quantity;
    } else {
      return a;
    }
  }, 0);

  let roleBasedProductsSum = listings.reduce((a, b) => {
    if (role === 'SELLER' && b?.delivery_option?.user?._id === _id) {
      return a + b.delivery_option?.price * b.quantity;
    } else if (generalRoles.includes(role)) {
      return a + b.delivery_option?.price * b.quantity;
    } else {
      return a;
    }
  }, 0);

  let roleBasedReturnProductsSum = returnProduct.reduce((a, b) => {
    let isAssigned = false;
    if (role === 'SELLER') {
      isAssigned =
        b.list?.user?._id === _id || b.list?.user === _id ? true : false;
    } else if (generalRoles.includes(role)) {
      isAssigned = true;
    } else {
      isAssigned = false;
    }
    if (b.status === 'quality_check_pass' && isAssigned) {
      return a + b?.list?.price * b?.quantity;
    }
    return a;
  }, 0);
  let roleBasedCancelledProductsSum = listings?.reduce((a, b) => {
    if (
      b?.status === 'cancelled' &&
      role === 'SELLER' &&
      b?.delivery_option?.user?._id === _id
    ) {
      return a + b?.delivery_option?.price * b?.quantity;
    } else if (b?.status === 'cancelled' && generalRoles.includes(role)) {
      return a + b?.delivery_option?.price * b?.quantity;
    } else {
      return a;
    }
  }, 0);

  let discountableProducts = listings?.filter((l) => {
    if (
      coupon?.parameters?.label === 'Category' &&
      coupon?.parameters?.value?.includes(l.delivery_option?.category)
    ) {
      return l;
    }
    if (
      coupon?.parameters?.label === 'Sub-Category' &&
      coupon?.parameters?.value?.includes(l.delivery_option?.subCategory)
    ) {
      return l;
    }
    if (
      coupon?.parameters?.label === 'Brand' &&
      coupon?.parameters.value?.includes(l.delivery_option?.partBrand)
    ) {
      return l;
    }
    if (
      coupon?.parameters?.label === 'SKU' &&
      !coupon?.parameters?.selectedAll &&
      coupon?.parameters?.value?.includes(l?.delivery_option?._id)
    ) {
      return l;
    }
    if (
      coupon?.parameters?.label === 'SKU' &&
      coupon?.parameters?.selectedAll
    ) {
      return l;
    }
    if (
      coupon?.parameters?.label === 'Seller' &&
      coupon?.parameters?.value?.includes(l?.delivery_option?.user?._id)
    ) {
      return l;
    }
  });
  let roleBasedDiscountableProductsAndReturnedSum =
    discountableProducts?.reduce((a, b) => {
      let returnedSum = returnProduct?.reduce((a, item) => {
        if (
          b?.delivery_option?._id === item.list?._id &&
          item.status === 'quality_check_pass'
        ) {
          return a + item.list?.price * item.quantity;
        }
        return a;
      }, 0);
      let isAssigned = false;
      if (role === 'SELLER') {
        isAssigned = b?.delivery_option?.user?._id === _id ? true : false;
      } else if (generalRoles.includes(role)) {
        isAssigned = true;
      } else {
        isAssigned = false;
      }
      if (isAssigned) {
        return a + returnedSum;
      } else {
        return a;
      }
    }, 0);
  let roleBasedDiscountableProductsAndCancelledSum =
    discountableProducts?.reduce((a, b) => {
      let isAssigned = false;
      if (role === 'SELLER') {
        isAssigned = b?.delivery_option?.user?._id === _id ? true : false;
      } else if (generalRoles.includes(role)) {
        isAssigned = true;
      } else {
        isAssigned = false;
      }
      if (b.status === 'cancelled' && isAssigned) {
        return a + b.delivery_option?.price * b.quantity;
      } else {
        return a;
      }
    }, 0);

  let initalCartVal = productsSum;
  let initalRoleBasedCartVal = roleBasedProductsSum;
  let latestCartVal = initalCartVal - returnProductsSum - cancelledProductsSum;
  let latestRoleBasedCartVal =
    initalRoleBasedCartVal -
    roleBasedReturnProductsSum -
    roleBasedCancelledProductsSum;

  let latestSubTotal = latestCartVal / TAX;
  let initalSubtotal = initalCartVal / TAX;
  let initalRoleBasedSubTotal = initalRoleBasedCartVal / TAX;
  let latestRoleBasedSubTotal = latestRoleBasedCartVal / TAX;

  let initalDiscountableProductsSum = discountableProducts.reduce((a, b) => {
    return a + b?.delivery_option?.price * b.quantity;
  }, 0);
  let initalRoleBasedDiscountableProductsSum = discountableProducts.reduce(
    (a, b) => {
      if (role === 'SELLER' && b?.delivery_option?.user?._id === _id) {
        return a + b?.delivery_option?.price * b?.quantity;
      } else if (generalRoles.includes(role)) {
        return a + b?.delivery_option?.price * b?.quantity;
      } else {
        return a;
      }
    },
    0
  );
  let latestRoleBasedDiscountableProductsSum =
    initalRoleBasedDiscountableProductsSum -
    roleBasedDiscountableProductsAndReturnedSum -
    roleBasedDiscountableProductsAndCancelledSum;

  let initalDiscountableProductsSumWithoutTax =
    initalDiscountableProductsSum / TAX;
  let initalRoleBasedDiscountableProductsSumWithoutTax =
    initalRoleBasedDiscountableProductsSum / TAX;
  let latestRoleBasedDiscountableProductsSumWithoutTax =
    latestRoleBasedDiscountableProductsSum / TAX;

  //discount
  let discountPerUnit = 0;
  if (latestSubTotal > coupon?.min_cart_value) {
    if (coupon?.discount_type?.label === 'by_percent') {
      discountPerUnit = coupon?.discount_type?.value / 100;
    }
    if (coupon?.discount_type?.label === 'by_value') {
      discountPerUnit =
        coupon?.discount_type?.value / initalDiscountableProductsSumWithoutTax;
    }
  }

  let initalDiscountPerUnit = 0;
  if (initalSubtotal > coupon?.min_cart_value) {
    if (coupon?.discount_type?.label === 'by_percent') {
      initalDiscountPerUnit = coupon?.discount_type?.value / 100;
    }
    if (coupon?.discount_type?.label === 'by_value') {
      initalDiscountPerUnit =
        coupon?.discount_type?.value / initalDiscountableProductsSumWithoutTax;
    }
  }

  let initalDA = initalDiscountableProductsSumWithoutTax * discountPerUnit;
  if (coupon?.discount_cap_amount) {
    discountPerUnit =
      initalDA < coupon?.discount_cap_amount
        ? discountPerUnit
        : coupon?.discount_cap_amount / initalDiscountableProductsSumWithoutTax;
  }
  let _initalDA =
    initalDiscountableProductsSumWithoutTax * initalDiscountPerUnit;
  if (coupon?.discount_cap_amount) {
    initalDiscountPerUnit =
      _initalDA < coupon?.discount_cap_amount
        ? initalDiscountPerUnit
        : coupon?.discount_cap_amount / initalDiscountableProductsSumWithoutTax;
  }
  let initalRoleBasedDiscountAmount =
    initalRoleBasedDiscountableProductsSumWithoutTax * initalDiscountPerUnit;
  let latestRoleBasedDiscountAmount =
    latestRoleBasedDiscountableProductsSumWithoutTax * discountPerUnit;

  let initalRoleBasedTotalAmountAfterDiscount =
    initalRoleBasedSubTotal - initalRoleBasedDiscountAmount;
  let latestRoleBasedTotalAmountAfterDiscount =
    latestRoleBasedSubTotal - latestRoleBasedDiscountAmount;

  let initalRoleBasedTotalAmountAfterDiscountWithTax =
    initalRoleBasedTotalAmountAfterDiscount * TAX;
  let latestRoleBasedTotalAmountAfterDiscountWithTax =
    latestRoleBasedTotalAmountAfterDiscount * TAX;

  let latestTax =
    (latestRoleBasedTotalAmountAfterDiscount + shippingPriceWithoutTax) * 0.05;

  let initalRoleBasedGrandTotal =
    initalRoleBasedTotalAmountAfterDiscountWithTax + shippingPrice;
  let latestRoleBasedGrandTotal =
    latestRoleBasedTotalAmountAfterDiscountWithTax + shippingPrice;

  let refundableAmount =
    initalRoleBasedGrandTotal -
    latestRoleBasedGrandTotal -
    roleBasedCancelledProductsSum;

  return {
    discount: latestRoleBasedDiscountAmount,
    discountPerUnit,
    actual_total_price: latestRoleBasedGrandTotal,
    tax: latestTax,
    shipping_price: shippingPriceWithoutTax,
    actualSubTotal: latestRoleBasedSubTotal,
    totalOrderAmt: initalRoleBasedGrandTotal,
    remainingOrderAmt: latestRoleBasedGrandTotal,
    refundAmt: refundableAmount,
    initalDiscountPerUnit: initalDiscountPerUnit,
    initalDiscountAmount: initalRoleBasedDiscountAmount,
  };
};

export const stringToUrl = (str) => {
  if (str)
    return str
      .toString()
      .replaceAll('%', '')
      .toLowerCase()
      .replaceAll(' ', '-')
      .replaceAll('/', '-');
  else return null;
};

export const UserAvatar = (props) => {
  const [image, setImage] = useState('/assets/img/avatar.png');
  const { avatar } = props;
  useEffect(() => {
    if (avatar) {
      setImage(avatar);
    }
  }, [avatar]);
  function handleErr() {
    setImage('/assets/img/avatar.png');
  }
  return <img src={image} onError={handleErr} {...props} />;
};

export const ProductImage = ({ avatar, style = null }) => {
  const [image, setImage] = useState('/assets/img/byparts.jpg');

  useEffect(() => {
    if (avatar) {
      setImage(avatar);
    }
  }, [avatar]);

  function handleErr() {
    setImage('/assets/img/byparts.jpg');
  }
  return (
    <>
      <img src={image} style={style} onError={handleErr} />
    </>
  );
};

export const validateFile = (file) => {
  if (!file) return true;
  const isLt5M = file?.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    popUp('File must smaller than 5MB!');
    return false;
  } else {
    return true;
  }
};

export const getCartLength = (items, value) => {
  if (value && value?.orderQty) {
    return value?.orderQty
  }
  let count = 0;
  for (let i = 0; i < items.length; ++i) {
    if (items[i] === value?._id) count++;
  }
  return count;
};

export function formatPhoneNumber(phone) {
  return [
    phone.slice(0, 4),
    ' ',
    phone.slice(4, 6),
    ' ',
    phone.slice(6, 9),
    ' ',
    phone.slice(9, phone.length),
  ].join('');
}

export const getTimeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 604800;
  if (interval > 1) {
    return Math.floor(interval) + ' weeks';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (Math.floor(interval) === 1) return Math.floor(interval) + ' day';
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
};

export const popUp = (message, btnText, url, history) => {
  toast.success(
    <div className='d-flex justify-content-between align'>
      <p className='toast--message'>
        {message
          ? message
          : 'There was a problem with server. Please try again.'}
      </p>

      {btnText && (
        <p
          className='toast--buttons'
          onClick={(e) => {
            e.preventDefault();
            history ? history.push(url) : (window.location.href = `${url}`);
          }}
        >
          {btnText}{' '}
        </p>
      )}
    </div>
  );
};

export function cbm(h, w, l) {
  return (h / 1000) * (w / 1000) * (l / 1000);
}

export function clickablePopUp(text, status = 'success', to, history) {
  return toast(
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <img
        src='/assets/img/logo-icon.png'
        style={{ height: 60, marginRight: 20 }}
      />
      <div>
        <p
          style={{
            fontSize: 18,
            marginBottom: 0,
            fontWeight: 'bold',
            color: status === 'error' ? 'rgb(243, 146, 0)' : '#1b0451',
          }}
        >
          {status === "error"
            ? "Oops"
            : status === "thankyou"
              ? "Thank you"
              : "Success"}
        </p>
        <p style={{ marginBottom: 0, lineHeight: 1.4 }}>{text}</p>
        <p onClick={() => history.push(to)} style={{ color: '#1d71b8' }}>
          Click Here !
        </p>
      </div>
    </div>
  );
}
export const changeToGroup = (rows) => {
  const data = rows.reduce(function (rv, x) {
    (rv[x.user?.details?.company_name] = rv[x.user?.details?.company_name] || []).push(x);
    return rv;
  }, {});
  let i = 0;
  const group = [];
  for (const key in data) {
    group.push({ sellerName: key, data: data[key] });
    i++;
  }
  return group;
};

export const changeOrderToGroup = (rows) => {
  const data = rows.reduce(function (rv, x) {
    (rv[x.seller?.details?.company_name] =
      rv[x.seller?.details?.company_name] || []).push(x);
    return rv;
  }, {});
  let i = 0;
  const group = [];
  for (const key in data) {
    group.push({
      sellerName: key,
      data: data[key],
      sellerId: data[key][0]?.seller?._id,
    });
    i++;
  }
  return group;
};

export const validPassword = new RegExp(
  '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/'
);

export const getGarageType = (type) => {
  switch (type) {
    case 'Authorized':
    case 'Authorized Garages':
    case 'Fleet & Corporate':
      return 'Fleet & Corporate';
    case 'Chained/Third-party [Independent] Garages':
    case 'Chained/Third-party [Independent] garages':
    case 'Chained/Third-party Garages':
      return 'Chained/Third-party [Independent] Garages';
    case 'Independent Mom & Pop Garages':
    case 'Independent Garages':
    case 'Independent mom & pop Garages':
      return 'Independent Mom & Pop Garages';
    case 'Gas Station Network':
      return 'Gas Station Network';
    case 'Tyre & Brake Outlets':
      return 'Tyre & Brake Outlets';
    case 'Unauthorized Garages':
    case 'Fleet/Corporate Garages':
    case 'Spare Parts & Traders':
    case 'unauthorized':
      return 'Spare Parts & Traders';
    default:
      return 'Spare Parts & Traders';
  }
};
