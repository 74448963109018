import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './login.css';
import { string as YupString } from 'yup';
import FormikValidationError from './FormikValidationError';
import { useState } from 'react';
import { LogInAc } from '../../../Store/action/loginActions';
import { visitor1 } from '../../../Store/action/listingActions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIcon from '@material-ui/core/Chip';

import {
  emailValidationSchema,
  otpValidationSchema,
  phoneValidationSchema,
} from './validationSchema';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  MailOutline,
  MailOutlineOutlined,
  PhoneAndroidOutlined,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { popUp } from '../../../utils';

const initialValue = {
  email: '',
  password: '',
  phone: '',
  otp: '',
  newPassword: '',
  confirmPassword: '',
  isChecked: true,
};
const LoginModal = ({ isModalOpen, toggle, login, visitor }) => {
  const [signWithPhone, setSignWithPhone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isForgetPass, setIsForgetPass] = useState(false);
  const [isOtpFound, setIsOtpFound] = useState(false);
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [value, setValue] = useState('');

  const history = useHistory();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [newVisibility, setNewVisibility] = useState(false);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);

  const [initialValues, setInitialValues] = useState(initialValue);

  const validationSchema = (signWithPhone, value, isForgetPass) => {
    let schema = {
      email: yup.mixed().required('Email is required'),
      password: yup.mixed().required('Password is required'),
    };
    if (signWithPhone && isForgetPass) {
      schema = {
        ...schema,
        email: yup.mixed().nullable(),
        password: yup.mixed().nullable(),
      };
    }
    if (signWithPhone && !value) {
      schema = {
        ...schema,
        email: yup.mixed().nullable(),
        // password: yup.mixed().nullable(),
      };
    } else if (signWithPhone) {
      schema = {
        ...schema,
        email: yup.mixed().nullable(),
      };
    }
    return yup.object().shape(schema);
  };

  //toast notification
  const notify = (message) => {
    popUp(message);
  };

  const submitHandler = async (values) => {
    setIsLoading(true);
    if (isValidOtp) {
      axios
        .post('/api/users/update-password', {
          method: signWithPhone ? 'phone' : 'email',
          identity: signWithPhone ? value?.replace(/\s/g, '') : values?.email,
          password: values.confirmPassword,
        })
        .then((res) => {
          setIsLoading(false);
          // if (res?.status === 200) {
          setIsValidOtp(false);
          setIsOtpFound(false);
          setValue('');
          resetForm();
          setSignWithPhone(false);
          setIsForgetPass(false);
          setIsResetPassword(false);
          setPasswordVisibility(false);
          setNewVisibility(false);
          setConfirmVisibility(false);
        })
        .catch((err) => {
          notify(err.response?.data?.message);
        });
    } else if (signWithPhone && !value) {
      setIsLoading(false);
      handleCustomError('phone', true);
    } else if (isResetPassword) {
      handleCustomError('phone', false);
      axios
        .post(`/api/users/verify-otp-for-password`, {
          identify: signWithPhone ? value?.replace(/\s/g, '') : values?.email,
          code: values.otp,
        })
        .then((res) => {
          setIsValidOtp(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          notify(err.response?.data?.message);
        });
      // api/users/verify-otp-for-password
    } else if (isForgetPass && !signWithPhone) {
      handleCustomError('phone', false);
      await axios
        .post(`/api/users/get-otp-by-email`, { email: values.email })
        .then((res) => {
          setIsOtpFound(res?.status === 200 && true);
          setIsLoading(false);
          setIsResetPassword(true);
        })
        .catch((err) => {
          setIsOtpFound(false);
          setIsLoading(false);
          notify(err.response?.data?.message);
        });
    } else if (isForgetPass && signWithPhone) {
      handleCustomError('phone', false);
      await axios
        .post(`/api/users/get-otp-by-phone`, {
          phone: value?.replace(/\s/g, ''),
        })
        .then((res) => {
          setIsOtpFound(res?.status === 200 && true);
          setIsLoading(false);
          setIsResetPassword(true);
        })
        .catch((err) => {
          setIsOtpFound(false);
          setIsLoading(false);
          notify(err.response?.data?.message);
        });
    } else {
      handleCustomError('phone', false);
      const reqData = {
        method: signWithPhone ? 'phone' : 'email',
        email: signWithPhone ? '' : values.email,
        phone: '+' + value ? value?.replace(/\s/g, '') : '',
        password: values.password,
        checked: values.isChecked,
      };
      await axios
        .post(`/api/users/login`, reqData)
        .then(async (res) => {
          await visitor(0);
          await login({ ...res.data.user, token: res.data.token });
          localStorage.setItem('user', JSON.stringify([...[], values]));
          window.location.reload();
        })
        .catch((err) => {
          setIsLoading(false);
          notify(err.response?.data?.message);
        });
    }
  };

  const onResetSubmit = async (values) => {
    if (isOtpFound && isForgetPass && !signWithPhone) {
      handleCustomError('phone', false);
      await axios
        .post(`/api/users/get-otp-by-email`, { email: values.email })
        .then((res) => {
          setIsOtpFound(res?.status === 200 && true);
          setIsResetPassword(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsOtpFound(false);
          setIsLoading(false);
          notify(err.response?.data?.message);
        });
    } else if (isOtpFound && isForgetPass && signWithPhone) {
      handleCustomError('phone', false);
      await axios
        .post(`/api/users/get-otp-by-phone`, {
          phone: value?.replace(/\s/g, ''),
        })
        .then((res) => {
          setIsOtpFound(res?.status === 200 && true);
          setIsResetPassword(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsOtpFound(false);
          setIsLoading(false);
          notify(err.response?.data?.message);
        });
    }
  };

  //for phone validation formik doesnt support so custom validation
  const [customErrorData, setCustomErrorData] = useState({ phone: false });
  const handleCustomError = (errorFor, status) => {
    setCustomErrorData((prev) => {
      return { ...prev, [errorFor]: status };
    });
  };
  const handleInputChange = (e) => {
    e?.target?.value && setValue(e?.target?.value);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: isValidOtp
      ? phoneValidationSchema()
      : isOtpFound
      ? otpValidationSchema()
      : isForgetPass && !signWithPhone
      ? emailValidationSchema()
      : validationSchema(signWithPhone, value, isForgetPass),
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isEmailPhnCheck, setIsEmailPhnCheck] = useState(false);
  function checkFirstLetterNumber(_string) {
    return /^\d/.test(_string);
  }

  // useEffect(() => {
  //   if(values.email && checkFirstLetterNumber(values.email) || checkFirstLetterNumber(value)){
  //     setSignWithPhone(true);
  //   }
  // },[values.email,value])

  useEffect(() => {
    return () => {
      setSignWithPhone(false);
      setIsLoading(false);
      setIsForgetPass(false);
      setIsOtpFound(false);
      setIsValidOtp(false);
      setIsResetPassword(false);
      setIsEmailPhnCheck(false);
      // Cleanup
    };
  }, [isModalOpen]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={(e) => {
          toggle(e);
          setInitialValues(initialValue);
        }}
        centered
      >
        <ModalBody>
          <div className='mb-3'>
            <button
              type='button'
              className='close mr-2'
              data-dismiss='modal'
              aria-label='Close'
              style={{ outline: 'none' }}
              onClick={toggle}
            >
              <img
                src='/assets/img/close.png'
                alt='close'
                style={{ fontSize: 37, height: 19 }}
              />
            </button>
          </div>
          <div className='p-4'>
            <div className='text-center'>
              <div className='logo logo-top'>
                <img
                  src='/assets/img/logo-icon.png'
                  alt='buyParts'
                  style={{ height: 60 }}
                />
              </div>
              <h4 className='mt-2 mb-3' style={{ fontSize: 26 }}>
                {isValidOtp
                  ? 'Create new password'
                  : isOtpFound
                  ? 'Enter your OTP'
                  : isForgetPass
                  ? 'Reset your password'
                  : 'Welcome to BuyParts24'}
              </h4>
            </div>
            <form onSubmit={handleSubmit}>
              <>
                {isValidOtp ? (
                  <>
                    <div className='form-outline mb-4'>
                      <TextField
                        id='standard-basic'
                        name='newPassword'
                        type={newVisibility ? 'text' : 'password'}
                        fullWidth
                        placeholder='New Password'
                        variant='outlined'
                        hiddenLabel
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position='end'
                              style={{
                                backgroundColor: 'transparent',
                                fontSize: '15px',
                              }}
                            >
                              <IconButton
                                aria-label='toggle password'
                                edge='end'
                                onClick={() => setNewVisibility(!newVisibility)}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {newVisibility ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        value={values.newPassword}
                        onBlur={handleBlur}
                      />
                      <FormikValidationError
                        name='newPassword'
                        touched={touched}
                        error={errors}
                      />
                    </div>
                    <div className='form-outline mb-4'>
                      <TextField
                        id='standard-basic'
                        name='confirmPassword'
                        type={confirmVisibility ? 'text' : 'password'}
                        fullWidth
                        placeholder='Confirm Password'
                        variant='outlined'
                        hiddenLabel
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position='end'
                              style={{
                                backgroundColor: 'transparent',
                                fontSize: '15px',
                              }}
                            >
                              <IconButton
                                aria-label='toggle password'
                                edge='end'
                                onClick={() =>
                                  setConfirmVisibility(!confirmVisibility)
                                }
                                onMouseDown={handleMouseDownPassword}
                              >
                                {confirmVisibility ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      <FormikValidationError
                        name='confirmPassword'
                        touched={touched}
                        error={errors}
                      />
                    </div>
                  </>
                ) : isOtpFound ? (
                  <>
                    <div className='form-outline mb-4'>
                      <TextField
                        id='standard-basic'
                        name='otp'
                        type={'text'}
                        fullWidth
                        placeholder='Enter your OTP here'
                        variant='outlined'
                        hiddenLabel
                        onChange={handleChange}
                        value={values.otp}
                        onBlur={handleBlur}
                        style={{
                          fontSize: '15px',
                        }}
                      />
                      <FormikValidationError
                        name='otp'
                        touched={touched}
                        error={errors}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {!signWithPhone ? (
                      <div className='form-outline mb-3'>
                        <TextField
                          id='standard-basic'
                          name='email'
                          type={'email'}
                          fullWidth
                          placeholder='Email'
                          variant='outlined'
                          hiddenLabel
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                          // autoComplete="@gmail.com"
                          style={{
                            fontSize: '15px',
                          }}
                          // InputProps={{
                          //   startAdornment:!values?.email && (
                          //     <InputAdornment
                          //       position="start"
                          //       style={{
                          //         backgroundColor: "transparent",
                          //         color: "black"
                          //       }}
                          //     >
                          //         @
                          //     </InputAdornment>
                          //   ),
                          // }}
                        />
                        <FormikValidationError
                          name='email'
                          touched={touched}
                          error={errors}
                        />
                      </div>
                    ) : (
                      <div className='form-outline mb-3'>
                        <PhoneInput
                          placeholder='Enter phone number'
                          name='Phone'
                          country={'ae'}
                          value={value}
                          inputStyle={{
                            height: '3.8rem',
                            fontSize: '15px',
                          }}
                          onChange={(phoneNumber, country, e) => {
                            e.preventDefault();
                            handleInputChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {customErrorData?.phone && (
                          <span className='text-danger'>
                            {' '}
                            <span className='ic-error'></span>
                            Phone number is required
                          </span>
                        )}
                      </div>
                    )}
                  </>
                )}

                {!isForgetPass && (
                  <div className='form-outline mb-4'>
                    <TextField
                      id='standard-basic'
                      name='password'
                      type={passwordVisibility ? 'text' : 'password'}
                      fullWidth
                      placeholder='Password'
                      variant='outlined'
                      hiddenLabel
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            style={{
                              backgroundColor: 'transparent',
                              fontSize: '15px',
                            }}
                          >
                            <IconButton
                              aria-label='toggle password'
                              edge='end'
                              onClick={() =>
                                setPasswordVisibility(!passwordVisibility)
                              }
                              onMouseDown={handleMouseDownPassword}
                            >
                              {passwordVisibility ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                      value={values.password}
                      onBlur={handleBlur}
                    />
                    <FormikValidationError
                      name='password'
                      touched={touched}
                      error={errors}
                    />
                  </div>
                )}

                {!isForgetPass && (
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='custom-control custom-checkbox checkbox-outline checkbox-outline-dark'>
                      <input
                        type='checkbox'
                        className='custom-control-input'
                        onChange={handleChange}
                        checked={values?.isChecked}
                        id='isChecked'
                      />
                      <label
                        style={{
                          color: 'black',
                          fontWeight: 600,
                        }}
                        className='custom-control-label'
                        htmlFor='isChecked'
                      >
                        Stay logged in
                      </label>
                    </div>

                    <u>
                      <a
                        href=' '
                        style={{
                          fontSize: '14px',
                          color: 'black',
                          fontWeight: 550,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsForgetPass(true);
                          resetForm();
                        }}
                      >
                        Forgot password?
                      </a>
                    </u>
                  </div>
                )}
              </>

              <button
                type='submit'
                className='btn btn-block submitBtn'
                style={{
                  backgroundColor: '#f39200',
                  color: '#ffff',
                  whiteSpace: 'nowrap',
                  fontSize: '15px',
                }}
                disabled={isLoading ? true : false}
              >
                {isLoading && <i className='las la-spinner la-spin mr-2'></i>}
                {isValidOtp
                  ? 'Reset my password'
                  : isOtpFound
                  ? 'Continue to reset my password'
                  : isForgetPass
                  ? `Get OTP on ${!signWithPhone ? 'email' : 'mobile number'}`
                  : 'Login'}
              </button>

              {isForgetPass && isOtpFound && !isValidOtp && (
                <div class='text-center mt-2'>
                  <p style={{ fontSize: '15px' }}>
                    Haven't received the OTP?
                    <u>
                      <a
                        className=''
                        href='#!'
                        onClick={(e) => {
                          e.preventDefault();
                          onResetSubmit(values);
                        }}
                      >
                        Send OTP again
                      </a>
                    </u>
                  </p>
                </div>
              )}

              {!isOtpFound && (
                <>
                  <div className='divider d-flex align-items-center my-3'>
                    <p
                      style={{ color: 'black', fontWeight: 600 }}
                      className='text-center mx-3 mb-0'
                    >
                      OR
                    </p>
                  </div>

                  <button
                    type='button'
                    className='btn btn-block btn-light align-left'
                    onClick={() => {
                      resetForm();
                      setValue('');
                      setSignWithPhone(!signWithPhone);
                      setIsEmailPhnCheck(!isEmailPhnCheck);
                    }}
                    style={{ fontSize: '15px' }}
                  >
                    <span className='push-right'>
                      {' '}
                      {signWithPhone ? (
                        <img
                          src='/assets/img/email.png'
                          alt='email'
                          style={{ fontSize: 37, height: 25 }}
                        />
                      ) : (
                        <img
                          src='/assets/img/iphone.png'
                          alt='phone'
                          style={{ fontSize: 37, height: 25 }}
                        />
                      )}
                    </span>
                    {isForgetPass
                      ? `Reset using ${!signWithPhone ? 'Mobile' : 'Email'} `
                      : `Login with ${!signWithPhone ? 'Mobile' : 'Email'} `}
                  </button>

                  <div
                    className='text-center mt-3'
                    onClick={() => {
                      history.push('/register');
                    }}
                  >
                    <p>
                      <u>
                        {' '}
                        <NavLink
                          to='/register'
                          className='access-link'
                          onClick={toggle}
                          style={{ fontSize: '15px', fontWeight: 550 }}
                        >
                          Create a new Account
                        </NavLink>
                      </u>
                    </p>
                  </div>
                </>
              )}
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    login: (data) => dispatch(LogInAc(data)),
    visitor: (data) => dispatch(visitor1(data)),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(LoginModal);
