import React from "react";
import Einvoice from "./index";
import "./style.css";

import { useReactToPrint } from "react-to-print";

import { Modal, ModalBody, ModalHeader } from "reactstrap";

const TaxModal = ({
  isEinvoiceModal,
  setIsEinvoiceModal,
  data,
  groupproducts,
  discountPerUnit,
  returnProduct,
  initalDiscountPerUnit

}) => {
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal
      isOpen={isEinvoiceModal}
      toggle={() => setIsEinvoiceModal(false)}
      center
      style={{ maxWidth: "65rem" }}
    >
      <ModalHeader
        toggle={() => setIsEinvoiceModal(false)}
        style={{ width: "100%" }}
      >
        <div
          className="primary-btn"
          style={{ float: "right" }}
          onClick={handlePrint}
        >
          Print
        </div>
      </ModalHeader>
      <ModalBody>
        <div ref={componentRef}>
        
          <Einvoice data={data} groupproducts={groupproducts} discountPerUnit={discountPerUnit} returnProduct={returnProduct} initalDiscountPerUnit={initalDiscountPerUnit}/>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TaxModal;
