import React from "react";

const OnestopContent = () => {
  return (
    <div className="benefits-buyer-content mt-4">
      <ul>
        <li>Wildest range across brands and categories</li>
        <li>Instant parts availability</li>
        <li>Verified and trusted part sellers</li>
        <li>High quality products</li>
        <li>Rating & Reviews</li>
      </ul>
    </div>
  );
};

export default OnestopContent;
