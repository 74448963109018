import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import { Button } from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { popUp } from "../../../../utils";
import Select from "react-select";
import Step2 from "../../../page/Checkout/Step2";

const Index = (props) => {
  const { isOpenAddModal, handleAddFormModal, stateData, locationData, regionData, fetchAddress } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [contact, setContact] = useState("");
  const [emirate, setEmirates] = useState({ label: "", value: "" });
  const [region, setRegion] = useState({ label: "", value: "" });
  const [location, setLocation] = useState({ label: "", value: "" });


  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [, forceUpdate] = useState();



  const handleChangeState = (e) => {
    setEmirates({ label: e.value, value: e.value });
    setRegion({ label: "", value: "" });
    setLocation({ label: "", value: "" });
  };

  const handleChangeRegion = (e) => {
    setRegion({ label: e.value, value: e.value });
    setLocation({ label: "", value: "" });
  };

  const filteredRegions =
    regionData &&
    regionData.length &&
    regionData.filter((item) => {
      return item?.state?.name === emirate.value;
    });

  const stateOption =
    stateData?.state &&
    stateData.state.length &&
    stateData.state.map((state) => {
      return { label: state?.name, value: state?.name };
    });

  const regionOption =
    filteredRegions &&
    filteredRegions.length &&
    filteredRegions.map((region) => {
      return { label: region.name, value: region.name };
    });

  const filteredCities =
    locationData &&
    locationData.length &&
    locationData.filter((item) => {
      return item?.region?.name === region.value;
    });
  const sortedCities =
    filteredCities &&
    filteredCities.length > 0 &&
    filteredCities.sort((a, b) => a.name.localeCompare(b.name));

  const formattedCities =
    sortedCities &&
    sortedCities.length > 0 &&
    sortedCities.map((item) => {
      return { label: item?.name, value: item?.name };
    });
  const validator = React.useRef(
    new SimpleReactValidator({
      className: "text-danger",
      validators: {
        firstName: {
          message: "Name must be greater than 2 characters",
          rule: (val) => {
            return val.length >= 2;
          },
          required: true,
        },
        lastName: {
          message: "Name must be greater than 2 characters",
          rule: (val) => {
            return val.length >= 2;
          },
          required: true,
        },
      },
    })
  );



  const submitAddress = () => {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (!invalidPhoneNumber && validator.current.allValid()) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        state: emirate.value,
        region: region.value,
        location: location.value,
        postcode: postCode,
        contact: contact,
        user: currentUser?._id,
      };

      axios
        .post("/api/address/create-user-address", data)
        .then((res) => {

          popUp("Your Address was added successfully.");
          setFirstName("");
          setLastName("");
          setAddress("");
          setEmirates({ label: "", value: "" });
          setRegion({ label: "", value: "" });
          setLocation({ label: "", value: "" });
          setPostCode("");
          setContact("");
          handleAddFormModal(false);
          fetchAddress()
        })
        .catch((err) => popUp("There was a problem with the server"));
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }

  };


  const handleBlur = async () => {
    const res = await axios.get(
      `http://apilayer.net/api/validate?access_key=${process.env.REACT_APP_NUM_VERIFY_API_KEY}&number=${contact}`
    );
    if (res?.data?.valid === false) {
      setInvalidPhoneNumber(true);
    } else {
      setInvalidPhoneNumber(false);
    }
  };

  useEffect(() => {
    if (!isOpenAddModal) {
      validator.current.hideMessages();
    }
  }, [isOpenAddModal]);

  return (
    <>
      <Modal
        isOpen={isOpenAddModal}
        toggle={() => handleAddFormModal(false)}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalHeader
          style={{ position: "relative", height: 50 }}
          toggle={() => handleAddFormModal(false)}
        >
          <div style={{ position: "absolute", right: 20 }}>Add address</div>
        </ModalHeader>
        <ModalBody style={{ padding: "20px" }}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="firstName">First Name <span style={{ color: 'red' }}>*</span></Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  required={true}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />

                {validator.current.message(
                  "firstName",
                  firstName,
                  "required|firstName"
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="lastName">Last Name <span style={{ color: 'red' }}>*</span></Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
                {validator.current.message(
                  "lastName",
                  lastName,
                  "required|lastName"
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="address">Address <span style={{ color: 'red' }}>*</span></Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
                {validator.current.message("address", address, "required")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="emirate">Emirates <span style={{ color: 'red' }}>*</span></Label>


                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={stateOption ? stateOption : []}
                  value={emirate}
                  onChange={(e) => {
                    handleChangeState(e);
                  }}
                />

                {validator.current.message("emirate", emirate, "required")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="region">Regions <span style={{ color: 'red' }}>*</span></Label>

                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={regionOption ? regionOption : []}
                  value={region}
                  onChange={(e) => {
                    handleChangeRegion(e);
                  }}
                />

                {validator.current.message("region", region, "required")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="location">Location <span style={{ color: 'red' }}>*</span></Label>

                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={formattedCities ? formattedCities : []}
                  value={location}
                  onChange={(e) => {
                    setLocation({ label: e.value, value: e.value });
                  }}
                />
                {validator.current.message("location", location, "required")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="postCode">PO Box <span style={{ color: 'red' }}>*</span></Label>
                <Input
                  type="text"
                  name="postCode"
                  id="postCode"
                  placeholder="Post Code"
                  value={postCode}
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                />
                {validator.current.message("postCode", postCode, "alpha_num_space")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="contact">Contact No <span style={{ color: 'red' }}>*</span></Label>
                <PhoneInput
                  name="contact"
                  country={'ae'}
                  onlyCountries={['ae']}
                  value={contact}
                  placeholder="+971 123456789"
                  onBlur={handleBlur}
                  onChange={(contact) => setContact(contact)}
                />

                {validator.current.message("contact", contact, "required")}
                {invalidPhoneNumber && (
                  <div className="text-danger">Invalid Contact Number.</div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => handleAddFormModal(false)}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={submitAddress}>
            Save
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Index;
