import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { PageBanner } from "../content/element/page-banner";
import PreHeader from "../layout/pre-header";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Row, Col } from "reactstrap";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Badge } from "reactstrap";

import ReactStars from "react-rating-stars-component";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

const YourOrderSeller = ({ history }) => {
  const [modal, setModal] = useState(false);
  const [modalOrderDetails, setModalOrderDetails] = useState(false);
  const [modalSellerContact, setModalSellerContact] = useState(false);
  const [amount, setAmount] = useState(35);
  const [activeTabDate, setActiveTabDate] = useState("1");

  const [sellerOrderArr, setSellerOrderArr] = useState([
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Pending",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Delivered",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Issue",
    },

    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Issue",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Issue",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Cancelled",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Preparing",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Preparing",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Preparing",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "Preparing",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "In Transit",
    },
    {
      id: "2343242",
      orderBy: "Mark",
      pickupDelivery: "Delivery",
      collectionShippingDetails: "11 Bond St, Sydney NSW 2000	",
      orderDate: "21/02/2021	",
      item: "/assets/img/stepperimg/gilan.png",
      cost: "AED10.99",
      status: "In Transit",
    },
  ]);

  const [pendingOrderArr, setPendingOrderArr] = useState([]);
  const [pendingOrderArrLength, setPendingOrderArrLength] = useState("");
  const [preparingOrderArr, setPreparingOrderArr] = useState([]);
  const [preparingOrderArrLength, setPreparingOrderArrLength] = useState("");
  const [inProgressOrderArr, setInProgressOrderArr] = useState([]);
  const [inProgressOrderArrLength, setInProgressOrderArrLength] = useState("");
  const [deliveredOrderArr, setDeliveredOrderArr] = useState([]);
  const [deliveredOrderArrLength, setDeliveredOrderArrLength] = useState("");
  const [cancelledOrderArr, setCancelledOrderArr] = useState([]);
  const [cancelledOrderArrLength, setCancelledOrderArrLength] = useState("");
  const [issueOrderArr, setIssueOrderArr] = useState([]);
  const [issueOrderArrLength, setIssueOrderArrLength] = useState("");

  useEffect(() => {
    let pendingArr = sellerOrderArr.filter((el) => el.status == "Pending");
    setPendingOrderArr(pendingArr);
    setPendingOrderArrLength(pendingArr.length);

    let preparingArr = sellerOrderArr.filter((el) => el.status == "Preparing");
    setPreparingOrderArr(preparingArr);
    setPreparingOrderArrLength(preparingArr.length);

    let inTransitArr = sellerOrderArr.filter((el) => el.status == "In Transit");
    setInProgressOrderArr(inTransitArr);
    setInProgressOrderArrLength(inTransitArr.length);

    let deliveredArr = sellerOrderArr.filter((el) => el.status == "Delivered");
    setDeliveredOrderArr(deliveredArr);
    setDeliveredOrderArrLength(deliveredArr.length);

    let cancelledArr = sellerOrderArr.filter((el) => el.status == "Cancelled");
    setCancelledOrderArr(cancelledArr);
    setCancelledOrderArrLength(cancelledArr.length);

    let issueArr = sellerOrderArr.filter((el) => el.status == "Issue");
    setIssueOrderArr(issueArr);
    setIssueOrderArrLength(issueArr.length);
  }, []);

  const toggleDate = (tab) => {
    if (activeTabDate !== tab) setActiveTabDate(tab);
  };

  const allOrders = () => {
    return (
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Order By</th>
              <th>Pick up Delivery</th>
              <th>Collection or Shipping Details</th>
              <th>Order Date</th>
              <th>Item</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sellerOrderArr.map((item, k) => {
              return (
                <tr key={k}>
                  <th scope="row">{item.id}</th>
                  <td>{item.orderBy}</td>
                  <td>{item.pickupDelivery}</td>
                  <td>{item.collectionShippingDetails}</td>
                  <td>{item.orderDate}</td>
                  <td>
                    <img
                      src={item.item || "/assets/img/product-default.jpg"}
                      alt="item"
                      style={{ width: "50px" }}
                    />
                  </td>
                  <td>{item.cost}</td>
                  <td>
                    <Badge
                      color={
                        item.status == "Pending"
                          ? "warning"
                          : item.status == "Delivered"
                            ? "success"
                            : item.status == "Issue"
                              ? "dark"
                              : item.status == "Cancelled"
                                ? "danger"
                                : item.status == "Preparing"
                                  ? "info"
                                  : "primary"
                      }
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    <Button color="light" size="sm">
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    );
  };
  const pendingOrder = () => {
    return (
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Order By</th>
              <th>Pick up Delivery</th>
              <th>Collection or Shipping Details</th>
              <th>Order Date</th>
              <th>Item</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {pendingOrderArr.map((item, k) => {
              return (
                <tr key={k}>
                  <th scope="row">{item.id}</th>
                  <td>{item.orderBy}</td>
                  <td>{item.pickupDelivery}</td>
                  <td>{item.collectionShippingDetails}</td>
                  <td>{item.orderDate}</td>
                  <td>
                    <img
                      src={item.item || "/assets/img/product-default.jpg"}
                      alt="item"
                      style={{ width: "50px" }}
                    />
                  </td>
                  <td>{item.cost}</td>
                  <td>
                    <Badge
                      color={
                        item.status == "Pending"
                          ? "warning"
                          : item.status == "Delivered"
                            ? "success"
                            : item.status == "Issue"
                              ? "dark"
                              : item.status == "Cancelled"
                                ? "danger"
                                : item.status == "Preparing"
                                  ? "info"
                                  : "primary"
                      }
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    <Button color="light" size="sm">
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}{" "}
          </tbody>
        </Table>
      </Row>
    );
  };
  const preparingOrder = () => {
    return (
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Order By</th>
              <th>Pick up Delivery</th>
              <th>Collection or Shipping Details</th>
              <th>Order Date</th>
              <th>Item</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {preparingOrderArr.map((item, k) => {
              return (
                <tr key={k}>
                  <th scope="row">{item.id}</th>
                  <td>{item.orderBy}</td>
                  <td>{item.pickupDelivery}</td>
                  <td>{item.collectionShippingDetails}</td>
                  <td>{item.orderDate}</td>
                  <td>
                    <img
                      src={item.item || "/assets/img/product-default.jpg"}
                      alt="item"
                      style={{ width: "50px" }}
                    />
                  </td>
                  <td>{item.cost}</td>
                  <td>
                    <Badge
                      color={
                        item.status == "Pending"
                          ? "warning"
                          : item.status == "Delivered"
                            ? "success"
                            : item.status == "Issue"
                              ? "dark"
                              : item.status == "Cancelled"
                                ? "danger"
                                : item.status == "Preparing"
                                  ? "info"
                                  : "primary"
                      }
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    <Button color="light" size="sm">
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}{" "}
          </tbody>
        </Table>
      </Row>
    );
  };
  const inProgressOrders = () => {
    return (
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Order By</th>
              <th>Pick up Delivery</th>
              <th>Collection or Shipping Details</th>
              <th>Order Date</th>
              <th>Item</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {inProgressOrderArr.map((item, k) => {
              return (
                <tr key={k}>
                  <th scope="row">{item.id}</th>
                  <td>{item.orderBy}</td>
                  <td>{item.pickupDelivery}</td>
                  <td>{item.collectionShippingDetails}</td>
                  <td>{item.orderDate}</td>
                  <td>
                    <img
                      src={item.item || "/assets/img/product-default.jpg"}
                      alt="item"
                      style={{ width: "50px" }}
                    />
                  </td>
                  <td>{item.cost}</td>
                  <td>
                    <Badge
                      color={
                        item.status == "Pending"
                          ? "warning"
                          : item.status == "Delivered"
                            ? "success"
                            : item.status == "Issue"
                              ? "dark"
                              : item.status == "Cancelled"
                                ? "danger"
                                : item.status == "Preparing"
                                  ? "info"
                                  : "primary"
                      }
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    <Button color="light" size="sm">
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}{" "}
          </tbody>
        </Table>
      </Row>
    );
  };
  const deliveredOrders = () => {
    return (
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Order By</th>
              <th>Pick up Delivery</th>
              <th>Collection or Shipping Details</th>
              <th>Order Date</th>
              <th>Item</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {deliveredOrderArr.map((item, k) => {
              return (
                <tr key={k}>
                  <th scope="row">{item.id}</th>
                  <td>{item.orderBy}</td>
                  <td>{item.pickupDelivery}</td>
                  <td>{item.collectionShippingDetails}</td>
                  <td>{item.orderDate}</td>
                  <td>
                    <img
                      src={item.item || "/assets/img/product-default.jpg"}
                      alt="item"
                      style={{ width: "50px" }}
                    />
                  </td>
                  <td>{item.cost}</td>
                  <td>
                    <Badge
                      color={
                        item.status == "Pending"
                          ? "warning"
                          : item.status == "Delivered"
                            ? "success"
                            : item.status == "Issue"
                              ? "dark"
                              : item.status == "Cancelled"
                                ? "danger"
                                : item.status == "Preparing"
                                  ? "info"
                                  : "primary"
                      }
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    <Button color="light" size="sm">
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}{" "}
          </tbody>
        </Table>
      </Row>
    );
  };

  const cancelledOrders = () => {
    return (
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Order By</th>
              <th>Pick up Delivery</th>
              <th>Collection or Shipping Details</th>
              <th>Order Date</th>
              <th>Item</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {cancelledOrderArr.map((item, k) => {
              return (
                <tr key={k}>
                  <th scope="row">{item.id}</th>
                  <td>{item.orderBy}</td>
                  <td>{item.pickupDelivery}</td>
                  <td>{item.collectionShippingDetails}</td>
                  <td>{item.orderDate}</td>
                  <td>
                    <img
                      src={item.item || "/assets/img/product-default.jpg"}
                      alt="item"
                      style={{ width: "50px" }}
                    />
                  </td>
                  <td>{item.cost}</td>
                  <td>
                    <Badge
                      color={
                        item.status == "Pending"
                          ? "warning"
                          : item.status == "Delivered"
                            ? "success"
                            : item.status == "Issue"
                              ? "dark"
                              : item.status == "Cancelled"
                                ? "danger"
                                : item.status == "Preparing"
                                  ? "info"
                                  : "primary"
                      }
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    <Button color="light" size="sm">
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}{" "}
          </tbody>
        </Table>
      </Row>
    );
  };
  const issueOrder = () => {
    return (
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Order By</th>
              <th>Pick up Delivery</th>
              <th>Collection or Shipping Details</th>
              <th>Order Date</th>
              <th>Item</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {issueOrderArr.map((item, k) => {
              return (
                <tr key={k}>
                  <th scope="row">{item.id}</th>
                  <td>{item.orderBy}</td>
                  <td>{item.pickupDelivery}</td>
                  <td>{item.collectionShippingDetails}</td>
                  <td>{item.orderDate}</td>
                  <td>
                    <img
                      src={item.item || "/assets/img/product-default.jpg"}
                      alt="item"
                      style={{ width: "50px" }}
                    />
                  </td>
                  <td>{item.cost}</td>
                  <td>
                    <Badge
                      color={
                        item.status == "Pending"
                          ? "warning"
                          : item.status == "Delivered"
                            ? "success"
                            : item.status == "Issue"
                              ? "dark"
                              : item.status == "Cancelled"
                                ? "danger"
                                : item.status == "Preparing"
                                  ? "info"
                                  : "primary"
                      }
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    <Button color="light" size="sm">
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}{" "}
          </tbody>
        </Table>
      </Row>
    );
  };

  return (
    <Fragment>
      {/* Header section start */}


      {/* Header section end */}
      <section className="blog-area blog-grid section-padding-strict section-bg">
        <div className="container-fluid section-padding-all-listing">
          <div style={{ visibility: "hidden" }}></div>

          <Typography className="" style={{ textAlign: "left" }}>
            <h2>Your Orders</h2>
            <br />
            <h6>You have 1 pending order</h6>
          </Typography>
          <div>
            <Card style={{ padding: "20px" }}>
              <Row>
                <Col>
                  <img
                    src="/assets/img/stepperimg/gilan.png"
                    alt="item"
                    style={{ width: "70px" }}
                  />
                </Col>
                <Col sm="3">
                  <Typography>
                    <p>Mary Rock</p>
                    <p>11 Bond St, Sydney NSW 2000 </p>
                    <p>21/02/2021</p>
                  </Typography>
                </Col>
                <Col>
                  <Typography>AED10.99</Typography>
                </Col>
                <Col sm="6"></Col>
              </Row>
            </Card>
            <div>
              <Button size="sm" color="light">
                View Order{" "}
              </Button>
              <Button size="sm" color="primary">
                {" "}
                Mark as Delivered
              </Button>
            </div>
          </div>
          <br />
          <br />
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "1",
                })}
                onClick={() => {
                  toggleDate("1");
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "2",
                })}
                onClick={() => {
                  toggleDate("2");
                }}
              >
                Pending
                <Badge
                  style={{ marginLeft: "5px", borderRadius: "50%" }}
                  color="secondary"
                >
                  {pendingOrderArrLength}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "3",
                })}
                onClick={() => {
                  toggleDate("3");
                }}
              >
                Preparing
                <Badge
                  style={{ marginLeft: "5px", borderRadius: "50%" }}
                  color="secondary"
                >
                  {preparingOrderArrLength}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "4",
                })}
                onClick={() => {
                  toggleDate("4");
                }}
              >
                In Transit
                <Badge
                  style={{ marginLeft: "5px", borderRadius: "50%" }}
                  color="secondary"
                >
                  {inProgressOrderArrLength}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "5",
                })}
                onClick={() => {
                  toggleDate("5");
                }}
              >
                Delivered
                <Badge
                  style={{ marginLeft: "5px", borderRadius: "50%" }}
                  color="secondary"
                >
                  {deliveredOrderArrLength}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "6",
                })}
                onClick={() => {
                  toggleDate("6");
                }}
              >
                Cancelled
                <Badge
                  style={{ marginLeft: "5px", borderRadius: "50%" }}
                  color="secondary"
                >
                  {cancelledOrderArrLength}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "7",
                })}
                onClick={() => {
                  toggleDate("7");
                }}
              >
                Issue
                <Badge
                  style={{ marginLeft: "5px", borderRadius: "50%" }}
                  color="secondary"
                >
                  {issueOrderArrLength}
                </Badge>
              </NavLink>
            </NavItem>
          </Nav>
          <br />
          <br />

          <TabContent activeTab={activeTabDate}>
            <TabPane tabId="1">{allOrders()}</TabPane>
            <TabPane tabId="2">{pendingOrder()}</TabPane>
            <TabPane tabId="3">{preparingOrder()}</TabPane>
            <TabPane tabId="4">{inProgressOrders()}</TabPane>
            <TabPane tabId="5">{deliveredOrders()}</TabPane>
            <TabPane tabId="6">{cancelledOrders()}</TabPane>
            <TabPane tabId="7">{issueOrder()}</TabPane>
          </TabContent>
        </div>
      </section>

    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps)(YourOrderSeller);
