import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import { PageBanner } from "../content/element/page-banner";
import Footer from "../layout/footer";
import PageTitle from "../content/element/PageTitle";

import BuyerProfile from "../content/element/my-profile/buyer-profile";
import SellerProfile from "../content/element/my-profile/seller-profile";
import Pending from "../content/element/UserStatus/Pending";

class AuthProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    console.log(this.props.login, "this is login");

    if (currentUser && currentUser.status === "Pending") return <Pending />;
    return (
      <Fragment>

        <PageBanner title="My Profile" />
        {/* <PageBanner /> */}
        <section className="author-info-area section-padding-strict section-bg pb-5 pt-2">
          <div className="container">
            <PageTitle title="My Profile" />

            {this.props.login.role === "BUYER" ? (
              <BuyerProfile />
            ) : (this.props.login.role === "SELLER" && <SellerProfile />
            )}
          </div>
        </section>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProp)(AuthProfile);
