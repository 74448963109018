import React from "react";

const PaymentContent = () => {
  return (
    <div className="benefits-buyer-content mt-4">
      <ul>
        <li>Price competitiveness</li>
        <li>Multiple payment options</li>
        <li>Improved margins</li>
        <li>Single invoicing across various vendors</li>
        <li>Price comparison feature</li>
      </ul>
    </div>
  );
};

export default PaymentContent;
