import React from "react";

const BenefitsCard = ({ title, handleCardClick, active, imgSrc }) => {
  return (
    <>
      <div
        className="d-flex m-3 flex-column align-items-center"
        style={{ position: "relative", cursor: "pointer" }}
      >
        <div className="benefits-card" onClick={() => handleCardClick(title)}>
          <img src={imgSrc} alt="benefits_icon" style={{ width: "100%" }} />
        </div>
        <div className="mt-4 ">
          <h3 className="benefits-title section-inner-title">{title}</h3>
        </div>
        {active == title && <div className="triangle"></div>}
      </div>
    </>
  );
};

export default BenefitsCard;
