import React from "react";

const Access = ({ active, setActive }) => {
  return (
    <div className="benefits-seller-container">
      <div
        className="benefits-seller-image"
        onClick={() => setActive("access")}
      >
        <img src="/assets/img/become-seller/access.png" alt="buyparts-access" />
      </div>

      <div className="benefits-seller-text">Access</div>
      {active === "access" && <div className="benefits-seller-triangle" />}
    </div>
  );
};

export default Access;
