import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import SearchImage from "./modal/search-image";


const Search = (props) => {
  const { t } = props;
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false)
  const history = useHistory();
  function handleChange(e) {
    e.preventDefault();
    setSearch(e.target.value);
  }
  function handleSubmit(e) {
    e.preventDefault();
    history.push("/parts-search-vin?vin=" + search);
  }

  const uploadFile = (e) => {
    setShowModal(true)
  }
  const onClose = () => {
    setShowModal(false)
  }
  return (
    <Fragment>
    <SearchImage showModal={showModal} onClose={onClose}/>    
      <div className="directory_content_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12  d-lg-block">
              <div className="search-spare-part">
                <form onSubmit={handleSubmit} className="search_form search_img">
                  <div className="atbd_seach_button_wrapper_start">
                    <button
                      type="button"
                      onClick={uploadFile}
                      className="btn btn-md btn-primary btn_search d-flex p-2"
                      style={{
                        backgroundColor: " #F39200",
                        borderColor: "#F39200",
                      }}
                    >
                      <i className="las la-camera" />
                    </button>
                  </div>

                  <input
                    type="text"
                    value={search || ""}
                    name="vin"
                    onChange={handleChange}
                    placeholder={t("Find parts by VIN")}
                  />

                  <div className="atbd_seach_button_wrapper">
                    <button
                      type="submit"
                      className="btn btn-md btn-primary btn_search d-flex p-2"
                      style={{
                        backgroundColor: " #F39200",
                        borderColor: "#F39200",
                      }}
                    >
                      <i className="las la-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* <div className="col only-mobile">
              <div className="mobile-search">
                <form>
                  <div className="input-search-mobile">
                    <input
                      type="text"
                      value={search || ""}
                      name="vin"
                      onChange={handleChange}
                      placeholder={t("Find parts by VIN")}
                      className="mobile-search-by-vin-input"
                    />
                    <button type="submit">
                      {" "}
                      <SearchIcon style={{ fontSize: 24, marginTop: 0 }} />
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(Search);
