import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "45ch",
        },
        "& .MuiFormLabel-root": {
            color: "#f3932c",
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: "#f3932c",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f3932c",
        },
        "& .messageField": {
            position: "absolute",
            right: "0px",
            display: "flex",
            alignItems: "center",
        },
    },
    alert: {
        width: "100%",
        marginBottom: "4px",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
        "& .MuiAlert-message": {
            padding: "7px 0",
        },
        "& .MuiAlert-root": {
            alignItems:'center',
        },
        "& .MuiAlert-icon": {
            padding: 0,
        },
    },
    loader: {
        height: "90vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));

export default useStyles