import React, { Component } from "react";
import { WebSocketContext } from "../../../WebSocket";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18next from "i18next";
import SmartBanner from 'react-smartbanner';
import './smartbanner.css';

import {
  setCurrencyType,
  setCurrencyRate,
} from "../../../Store/action/currencyAction";
import { Link } from "react-router-dom";
import NotificationModal from "../../content/element/modal/NotificationModal";


class PreHeader extends Component {
  static contextType = WebSocketContext;
  constructor(props) {
    super(props);
    this.state = { conversionRate: "", loading: false };
    this.changeLang = this.changeLang.bind(this);
  }

  changeLang(e, lang) {
    e.preventDefault();
    localStorage.setItem("lang", lang);
    i18next.changeLanguage(lang);
  }

  async componentDidUpdate(prevState, prevProps) {
    const { currency } = this.props.currency;
    if (currency !== prevState?.currency?.currency) {
      this.setState({ loading: true });
      await fetch(
        `https://v6.exchangerate-api.com/v6/90eccd4a9aa0f4cd2409095c/pair/AED/${currency}`
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({ conversionRate: data?.conversion_rate });
          this.props.setCurrencyRate(data?.conversion_rate);
        });
      this.setState({ loading: false });
    }
  }

  render() {
    const { t } = this.props;
    const currentUser = JSON.parse(localStorage.getItem("login"));

    return (
      <>
        <SmartBanner title={'Buyparts24'} daysHidden={0} daysReminder={0} button="Use App" />
        <section className="pre-header-wrapper justify-content-between">
          <div className="header-left  d-md-block">
            <span>{t("top_bar_text")}</span>
          </div>
          <div className="header-right">
            <div className="row">
              <div className="col-lg-12">
                <nav className="nav nav-mobile">
                  <Link to="/about-us">About Us</Link>
                  {currentUser &&
                    currentUser.role === "BUYER" ? null : (
                    <Link to="/become-buyer">Become a Buyer</Link>
                  )}

                  {currentUser &&
                    currentUser.role === "SELLER" ? null : (
                    <Link to="/become-seller">Become a Seller</Link>
                  )}
                  <Link to="/stores">Sellers</Link>
                  <Link to="/faqs">FAQs</Link>
                  {/* <div
                  style={{ color: "#fff" }}
                  className="mobile-notification-view"
                  hodden
                >
                  <NotificationModal />
                </div> */}
                </nav>
              </div>
            </div>
          </div>

        </section>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currency: state.currency,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrencyType: (price) => dispatch(setCurrencyType(price)),
    setCurrencyRate: (rate) => dispatch(setCurrencyRate(rate)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PreHeader));
