import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export default function vehicleLinkageModal({
    showModal,
    onClose,
    vehicleLinkages,
}) {
    const handleClose = () => {
        onClose();
    };

    const formatYear = (year) => {
        if (year) {
            let y = String(year);
            let _year = y?.substring(0, 4);
            let _month = y?.substring(4, 6);
            return `${_month}.${_year}`;
        } else {
            return "";
        }
    };

    return (
        <div>
            <Dialog
                open={showModal}
                fullWidth={true}
                maxWidth="md"
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    style={{ position: "relative" }}
                >
                    <div
                        onClick={handleClose}
                        style={{
                            position: "absolute",
                            left: 15,
                            top: 0,
                            fontSize: 22,
                            cursor: "pointer",
                        }}
                    >
                        x
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className="atbd_content_module atbd_listing_features">
                            <div className="atbdb_content_module_contents">
                                <ul className="atbd_custom_fields features-table">
                                    <li>
                                        <div className="atbd_custom_field_title table-col-header">
                                            <p>Vehicle</p>
                                        </div>

                                        <div className="atbd_custom_field_content table-content-header">
                                            Model Year
                                        </div>
                                    </li>
                                    {vehicleLinkages.map((m, i) => (
                                        <li>
                                            <div
                                                key={i}
                                                className="atbd_custom_field_title table-col"
                                                // style={{ width: "60%" }}
                                            >
                                                <p>
                                                    {m?.modelDesc} {m?.carDesc}{" "}
                                                </p>
                                            </div>
                                            <div className="atbd_custom_field_content table-content">
                                                <p>
                                                    {formatYear(
                                                        m?.yearOfConstructionFrom
                                                    )}
                                                    -
                                                    {formatYear(
                                                        m?.yearOfConstructionTo
                                                    )}
                                                </p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{
                        paddingRight: "5.6%",
                        paddingLeft: "20%",
                        marginBottom: 10,
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            color: "#fff",
                            backgroundColor: "#1d71b8",
                            width: 150,
                        }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
