import React from "react";
import Header from "../../layout/header";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import { Link } from "react-router-dom";
import "./terms.css";

const TermsAndConditions = () => {
  return (
    <>

      <div
        className="breadcrumb-wrapper cover innerpage-banner"
        style={{ backgroundImage: 'url("/assets/img/section-banner.jpg")' }}
      >
        <div className="container">
          <h1>Terms and conditions</h1>
        </div>
      </div>
      <div
        className="content-wrapper privacy-policy-wrapper"
        style={{ paddingBottom: 0 }}
      >
        <div className="container" style={{ marginTop: 50 }}>
          {/* <div className="termsandconditions-content">
            <h3>WHO WE ARE</h3>
            <p style={{ textAlign: "justify" }}>
              The website{" "}
              <Link to="https://www.buyparts24.com/">
                https://www.buyparts24.com/
              </Link>{" "}
              is operated by <strong>GA AUTO PLATFORMS DMCC</strong>, with
              e-market place service licence No. DMCC-808255 and general trading
              licence number DMCC-808254 whose registered office address is Unit
              No 2182 Business Centre, Level No1- Jewellery & Gemplex 3- Dubai,
              United Arab Emirates, of PO Box 294377 (
              <strong>“we”, “our” or “us”</strong>) so that we may: (1) help
              direct you to products and services which may be of interest to
              you; and (2) contact you with information and offers, suggestions
              and recommendations of products and services which may be of
              interest to you.{" "}
            </p>
            <p style={{ textAlign: "justify" }}>
              Where we use “our site” in this privacy notice, we mean{" "}
              <Link to="https://www.buyparts24.com/">
                https://www.buyparts24.com/
              </Link>
            </p>
          </div> */}

          <ul
            className="termsandconditions-item-wrapper"
            style={{ padding: 0 }}
          >
            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>1</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Background
                  </h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  <Link to="https://www.buyparts24.com/">
                    https://www.buyparts24.com/
                  </Link>{" "} (the Platform) is made available to you by GA AUTO PLATFORMS DMCC, with e-market place service licence No. DMCC-808255 and general trading licence number  DMCC-808254 whose registered office address is Unit No 2182, DMCC Business Centre, Level No1- Jewellery & Gemplex 3- Dubai, United Arab Emirates, of PO Box 294377 (referred to in these terms and conditions (Terms and Conditions) as 'we', 'us' and 'our').
                </p>
                <p style={{ textAlign: "justify" }}>
                  When we refer to 'you' and 'your', we mean where the context indicates, a buyer or a seller or anyone who uses and accesses the Platform.
                </p>
                <p style={{ textAlign: "justify" }}>
                  These Terms and Conditions, and all other terms and conditions and documents referred to herein govern the conditions on which you are entitled to access and use the Platform. Please take the time to read them carefully.
                  By accessing and continuing to use the Platform, you will be deemed to agree to these Terms and Conditions and shall be bound by them. If you do not agree to these Terms & Conditions, then you must stop using or accessing the Platform immediately.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We reserve the right to modify or amend these Terms and Conditions from time to time. You should check these Terms and Conditions each time you access the Platform to ensure that you are aware of and are complying with the current version. Changes are binding on users of the Platform and will take effect immediately from posting of the revised documentation on the Platform.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>2</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Privacy and Data Protection
                  </h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  We take your privacy seriously. To find out more about how we handle your personal information please see our Privacy Policy (Privacy Policy). By accessing and/or using the Platform, you are agreeing that we can process your information in the ways set out in our Privacy Policy.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>3</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    The Platform
                  </h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  The Platform is an e-commerce marketplace that allows sellers (Sellers) to offer and sell their products, and buyers (Buyers) to browse and buy such products.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We reserve the right to modify or discontinue the Platform at
                  any time and without notice to you.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Furthermore, you accept that Platform unavailability may occur
                  in order to allow for improvements, scheduled maintenance or
                  may also be due to outside factors beyond our control.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>4</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    {" "}
                    Account Registration and Use
                  </h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  <p>In order to access and use the Platform, you must:</p>
                  <ul>
                    <li>be a legal entity duly registered in the UAE;</li>
                    <li>
                      if you are the Seller, then to have a current licence
                      permitting you to sell products onshore in the United Arab
                      Emirates;
                    </li>
                    <li>
                      provide proof of authorisation and ID (including Emirates
                      ID and visa) for the individual who will be registering
                      and using the Platform;
                    </li>
                    <li>
                      provide any other information reasonably requested from us
                      as part of your verification process; and have a user
                      account <strong>(Account)</strong>; or
                    </li>
                    <li>
                      if you are the supplier of the goods based on any type of
                      commercial engagement with us (the “Engagement”), and we
                      identified that you may not require to have an Account,
                      then, based on our Engagement with you, you will be
                      required to be in compliance with these Terms and
                      Conditions forming an integral part of our Engagement with
                      you.{" "}
                    </li>
                  </ul>
                </p>
                <p>
                  You can set-up your Account by following the process notified
                  by us from time to time. You acknowledge that you will not be
                  able to fully use the Platform until your Account has been
                  activated and your complete details have been uploaded. We
                  have the right to reject the creation of an Account for any
                  reason.
                </p>
                <p>
                  You will be solely responsible for maintaining the
                  confidentiality and security of your Account details. You may
                  not share, assign, or permit the use of your Account or
                  password with another person. You agree to notify us
                  immediately if you become aware of any unauthorized use of
                  your Account or password.
                </p>
                <p>
                  You are responsible for all use and all activities that occur
                  under your Account(s) and will be considered authorised by
                  you, and that you solely bear the responsibility for the
                  consequences of the misuse of your Account.
                </p>
                <p>
                  We may limit, suspend or withdraw your Account at any time,
                  without notice, for breach of these Terms, or for any reason
                  in our sole discretion.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>5</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    User Generated Content
                  </h3>
                </div>
                <p>
                  By posting or displaying any goods, photos, brands info, Electronic Parts Catalog (EPC), trademarks, logos, other information, reviews or other content on the Platform (collectively the (Content)) whether such posting is made directly by you as a Seller or through us based on our Engagement with you, you hereby grant an irrevocable, perpetual, worldwide, royalty-free, and sub-licensable license to us to use the Content in any form, media, or technology in any manner and for any purpose to market and sell the goods on the Platform and/or to market the goods on our other related social media channels/apps/platforms (“Our Social Media”). To that effect, when we refer to posting the Content on the Platform, we also mean, where the context indicates, posting the Content on Our Social Media supporting marketing the Content and the Platform.
                </p>
                <p>
                  When you post comments or reviews on the Platform, you also
                  grant us the right to use your username, in connection with
                  such review, comment, or other content.
                </p>
                <p>
                  You shall not use or provide any false information to us,
                  pretend to be someone other than yourself or otherwise mislead
                  us or third parties in any Content.
                </p>
                <p>
                  Content on the Platform is generated by the relevant user and
                  may not reflect our opinions. We may, but shall not be
                  obligated to, remove or edit any Content provided by you.
                </p>
                <p>
                  You will not attempt to hold us liable for any inaccuracies or
                  defects in any Content. As a Seller, it is your responsibility
                  to review the Content of the goods listings for accuracy and
                  you will not attempt to hold us responsible for inaccuracies
                  (even if we are the party uploaded the Content on the Platform
                  as long as such Content is provided based on our Engagement
                  with you).
                </p>
                <p>
                  You represent, warrant and agree that any Content submitted,
                  posted or displayed on the Platform:
                </p>
                <ul>
                  <li>
                    does not otherwise violate any of the copyright, patent,
                    trademark, trade name, trade secrets or any rights of any
                    third party;
                  </li>
                  <li>
                    is true, accurate, complete and complies with all relevant
                    laws;
                  </li>
                  <li>
                    <>does not contain:</>
                    <ul>
                      <li>
                        information that is discriminatory or promotes
                        discrimination;
                      </li>
                      <li>
                        information that is defamatory, threatening, harassing,
                        obscene, explicit, offensive, or otherwise
                        objectionable, or that may be considered culturally or
                        religiously offensive in any way;
                      </li>
                      <li>
                        anything which may not be considered to be in compliance
                        with general local law, Islamic law, rules, morals,
                        values, ethics and traditions;
                      </li>
                      <li>information that may threaten national security;</li>
                      <li>
                        content or items which may constitute or be considered
                        to promote gambling;
                      </li>
                      <li>
                        references to financial instruments of any description;
                      </li>
                      <li>any weapons of any description;</li>
                      <li>
                        any liquor, tobacco products, drugs, psychotropic
                        substances, narcotics, intoxicants of any description
                        and medicines; or
                      </li>
                      <li>
                        any items that are defective, fake, damaged, false or
                        misleading.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>6</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Your Responsibilities
                  </h3>
                </div>
                <p>
                  <>You represent, warrant and agree that:</>
                  <ul>
                    <li>
                      you have full power and authority to enter into these
                      Terms and Conditions, and have full power to execute and
                      perform your obligations under these Terms and Conditions;
                    </li>
                    <li>
                      you shall fully comply and will at all times continue to
                      fully comply with all applicable laws, statutes and
                      regulations;
                    </li>
                    <li>
                      If you create or use an Account on behalf of a business
                      entity, you represent that you are authorised to act on
                      behalf of such business and bind the business to these
                      Terms and Conditions. Such Account is deemed to be owned
                      and controlled by the business entity;
                    </li>
                    <li>
                      you own or have the authority to grant the rights and
                      licenses granted to us by you under these Terms and
                      Conditions;
                    </li>
                    <li>
                      any content submitted as part of either your use of the
                      Platform or as per your supply of the goods to us and any
                      goods/products that is listed do not violate the rights of
                      any third party anywhere in the world including, without
                      limitation, any intellectual property rights (whether
                      registered or not);
                    </li>
                    <li>
                      the documents and information submitted during the Account
                      registration process or in relation to the supply of the
                      goods, are true, accurate, current and complete, and you
                      will maintain and update all information as applicable;
                    </li>
                    <li>
                      you are solely responsible for obtaining all required
                      licenses, permits and certifications for the Content that
                      you submit, post or display on the Platform and/or shared
                      with us based on our Engagement with you to be submitted,
                      posted or displayed on the Platform;
                    </li>
                    <li>
                      If you are a Seller, you have the right and authority to
                      sell, trade, distribute or export the goods listed on the
                      Platform (including a valid and subsisting trade licence
                      allowing the sale of such goods onshore in the United Arab
                      Emirates) and the listing of these goods does not violate
                      any third-party rights (for example, exclusive agency or
                      distribution agreements);
                    </li>
                    <li>
                      neither you, your company, its directors, officers nor
                      affiliates are the subject of any trade restrictions,
                      sanctions or other legal restrictions of any country,
                      international organization or other jurisdiction;
                    </li>
                  </ul>
                  <p>
                    Furthermore you represent, warrant and agree that you will
                    not:
                  </p>
                  <ul>
                    <li>use the Platform to defraud any person or entity;</li>
                    <li>
                      impersonate any person or entity, misrepresent yourself or
                      your affiliation with any person or entity;
                    </li>
                    <li>
                      manipulate feedback through multiple user accounts or
                      third parties by leaving positive feedback for yourself or
                      unjustified negative feedback for another user;
                    </li>
                    <li>
                      copy, reproduce, download, republish, sell or distribute
                      any information, text, images, graphics, video clips,
                      sound, directories, files, databases or listings,
                      available on or through the Platform for any purpose;
                    </li>
                    <li>engage in spamming, phishing or spreading viruses;</li>
                    <li>
                      engage in any activity that would create liability for us;
                    </li>
                    <li>
                      use the Platform solely to collect information about other
                      users; or
                    </li>
                    <li>
                      use information provided to you on the Platform to solicit
                      additional sales offline.
                    </li>
                  </ul>
                </p>
                <p>
                  We have the right to remove a posting or listing or suspend or
                  terminate any Account, if there is a violation or suspected
                  violation of these Terms and Conditions.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>7</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>Payment</h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  By placing an order on the Platform for online payment, you
                  authorise our third-party payment processer to process your
                  credit/debit card details for the amount of your order. In
                  order to authorise credit/debit card payments, we may be
                  required to create an account for you with our third-party
                  payment processors, including accepting their standard terms
                  and conditions and submitting your details to them on your
                  behalf. You hereby authorise us to do so and we shall not be
                  liable to you for any damage or loss you may incur as a
                  result. For the avoidance of doubt, we do not store or collect
                  your card information.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We may remove or add cards or other payment methods that we
                  accept at any time without prior notice to you.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>8</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Coupons (Promotional Discount)
                  </h3>
                </div>
                <p>
                  <>From time to time and upon our sole discretion, we may present you with promotional discount via our  scheme coupons, whether as a “percentage coupon” or a “value coupon” (“Coupon(s)”) for which the following policy (“Coupon Policy”) shall be applicable:</>
                  <ul>
                    <li>
                      If you have been offered with any of our scheme Coupons, then you will be the sole party eligible to redeem the offer through your account. The Coupon(s) accordingly are not transferable to any 3rd party nor to other users or accounts.
                    </li>
                    <li>
                      Coupons and the applicable discount thereto are subject to a minimum purchase amount that might vary and shall be declared on the Platform.
                    </li>
                    <li>
                      To redeem a Coupon, you are required to place an order on the Platform to purchase one or any quantity of goods with the applicable minimum purchase amount, and then type the Coupon code into the promotional box where the discount will be automatically deducted from the final price.
                    </li>


                    <li>Coupons cannot be:</li>
                    <ul>
                      <li>redeemable for cash, used to pay for taxes, shipping and handling or other charges.</li>
                      <li>
                        combined with any other Coupons and/or offers, discounts, promotional offers  unless specified otherwise.
                      </li>
                    </ul>
                    <li>
                      Certain goods may be excluded from the promotional discounts.
                    </li>
                    <li>
                      Coupons are subject to validity and will not be used upon expiry.
                    </li>
                    <li>
                      Coupons are subject to cancellation at our sole discretion without notice.
                    </li>
                    <li>
                      In the event of opt to cancel an order then;
                    </li>
                    <ul>
                      <li>
                        If you use a Coupon and then decide to cancel the order, then the Coupon will be no longer valid and you will not be able to use it again
                      </li>
                      <li>
                        if you are canceling one or more items resulting that the order not achieving the threshold of minimum purchase amount, then the Coupon will not be applied to remaining items of the order and you will not be able to use the Coupon again.
                      </li>
                      <li>
                        Refund and returns will be subject to the Return Policy
                      </li>
                    </ul>
                  </ul>
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>9</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Refunds, Credits and Return of goods
                  </h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  Subject to the below, you may have the right to return any
                  goods purchased on the Platform and request a replacement or
                  refund by way of credit note to use against future purchases
                  in accordance with the Sellers return policy (Return Policy)
                  which can be viewed on the Platform at the time of placing an
                  order,{" "}
                </p>
                <p style={{ textAlign: "justify" }}>
                  Your credit will be added to your account once approved by the
                  relevant Seller. This is between the relevant Seller and you
                  and where such seller is a third party, we accept no liability
                  for any refusal on non-payment of refunds by the Seller.{" "}
                </p>
                <p style={{ textAlign: "justify" }}>
                  Return Policy, if linked to items that are subject to the Coupon Policy, then further conditions as stipulated hereto shall apply;
                  <ul>
                    <li>If you use a Coupon and then decide to cancel the order, then,  and subject to your eligibility for a return/refund, you will only be refunded for the amount paid. </li>
                    <li>If you use a Coupon for of multiple-items order and subsequently decide to cancel one or more items, which will result in the order not qualifying for minimum purchase order amount necessary to apply the Coupon, then, and subject to your eligibility for a return/refund,  you will only be refunded an amount less the paid amount to cover the elimination of the coupon and revision of the item price to its original in which case the refund will be  based on the following formula:
                      (price paid by you [for the respective canceled item] “minus” the discount offered on that item [when Coupon was valid] = refunded amount to you).
                    </li>

                  </ul>
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>10</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Third-Party Sites or Services
                  </h3>
                </div>
                <p>
                  We may give you access to third-party sites or services
                  through hyperlinks, API or other means on the Platform. We
                  have no control over and do not monitor such third-party sites
                  and we will not be liable to you for the content of these
                  sites or your use of them.
                </p>
                <p>
                  We may present you with other third-party services on the
                  Platform, such as delivery services and payment gateways.
                  These services will be subject to a separate agreement between
                  you and the service provider. We will not be liable to you for
                  the use of such services, or for any failures on the part of
                  these service providers.
                </p>
                <p>
                  Creating a link to our Platform is strictly forbidden without
                  our prior written consent. Furthermore, we reserve the right
                  to revoke our consent without notice or justification.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>11</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Intellectual property
                  </h3>
                </div>
                <p>
                  You agree that all intellectual property rights and database
                  rights, whether registered or unregistered, in the Platform
                  and any associated software (<strong>Software</strong>),
                  information on the Platform and Software and the design,
                  including, but not limited to, text, graphics, software,
                  photos, video, music, sound, and their selection and
                  arrangement, and all software compilations, underlying source
                  code and software shall remain at all times vested in us or
                  our licensors. Use of such material will only be permitted as
                  expressly authorized by us or our licensors.
                </p>
                <p>
                  Any unauthorized use of the material and content of the
                  Platform or the Software is strictly prohibited, and you agree
                  not to, or facilitate any third party to, copy, reproduce,
                  transmit, publish, display, distribute, commercially exploit
                  or create derivative works of such material and content.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>12</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Our Liability
                  </h3>
                </div>
                <ul>
                  <>
                    Nothing in these Terms and Conditions shall limit or exclude
                    a party’s liability:
                  </>
                  <li>
                    for fraud, including fraudulent misrepresentation,
                    perpetrated by that party;
                  </li>
                  <li>
                    for death or personal injury caused by the negligence of
                    that party; or
                  </li>
                  <li>
                    for any other liability that cannot be limited or excluded
                    under applicable law.
                  </li>
                </ul>
                <p>
                  We verify that each Seller is a licensed business when it
                  signs up to use the Platform. However, we do not control the
                  quality, legality, safety, or availability of the goods
                  offered for sale, the ability of Sellers to complete a sale,
                  or the ability of Buyers to complete a purchase and to the
                  extent permitted by law we disclaim all liability in relation
                  to any purchases made on our Platform.
                </p>
                <p>
                  The services provided by us and the goods displayed on the
                  Platform are provided "as is&rdquo; without representations,
                  warranties or conditions of any kind. We disclaim all
                  warranties, conditions and representations of any kind,
                  whether express, implied or collateral, including, but not
                  limited to, all conditions, representations or warranties of
                  merchantability, of fitness for a particular or general
                  purpose, of non-infringement, of compatibility or that the
                  Platform and services are secure or error free or will operate
                  without interruption or will be provided in a timely or proper
                  manner or at all.
                </p>
                <p>
                  We shall not be liable to pay any consequential, indirect,
                  punitive or incidental damages (including but not limited to
                  damages for loss of profits or savings, business interruption
                  or loss of information) arising out of or in connect with
                  these Terms and Conditions, even if we had been advised of the
                  possibility of such damages.
                </p>
                <p>
                  In addition, to the extent permitted by applicable law, we are
                  not liable, and you agree not to hold us responsible, for any
                  damages or losses resulting directly or indirectly from:
                </p>
                <ul>
                  <li>
                    the Content or other information you provide when using the
                    Platform;
                  </li>
                  <li>your use of or your inability to use the Platform;</li>
                  <li>
                    delays or disruptions in our services or the Platform;
                  </li>
                  <li>
                    viruses or other malicious software obtained by accessing or
                    linking to our Platform;
                  </li>
                  <li>
                    bugs, errors or inaccuracies of any kind in our Platform;
                  </li>
                  <li>
                    the content, actions or inactions of third parties using our
                    Platform, or providing services to you (including but not
                    limited to Buyers, Sellers or our delivery partners);
                  </li>
                  <li>
                    a suspension or other action taken by us with respect to
                    your use of the Platform;
                  </li>
                  <li>
                    your need to modify practices, Content or behaviour or your
                    loss of or inability to do business as a result of changes
                    to these Terms and Conditions; and
                  </li>
                  <li>
                    unsatisfactory or delayed performance of a Seller, or our
                    delivery partners.
                  </li>
                  <li>
                    Subject to the above, our total liability to you, whether
                    based on an action or claim in contract, negligence or
                    breach of statutory duty or otherwise, arising out of or in
                    relation to these Terms and Conditions shall be limited to
                    the lower of:
                  </li>
                  <li>
                    if you are a Buyer, the price of the item bought on our
                    Platform and its original shipping costs; or
                  </li>
                  <li>
                    if you are a Seller, the amount not to exceed the total fees
                    that you paid to us in the twelve (12) months prior to the
                    action giving rise to the liability.
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>13</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>Indemnity</h3>
                </div>
                <p>
                  If a dispute arises between users of the Platform, in
                  particular between a Buyer and a third party Seller, you agree
                  to release us (and our affiliated companies and each of their
                  respective officers, directors, employees, agents,
                  representatives and service providers) (
                  <strong>Platform Parties</strong>) from all claims, demands,
                  damages (actual or consequential) of any kind whatsoever
                  arising out of or in any way connected with such dispute.
                </p>
                <p>
                  You agree to defend, indemnify and hold harmless the Platform
                  Parties from any and all losses, claims and liabilities
                  (including legal costs) which may result from your:
                </p>
                <ul>
                  <li>breach of these Terms and Conditions;</li>
                  <li>
                    use of the Platform, or the Software or the services
                    provided on the Platform;
                  </li>
                  <li>violation of any applicable laws; or</li>
                  <li>
                    infringement of any third-party intellectual property
                    rights, or if the content of your listings is slanderous,
                    defamatory, obscene or violates any other rights of any
                    third party.
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>14</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Payment and pricing
                  </h3>
                </div>
                <p>
                  We are determined to provide the most accurate pricing
                  information on the Platform to our users; however, errors may
                  still occur, such as cases when the price of an item is not
                  displayed correctly on the Platform. As such, if an item is
                  mispriced, we may, at our own discretion, either contact you
                  for instructions or cancel your order and notify you of such
                  cancellation.
                </p>
                <p>
                  We shall have the right to refuse or cancel any such orders
                  whether or not the order has been confirmed and your
                  credit/debit card charged, or offline payment made. In the
                  event that we are unable to provide the goods, we will inform
                  you of this as soon as possible. A full refund will be given
                  where you have already paid for the products.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>15</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    Cooperation with Authorities
                  </h3>
                </div>
                <p>
                  We have the right to cooperate fully with governmental or
                  regulatory authorities or law enforcement in the investigation
                  of any suspected criminal or civil wrongdoing. As permitted by
                  applicable laws and policies, we may disclose a user's
                  identity and contact information regarding a user's account,
                  transactions or activities carried on the Platform. We will
                  not be liable for damages due to such disclosure, and you
                  agree not to bring any claim against us for the disclosure.
                </p>
              </div>
            </li>

            <li>
              <div className="termsandconditions-content">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <span>16</span>
                  <h3 style={{ marginLeft: 50, marginBottom: 0 }}>
                    General Terms
                  </h3>
                </div>
                <ul>
                  <li>
                    <strong>Governing Law</strong>&nbsp;- these Terms and
                    Conditions and any non-contractual rights or obligations
                    arising out of or in connection with it shall be governed by
                    and construed in accordance with the laws of the United Arab
                    Emirates, as applied in the Emirate of Dubai.
                  </li>
                  <li>
                    <strong>Dispute Resolution</strong>&nbsp;- If you have an
                    issue with the Platform or our services, please contact us.
                    We will endeavour to resolve your issue as soon as possible.
                    Any disputes or claims arising out of or in connection with
                    these Terms and Conditions, including any non-contractual
                    rights or obligations shall be referred to and finally
                    resolved by the Dubai courts.
                  </li>
                  <li>
                    <strong>Communication&nbsp;</strong>- you agree that all
                    agreements, notices, disclosures and other communications
                    that we provide to you electronically satisfy any legal
                    requirement that such communications be in writing.
                  </li>
                  <li>
                    <strong>Assignment</strong>&nbsp;- assigning or
                    sub-contracting any of your rights or obligations under
                    these Terms and Conditions to any third party is prohibited
                    unless agreed upon in writing. We reserve the right to
                    transfer, assign or sub-contract the benefit of the whole or
                    part of any rights or obligations under these Terms and
                    Conditions to any third party.
                  </li>
                  <li>
                    <strong>Entire Agreement</strong>&nbsp;- these Terms and
                    Conditions represent the entire agreement between us
                    overriding any prior written or verbal agreements that may
                    have been made.
                  </li>
                  <li>
                    <strong>Relationship between the Parties</strong>&nbsp;-
                    these Terms and Conditions do not create an agency,
                    partnership, joint venture, employment or franchise
                    relationship between you and us.
                  </li>
                  <li>
                    <strong>Severability</strong>&nbsp;- If any clause of these
                    Terms and Conditions is considered to be invalid or
                    unenforceable, such clause will be deleted and the remaining
                    clauses will remain valid and enforceable.
                  </li>
                  <li>
                    <strong>Waiver&nbsp;</strong>- if we do not enforce our
                    rights or act against any breach by you under these Terms
                    and Conditions, it does not mean that we are waiving our
                    rights to enforce actions against you in the future.
                  </li>
                  <li>
                    <strong>Force Majeure&nbsp;</strong>- neither party will be
                    liable for any loss or damage or for any delay or failure in
                    performance due to acts beyond the control of such party
                    whether or not such acts could reasonably be anticipated
                    (including acts of God, legislative, judicial or regulatory
                    acts of any provincial or the federal government, court or
                    regulatory authority, acts of any of our subcontractors or
                    any third party providers of goods or services to us, labour
                    disruptions, blackouts, embargoes or a pandemic, epidemic or
                    disease impacting the Party claiming to have been impacted
                    by the relevant force majeure event).
                  </li>
                  <li>
                    <strong>Survival -</strong> all provisions that either
                    expressly or by their nature survive, will survive
                    suspension or termination of your Account.
                  </li>
                  <li>
                    <strong>Third Party Rights -</strong>A person who is not a
                    party to these Terms and Conditions has no right to enforce
                    any of its terms.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
