import React from "react";
import { useSelector, useDispatch } from "react-redux";
const ShowDynamicPrice = (props) => {
  const { currency, conversionRate } = useSelector((state) => {
    return {
      currency: state.currency.currency,
      conversionRate: state.currency.conversionRate,
    };
  });

  const { price, isHighlighted } = props;

  return (
    <span className={isHighlighted ? "text-danger" : ""}>
      {conversionRate == 0
        ? Number(price)
            ?.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : (Number(price) * conversionRate)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    </span>
  );
};

export default ShowDynamicPrice;
