import React from 'react'

const Leaderboard = ({banner}) => {

  return (
    <section className="top-leaderboard">
        <div className="container" style={{marginBottom:"40px", marginTop:"40px"}}>
          <div className="row">
            <div className="col-md-12">
              <div className="top-leaderboard-content d-flex" style={{ justifyContent:"center"}}>
                <a href={banner?.banner_link}>
                  <img style={{height:"90px", width:"970px", objectFit:'cover'}} src={banner?.banner_upload} alt="banner" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Leaderboard