import React, {useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import { UpdateUserInfo } from "../../Store/action/loginActions";
import { PolicyDisagreed } from "../../Store/action/agreementActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogTitle-root": {
      background: "#1d71b8",
    },
    "& .MuiDialogTitle-root .MuiTypography-h6": {
      color: "white",
    },
    "& .MuiCheckbox-root": {
      padding: "0px 9px 9px 9px",
    },
    "& .MuiDialogContentText-root": {
      marginTop: 4,
    },
  },
  agreeStyle: {
    marginLeft: 42,
  },
}));

export default function PolicyAggremementModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { login } = useSelector((state) => {
    return {
      login: state.login
    };
  });
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const getOpt = async(user_id) => {
    try {
      await axios.get(`/api/opt/${user_id}`)
      setOpen(false);
    } catch (error) {
      setTimeout(() => {
        setOpen(true);        
      }, 5000);
    }
  }  

  useEffect(() => {
    if (login) {
      getOpt(login._id)
    }
  }, [login]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  const handleClose = () => {
    dispatch(PolicyDisagreed(true));
    setOpen(false);
  };
  const handleAgree = async () => {
    const data = {
      optin: checked ? moment() : null,
      optout: !checked ? moment() : null,
      user: login?._id,
    };
    await axios.post("/api/opt", data);
    getOpt(login?._id)
    setChecked(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      disableBackdropClick={true}
    >
      <DialogTitle id="alert-dialog-title">
        {"Terms and condition aggreement."}
      </DialogTitle>
      <DialogContent>
        <div>
          <div style={{ display: "flex", alignItems: "start" }}>
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "secondary checkbox" }}
              // style={{ padding: "-1px 9px 9px 9px" }}
            />
            <DialogContentText id="alert-dialog-description">
              By checking this box, I agree to receive news, offers, updates and
              other promotional communication from and about Buyparts24.com on
              SMS, email phone and social media messaging apps.(eg:WhatsApp,
              telegram, etc.).This will keep you up-to-date with all the latest
              information form Buyparts24.com
            </DialogContentText>
          </div>
          <div className={classes.agreeStyle}>
            <DialogContentText id="alert-dialog-description">
              Read our <Link to="/terms">Terms & condition</Link> and
              <Link to="/privacy">Privacy policy</Link> here.
            </DialogContentText>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {/* {checked && login_id && (<Button onClick={handleAgree} color="primary">
          Disagree
        </Button>)} */}

        {login?._id && (
          <Button onClick={handleAgree} color="primary">
            {checked ? "Agree" : "Disagree"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
