import { TextField } from '@material-ui/core'
import React from 'react'
import Alert from "@material-ui/lab/Alert";
import { Typography } from "@material-ui/core";
import ShowDynamicCurrency from '../../common/ShowDynamicCurrency'
import ShowDynamicPrice from '../../common/ShowDynamicPrice'
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CallRoundedIcon from "@material-ui/icons/CallRounded";

export default function StepReview({ step, setOrderNote, orderNote, login, formatPhoneNumber, selectedAddress, classes, newShippingPrice, getTaxAmount, getPriceTotal, flags, stepForward, disabler, placeOrder, couponCode, setCouponCode, setCouponShowModal, discount, coupon, walletAmount }) {

    let exc_tax_total = parseFloat(localStorage.getItem("totalAmountInCart")) / 1.05
    let discount1 = discount ? discount : 0
    // let cartValue = localStorage.getItem("totalAmountInCart")
    // if (Number(cartValue) >= coupon?.min_cart_value && coupon?.discount_type?.label === "by_percent") {
    //     discount1 = exc_tax_total * coupon?.discount_type?.value / 100
    // }
    // if (Number(cartValue) >= coupon?.min_cart_value && coupon?.discount_type?.label === "by_value") {
    //     discount1 = coupon?.discount_type?.value
    // }

    let tax = (exc_tax_total - discount1 + Number(newShippingPrice)) * 0.05

    let total = exc_tax_total - discount1 + Number(newShippingPrice) + tax

    disabler = login._id === "61b5a627e333e2a780ebc75a" ? true : disabler



    return (
        <>
            <div className="col-lg-3" style={{ borderRadius: 10 }}>

                {
                    step == 4 && (
                        <div className="order-summary-part" style={{ marginBottom: "20px" }}>
                            <div
                                className="messageField"
                                style={{ display: "flex" }}
                            >
                                <TextField
                                    fullWidth={true}
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Enter Coupon"
                                    onChange={(e) => setCouponCode(e.target.value)}
                                    value={couponCode}
                                />

                                <button
                                    className="btn btn-primary text-center"
                                    type="button"
                                    onClick={(e) => { couponCode && setCouponShowModal(true) }}
                                    style={{
                                        backgroundColor: "rgb(243 147 44)",
                                    }}
                                >
                                    Apply
                                </button>

                            </div>
                        </div>
                    )
                }


                {step == 5 && (
                    <>


                        <div className="order-summary-part" style={{ marginBottom: "20px" }}>
                            <div
                                className="messageField"
                                style={{ marginBottom: "10px" }}
                            >
                                <h3 className="mb-4">Buyer Info</h3>
                                <div className="subtotal-row d-flex justify-content-between mb-3">
                                    <span className="font-weight-bold">Name</span>
                                    <span></span>
                                    <span>
                                        <span className="text-secondary">
                                            {login.details?.garage_name}
                                        </span>
                                    </span>
                                </div>
                                <div className="shopping d-flex justify-content-between">
                                    <span className="font-weight-bold mb-3">
                                        Contact
                                    </span>
                                    <span className="order-summary-currency">
                                        <span className="text-secondary">
                                            {formatPhoneNumber(login.phone)}
                                            <CallRoundedIcon
                                                style={{
                                                    fontSize: 16,
                                                    marginLeft: 10,
                                                    marginTop: "-3px",
                                                }}
                                            />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {step === 2 && !Object.keys(selectedAddress).length && (
                    <div className={classes.alert}>
                        <Alert severity="error">
                            <Typography color="error">
                                Please select one of your address for deliveries.
                            </Typography>
                        </Alert>
                    </div>
                )}
                <div className="order-summary-part">
                    <h3 className="mb-4">Order Summary</h3>

                    <div className="subtotal-row d-flex justify-content-between mb-3">
                        <span className="font-weight-bold">
                            Subtotal (Excl. Tax)
                        </span>
                        <span></span>
                        <span>
                            <span className="checkout-currency">
                                <ShowDynamicCurrency />
                            </span>

                            <span className="text-secondary">
                                <ShowDynamicPrice
                                    price={exc_tax_total}
                                />
                            </span>
                        </span>
                    </div>

                    <div className="discount-row d-flex justify-content-between mb-3">
                        <span className="font-weight-bold">Discount</span>
                        <span className="order-summary-currency">
                            <span className="checkout-currency">
                                {/* {currency?.currency} */}
                                <ShowDynamicCurrency />
                            </span>
                            <span className="text-secondary">
                                <ShowDynamicPrice
                                    price={discount1}
                                /></span>
                        </span>
                    </div>

                    <div className="shopping d-flex justify-content-between">
                        <span className="font-weight-bold mb-3">Shipping</span>
                        <span className="order-summary-currency">
                            <span className="checkout-currency">
                                {" "}
                                {/* {currency?.currency} */}
                                <ShowDynamicCurrency />
                            </span>
                            <span className="text-secondary">
                                {" "}
                                <ShowDynamicPrice price={newShippingPrice} />
                            </span>
                        </span>
                    </div>

                    <div className="tax-row d-flex justify-content-between mb-3">
                        <span className="font-weight-bold">Tax</span>
                        <span className="order-summary-currency">
                            {/* 5% of{" "}
                        <span className="checkout-currency">
                          {currency?.currency}
                        </span>
                        {numberWithCommas(
                          parseFloat(
                            localStorage.getItem("totalAmountInCart")
                          ) 
                        )}{" "}
                        ={" "} */}
                            <span className="checkout-currency">
                                {/* {currency?.currency} */}
                                <ShowDynamicCurrency />
                            </span>
                            <span className="text-secondary">
                                <ShowDynamicPrice
                                    price={tax}
                                />
                            </span>
                        </span>
                    </div>

                    <div className="shopping d-flex justify-content-between">
                        <span className="font-weight-bold mb-3">Total</span>
                        <span className="order-summary-currency">
                            <span className="checkout-currency">
                                {" "}
                                {/* {currency?.currency} */}
                                <ShowDynamicCurrency />
                            </span>
                            <span className="text-secondary">
                                {" "}
                                <ShowDynamicPrice price={total} />
                            </span>
                        </span>
                    </div>

                    <div className="shopping d-flex justify-content-between">
                        <span className="font-weight-bold mb-3">From Wallet</span>
                        <span className="order-summary-currency">
                            <span className="checkout-currency">
                                {" "}
                                {/* {currency?.currency} */}
                                <ShowDynamicCurrency />
                            </span>
                            <span className="text-secondary">
                                {" "}
                                <ShowDynamicPrice price={walletAmount} />
                            </span>
                        </span>
                    </div>

                    <div className="total-row d-flex justify-content-between h4 py-4">
                        <span
                            className="font-weight-bold"
                            style={{ fontSize: "14px" }}
                        >
                            Total Amount:
                        </span>
                        {/* <span>{"AED" + totalAmount}</span> */}
                        <span style={{ fontSize: 15 }}>
                            <span style={{ fontSize: "10px" }}>
                                {/* {currency?.currency} */}
                                <ShowDynamicCurrency />
                            </span>

                            <span style={{ paddingLeft: 3 }}>
                                <ShowDynamicPrice
                                    price={total - walletAmount}
                                />
                            </span>
                        </span>
                    </div>
                    {step === 1 && (
                        <>
                            <button
                                className="btn btn-primary w-100 text-center mt-2"
                                type="button"
                                disabled={flags.current}
                                onClick={(e) => stepForward(e, 2, true)}
                            >
                                {/* {console.log(flags,"flaggggggggggg")} */}
                                Enter shipping details
                            </button>
                            <div style={{ width: "100%" }}>
{                                console.log(flags.current)
}                                {flags.current && (
                                    <p
                                        style={{
                                            color: "red",
                                            fontStyle: "italic",
                                            lineHeight: "15px",
                                            marginTop: 5,
                                        }}
                                    >
                                        Your cart items quantity exceeded stock quantity.!{" "}
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                    {step === 2 && (
                        <button
                            className="btn btn-primary w-100 text-center mt-2"
                            type="button"
                            onClick={(e) => stepForward(e, 3, true)}
                            disabled={!Object.keys(selectedAddress).length}
                        >
                            Enter shipping address
                        </button>
                    )}
                    {step === 3 && (
                        <button
                            className="btn btn-primary w-100 text-center mt-2"
                            type="button"
                            onClick={(e) => stepForward(e, 4, true)}
                        >
                            Make a payment
                        </button>
                    )}
                    {step === 4 && (
                        <button
                            className="btn btn-primary w-100 text-center mt-2"
                            type="button"
                            onClick={(e) => stepForward(e, 5, true)}
                        // onClick={(e) => this.placeOrder(e)}
                        // disabled={this.state.disabled}
                        >
                            Review Order
                        </button>
                    )}
                    {step === 5 && (
                        <>
                            {/* <div className="order-summary-part" style={{ marginBottom: "20px" }}> */}
                            <div
                                className="messageField"
                                style={{ display: "flex", marginBottom: "30px", color: "#4F4F4F", marginTop: 20 }}
                            >
                                <BorderColorIcon
                                    fontSize="medium"
                                    style={{
                                        color: "rgb(243 147 44)",
                                        marginRight: "5px",
                                        marginTop: "10px",
                                    }}
                                />
                                <TextField
                                    fullWidth={true}
                                    id="standard-textarea"
                                    // label="Drop note for this order"
                                    placeholder="Drop note for your order..."
                                    onChange={(e) => setOrderNote(e.target.value)}
                                    value={orderNote}
                                    multiline
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            {/* </div> */}
                            <button
                                className="btn btn-primary w-100 text-center mt-2"
                                type="button"
                                disabled={disabler}
                                onClick={(e) => placeOrder(e)}
                            // disabled={this.state.disabled}
                            >
                                {disabler ? "Finalizing order..." : "Finalize Order"}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
