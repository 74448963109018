import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone";
import StarRatingComponent from "react-star-rating-component";

import {
  AddToCart,
  SetFavouriteListing,
  setPathname,
} from "../../../../Store/action/listingActions";
import { numberWithCommas, stringToUrl } from "../../../../utils";

class CardListingGridSimilar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { size, listings } = this.props;
    const { isLoading } = this.props.list;
    const className =
      size === 3 ? "col-lg-3 col-xl-3 col-sm-3 col-md-3" : "col-lg-3 col-sm-3";
    return (
      <Fragment>
        {!isLoading &&
          listings &&
          listings.map((value, index) => {
            const {
              pic,
              imageArray,
              partName,
              partSKU,
              price,
              _id,
              quantity,
              date,
            } = value;
            const title = `${partName}`;
            const badge =
              moment.duration(moment().diff(moment(date))).asHours() <= 48
                ? "new listing"
                : "";
            const link = stringToUrl(partName) + "--" + stringToUrl(partSKU);

            let url = pic
              ? pic
              : imageArray.length > 0
                ? imageArray[0]
                : "/assets/img/product-default.jpg";
            return (
              <div className={className} key={index} style={{ marginTop: 30 }}>
                <div className="atbd_single_listing ">
                  <article className="atbd_single_listing_wrapper">
                    <figure className="atbd_listing_thumbnail_area">
                      <div className="atbd_listing_image">
                        <NavLink to={`/spare-part-details/${link}`}>
                          <img src={url || "/assets/img/product-default.jpg"} alt="Listing" />
                        </NavLink>
                      </div>
                      <div className="atbd_thumbnail_overlay_content">
                        <ul className="atbd_upper_badge">
                          <li>
                            <span
                              className={
                                "text-capitalize atbd_badge atbd_badge_" +
                                badge.replace(" ", "_")
                              }
                            >
                              {badge}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </figure>
                    <div className="atbd_listing_info">
                      <h4 className="atbd_listing_title">
                        <NavLink to={`/spare-part-details/${link}`}>
                          {title}
                        </NavLink>
                      </h4>
                      <div className="rating-group">
                        <div className="d-flex">
                          <StarRatingComponent
                            name="rate2"
                            editing={false}
                            renderStarIcon={() => <i className="la la-star" />}
                            renderStarIconHalf={() => (
                              <i
                                className="la la-star-half-alt"
                                style={{ color: "#ffb400" }}
                              />
                            )}
                            starColor="#ffb400"
                            emptyStarColor={"#cecece"}
                            starCount={5}
                            value={3.5}
                          />
                          <span className="rating-value">3.5</span>
                        </div>
                        <span className="review-value text-muted">760</span>
                      </div>
                      <div className="price-group">
                        <p className="symbol mr-1">
                          AED
                          <span className="price">
                            {numberWithCommas(price)}
                          </span>
                        </p>
                        {quantity && quantity > 0 ?
                          (<button
                            className="btn cart-btn"
                            onClick={() => {
                              this.props.addToCart(_id);
                              localStorage.setItem('filterPath', JSON.stringify(this.props.location.pathname))
                              this.props.setPathname(this.props.location.pathname)
                            }}
                          >
                            Add
                          </button>) : (
                            <p style={{ fontSize: 12, fontWeight: 600, margin: 2 }}>Out of stock.</p>
                          )}
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            );
          })}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    setFavouriteListing: (e, id) => dispatch(SetFavouriteListing(e, id)),
    addToCart: (data) => dispatch(AddToCart(data)),
    setPathname: (pathname) => dispatch(setPathname(pathname))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProp
)(CardListingGridSimilar);
