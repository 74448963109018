import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Line, Chart } from "react-chartjs-2";
Chart.defaults.global.legend = false;

const LineChart = ({ data, totalPurchases, range }) => {
  const [yearsOrders, setYearsOrders] = useState([0, 0, 0, 0, 0, 0]);
  const [prevWeekOrders, setPrevWeekOrders] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [thisWeekOrders, setThisWeekOrders] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [prevYearMonthsOrders, setPrevYearMonthsOrders] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [thisYearMonthsOrders, setThisYearMonthsOrders] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const noOfOrdersPerDayOnWeek = orders => {
    let counts = [0, 0, 0, 0, 0, 0, 0]
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      let index = moment(order.order_date).day()
      counts[index] += 1
    }
    return counts
  }

  const noOfOrdersPerMonth = orders => {
    let counts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      let index = moment(order.order_date).month()
      counts[index] += 1
    }
    return counts
  }

  const noOfOrdersPerRange = (orders, range) => {
    let a = moment(range[1]);
    let b = moment(range[0]);
    let noOfDays = a.diff(b, 'days') + 1
    let counts = new Array(noOfDays).fill(0)
    for (let i = 0; i < counts.length; i++) {
      let thisday = moment(range[0]).add(i, 'days')
      let thisDateOrders = orders.filter(o => {
        let isOfSameDay = moment(o.order_date).isSame(thisday, 'days')
        if (isOfSameDay) {
          return o
        }
      })
      counts[i] = thisDateOrders.length;
    }
    // counts = counts.reverse()
    return counts
  }

  const labelsForRange = (range) => {
    let a = moment(range[1]);
    let b = moment(range[0]);
    let noOfDays = a.diff(b, 'days') + 1
    let labels = []
    for (let i = 0; i < noOfDays; i++) {
      labels.push(moment(range[0]).add(i, 'days').format("Do MMM"))
    }
    return labels
  }
  const noOfOrdersPerYears = orders => {
    let counts = [0, 0, 0, 0, 0, 0]
    counts[0] = orders.lastYearOrders_4?.length
    counts[1] = orders.lastYearOrders_3?.length
    counts[2] = orders.lastYearOrders_2?.length
    counts[3] = orders.lastYearOrders_1?.length
    // counts[4] = orders.lastYearOrders_0?.length 
    counts[4] = orders.lastYearOrders?.length
    counts[5] = orders.thisYearOrders?.length

    return counts
  }
  useEffect(() => {
    setPrevWeekOrders(noOfOrdersPerDayOnWeek(totalPurchases?.lastWeekOrders || []))
    setThisWeekOrders(noOfOrdersPerDayOnWeek(totalPurchases?.thisWeekOrders || []))
    setPrevYearMonthsOrders(noOfOrdersPerMonth(totalPurchases?.lastYearOrders || []))
    setThisYearMonthsOrders(noOfOrdersPerMonth(totalPurchases?.thisYearOrders || []))
    setYearsOrders(noOfOrdersPerYears(totalPurchases || []))

  }, [totalPurchases])
  return data == "week" ? (
    <div>
      <Line
        data={{
          labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
          datasets: [
            {
              data: thisWeekOrders,
              backgroundColor: "#EDF3F8",
              borderColor: "#3871B3",
              borderWidth: "5",
              label: 'This Week orders'
            },
            {
              data: prevWeekOrders,
              borderWidth: "5",
              label: 'Previous Week Orders'
            }
          ],
        }}
        height={400}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : data == "year" ? (
    <div>
      <Line
        data={{
          labels: [
            // moment().subtract(6, "years").year(), 
            moment.utc().utcOffset(4).subtract(5, "years").year(),
            moment.utc().utcOffset(4).subtract(4, "years").year(),
            moment.utc().utcOffset(4).subtract(3, "years").year(),
            moment.utc().utcOffset(4).subtract(2, "years").year(),
            moment.utc().utcOffset(4).subtract(1, "years").year(),
            moment.utc().utcOffset(4).subtract(0, "years").year()],
          datasets: [
            {
              data: yearsOrders,
              backgroundColor: "#EDF3F8",
              borderColor: "#3871B3",
              borderWidth: "5",
            },
            // {
            //   data: [11, 10, 9, 8, 16, 19, 5, 11, 6, 7, 8, 17],
            // },
          ],
        }}
        height={400}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : data == "date_range" ? (
    <div>
      <Line
        data={{
          labels: labelsForRange(range),
          datasets: [
            {
              data: noOfOrdersPerRange(totalPurchases.dateRangeOrders, range),
              backgroundColor: "#EDF3F8",
              borderColor: "#3871B3",
              borderWidth: "5",
            },
            // {
            //   data: [11, 10, 9, 8, 16, 19, 5, 11, 6, 7, 8, 17],
            // },
          ],
        }}
        height={400}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : data == "month" ? (
    <div>
      <Line
        data={{
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              data: thisYearMonthsOrders,
              backgroundColor: "#EDF3F8",
              borderColor: "#3871B3",
              borderWidth: "5",
              label: 'thisYearMonth'
            },
            {
              data: prevYearMonthsOrders,
              label: 'prevYearMonth',
              borderWidth: "5",
            },
          ],
        }}
        height={400}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : data == "today" ? (
    <div>
      <Line
        data={{
          labels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ],
          datasets: [
            {
              data: [15, 12, 13, 14, 25, 26, 8, 14, 11, 10, 11, 22],
              backgroundColor: "#EDF3F8",
              borderColor: "#3871B3",
              borderWidth: "5",
            },
            {
              data: [11, 10, 9, 8, 16, 19, 5, 11, 6, 7, 8, 17],
            },
          ],
        }}
        height={400}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : null;
};

export default LineChart;
