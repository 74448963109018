import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
	chipStyle: {
		backgroundColor: "#f7af30",
	},
	imgStyle: {
		height: '20px!important',
		width: '20px!important',
		objectFit: "contain",
		objectPosition: "center",

	},
	imgStyleDiv: {
		height: '30px!important',
		width: '30px!important',
		borderRadius: 20,
		border: '2px solid #ddd',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		background: "#FFF",
		zIndex: 1
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	textStyle: {
		marginLeft: -15,
		fontSize: 11,
		fontWeight: 500,
		color: '#FFF',
		padding: '3px 10px',
		paddingLeft: 18,
		borderRadius: 15,
		// color: (props) => props.color || "#00FFFF",
	},
	divStyle: {
		position: 'absolute',
		display: 'flex',
		top: 10,
		left: 10,
		alignItems: 'center',
		// display: 'flex',
	}
}));

const TextOverlay = (props) => {
	const { business_model } = props;
	const classes = useStyles(props);
	const login = useSelector((state) => state.login);
	
	if (!business_model || business_model === "Pure Play") {
		return <></>
	}
	
	let _color = ""; 
	let _text="";
	let url = "";
	let text = "";
	let color = "";
	let after = moment().tz("Asia/Dubai").format("HH:mm:ss");
	let checkExpressCutoff = moment(after, "hh:mm:ss").isBefore(
		moment("18:00:00", "hh:mm:ss")
	);
	const isValidShippingAddress = (login?.details?.emirate === "Dubai" || login?.details?.emirate === "Sharjah" || login?.details?.emirate === "Abu Dhabi")

	let condition = checkExpressCutoff && isValidShippingAddress

	if (condition && business_model === "Fulfilment") {
		url = "/assets/img/express-delivery.png";
		text = "Express";
		color = "rgb(243, 147, 41)"
	} else {
		url = "/assets/img/consolidate.png";
		text = "Standard";
		color = "#1d71b8";
	}

	if (business_model === "Fulfilment") {
		_color = "#1a0451";
		_text = "Fulfilment";
	} else {
		_color = '#32cc6f';
		_text = "Consolidation";
	}




	return (
		<div>
			<div className={classes.divStyle} style={{ ...props?.style }}>
				<div className={classes.imgStyleDiv} style={{ borderColor: color }}>
					<img
						className={classes.imgStyle}
						src={url}
						alt="express"
					/>
				</div>
				<h3
					className={classes.textStyle}
					style={{
						backgroundColor: color,
					}}
				>
					{" "}
					{text}
				</h3>

				<div className={classes.imgStyleDiv} style={{ borderColor: _color, marginLeft:'4px' }}>
					<img
						className={classes.imgStyle}
						src={url}
						alt="express"
					/>
				</div>
				<h3
					className={classes.textStyle}
					style={{
						backgroundColor: _color,
					}}
				>
					{" "}
					{_text}
				</h3>
			</div>

		</div>
	);
};

export default TextOverlay;
