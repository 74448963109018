import React, { useEffect, useState, useRef } from "react";
import ConfirmModal from "../../content/element/modal/confirmation";
import CouponConfirmModal from "../../content/element/modal/coupon-confirmation";
import { PageBanner } from "../../content/element/page-banner";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import Header from "../../layout/header/";
import AddForm from "../../content/element/AddForm";
import EditForm from "../../content/element/EditForm";
import { useDispatch, useSelector } from "react-redux";
import NoData from "../../content/element/Nodata";
import { getCartLength, popUp, changeToGroup, getTaxAmount, } from "../../../utils";
import { Backdrop, Box, CircularProgress, Typography, } from "@material-ui/core";
import { getBulkCartListing, updateBulkCartListing, UpdateCart as updateCart } from "../../../Store/action/listingActions";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { updatestock } from "../../../Store/action/listingActions";
import InfoIcon from "@material-ui/icons/Info";
import Steps from "./Steps";
import StepButton from "./StepButton";
import StepDetailText from "./StepDetailText";
import PaymentModal from "./PaymentModal"

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import StepReview from "./StepReview";
import useStyles from "./Styles";
import WalletPaymentModal from "./WalletPayment";
import ReactGA from 'react-ga4';
import CheckoutEmpty from "./CheckoutEmpty";
import BulkOrderStep1 from "./BulkUpload/Step1";
import { RemoveShoppingCart } from "@material-ui/icons";




export function formatPhoneNumber(phone) {
  if (phone)
    return [
      phone.slice(0, 4),
      " ",
      phone.slice(4, 6),
      " ",
      phone.slice(6, 9),
      " ",
      phone.slice(9, phone.length),
    ].join("");
}

export default (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // reducers
  const list = useSelector((state) => state.list);

  const login = useSelector((state) => state.login);
  const currency = useSelector((state) => state.currency);

  // confirm Modal
  const [showModal, setShowModal] = useState(false);
  const [couponShowModal, setCouponShowModal] = useState(false);
  const [walletShowModal, setWalletShowModal] = useState(false);
  const [wallet, setWallet] = useState({});
  const [walletAmount, setWalletAmount] = useState(0);
  const [partialPaymentType, setPartialPaymentType] = useState('');

  const [passId, setPassId] = useState("");
  const [passevent, setPassevent] = useState("");
  const [passName, setPassName] = useState("");

  // step
  const [step, setStep] = useState(1);
  const [deliveryAddress, setdeliveryAddress] = useState(null);
  const [discountPerUnit, setDiscountPerUnit] = useState(0);
  const [discount, setDiscount] = useState(0)

  // amount
  const [tempsubTotal, setTempsubTotal] = useState(0);
  let totalAmount = 0;
  const [price, setPrice] = useState(0);
  const [couponCode, setCouponCode] = useState('')
  const [coupon, setCoupon] = useState({})

  // listings
  const [listings, setListings] = useState([]);
  const [orderNote, setOrderNote] = useState("");
  const [delivery_option, setDelivery_option] = useState([]);

  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  // payment
  const [payOnCard, setpayOnCard] = useState(false);
  const [payByWallet, setPayByWallet] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState(false);
  const [paymentSessionId, setPaymentSessionId] = useState('')
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  //warehouse
  const [warehouse, setWarehouse] = useState(null);
  const [disabler, setdisabler] = useState(false);

  // funcs
  const flags = useRef(false);
  const [loader, setLoader] = useState(false);

  //edit address
  const [editedAddressId, setEditedAddressId] = useState(null);

  //address data
  const [stateData, setStateData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [locationData, setLocationData] = useState([]);


  useEffect(async () => {
    try {
      let state = await axios.get("/api/products/get-state")
      let location = await axios.get("/api/products/get-regions")
      let city = await axios.get("/api/products/a-city")
      let warehouse = await axios.get("/api/warehouse")
      let wallet = await axios.get("/api/wallet/wallet-user/" + login._id)
      fetchAddress();
      setWarehouse(warehouse?.data?.warehouse);
      setStateData(state.data)
      setRegionData(location?.data?.regions)
      setLocationData(city.data)

      setWallet(wallet?.data)
    } catch (error) {
      // console.log({ errors: error })
      popUp("There was a problem with server. Please try again.")
    }
  }, []);



  useEffect(() => {
    if (list.itemsInCart.length !== 0) {
      getListings();
    }
  }, [list.itemsInCart]);

  useEffect(() => {
    if (list?.bulkUploadedList.length !== 0 && list?.bulkCartList.length === 0) {
      dispatch(getBulkCartListing());
    }

  }, [list?.bulkUploadedList])

  useEffect(() => {
    if (list?.bulkCartList && list?.bulkCartList.length !== 0) {
      const newListings = [];
      let total_price = 0;
      let initalDeliveryOptions = [];
      let isFlagged = false
      setLoader(false);
      list?.bulkCartList.forEach((item, i) => {
        newListings.push({ user: item.user, listings: [item] });

        total_price += item.price * item?.orderQty;

        if (item?.orderQty > item?.quantity) {
          isFlagged = true;
        }
        flags.current = isFlagged;

        return true;
      });

      localStorage.setItem(
        "totalAmountInCart",
        total_price
      );

      newListings.map((item) => {
        return item.listings.forEach((list) => {
          initalDeliveryOptions.push({
            ...list,
            item_id: list._id,
            delivery_option: "next_day",
          });
        });
      });
      setPrice(total_price);
      setTempsubTotal(total_price);
      setListings(changeToGroup(newListings));
      setDelivery_option(initalDeliveryOptions);
    }
  }, [list?.bulkCartList])


  const handleCloseModal = () => setShowModal(false);

  function removeCartItem(e, id) {
    e.preventDefault();
    flags.current = false;
    if (list?.isBulkUpload) {
      const products = [...list?.bulkCartList];

      let filteredProducts = products.filter(product => product?._id !== id);

      dispatch(updateBulkCartListing(filteredProducts, "delete"))
    } else {
      let items = list.itemsInCart;
      items = items.filter((e) => e !== id);
      dispatch(updateCart(items));
    }
    setShowModal(false);
    setWalletAmount(0);
    popUp("Item removed from cart successfully!");
  }

  function stepForward(e, i, rightStep = false) {
    if (deliveryAddress && step === 2 && i > step) return false;
    if (rightStep || step > i) setStep(i);
  }

  function qtyIncrement(e, id) {
    checkQuantity(id, true)
    e.preventDefault();

    if (list?.isBulkUpload) {
      const products = [...list?.bulkCartList];

      let product = products.find(product => product?._id === id);
      if (product)
        product.orderQty = parseInt(product.orderQty) + 1;

      dispatch(updateBulkCartListing(products, "increment"))
    } else {
      const items = list.itemsInCart;
      items.push(id);
      dispatch(updateCart(items));
    }
    setWalletAmount(0);
  }

  const checkQuantity = (id, shoudlIncrement) => {
    listings.map(listing => {
      listing.data?.map(d => {
        const { _id, quantity } = d.listings[0];
        if (_id?.toString() == id) {
          let total = getCartLength(list.itemsInCart, id);
          if (shoudlIncrement == true) total = total + 1;
          else total = total - 1;
          if (total > quantity) flags.current = true;
          else flags.current = false
        }
      })
    })
  }

  const checkTotal = () => {
    listings.map(listing => {
      listing.data?.map(d => {
        const { _id, quantity } = d.listings[0];
        let total = getCartLength(list.itemsInCart, _id);
        if (total > quantity) flags.current = true
        else flags.current = false
      })
    })
  }

  function changeCartQty(qty, id) {
    if (qty < 1) qty = 1

    if (list?.isBulkUpload) {
      const products = [...list?.bulkCartList];

      let product = products.find(product => product?._id === id);
      if (product)
        product.orderQty = qty;

      dispatch(updateBulkCartListing(products, "update"))
    } else {
      let newItems = new Array(qty).fill(id);
      const items = list.itemsInCart.filter(e => e !== id).concat(newItems);
      // const _items = [...list.itemsInCart];
      // let len = items.length;
      // for (let i = 0; i < len; i++) {
      //   if (items[i] === id) {
      //     items.splice(i, 1);
      //   }
      //   len = items.len
      // }

      // console.log({items, newItems })

      // for (let i = 0; i < newItems.length; i++) {
      //   const item = newItems[i];
      //   items.push(item);      
      // }
      dispatch(updateCart(items));
    }
  }

  function qtyDecrement(e, id) {
    e.preventDefault();

    if (list?.isBulkUpload) {
      const products = [...list?.bulkCartList];

      let product = products.find(product => product?._id === id);
      if (product)
        product.orderQty = parseInt(product.orderQty) - 1;

      dispatch(updateBulkCartListing(products, "decrement"))
    } else {
      checkQuantity(id, false)
      const items = list.itemsInCart;
      const index = items.indexOf(id);
      if (index >= 0) items.splice(index, 1);
      dispatch(updateCart(items));
    }
    setWalletAmount(0);
  }
  const fetchAddress = async () => {
    try {
      let res = await axios.get("/api/address/get-user-address?user_id=" + login._id)
      setAddresses(res.data.addresses);
      const DefaultAddress = res.data.addresses.find((address) => address.default_address == true) || {};
      setDefaultAddress(DefaultAddress);
      if (!Object.keys(selectedAddress).length > 0) {
        setSelectedAddress(DefaultAddress);
      } else {
        const editedAddress = res.data.addresses.find((address) => address._id === selectedAddress._id) || {};
        setSelectedAddress(editedAddress);
      }
    } catch (error) {
      console.log({ addErr: error })
      popUp("There was a problem with server1. Please try again.")
    }
  }

  function handleDeliveryOption(val, id) {
    let del = [...delivery_option];
    let i = del.findIndex((obj) => obj._id === id);
    del[i].delivery_option = val;
    setDelivery_option(del);
  }

  function getPriceTotal() {
    let price = delivery_option.reduce((total, obj) => {
      if (obj.delivery_option === "express") {
        return total + 20;
      } else {
        return total + 0;
      }
    }, 0);
    return price >= 20 ? 20 : 0;
  }
  //discountPerUnit
  const calcDiscountPerUnit = (listings, coupon) => {
    let _filteredListings = listings.filter(l => {
      if (coupon.parameters.label === "Category" && coupon.parameters.value?.includes(l.category)) {
        return l
      }
      if (coupon.parameters.label === "Brand" && coupon.parameters.value?.includes(l.partBrand)) {
        return l
      }
      if (coupon.parameters.label === "Sub-Category" && coupon.parameters.value?.includes(l.subCategory)) {
        return l
      }
      if (coupon.parameters.label === "SKU" && !coupon.parameters.selectedAll && coupon.parameters.value?.includes(l._id)) {
        return l
      }
      if (coupon.parameters.label === "SKU" && coupon.parameters.selectedAll) {
        return l
      }
      if (coupon.parameters.label === "Seller" && coupon.parameters.value?.includes(l?.user?._id)) {
        return l
      }
    })

    let filteredListings = listings
    let totalVal = _filteredListings.reduce((total, obj) => {
      return total + obj.price * getCartLength(list.itemsInCart, obj)
    }, 0) / 1.05;

    let _totalVal = filteredListings.reduce((total, obj) => {
      return total + obj.price * getCartLength(list.itemsInCart, obj)
    }, 0) / 1.05;
    let discountPerUnit = 0

    if (_totalVal >= coupon?.min_cart_value && coupon?.discount_type?.label === "by_percent") {
      discountPerUnit = (coupon?.discount_type?.value / 100)
      // if (newTotal > coupon?.discount_cap_amount) {
      // let _discountPerUnit = discountPerUnit
      let cap_discountAmt = totalVal * discountPerUnit
      if (cap_discountAmt > coupon?.discount_cap_amount) {
        discountPerUnit = (coupon?.discount_cap_amount / totalVal)
      }

      // }
    }
    if (_totalVal >= coupon?.min_cart_value && coupon?.discount_type?.label === "by_value") {
      discountPerUnit = coupon?.discount_type?.value / totalVal
    }

    if (_totalVal < coupon?.min_cart_value) {
      discountPerUnit = 0
    }


    let payment_method = payOnCard ? 'Online Payment' : 'Cash on delivery'

    if (coupon.payment_method !== "All" && coupon.payment_method !== payment_method) {
      discountPerUnit = 0
    }


    // console.log({totalVal,discountPerUnit})
    //set total discount as well
    setDiscount(discountPerUnit * totalVal)
    setDiscountPerUnit(discountPerUnit)
  }

  const getFlattenListings = (listings) => {
    let listingsArray = []
    listings.forEach(l => {
      l.data.forEach(d => {
        listingsArray.push(d.listings[0])
      })
    })
    return listingsArray
  }

  useEffect(() => {
    coupon?._id && calcDiscountPerUnit(getFlattenListings(listings), coupon)
  }, [list, coupon, payOnCard, listings])



  const verifyCoupon = async () => {
    try {
      if (couponCode) {
        const res = await axios.post('/api/coupon/verify-coupon', { coupon_code: couponCode, user_id: login._id, listings: getFlattenListings(listings)?.map(l => ({ ...l, qty: getCartLength(list.itemsInCart, l) })), payment_method: payOnCard ? 'Online Payment' : 'Cash on delivery' })
        let _coupon = { ...res.data.data }
        setCoupon(_coupon)
        setCouponShowModal(false)
        setWalletAmount(0)
        setPayByWallet(false)
        setDisabled(false)

        if (partialPaymentType === '') {
          _handleCash()
        } else {
          setPartialPaymentType('')//and useEffect will set the  payment type
        }

        popUp("Coupon applied successfully.");
      }
    } catch (error) {
      setCoupon({})
      console.log(error.response.data.message)
      popUp(error.response?.data?.message || "Invalid coupon code.");
    }
  }

  const handleAddFormModal = (res) => { setIsOpenAddModal(res); };

  const handleEditFormModal = (res) => setIsOpenEditModal(res);

  useEffect(() => {
    if (partialPaymentType === 'cash_on_delivery') {
      handleCash()
    }
    if (partialPaymentType === 'online_payment') {
      handleCard()
    }
  }, [partialPaymentType])

  const _handleCash = () => {
    setpayOnCard(false);
    setSelectedDelivery(true);
    setSelectedCreditCard(false);
    window.paymentSessionId = undefined
    window.isSessionExpired = undefined
  }


  const handleCash = () => {
    if (disabled) {
      return
    }
    _handleCash()

  }

  const handleCard = () => {
    if (disabled) {
      return
    }
    setpayOnCard(true);
    setSelectedDelivery(false);
    setSelectedCreditCard(true);
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=350,left=500,top=150`;

    let paymentWin = window.open('/payment', 'online_payment', params);
    paymentWin.focus();

    //on open
    paymentWin.onload = function () {
      window.paymentSessionId = undefined
      window.isSessionExpired = undefined
    }


    //on close
    paymentWin.onbeforeunload = function () {
      if (!window.paymentSessionId && !window.isSessionExpired) {
        handleCash()
      }
      if (window.isSessionExpired) {
        popUp("Your session has expire or have provided invalid data. Please try again.");
      }

      return null;
    }

  }

  const handleWallet = () => {
    setPayByWallet(true);
    setSelectedDelivery(false);
    setSelectedCreditCard(false);
    setWalletShowModal(true);
  }

  const updateOrderProducts = (products, order) => {
    const noOfChunks = Math.ceil(products?.length / 20);
    let promises = []

    for (let i = 0; i < noOfChunks; i++) {
      const postData = {
        order_id: order?.order_id,
        products: products?.splice(0, 20)
      }

      promises.push(axios.put("/api/order/update-order-products", postData));
    }

    Promise.all(promises).then(res => {
      axios.get(`/api/order/send-order-notification/${order?.order_id}`)

      popUp("Your order was placed successfully!");
      window.paymentSessionId = undefined
      window.isSessionExpired = undefined

      if (list?.isBulkUpload) {
        dispatch({ type: "CLEAR_BULK_CART" })
      } else {
        dispatch({ type: "CLEAR_CART" });
        localStorage.removeItem("itemsInCart");
      }
      setListings([]);
      setdisabler(false);
      ReactGA.event({
        category: 'Order',
        action: 'Order Created'
      });
      history.push("/thankyou?order=" + order?.order_id);
    }).catch(async (err) => {
      await axios.delete(`/api/order/delete-order-by-id/${order?.order_id}`)
      window.paymentSessionId = undefined
      window.isSessionExpired = undefined
      window.isPaymentSuccess = undefined
      popUp("Your order placement wasn't successful!", "error");
      setdisabler(false);
    })
  }


  function placeOrder(e) {
    //here warehouse[0] is default model
    setdisabler(true);
    const buss_model = ["Consolidation", "Fulfilment"];
    const defaultWarehouse = null;
    const order_id = uuidv4().replace("-", "").slice(0, 8).toUpperCase();
    const today = moment().tz("Asia/Dubai").unix() * 1000;
    const stockinfo = [];

    let products = delivery_option.map((res) => {
      stockinfo.push({
        listing_id: res._id,
        quantity: getCartLength(list.itemsInCart, res),
        // stockQuantity: res.quantity,
      });
      return {
        listing: res._id,
        quantity: getCartLength(list.itemsInCart, res),
        seller: res?.user?._id,
        status: "PENDING",
        delivery_option: res,
        shipping_price_individual: 0,
        warehouse: buss_model.includes(res.business_model)
          ? defaultWarehouse
          : null,
      };
    });

    let price1 = delivery_option.reduce((t, c) => {
      return t + c.price * getCartLength(list.itemsInCart, c);
    }, 0);
    let totalAmt = parseFloat(localStorage.getItem("totalAmountInCart"))
    let cartValue = localStorage.getItem("totalAmountInCart")
    let discount1 = 0
    if (Number(cartValue) >= coupon?.min_cart_value && coupon?.discount_type?.label === "by_percent") {
      discount1 = totalAmt * coupon?.discount_type?.value / 100
    }
    if (Number(cartValue) >= coupon?.min_cart_value && coupon?.discount_type?.label === "by_value") {
      discount1 = coupon?.discount_type?.value
    }

    const totalProducts = [...products];

    let order = {
      currency: currency?.currency,
      products: (totalProducts?.length <= 20 || !list?.isBulkUpload) ? products : products?.splice(0, 20),
      delivery_address: selectedAddress || defaultAddress,
      pay_on_card: payOnCard,
      discount: discount,
      coupon,
      total_price: price1 + getPriceTotal(),
      shipping_price: getPriceTotal(),
      user: login._id,
      walletAmount: +walletAmount,
      order_date: today,
      order_id,
      order_note: orderNote,
      paymentSessionId: window.paymentSessionId,
      isPaidOnline: window.paymentSessionId ? true : false,
      isProductsRemaining: totalProducts?.length > 20 && list?.isBulkUpload
    };
    axios
      .post("/api/order/place-an-order", order)
      .then(async (res) => {
        const orderedListing = res.data?.order;

        if (totalProducts?.length > 20 && list?.isBulkUpload) {
          await updateOrderProducts(products, orderedListing[0])
        } else {
          popUp("Your order was placed successfully!");
          window.paymentSessionId = undefined
          window.isSessionExpired = undefined

          if (list?.isBulkUpload) {
            dispatch({ type: "CLEAR_BULK_CART" })
          } else {
            dispatch({ type: "CLEAR_CART" });
            localStorage.removeItem("itemsInCart");
          }
          setListings([]);
          setdisabler(false);
          ReactGA.event({
            category: 'Order',
            action: 'Order Created'
          });
          history.push("/thankyou?order=" + orderedListing[0]?.order_id);
        }
      })
      .catch(async (err) => {
        // window.paymentSessionId = undefined
        // window.isSessionExpired = undefined
        window.isPaymentSuccess = undefined
        // //3ds payments        
        if (err.response.data?.paymentRes?.state == "AWAIT_3DS") {
          let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=350,left=500,top=150`;

          let threeDspaymentWin = window.open('/3ds-payment', '3ds_payment', params);
          threeDspaymentWin.paymentRes = err.response.data.paymentRes
          threeDspaymentWin.focus();

          //on open
          threeDspaymentWin.onload = function () {
          }


          //on close
          threeDspaymentWin.onbeforeunload = function () {
            let paymentRes = err.response.data.paymentRes;
            let isPaymentSuccess = window.isPaymentSuccess;

            paymentRes.state = isPaymentSuccess ? "CAPTURED" : "AWAIT_3DS";
            let data = {
              paymentRes,
              isPaymentSuccess,
              paymentSessionId: window.paymentSessionId,
              // isProductsRemaining: products?.length > 20
            }

            axios
              .put("/api/order/update-payment", data)
              .then(() => {
                popUp("Your order was placed successfully!");
                window.paymentSessionId = undefined
                window.isSessionExpired = undefined
                window.isPaymentSuccess = undefined
                dispatch({ type: "CLEAR_CART" });
                localStorage.removeItem("itemsInCart");
                setListings([]);
                setdisabler(false);
                history.push("/thankyou?order=" + order_id);
                // setTimeout(() => {
                //   // dispatch(updatestock(stockinfo));
                // }, 5000);

              })
              .catch(async (err) => {
                await axios.delete(`/api/order/delete-order-by-id/${order_id}`)
                window.paymentSessionId = undefined
                window.isSessionExpired = undefined
                window.isPaymentSuccess = undefined
                popUp("Your payment card is invalid!");
                setdisabler(false);
              })
          }
        } else {
          let error = err?.response?.data?.message || "Your order cannot be placed now!"
          popUp(error);
          setdisabler(false);
        }

      });
  }

  function handleConfirm(e, id, list) {
    setShowModal(true);
    setPassId(id);
    setPassevent(e);
    setPassName(list?.partName);
  }

  function getListings() {
    // setLoader(true); 
    axios
      .post("/api/listing/cart-listings", { listings: list.itemsInCart, user: login._id })
      .then((res) => {
        const newListings = [];
        let total_price = 0;
        let initalDeliveryOptions = [];
        setLoader(false);
        res.data.forEach((item, i) => {
          newListings.push({ user: item.user, listings: [item] });

          total_price += item.price * getCartLength(list.itemsInCart, item);

          return true;
        });
        newListings.map((item) => {
          return item.listings.forEach((list) => {
            initalDeliveryOptions.push({
              ...list,
              item_id: list._id,
              delivery_option: "next_day",
            });
          });
        });
        setPrice(total_price);
        setTempsubTotal(total_price);
        setListings(changeToGroup(newListings));
        setDelivery_option(initalDeliveryOptions);
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
        popUp("There was a problem with server. Please try again.");
      });
    fetchAddress();
  }


  let newShippingPrice = (getPriceTotal() - (getPriceTotal() - getPriceTotal() / 1.05)).toFixed(2);

  const updatestockflag = (flag) => {
    flags.current = flag;
    return <InfoIcon style={{ color: "orange", fontSize: 24 }} />;
  };

  const handleClearCart = () => {
    dispatch({ type: "CLEAR_BULK_CART" });
    setShowModal(false);
  }

  // console.log({coupon})


  let s1 = { listings, totalAmount, getCartLength, list, updatestockflag, qtyDecrement, qtyIncrement, handleConfirm, getTaxAmount, discount, coupon, discountPerUnit, changeCartQty }
  let s1Bulk = { listings, getCartLength, list, updatestockflag, qtyDecrement, qtyIncrement, handleConfirm, coupon, discountPerUnit, changeCartQty }
  let s2 = { addresses, setSelectedAddress, selectedAddress, setIsOpenEditModal, setEditedAddressId, handleAddFormModal }
  let s3 = { delivery_option, selectedAddress, defaultAddress, handleDeliveryOption, getCartLength, list }
  let s4 = { setpayOnCard, setDisabled, setSelectedDelivery, setSelectedCreditCard, payOnCard, selectedCreditCard, selectedDelivery, setShowPaymentModal, payByWallet, wallet, handleCash, handleCard, handleWallet }
  let s5 = { selectedAddress, login, formatPhoneNumber, payOnCard, stepForward, list, totalAmount, getCartLength, discountPerUnit, coupon, delivery_option, payByWallet, partialPaymentType }
  let sReview = { step, orderNote, login, formatPhoneNumber, selectedAddress, classes, getTaxAmount, newShippingPrice, getPriceTotal, flags, stepForward, disabler, placeOrder, couponCode, setCouponCode, setCouponShowModal, discount, coupon, setOrderNote, walletAmount }

  let aForm = { stateData, regionData, locationData }
  let exc_tax_total = parseFloat(localStorage.getItem("totalAmountInCart")) / 1.05
  let discount1 = discount ? discount : 0

  let tax = (exc_tax_total - discount1 + Number(newShippingPrice)) * 0.05

  let total = exc_tax_total - discount1 + Number(newShippingPrice) + tax

  if (loader || list?.isBulkListLoading)
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );

  if (!list.itemsInCart.length && !list.bulkCartList.length) {
    return (
      <CheckoutEmpty />
    );
  }

  return (
    <>

      {/* <Header className="menu--light" /> */}
      <PageBanner />
      <ConfirmModal
        showModal={showModal}
        onClose={handleCloseModal}
        eventFunc={passName ? removeCartItem : handleClearCart}
        id={passId ? passId : null}
        e={passevent}
        name={passName ? passName : "all items in the cart"}
      />

      <CouponConfirmModal
        showModal={couponShowModal}
        onClose={() => setCouponShowModal(false)}
        eventFunc={verifyCoupon}
      />

      <PaymentModal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        setPaymentSessionId={setPaymentSessionId}
      ></PaymentModal>

      <WalletPaymentModal
        isOpen={walletShowModal}
        onClose={() => setWalletShowModal(false)}
        wallet={wallet}
        setPayByWallet={setPayByWallet}
        setDisabled={setDisabled}
        setWalletAmount={setWalletAmount}
        handleCash={handleCash}
        walletAmount={walletAmount}
        partialPaymentType={partialPaymentType}
        max={Number(total.toFixed(2))} //need to change
        setPartialPaymentType={setPartialPaymentType}
        _handleCash={_handleCash}
      />

      <AddForm
        {...aForm}
        isOpenAddModal={isOpenAddModal}
        handleEditFormModal={handleEditFormModal}
        handleAddFormModal={handleAddFormModal}
        fetchAddress={fetchAddress}
      />
      <EditForm
        {...aForm}
        isOpenEditModal={isOpenEditModal}
        handleEditFormModal={handleEditFormModal}
        editedAddressId={editedAddressId}
        fetchAddress={fetchAddress}
      />

      <section className="checkout-wrapper section-padding-strict bgcolor-white pb-5">
        <Steps step={step} />
        <div className="container">
          <StepButton step={step} stepForward={stepForward} />
          <div className="row">
            <div className="col-lg-9">
              <StepDetailText step={step} />
              <div className="checkout-form">
                {
                  list?.isBulkUpload && step === 1 &&
                  <div className="d-flex justify-content-end mb-2">
                    <button
                      htmlFor="customUpload"
                      className="btn btn-sm btn-primary text-white d-flex"
                      onClick={(e) => handleConfirm(e)}
                    >
                      <RemoveShoppingCart
                        fontSize="small"
                      />
                      Clear Cart
                    </button>
                  </div>
                }
                <div className={classes.root}>
                  {step === 1 && (
                    <>
                      {
                        list?.isBulkUpload ?
                          <BulkOrderStep1 {...s1Bulk} /> :
                          <Step1 {...s1} />
                      }
                    </>
                  )}
                  {step === 2 && (
                    <Step2 {...s2} />
                  )}
                  {step === 3 && (
                    <Step3 {...s3} />
                  )}
                  {step === 4 && (
                    <Step4 {...s4} />
                  )}
                </div>
                {step === 5 && (
                  <Step5 {...s5} />
                )}
              </div>
            </div>
            {step !== 6 && (list?.bulkCartList?.length > 0 || list?.itemsInCart?.length > 0) && (
              <StepReview {...sReview} />
            )}
          </div>
        </div>
      </section>
      <Backdrop style={{ zIndex: 100000 }} open={disabler}>
        <Box bgcolor="#fff" padding="2rem 1.5rem" borderRadius="8px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <CircularProgress color="primary" size={40} />
          <Box mt="1rem">
            <Typography align="center" variant="h6" color="white">Finalizing you order.</Typography>
            <Typography align="center" variant="h6" color="white">Please don't refresh the page while it is in progress.</Typography>
          </Box>
        </Box>
      </Backdrop>

    </>
  );
};
