import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { PageBanner } from "../../content/element/page-banner";
import PreHeader from "../../layout/pre-header";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { Row, Col } from "reactstrap";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

const Payment = (props) => {
  const [modal, setModal] = useState(false);
  const [saveAddress, setSaveAddress] = useState(true);

  const ModalPaySecurelyOnline = (props) => {
    const toggle = () => {
      setModal(!modal);
    };

    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Pay Securely Online</ModalHeader>
          <Typography
            className=""
            style={{ textAlign: "left", marginLeft: "10px" }}
          >
            <p>*indicates required fields</p>
          </Typography>
          <ModalBody style={{ padding: "20px" }}>
            <div style={{ textAlign: "center" }}>
              <img
                style={{ width: "200px", marginLeft: "" }}
                src="/assets/img/stepperimg/icons.png"
                alt="icons"
              />
            </div>
            <br />

            <Row>
              <Col>
                <FormGroup>
                  <Label for="nameonCard">Name on card</Label>
                  <Input
                    type="text"
                    name="nameonCard"
                    id="nameonCard"
                    placeholder="Name on card"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="debitCreditNum">Debit/Credit card number</Label>
                  <Input
                    type="text"
                    name="debitCreditNum"
                    id="debitCreditNum"
                    placeholder="Debit/Credit card number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="expirydate">Expiry Date</Label>
                      <Input
                        type="text"
                        name="month"
                        id="month"
                        placeholder="Month"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="expirydate">.</Label>
                      <Input
                        type="text"
                        name="year"
                        id="year"
                        placeholder="Year"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="securityCode">Security Code</Label>
                  <Input
                    type="text"
                    name="securityCode"
                    id="securityCode"
                    placeholder="Security Code"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Pay and Checkout
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  return (
    <Fragment>
      {/* Header section start */}
      {/*  
      <Header /> */}
      {/* Header section end */}
      <section className="blog-area blog-grid section-padding-strict section-bg">
        <div className="container">
          {ModalPaySecurelyOnline()}
          <Typography className="" style={{ textAlign: "left" }}>
            <h3>Shippings</h3>
            <p>*indicates required fields</p>
          </Typography>
          <Row>
            <Col xs="8" sm="8">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Card>
                  <CardBody>
                    <CardTitle tag="h5" style={{ textAlign: "left" }}>
                      How would you like to pay?
                    </CardTitle>
                    <br />

                    <Row>
                      <Col>
                        <Card
                          style={{
                            border: "solid 2px lightgrey",
                            padding: "20px",
                          }}
                        >
                          <img
                            style={{ width: "100px", marginLeft: "35%" }}
                            src="/assets/img/stepperimg/cashondelivery.png"
                            alt="cashondelivery"
                          />
                          <Typography>Cash on delivery</Typography>
                        </Card>
                      </Col>
                      <Col>
                        <Card
                          style={{
                            border: "solid 2px lightgrey",
                            padding: "15px",
                          }}
                          onClick={() => {
                            setModal(true);
                          }}
                        >
                          <img
                            style={{ width: "100px", marginLeft: "35%" }}
                            src="/assets/img/stepperimg/cashsecurely.png"
                            alt="cashsecurely"
                          />
                          <Typography>Pay securely online</Typography>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col sm="4">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Card>
                  <CardBody>
                    <CardTitle tag="h5" style={{ textAlign: "left" }}>
                      Order Summary
                    </CardTitle>
                    {/* <CardSubtitle tag="h6" className="mb-2 text-muted">
                      Card subtitle
                    </CardSubtitle> */}
                    <div style={{ padding: "" }}>
                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>
                          Subtotal
                        </p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          76.40$
                        </p>
                      </div>
                      <br />
                      <br />
                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>
                          Discount
                        </p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          36.12$
                        </p>
                      </div>

                      <br />
                      <br />

                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>Tax</p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          1.12$
                        </p>
                      </div>
                      <br />
                      <br />

                      <div>
                        <p style={{ textAlign: "left", fontWeight: "bold" }}>
                          Shipping
                        </p>
                        <Typography style={{ textAlign: "left" }}>
                          Tommy A car parts
                        </Typography>
                        <Typography style={{ textAlign: "left" }}>
                          Tommy B car parts
                        </Typography>
                      </div>
                      <br />
                      <div>
                        <FormGroup>
                          <Input
                            style={{ float: "left", width: "70%" }}
                            placeholder="Gift card/Discount code"
                            type="text"
                            name="text"
                            id="text"
                          />
                        </FormGroup>
                        <Button
                          style={{ float: "right" }}
                          color="primary"
                          size="sm"
                          style={{ width: "" }}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <p style={{ float: "left", fontWeight: "bold" }}>Total</p>
                      <p style={{ float: "right", fontWeight: "bold" }}>
                        40.45$
                      </p>
                    </div>
                    <br />

                    <br />
                    <div>
                      <Button color="primary" style={{ width: "100%" }}>
                        Make a payment
                      </Button>
                    </div>
                  </CardBody>
                  <Alert
                    color="warning"
                    style={{
                      padding: "10px",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    Free standard shipping to U.A.E. when you spend over AED100.00
                  </Alert>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps)(Payment);
