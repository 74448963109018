
import React from 'react';
import { popUp, validateFile,validPassword } from "../../../../utils";
import axios from 'axios' 
import { LogOut } from "../../../../Store/action/logoutAction";
import { UpdateUserInfo } from "../../../../Store/action/loginActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
class UpdatePassword extends React.Component {
    constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      submitLoadingPassword:false
    };
     
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeOld = this.handleChangeOld.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }
     
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  
    this.setState({
      input
    });
    this.validate()
  }
  handleChangeOld(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  
    this.setState({
      input
    });
  }
     
  handleSubmit(event) {
    event.preventDefault();
  
    if(this.validate()){
        this.setState({submitLoadingPassword:true})
  
        let input = {};
        input["oldpassword"] = "";
        input["password"] = "";
        input["confirm_password"] = "";
        this.setState({input:input});

                     let data={
                            id:this.props.login?._id,
                            password:this.state.input.password,
                            oldpassword:this.state.input.oldpassword
                          }
                          axios
                          .patch( 
                            `/api/users/update-profile-password`,data
                          )
                          .then((res) => {
                            console.log({res})
                              this.setState({submitLoadingPassword:false})
                              if(res?.data?.status==='error'){
                                popUp(res?.data?.message, "error");
                                
                              }else{
                                popUp("Password changed successfully!");
                              }
                              // this.props.logOutdata(null)
                          
                          })
                          .catch((err) => {
                            console.log('errrr',err)
                            this.setState({submitLoadingPassword:false})
                            popUp("There was a problem with server. Please try again.");
                       
                          });



    }
  }
  
  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
  
     
     
      if (typeof input["password"] !== "undefined") {
          
        var pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
        if (!pattern.test(input["password"])) {
          isValid = false;
          errors["password"] = "Your Password must contain : Minimum eight characters, at least one uppercase letter, one lowercase letter,one number and one special character.!";
        }
      }
  
      if (!input["password"]) {
        isValid = false;
        errors["password"] = "Please enter your password.";
      }
  
      if (!input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Please enter your confirm password.";
      }
  
      if (typeof input["confirm_password"] !== "undefined") {
          
        var pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
        if (!pattern.test(input["confirm_password"])) {
          isValid = false;
          errors["confirm_password"] = " Your Password must contain : Minimum eight characters, at least one uppercase letter, one lowercase letter,one number and one special character.!";
        }
      }
      if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
          
        if (input["password"] != input["confirm_password"]) {
          isValid = false;
          errors["password"] = "Passwords don't match.";
        }
      } 
  
  
      this.setState({
        errors: errors
      });
  
      return isValid;
  }
     
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div class="form-group">
            <label htmlFor="oldpassword" className="form-label">
                        Old Password
            </label>
            <input 
              type="password" 
              name="oldpassword" 
              value={this.state.input.oldpassword}
              onChange={this.handleChangeOld}
              class="form-control" 
              placeholder="Enter Oldpassword" 
              id="oldpassword" />
  
              <div className="text-danger">{this.state.errors.oldpassword}</div>
          </div>
   
          <div class="form-group">
            <label htmlFor="password" className="form-label">
                        New Password
            </label>
            <input 
              type="password" 
              name="password" 
              value={this.state.input.password}
              onChange={this.handleChange}
              class="form-control" 
              placeholder="Enter New password" 
              id="password" />
  
              <div className="text-danger">{this.state.errors.password}</div>
          </div>
  
          <div class="form-group">
            <label htmlFor="confirm_password" className="form-label">
                        Confirm New Password
            </label>
            <input 
              type="password" 
              name="confirm_password" 
              value={this.state.input.confirm_password}
              onChange={this.handleChange}
              class="form-control" 
              placeholder="Enter confirm password" 
              id="confirm_password" />
  
              <div className="text-danger">{this.state.errors.confirm_password}</div>
          </div>
              
          <div className="form-group">
                      <button
                        type="submit"
                        disabled={
                          this.state.input.password==='' || this.state
                          .input.confirm_password==='' ||this.state.input.oldpassword==''
                        }
                        className="btn btn-sm btn-primary listing_submit_btn"
                        value="Submit"
                      >
                        {this.state.submitLoadingPassword && (
                          <i className="las la-spinner la-spin mr-2"></i>
                        )}
                        Update Password
                      </button>
                    </div>
        </form>
      </div>
    );
  }
}
  
const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    updateUserInfo: (data) => dispatch(UpdateUserInfo(data)),
    logOutdata: (login) => dispatch(LogOut(login)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProp
)(withRouter(UpdatePassword));

