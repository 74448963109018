const initState = {
    categories: [],
    subCategories: [],
    allCategories: [],
    isAllCategoriesLoading: false,
}

const CategoryReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_CATEGORIES':
            return {
                ...state,
                categories: action.data
            }
        case 'GET_ALL_CATEGORIES_LOADING':
            return {
                ...state,
                isAllCategoriesLoading: true
            }
        case 'GET_ALL_CATEGORIES':
            return {
                ...state,
                allCategories: action.data,
                isAllCategoriesLoading: false
            }
        case 'GET_ALL_CATEGORIES_ERROR':
            return {
                ...state,
                allCategories: [],
                isAllCategoriesLoading: false
            }
        case 'GET_SUB_CATEGORIES':
            return {
                ...state,
                subCategories: action.data
            }

        default:
            return state;
    }
};
export default CategoryReducer;
