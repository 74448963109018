import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

const ConfirmDialogBox = ({ confirmDialog, setConfirmDialog }) => {
  const handleClose = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  return (
    <div>
      <Dialog
        open={confirmDialog.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ position: "relative" }}>
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 10,
              top: 0,
              fontSize: 20,
              cursor: "pointer",
            }}
          >
            x
          </div>
          <div
            style={{
              width: "100%",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <HighlightOffRoundedIcon
              style={{
                fontSize: 80,
                marginLeft: "auto",
                marginRight: "auto",
                color: "rgb(243, 146, 0)",
              }}
            />
            <h6>Are you sure?</h6>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ textAlign: "center", color: "#686a6b" }}>
              {confirmDialog.title}
              <br />
              <span
                style={{ color: "rgb(35, 36, 35)", fontWeight: 600 }}
              ></span>
              <br />
              {confirmDialog.subTitle}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ paddingRight: "20%", paddingLeft: "20%", marginBottom: 10 }}
        >
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#1d71b8", width: 150 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={confirmDialog.onConfirm}
            style={{
              backgroundColor: "rgb(243, 146, 0)",
              color: "#fff",
              width: 150,
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmDialogBox;
