import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import { baseUrl, topModals } from "../../../constants";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FaRegCommentDots } from "react-icons/fa";
import MobileMenu from "./MobileMenu";
import { ArrowForwardIos, KeyboardArrowLeft, KeyboardArrowRight, Menu } from "@material-ui/icons";

class NewNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      subCategories: [],
      sortedArrayCategories: [],
      sortedArraySubCategories: [],
      openSelectSearchOption: false,
      isMouseIn: false,
      leftHide: false,
      rightHide: true
    };
  }

  // componentDidMount() {
  //   axios.get(`${baseUrl}/api/categories/all`).then((res) => {
  //     this.setState({ categories: res.data });
  //     let sortedArrayCategories = res.data.sort((a, b) =>
  //       a?.name.localeCompare(b.name)
  //     );
  //     this.setState({ sortedArrayCategories: sortedArrayCategories });
  //   });
  // }

  getCategories = () => {
    this.setState({ categories: this.props.allCategories });


    let sortedArrayCategories = this.props.allCategories.sort((a, b) =>
      a?.name.localeCompare(b.name)
    );
    this.setState({ sortedArrayCategories: sortedArrayCategories });

  }

  componentDidMount() {
    // axios.get(`${baseUrl}/api/categories/all`).then((res) => {
    //   this.setState({ categories: res.data });
    //   let sortedArrayCategories = res.data.sort((a, b) =>
    //     a?.name.localeCompare(b.name)
    //   );
    //   this.setState({ sortedArrayCategories: sortedArrayCategories });
    // });
    this.getCategories();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("this.props", this.props.allCategories, prevProps);
  //   if (prevProps.allCategories !== this.props.allCategories) {
  //     this.getCategories();
  //     let result = this.props.allCategories.find(
  //       (cat) => cat.name === this.props.activeCategory
  //     );
  //     if (result) {// axios.get(`${baseUrl}/api/subcategories/${result?._id}`).then((res) => {
  //       this.setState({ subCategories: result.subCategories });
  //       const sortedArraySubCategories = result.subCategories.sort((a, b) =>
  //         a?.name.localeCompare(b.name)
  //       );
  //       this.setState({ sortedArraySubCategories: sortedArraySubCategories });
  //       // });}
  //     }
  //   }
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeCategory !== this.props.activeCategory) {
      this.getCategories();
      let result = this.props.allCategories.find(
        (cat) => cat.name === this.props.activeCategory
      );
      if (result) {// axios.get(`${baseUrl}/api/subcategories/${result?._id}`).then((res) => {
        this.setState({ subCategories: result.subCategories });
        const sortedArraySubCategories = result.subCategories.sort((a, b) =>
          a?.name.localeCompare(b.name)
        );
        this.setState({ sortedArraySubCategories: sortedArraySubCategories });
        // });}
      }
    }

    if (prevProps.allCategories !== this.props.allCategories) {
      this.getCategories();

    }
  }

  slideLeft = () => {
    this.setState({ rightHide: true, leftHide: false })
    const slider = document.getElementById("sliders");
    slider.scrollLeft = slider.scrollLeft - 500
  }

  slideRight = () => {
    this.setState({ leftHide: true })
    const slider = document.getElementById("sliders");
    slider.scrollLeft = slider.scrollLeft + 500;
  }

  render() {
    const inHeaderCategory = [
      "Service Parts",
      "Suspension Parts",
      "Drivetrain System",
      "Engine Parts",
      "Batteries & Electricals",
      "Lubricants & Fluids",
      "Engine Cooling System",
      "Body & Chassis Parts",
      "Air Conditioning",
      "Steering Parts"
    ];
    const {
      activeCategory,
      displayOthersCategoryDiv,
      displayAllCategoryDiv,
      handleDisplayOthersCategoryDiv,
      handleDisplayAllCategoryDiv,
      handleCategoryHover,
    } = this.props;
    const { sortedArrayCategories, sortedArraySubCategories } = this.state;

    return (
      <Fragment>
        <nav className="navbar navbar-expand-lg navbar-light bg-light headNav " >  {(
          <MobileMenu
            menuList={sortedArrayCategories}
            activeCategory={activeCategory}
            handleDisplayOthersCategoryDiv={handleDisplayOthersCategoryDiv}
            isMouseIn={this.state.isMouseIn}
          />
        )}</nav>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light headNav categories-container"
          style={{ whiteSpace: "nowrap" }}>
          <li
            className="nav-item dropdown px-3"
            style={{
              borderRight: "1px solid rgba(211, 208, 208, 0.747)",
              fontSize: "13px",
              fontWeight: "700",
              paddingLeft: "15px",
            }}
            onMouseEnter={(e) => handleDisplayAllCategoryDiv(e, true)}
          >
            <NavLink to="/all-listings">
              <Menu style={{ fontSize: 20, margin: "auto 0" }} /> All
            </NavLink>
          </li>

          {this.state.leftHide &&
           <div style={{ cursor: "pointer", marginBottom: "6px", }} onClick={() => this.slideLeft()}>
            <KeyboardArrowLeft />
          </div>}

          <div
            className="navbar-collapse pr-2 navbar-scroll"
            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
            id="sliders"
          >
            <ul className="navbar-nav mr-auto">
              {inHeaderCategory.map((item, index) => {
                return (
                  <li
                    className={`nav-item px-3 ${activeCategory === item && "activecategory"}`}
                    key={index}
                    style={{
                      borderRight: "1px solid rgba(211, 208, 208, 0.747)",
                      fontSize: "13px",
                      fontWeight: "700",
                      paddingLeft: "15px",
                    }}
                    onMouseEnter={(e) => handleDisplayOthersCategoryDiv(e, true, item)}
                  >
                    <NavLink to='#'>
                      <span className={`navitem-css ${activeCategory === item && "activetextcolor"}`}
                        onClick={(e) => {
                          handleDisplayOthersCategoryDiv(e, true, item);
                        }}
                        onMouseEnter={(e) => {
                          this.setState({ isMouseIn: true });
                        }}
                        onMouseOut={(e) => {
                          this.setState({ isMouseIn: false });
                        }}
                        style={{ lineHeight: "1px" }}>
                        {item}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>

          {this.state.rightHide &&
            <div style={{ cursor: "pointer", marginBottom: "6px" }} onClick={() => this.slideRight()}>
              <KeyboardArrowRight />
            </div>}


        </nav>

        {displayOthersCategoryDiv && (
          <div
            className="show-li-categories"
            onMouseLeave={(e) => handleDisplayOthersCategoryDiv(e, false)}
          >
            <div className="row">
              <div className="col-md-5">
                <div className="d-flex flex-column individual-li-div">
                  <p className="individual-li-title">Categories</p>
                  <ul>
                    {sortedArraySubCategories.length > 0 &&
                      sortedArraySubCategories.map((item, index) => {
                        let subCat = item?.name
                          ?.toLowerCase()
                          ?.replaceAll(" ", "-")
                          ?.replaceAll("/", "-");
                        return (
                          <li
                            key={index}
                            style={{
                              padding: 0,
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                            onClick={(e) => {
                              handleDisplayOthersCategoryDiv(e, false);
                            }}
                          >
                            <NavLink
                              to={`/spare-parts/${activeCategory
                                .toLowerCase()
                                .replaceAll(" ", "-")}/${subCat}`}
                            >
                              {item.name}
                            </NavLink>
                          </li>
                        );
                      })}
                    <li
                      key={'index'}
                      style={{
                        padding: 0,
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      <NavLink
                        to={`/spare-parts/${activeCategory
                          .toLowerCase()
                          .replaceAll(" ", "-").replaceAll("/", "-")}`}
                      >
                        All {activeCategory}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        {displayAllCategoryDiv && (
          <div
            className="show-li-allcategories"
            onMouseLeave={(e) => handleDisplayAllCategoryDiv(e, false)}
          >
            <div className="side-menu" style={{ backgroundColor: "#f5f7fc" }}>
              <ul
                className={`mega-menu-side-menu`}
                style={{
                  paddingLeft: "15px",
                  width: "235px",
                  borderRight: "1px solid rgba(211, 208, 208, 0.747)",
                }}
              >
                {sortedArrayCategories.map((cat, index) => {
                  return (
                    <li
                      key={index}
                      className={`${this.state.activeCategory === cat.value ? "active" : ""
                        }`}
                      onMouseEnter={(e) => handleCategoryHover(e, cat.name)}
                      onClick={(e) => handleDisplayAllCategoryDiv(e, false)}

                    >
                      <NavLink
                        to={`/spare-parts/${activeCategory
                          .toLocaleLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        {cat.name}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="sub-menu-list" style={{ paddingTop: "20px", overflow: 'auto', width: '100%' }}>
              <ul
                className="category-list"
                style={{
                  listStyle: "none",
                  paddingInlineStart: "0",
                  padding: "0 15px",
                }}
              >
                <h3 style={{ fontSize: "16px" }}>{activeCategory}</h3>
                <div className="cat-line" />
                <div className="subCat-list">
                  <div className="subcat-left">
                    <h5 style={{ marginBottom: 10, fontSize: "14px" }}>
                      Most Popular
                    </h5>
                    <ul>
                      {sortedArraySubCategories.length > 0 &&
                        sortedArraySubCategories.map((item, index) => {
                          let subCat = item?.name
                            ?.toLowerCase()
                            ?.replaceAll(" ", "-")
                            ?.replaceAll("/", "-");
                          return (
                            <li
                              key={index}
                              style={{
                                padding: 0,
                                fontSize: "12px",
                                fontWeight: "400",
                                margin: "10px 0px",
                              }}
                              onClick={(e) => handleDisplayAllCategoryDiv(e, false)}

                            >
                              <NavLink
                                to={`/spare-parts/${activeCategory
                                  .toLowerCase()
                                  .replaceAll(" ", "-")}/${subCat}`}
                              >
                                {item.name}
                              </NavLink>
                            </li>
                          );
                        })}
                    </ul>

                    {activeCategory && (
                      <li>
                        <NavLink
                          to={`/spare-parts/${activeCategory
                            .toLocaleLowerCase()
                            .replaceAll(" ", "-")}`}
                        >
                          All {activeCategory}
                        </NavLink>
                      </li>
                    )}
                  </div>

                </div>
              </ul>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    allCategories: state.category.allCategories

  };
};
const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(NewNavbar);
