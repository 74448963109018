import React, { Component, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import queryString from "query-string";

import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import Listing from "../container/all-listing";

import {
  Initialize,
  FilterListing,
  SetSearchQuery,
  setFilters,
  setPathname,
} from "../../Store/action/listingActions";
import axios from "axios";

// import { subCategories, categories } from "../../constants";
import { BreadcrumbCategory } from "../content/element/breadcrumb";
import VisitorModal from "../content/element/modal/visitor-modal";

class AllListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationPath: "",
      count: 1,
      categories: [],
      subCategories: { data: [] },
      showVisitorModal: true,
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.initialize = this.initialize.bind(this);
    this.closeVisitorModal = this.closeVisitorModal.bind(this);
  }

  async componentDidMount() {
    let search = "";
    const categoriess = await axios.get("/api/categories/all");
    this.setState({ categories: categoriess?.data });

    const subcactegories = await axios.get("/api/subcategories/all");
    this.setState({ subCategories: subcactegories?.data });

    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (params.search) search = params.search;
    if (search) {
      this.props.setPathname(
        `${this.props.location.pathname}/?search=${search}`
      );
    } else {
      this.props.setPathname(this.props.location.pathname);
    }
    if (
      params.api !== "true" &&
      params.tire !== "true" &&
      params.batteries !== "true" &&
      params.image !== "true"
    ) {
      this.setState({ locationPath: this.props.location.pathname }, () => {
        this.initialize();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.setState({ locationPath: this.props.location.pathname });
      const params = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });
      if (
        params.api !== "true" &&
        params.tire !== "true" &&
        params.batteries !== "true" &&
        params.image !== "true"
      ) {
        this.setState({ locationPath: this.props.location.pathname }, () => {
          this.initialize();
        });
      }
    }
  }

  initialize() {
    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let filterQuery = { search: "" };
    if (params.search) filterQuery.search = params.search;
    if (
      this.props.match.path.includes("/spare-parts/:category") &&
      this.props.match.params.category
    ) {
      const cat = this.state.categories?.filter(
        (e) =>
          e.name.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-") ===
          this.props.match.params.category
      );
      filterQuery.category = cat[0];
      if (this.props.match.params.subcategory) {
        const subCatList = this.state.subCategories?.data;
        const subCat = subCatList?.filter(
          (e) =>
            e?.name
              ?.toLowerCase()
              .replaceAll(" ", "-")
              ?.replaceAll("%20", "-")
              ?.replaceAll("/", "-") === this.props.match.params.subcategory
        );
        const catIndex = subCat?.findIndex(
          (obj) => obj?.category_id === cat[0]?._id
        );
        filterQuery.subcategory = subCat?.[catIndex];
      }
    }
    if (
      this.props.match.path === "/car-parts/:make" &&
      this.props.match.params.make
    )
      filterQuery.make = this.props.match.params.make.replace("-", " ");
    // this.props.setFilter(filterQuery);
    this.props.initializeListing(filterQuery, this.props?.list?.count);
  }

  //onClose modal
  closeVisitorModal = (e) => {
    e.preventDefault();
    this.setState({
      showVisitorModal: !this.state.showVisitorModal,
    });
  };

  handleFilter(query) {
    console.log("hello", query);
    this.props.filterListing(query);
  }
  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  render() {
    const { isLoading } = this.props.list;
    console.log(this.props.list?.searchQuery?.make);

    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let { category: cat1, subcategory: sub1 } = this.props.match.params;
    let cat = cat1
      ?.split("-")
      .map((res) => this.capitalizeFirstLetter(res))
      .join(" ");
    let sub = sub1
      ?.split("-")
      .map((res) => this.capitalizeFirstLetter(res))
      .join(" ");
    const currentUser = JSON.parse(localStorage.getItem("login"));
    return (
      <Fragment>
        {/* <LoadingOverlay active={isLoading} spinner text="Loading .."> */}

        {/* <Header className="menu--light" /> */}
        {(!currentUser || currentUser?.status === "Pending") &&
          this.props.list.count >= 3 ? (
          <>
            <VisitorModal
              showModal={this.state.showVisitorModal}
              onClose={this.closeVisitorModal}
              msg={
                "You have exceeded your visitor's limit ! Please Login Or Register for further process."
              }
            />
          </>
        ) : null}
        <Listing
          handleFilter={this.handleFilter}
          api={params.api}
          type={params.tire ? "tire" : params.batteries ? "batteries" : null}
          category={sub ? sub : cat}
          loading={isLoading}
        />

        {/* </LoadingOverlay> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    initializeListing: (data, data1) => dispatch(Initialize(data, data1)),
    setFilter: (filterQuery) => dispatch(setFilters(filterQuery)),
    filterListing: (query) => dispatch(FilterListing(query)),
    setSearchQuery: (query) => dispatch(SetSearchQuery(query)),
    setPathname: (pathname) => dispatch(setPathname(pathname)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(AllListing);
