import React from "react";
import { PageBanner } from "../page-banner";
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import PreHeader from "../../../layout/pre-header";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Table,
} from "reactstrap";
import { Typography } from "@material-ui/core";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

const MyMessages = () => {
  const [active, setActive] = React.useState("allmessages");
  const [filterOptions, setFilterOptions] = React.useState("allmessages");
  const messagesList = [
    {
      msgFile: "Yes",
      msgDescription: "Imp Message",
      msgFrom: "Buyer",
      msgReceived: "02 Mar",
      msgTypes: "buyer",
    },
    {
      msgFile: "Yes",
      msgDescription: "Imp Message",
      msgFrom: "Seller",
      msgReceived: "02 Mar",
      msgTypes: "seller",
    },
    {
      msgFile: "Yes",
      msgDescription: "Imp Message",
      msgFrom: "Buyer",
      msgReceived: "02 Mar",
      msgTypes: "buyer",
    },
    {
      msgFile: "Yes",
      msgDescription: "Imp Message",
      msgFrom: "Sender",
      msgReceived: "02 Mar",
      msgTypes: "sent",
    },
    {
      msgFile: "Yes",
      msgDescription: "Imp Message",
      msgFrom: "deleted msg",
      msgReceived: "02 Mar",
      msgTypes: "trash",
    },
  ];

  const filteredData = messagesList.filter((item) => {
    if (filterOptions === "allmessages") {
      return item;
    }
    return item.msgTypes === filterOptions;
  });
  return (
    <>
      {/* <PreHeader /> */}
      {/* <Header className="menu--light" /> */}
      <PageBanner />
      <div className="container mt-5 mb-5">
        <Row>
          <Col sm="12">
            <Card>
              <div
                className="row  box-shadow"
                style={{ maxHeight: "500px", minHeight: "500px" }}
              >
                <div className="col message-table-index">
                  <ul className="nav nav-pills nav-stacked mt-3">
                    <li
                      className={`${active === "allmessages" && "active-table-index"
                        } `}
                      onClick={() => {
                        setActive("allmessages");
                        setFilterOptions("allmessages");
                      }}
                    >
                      All Messages
                    </li>
                    <li
                      className={`${active === "buyparts" && "active-table-index"
                        } `}
                      onClick={() => {
                        setActive("buyparts");
                        setFilterOptions("buyer");
                      }}
                    >
                      From Buyparts24
                    </li>
                    <li
                      className={`${active === "seller" && "active-table-index"
                        } `}
                      onClick={() => {
                        setActive("seller");
                        setFilterOptions("seller");
                      }}
                    >
                      From Seller
                    </li>
                    <li
                      className={`${active === "sent" && "active-table-index"
                        } `}
                      onClick={() => {
                        setActive("sent");
                        setFilterOptions("sent");
                      }}
                    >
                      Sent
                    </li>
                    <li
                      className={`${active === "trash" && "active-table-index"
                        } `}
                      onClick={() => {
                        setActive("trash");
                        setFilterOptions("trash");
                      }}
                    >
                      Trash
                    </li>
                  </ul>
                </div>
                <div className="col-md-10">
                  <div className="container-fluid">
                    <div className="dashboard-head align-items-center py-3 px-3">
                      <div className="row">
                        {/* <div className="col-md-2">
                          <Typography>{"All message"}</Typography>
                        </div> */}
                        <div>
                          <Button
                            size="sm"
                            onClick={() => {
                              // this.setState({
                              //   modal: true,
                              // });
                            }}
                            color="light"
                          >
                            Delete Selected
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-body ">
                      <div className="messages-wrapper">
                        <Table className="message-table">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Attached</th>
                              <th>From</th>
                              <th>Description</th>
                              <th>Received</th>
                              <th>View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.map((item, k) => {
                              return (
                                <tr key={k}>
                                  <th scope="row">
                                    {" "}
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          style={{ position: "static" }}
                                        />
                                      </Label>
                                    </FormGroup>
                                  </th>
                                  <td>{item.msgFile}</td>
                                  <td>{item.msgFrom}</td>
                                  <td>{item.msgDescription}</td>
                                  <td>{item.msgReceived}</td>
                                  <td>
                                    <a href="#">LINK</a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default MyMessages;
