import React from "react";

const DigitizationContent = () => {
  return (
    <div className="benefits-buyer-content mt-4">
      <ul>
        <li>Procurement cycle digitization</li>
        <li>Easy procurement:e-payment,e-bill</li>
        <li>Hassle-free e-ordering</li>
        <li>Live chat</li>
        <li>Digital request for quotation</li>
      </ul>
    </div>
  );
};

export default DigitizationContent;
