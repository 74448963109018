import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { MailOutlined, ShoppingCartOutlined, InfoOutlined } from '@material-ui/icons';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useSelector, useDispatch } from 'react-redux'
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { getNotification, getNotificationInital } from '../../../../Store/action/notificationAction';
import moment from 'moment'
import { useHistory } from 'react-router';
export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(243, 146, 0)',
      outline: '1px solid rgb(243, 146, 0)'
    }
  },
})((props) => (
  <Menu
    elevation={10}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: '120px',
      horizontal: 'center',
    }}
    {...props}
  />
));


export default function NotificationModal() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = withStyles();
  const [selectedIndex, setSelectedIndex] = React.useState();
  const { noOfUnseen, notifications } = useSelector(state => {
    return {
      noOfUnseen: state.notification.noOfUnseen,
      notifications: state.notification.notifications || []
    };
  });
  const login = localStorage.getItem("login");
  const user = JSON.parse(login);
  const handleListItemClick = (event, index, link) => {
    setSelectedIndex(index);
    history.push(link)
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    user?._id && dispatch(getNotification(user._id))
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // const login = localStorage.getItem("login");
    // const user = JSON.parse(login);
    // if (user?._id) {
    //   dispatch(getNotificationInital(user._id))

    // }

  }, [])

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", handleClose);
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", handleClose);
    };
  }, []);

  const msg = (sub_type, info = '', rest = {}) => {
    if (sub_type === 'status_changed') {
      return <><span>{rest.product} </span> of order({info.toUpperCase()}) has been changed to status <span>{rest.order_status?.toUpperCase()}</span>.</>
    }
    if (sub_type === 'message') {
      return <>{rest.message}</>
    }
    if (sub_type === 'order_returned') {
      return <><span>{rest.product} </span> of order({info.toUpperCase()}) has been been changed to status <span>{rest.status.toUpperCase()}</span>.</>
    }
    if (sub_type === 'trade_license_expiry') {
      return <>Your trade license expires in {' '}
        {moment(rest.date).tz("Asia/Dubai").fromNow()}.</>
    }
    if (sub_type === 'order_created') {
      return <><span>New Order</span> has been arrived.({info.toUpperCase()})</>
    }
    if (sub_type === 'create_excel') {
      return <><span>New Quotation</span> has been added by {info.toUpperCase()}</>
    }

    if (sub_type === 'msg_receiver') {
      return (
        <>
          <span>{info}</span> has sent message to you.
        </>
      );
    }

    if (sub_type === 'coupon_created') {
      return <>{rest.msg}</>
    }
    if (sub_type === 'price_diff_created') {
      return <>{rest.msg}</>
    }

    if (sub_type === 'bulk_listing_update') {
      return <><a href={rest.report} download>Bulk List Update. Click here to downlaod the report.</a></>
    }
  }

  return (
    <div >
      <Badge onClick={handleClick} badgeContent={noOfUnseen} color="primary">
        <NotificationsNoneIcon style={{ fontSize: 30, cursor: 'pointer' }} />
      </Badge>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '285px',
            width: '350px',
          },
        }}
      >
        {
          notifications.map((n, i) => {
            const { sub_type, order_id, _id, sender, name, ...rest } = n.notificationDetail
            let link = '#'
            let icon
            let data
            function filterInfo(type) {
              if (type === 'order') {
                if (user.role == 'BUYER') {
                  link = _id ? `/orders/view/${_id}` : '/my-order'
                } else {
                  link = _id ? `/orders/view/${_id}` : '/my-sales'
                }
                icon = <ShoppingCartOutlined style={{ margin: 2 }} />
                data = order_id
              }
              if (type === 'expiry') {
                icon = <ScheduleIcon style={{ margin: 2 }} />
              }
              if (type === 'coupon') {
                icon = <InfoOutlined style={{ margin: 2 }} />
              }

              if (type === 'price_diff') {
                icon = <InfoOutlined style={{ margin: 2 }} />
              }

              if (type === 'custom-message') {
                icon = <MailOutlined style={{ margin: 2 }} />
              }
              if (type === 'chat') {
                icon = <MailOutlined style={{ margin: 2 }} />
                link = '/messages'
                data = sender
              }

              if (type === 'bulk_listing_report') {
                icon = <ScheduleIcon style={{ margin: 2 }} />
              }
              if (type === 'quotation') {
                icon = <InfoOutlined style={{ margin: 2 }} />
                data = name
                link = "/quotations"
              }
            }

            filterInfo(n.notificationType)

            return (
              <div key={i}>
                <ListItem alignItems="flex-start"
                  button
                  selected={selectedIndex === i}
                  onClick={(event) => handleListItemClick(event, i, link)}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: 'rgb(243, 146, 0)' }}>{icon}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        component="div"
                        // variant="h6"
                        style={{ color: '#1E71B8' }}
                      >
                        {msg(sub_type, data, rest)}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {moment(n.date).tz("Asia/Dubai").fromNow()}
                      </Typography>

                    }
                  />
                </ListItem>
                <Divider light />
              </div>
            )
          })
        }
        {!notifications?.length && <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Travis Howard" src="/assets/img/avatar.png" />
          </ListItemAvatar>
          <ListItemText
            primary="Oops"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  No notification to show
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>}
      </StyledMenu>
    </div>
  );
}