import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import Select from "react-select";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";

import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import { PageBanner } from "../content/element/page-banner";
import {
  SetListings,
  VinSearchDetails,
  SetTradesoftSKUs,
} from "../../Store/action/listingActions";
import qs from "query-string";
import { popUp } from "../../utils";

class SearchVin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      makes: [],
      models: [],
      engines: [],
      categories: [],
      categoriesVIN: [],
      subGroupsTradeSoft: [],
      subGroupsTradeSoft1: [],
      tradesoftSKUs: [],
      selectedMake: null,
      selectedModel: null,
      selectedEngine: null,
      carId: null,
      catalogId: null,
      isSearchByMake: false,
      criteria: null,
      isLoading: false,
      partIdentified: false,
      vinCode: "",
      partSKU: "",
      carIdentified: false,
      isSearchByVin: false,
      makeFromVIN: null,
      modelFromVIN: null,
      categoryFromVIN: null,
      engineFromVIN: null,
      isLoadingSpareParts: false,
    };
    this.handleChangeMake = this.handleChangeMake.bind(this);
    this.handleChangeModel = this.handleChangeModel.bind(this);
    this.handleChangeEngine = this.handleChangeEngine.bind(this);
    this.handleChangeCategoriesVin = this.handleChangeCategoriesVin.bind(this);
    this.handleChangeVINCode = this.handleChangeVINCode.bind(this);
    this.getVehiclesByVIN = this.getVehiclesByVIN.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get("/api/info/get-catalogs-tradesoft")
      .then((res) => {
        const makes = res.data.map((d) => {
          return { label: d.name, value: d.id, ...d };
        });
        this.setState({ makes, isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        popUp("There was a problem with server. Please try again.");
      });


    if (this.props.location.search) {
      let { vin } = qs.parse(this.props.location.search);
      this.setState({ vinCode: vin }, () => {

        this.getVehiclesByVIN();
      });
    }

  }


  handleChangeVINCode(e) {
    this.setState({ vinCode: e.target.value });
  }


  handleChangeMake(option) {
    this.setState({
      selectedMake: option,
      isLoading: true,
      vinCode: null,
      isSearchByMake: true,
      categoriesVIN: [],
      categoryFromVIN: null,
      models: [],
      selectedModel: null,
      engines: [],
      selectedEngine: null,
      subGroupsTradeSoft: [],
      selectedsubGroupsTradeSoft: null,
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft1: null,
      tradesoftSKUs: [],
      categories: [],
      partsError: false,
      carIdentified: false,
      partIdentified: false,
      isSearchByVin: false,
      makeFromVIN: null,
      modelFromVIN: null,
      carId: null,
      catalogId: null,
      criteria: null,
    });
    axios
      .get("/api/info/get-models-tradesoft?catalogId=" + option.value)
      .then((res) => {
        const models = res.data.map((d) => {
          return { label: d.name, value: d.id, ...d };
        });
        this.setState({ models, isLoading: false });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        this.setState({ isLoading: false });
      });
  }

  handleChangeModel(option) {
    const { selectedMake } = this.state;
    this.setState({
      selectedModel: option,
      engines: [],
      selectedEngine: null,
      subGroupsTradeSoft: [],
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft: null,
      selectedsubGroupsTradeSoft1: null,
      tradesoftSKUs: [],
      categories: [],
      categoriesVIN: [],
      categoryFromVIN: null,
      carId: null,
      catalogId: null,
      partsError: false,
      criteria: null,
      isLoading: true
    });
    axios
      .post(
        `/api/info/get-trim-tradesoft`, { catalogId: selectedMake.value, modelId: option.value }
      )
      .then(async (res) => {
        const engines = res.data.map((d) => {
          return { label: d.name, value: d.id, ...d };
        });
        this.setState({ engines, isLoading: false });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        this.setState({ isLoading: false });
      });
  }

  handleChangeEngine(option) {
    this.setState({
      selectedEngine: option,
      isLoading: true,
      carId: option.id,
      catalogId: option.catalogId,
      criteria: option.criteria,
      subGroupsTradeSoft: [],
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft: null,
      selectedsubGroupsTradeSoft1: null,
      partsError: false,
      tradesoftSKUs: [],
      categories: [],
    });
    axios
      .post(`/api/info/get-cats-tradesoft`, { carId: option.id, catalogId: option.catalogId })
      .then((res) => {
        this.setState({ categories: res.data.group2 });
      }).finally(() => {
        this.setState({ isLoading: false });
      })
  }

  handleChangeCategoriesVin(option) {
    this.setState({
      categoryFromVIN: option,
      isLoading: true,
      subGroupsTradeSoft: [],
      selectedsubGroupsTradeSoft: null,
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft1: null,
      tradesoftSKUs: [],
      partsError: false,
    });
    if (option.hasParts === false) {
      axios.post(`/api/info/get-sub-groups-tradesoft`, {
        carId: this.state.carId,
        catalogId: this.state.catalogId,
        criteria: this.state.criteria,
        groupId: option.value
      }).then((res) => {
        this.setState({ subGroupsTradeSoft: res.data });
      }).finally(() => {
        this.setState({ isLoading: false });
      })
    }
  }

  getTradesoftSKUs = (data) => {
    let skus = []
    data.forEach(ele => {
      ele.parts.forEach(e => {
        if (e.number) {
          skus.push(e.number)
        }
      });
    });
    this.setState({ tradesoftSKUs: skus, partIdentified: true })
  }

  handleChangeSubGroupsTradeSoft = (option) => {
    this.setState({
      isLoading: true,
      selectedsubGroupsTradeSoft: option,
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft1: null,
      partsError: false,
      partIdentified: false,
      tradesoftSKUs: []
    });
    if (option.hasParts === true) {
      axios.post(`/api/info/get-spareparts-tradesoft`, {
        carId: this.state.carId,
        catalogId: this.state.catalogId,
        criteria: this.state.criteria,
        groupId: option.value
      }).then((res) => {
        this.getTradesoftSKUs(res.data.partGroups);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
    }
    if (option.hasParts === false) {
      axios.post(`/api/info/get-sub-groups-tradesoft`, {
        carId: this.state.carId,
        catalogId: this.state.catalogId,
        criteria: this.state.criteria,
        groupId: option.value
      }).then((res) => {
        this.setState({ subGroupsTradeSoft1: res.data });
      }).finally(() => {
        this.setState({ isLoading: false });
      })
    }
  }

  handleChangeSubGroupsTradeSoft1 = (option) => {

    this.setState({
      isLoading: true,
      selectedsubGroupsTradeSoft1: option,
      partsError: false,
      partIdentified: false,
      tradesoftSKUs: []
    });
    if (option.hasParts === true) {
      axios.post(`/api/info/get-spareparts-tradesoft`, {
        carId: this.state.carId,
        catalogId: this.state.catalogId,
        criteria: this.state.criteria,
        groupId: option.value
      }).then((res) => {
        this.getTradesoftSKUs(res.data.partGroups);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
    } else {
      this.setState({ partIdentified: false, partsError: true })
      this.setState({ isLoading: false });
    }
  }




  showSparePartsTradeSoft = (e) => {
    this.setState({ isLoadingSpareParts: true });
    let emirate = this.props.login?.role === "BUYER" ? this.props.login?.details?.emirate : '';
    let user_id = this.props.login?.role === "BUYER" ? this.props.login?._id : '';
    if (this.state.tradesoftSKUs.length) {
      axios.post(`/api/info/tradesoft-skus?emirate=${emirate}&user_id=${user_id}`, { skus: [... new Set(this.state.tradesoftSKUs)] }).then((res) => {
        let bp24 = res.data

        let _bp24 = bp24.map(b => {
          return {
            ...b,
            date: new Date(),
            pic: b.imageArray[0],
            istecAll: false,
          }
        })

        this.props.setListings({ listings: [..._bp24] });
        this.props.SetTradesoftSKUs([... new Set(this.state.tradesoftSKUs)]);
        this.props.vinSearchDetails({
          make: this.state.makeFromVIN,
          model: this.state.modelFromVIN,
          vin: this.state.vinCode,
        });

        this.setState({ isLoadingSpareParts: false });
        this.props.history.push("/all-listings?api=true");
      }
      ).catch((err) => {
        this.setState({ isLoadingSpareParts: false });
        console.log(err);
        popUp("There was a problem with server. Please try again.");
      });
    }
  }


  getVehiclesByVIN() {
    if (this.state.vinCode) {
      this.setState({
        isLoading: true,
        isSearchByMake: false,
        partIdentified: false,
        partsError: false,
        tradesoftSKUs: [],
        selectedMake: null,
        categoriesVIN: [],
        categoryFromVIN: null,
        models: [],
        selectedModel: null,
        engines: [],
        selectedEngine: null,
        subGroupsTradeSoft: [],
        selectedsubGroupsTradeSoft: null,
        subGroupsTradeSoft1: [],
        selectedsubGroupsTradeSoft1: null,
        tradesoftSKUs: [],
        categories: [],
        partsError: false,
        carId: null,
        catalogId: null,
        criteria: null,
        carIdentified: false,
        makeFromVIN: null,
        modelFromVIN: null,
        carId: null,
        catalogId: null,
        criteria: null,
      });
      axios
        .get(`/api/info/get-vehicles-by-vin-tradesoft?vinCode=${this.state.vinCode}`)
        .then((res) => {
          if (res.data) {
            this.setState({ categoriesVIN: res.data.group2 });
            this.setState({ carIdentified: true, isSearchByVin: true });
            this.setState({
              makeFromVIN:
                res.data.make,
            });
            this.setState({
              modelFromVIN:
                res.data.model
            });
            this.setState({
              carId: res.data.carId,
              catalogId: res.data.catalogId,
              criteria: res.data.criteria,
            })
          } else {
            this.setState({ carIdentified: false, isSearchByVin: true });
          }
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            carIdentified: false,
            isSearchByVin: false,
          });
          popUp("There was a problem with server. Please try again.");
        });
    }
  }

  render() {
    const {
      makes,
      models,
      engines,
      categories,
      isLoading,
      makeFromVIN,
      modelFromVIN,
      isLoadingSpareParts,
      carIdentified,
      isSearchByVin,
      categoryFromVIN,
      selectedsubGroupsTradeSoft,
      selectedsubGroupsTradeSoft1,
      partsError,
      selectedEngine,
      selectedModel,
      selectedMake,
      partIdentified,
      isSearchByMake,
      vinCode,
      categoriesVIN,
      subGroupsTradeSoft,
      subGroupsTradeSoft1,
    } = this.state;
    return (
      <Fragment>
        <LoadingOverlay active={isLoading} spinner text={`Loading...`}>


          <PageBanner title="Parts Search Vin" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="searh-vin-page">
                  <section className="search-vin-col">

                    <h2>Find the model by VIN number</h2>
                    <p>
                      Vehicle VIN is the most reliable identifier. If you are
                      looking for a Japanese car, enter FRAME
                    </p>
                    <div className="vin-search-wrapper">
                      <i className="la la-search"></i>
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        autoComplete="off"
                        placeholder="VIN or FRAME"
                        onChange={this.handleChangeVINCode}
                        value={vinCode}
                      />
                    </div>
                    <p className="ex-text">
                      For example: VIN XW8AN2NE3JH035743 or FRAME KZN185-9023353
                    </p>
                    <button
                      id="findCarVin"
                      style={{ fontSize: "1rem" }}
                      disabled={isLoading}
                      type="button"
                      className="btn btn-block btn-primary"
                      onClick={this.getVehiclesByVIN}
                    >
                      Find Car with VIN
                    </button>
                    {isSearchByVin && carIdentified ? (
                      <div className="car-identified-wrap">
                        <div className="p-3 bg-success w-100 text-light text-center h2 my-4 border-top">
                          <i className="las la-check-square mr-1"></i>
                          Car identified!
                        </div>
                        <div className="form-group row">
                          <label className="control-label col-sm-3">
                            Make:
                          </label>
                          <div className="col-sm-9">
                            <h3>{makeFromVIN}</h3>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="control-label col-sm-3">
                            Model:
                          </label>
                          <div className="col-sm-9">
                            <h3>{modelFromVIN}</h3>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="control-label col-sm-3">
                            Categories:
                          </label>
                          <div className="col-sm-9">
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              placeholder="Choose Categories..."
                              name="categories"
                              value={categoryFromVIN}
                              onChange={this.handleChangeCategoriesVin}
                              options={categoriesVIN.map((value) => {
                                return { label: value.name, value: value.id, ...value };
                              })}
                            // isMulti={true}
                            />
                          </div>
                        </div>

                        {(subGroupsTradeSoft.length > 0 && isSearchByVin) && <div className="form-group row">
                          <label className="control-label col-sm-3">
                            Sub-Categories:
                          </label>
                          <div className="col-sm-9">
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              placeholder="Choose Categories..."
                              name="categories"
                              value={selectedsubGroupsTradeSoft}
                              onChange={this.handleChangeSubGroupsTradeSoft}
                              options={subGroupsTradeSoft.map((value) => {
                                return { label: value.name, value: value.id, ...value };
                              })}
                            // isMulti={true}
                            />
                          </div>
                        </div>}
                        {(subGroupsTradeSoft1.length > 0 && isSearchByVin) && <div className="form-group row">
                          <label className="control-label col-sm-3">
                            Sub-Categories 2:
                          </label>
                          <div className="col-sm-9">
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              placeholder="Choose Categories..."
                              name="categories"
                              value={selectedsubGroupsTradeSoft1}
                              onChange={this.handleChangeSubGroupsTradeSoft1}
                              options={subGroupsTradeSoft1.map((value) => {
                                return { label: value.name, value: value.id, ...value };
                              })}
                            // isMulti={true}
                            />
                          </div>
                        </div>}
                        <button
                          style={{ fontSize: "1rem" }}
                          type="button"
                          className="btn btn-block btn-primary"
                          disabled={isSearchByVin && !isSearchByMake && (isLoadingSpareParts || !partIdentified)}
                          onClick={this.showSparePartsTradeSoft}
                        >
                          Show Spare Parts{" "}
                          {isLoadingSpareParts && (
                            <i className="las la-spinner la-spin mr-2"></i>
                          )}
                        </button>
                        {isSearchByVin && partsError ? (
                          <div className="car-identified-wrap">
                            <div className="p-3 bg-danger w-100 text-light text-center h2 my-4 border-top">
                              {/* <i className="las la-check-square mr-1"></i> */}
                              Parts not found!
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : isSearchByVin && !carIdentified ? (
                      <div className="car-identified-wrap">
                        <div className="p-3 bg-danger w-100 text-light text-center h2 my-4 border-top">
                          {/* <i className="las la-check-square mr-1"></i> */}
                          Car not found!
                        </div>
                      </div>
                    ) : null}
                  </section>
                  <div className="separator-line">
                    <span>or</span>
                  </div>
                  <section className="search-vin-col">
                    <h2>Find car parts for your car</h2>
                    <p>
                      If you do not have or can't find the VIN number, please
                      fill the details below
                    </p>
                    <form className="form-horizontal">
                      <div className="form-group row">
                        <label className="control-label col-sm-3">Make:</label>
                        <div className="col-sm-9">
                          <Select
                            className={`react-select`}
                            classNamePrefix="react-select"
                            placeholder="Choose..."
                            name="make"
                            value={selectedMake}
                            onChange={this.handleChangeMake}
                            options={makes}
                          // isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-sm-3">Model:</label>
                        <div className="col-sm-9">
                          <Select
                            className={`react-select`}
                            classNamePrefix="react-select"
                            placeholder="Choose..."
                            name="model"
                            value={selectedModel}
                            onChange={this.handleChangeModel}
                            options={models}
                          // isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-sm-3">
                          Trim:
                        </label>
                        <div className="col-sm-9">
                          <Select
                            className={`react-select`}
                            classNamePrefix="react-select"
                            placeholder="Choose..."
                            name="engine"
                            value={selectedEngine}
                            onChange={this.handleChangeEngine}
                            options={engines}
                          // isMulti={true}
                          />
                        </div>
                      </div>
                      {isSearchByMake && <><div className="form-group row">
                        <label className="control-label col-sm-3">
                          Categories:
                        </label>
                        <div className="col-sm-9">
                          <Select
                            className={`react-select`}
                            classNamePrefix="react-select"
                            placeholder="Choose Categories..."
                            name="categories"
                            value={categoryFromVIN}
                            onChange={this.handleChangeCategoriesVin}
                            options={categories.map((value) => {
                              return { label: value.name, value: value.id, ...value };
                            })}
                          // isMulti={true}
                          />
                        </div>
                      </div>
                        {subGroupsTradeSoft.length > 0 && <div className="form-group row">
                          <label className="control-label col-sm-3">
                            Sub-Categories:
                          </label>
                          <div className="col-sm-9">
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              placeholder="Choose Categories..."
                              name="categories"
                              onChange={this.handleChangeSubGroupsTradeSoft}
                              options={subGroupsTradeSoft.map((value) => {
                                return { label: value.name, value: value.id, ...value };
                              })}
                            // isMulti={true}
                            />
                          </div>
                        </div>}

                        {subGroupsTradeSoft1.length > 0 && <div className="form-group row">
                          <label className="control-label col-sm-3">
                            Sub-Categories 2:
                          </label>
                          <div className="col-sm-9">
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              placeholder="Choose Categories..."
                              name="categories"
                              onChange={this.handleChangeSubGroupsTradeSoft1}
                              options={subGroupsTradeSoft1.map((value) => {
                                return { label: value.name, value: value.id, ...value };
                              })}
                            // isMulti={true}
                            />
                          </div>
                        </div>}
                      </>
                      }
                      {isSearchByMake && partsError ? (
                        <div className="car-identified-wrap">
                          <div className="p-3 bg-danger w-100 text-light text-center h2 my-4 border-top">
                            {/* <i className="las la-check-square mr-1"></i> */}
                            Parts not found!
                          </div>
                        </div>
                      ) : null}
                    </form>
                    <fieldset>
                      {selectedMake && <button
                        className="btn btn-primary btn-block"
                        onClick={this.showSparePartsTradeSoft}
                        style={{ fontSize: "1rem" }}
                        disabled={!isSearchByVin && isSearchByMake && (isLoadingSpareParts || !partIdentified)}
                      >
                        {isLoadingSpareParts && (
                          <i className="las la-spinner la-spin mr-2"></i>
                        )}
                        Show Spare Parts
                      </button>}
                    </fieldset>
                  </section>
                </div>
              </div>
            </div>
          </div>

        </LoadingOverlay>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    setListings: (data) => dispatch(SetListings(data)),
    SetTradesoftSKUs: (data) => dispatch(SetTradesoftSKUs(data)),
    vinSearchDetails: (data) => dispatch(VinSearchDetails(data)),
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(SearchVin);
