import React from 'react'
import { useSelector } from 'react-redux';
import { getDiscountAmount, ProductImage } from '../../../utils';
import ShowDynamicCurrency from '../../common/ShowDynamicCurrency';
import ShowDynamicPrice from '../../common/ShowDynamicPrice';
import QtyInput from './QtyInput';

export default function Step1({ listings, totalAmount, getCartLength, list, updatestockflag, qtyDecrement, qtyIncrement, handleConfirm, getTaxAmount, discount, coupon, discountPerUnit, changeCartQty }) {


    return (
        <div className="checkout-table table-responsive order-review-step">
            {
                listings?.map((data, index) => {
                    if (!data?.data?.length) return <></>;


                    return (
                        <table
                            id="directorist-checkout-table"
                            className="table bg-transparent checkout-table"
                            key={index}
                        >
                            <thead>
                                <tr><th colSpan={6}>Seller : {data?.sellerName}</th></tr>
                                <tr>
                                    <td colSpan={2} style={{ backgroundColor: "#f7f7f7", width: "60%", }}>Name</td>
                                    <td style={{ backgroundColor: "#f7f7f7", width: "15%", }}>Qty</td>
                                    <td style={{ backgroundColor: "#f7f7f7", width: "15%", }}>Price(Excl. Tax)</td>
                                    <td style={{ backgroundColor: "#f7f7f7", width: "15%", }}>Discount</td>
                                    <td style={{ backgroundColor: "#f7f7f7", width: "10%", }}>Delete</td>
                                </tr>
                            </thead>
                            {data.data.map((userListings, i) => {
                                return (
                                    <tbody className="bg-white" key={i}>
                                        {userListings?.listings?.length > 0 &&
                                            userListings?.listings.map(
                                                (listing, index) => {
                                                    totalAmount +=
                                                        listing.price *
                                                        getCartLength(
                                                            list.itemsInCart,
                                                            listing
                                                        );
                                                    if (
                                                        getCartLength(
                                                            list.itemsInCart,
                                                            listing
                                                        ) === 0
                                                    ) {
                                                        return false;
                                                    }
                                                    const cartItemPrice = (listing.price / 1.05)

                                                    localStorage.setItem("totalAmountInCart",totalAmount);
                                                    let url = listing.pic || listing.imageArray?.[0];
                                                    return (
                                                        <tr key={index}>
                                                            <td
                                                                width="75"
                                                                style={{
                                                                    position: "relative",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: -5,
                                                                        left: 50,
                                                                    }}
                                                                >
                                                                    {listing?.quantity <
                                                                        getCartLength(
                                                                            list.itemsInCart,
                                                                            listing
                                                                        ) && updatestockflag(true)}
                                                                </div>
                                                                <ProductImage
                                                                    avatar={url}
                                                                    className="item-image"
                                                                    alt={listing.partName}
                                                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <h4>{listing.partName}</h4>
                                                                <p
                                                                    className="text-muted"
                                                                    style={{ marginBottom: 0 }}
                                                                >
                                                                    <strong>
                                                                        {listing.partSKU}</strong>
                                                                    <br />
                                                                    Avail.Qty({listing?.quantity})

                                                                </p>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <button
                                                                        disabled={
                                                                            getCartLength(
                                                                                list.itemsInCart,
                                                                                listing
                                                                            ) < 2
                                                                        }
                                                                        className="btn checkout-qty"
                                                                        onClick={(e) =>{
                                                                            qtyDecrement( e, listing._id)
                                                                            if(listing?.quantity <= getCartLength(list.itemsInCart,listing._id)){
                                                                            updatestockflag(false)}
                                                                        }
                                                                        
                                                                        }
                                                                        style={{
                                                                            marginRight: "12px",
                                                                        }}
                                                                    >
                                                                        <img src="/assets/img/left-arrow.png" />
                                                                    </button>
                                                                    <QtyInput
                                                                        listing={listing}
                                                                        changeCartQty={changeCartQty}
                                                                        value={getCartLength(
                                                                            list.itemsInCart,
                                                                            listing
                                                                        )}
                                                                    />
                                                                    <button
                                                                        disabled={
                                                                            getCartLength(
                                                                                list.itemsInCart,
                                                                                listing
                                                                            ) >
                                                                            listing?.quantity - 1
                                                                        }
                                                                        className="btn checkout-qty"
                                                                        onClick={(e) =>
                                                                            qtyIncrement( e, listing._id)
                                                                        }
                                                                        style={{
                                                                            marginLeft: "10px",
                                                                        }}
                                                                    >
                                                                        <img src="/assets/img/right-arrow.png" />
                                                                    </button>
                                                                   
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span className="country-currency">
                                                                    {" "}
                                                                    <ShowDynamicCurrency />
                                                                </span>
                                                                {""}
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "17px",
                                                                        fontWeight: "bold",
                                                                        fontFamily: "Montserrat",
                                                                    }}
                                                                >
                                                                    <ShowDynamicPrice
                                                                        price={cartItemPrice}
                                                                    />
                                                                </span>
                                                            </td>

                                                            {/* discount */}
                                                            <td>
                                                                <span className="country-currency">
                                                                    {" "}
                                                                    <ShowDynamicCurrency />
                                                                </span>
                                                                {""}
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "17px",
                                                                        fontWeight: "bold",
                                                                        fontFamily: "Montserrat",
                                                                    }}
                                                                >
                                                                    <ShowDynamicPrice
                                                                        price={getDiscountAmount(cartItemPrice, getCartLength(
                                                                            list.itemsInCart,
                                                                            listing
                                                                        ), listing, discountPerUnit, coupon)}
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                <a
                                                                    href="#!"
                                                                    onClick={(e) =>
                                                                        handleConfirm(
                                                                            e,
                                                                            listing._id,
                                                                            listing
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="las la-times mr-1"></i>
                                                                </a>
                                                            </td>
                                                        </tr>

                                                    );
                                                }
                                            )}
                                    </tbody>
                                );
                            })}
                        </table>
                    );
                })}
        </div>
    )
}
