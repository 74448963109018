import { combineReducers } from "redux";
import listReducer from "./listingReducer";
import cartypeReducer from "./cartypeReducer";
import clientReducer from "./clientReducer";
import loginReducer from "./loginReducer";
import signupReducer from "./signupReducer";
import sellingReducer from "./sellingReducer";
import chatReducer from "./chatReducer";
import newsReducer from "./newsReducer";
import quotationReducer from "./quotationReducer";
import currencyReducer from "./currencyReducer";
import dashboardReducer from "./dashboardReducer";
import notificationReducer from "./notificationReducer";
import agreementReducer from "./agreementReducer";
import categoryReducer from "./categoryReducer";


import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import PaymentReducer from "./paymentReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["list"],
};

const rootReducer = combineReducers({
  list: listReducer,
  cartypes: cartypeReducer,
  client: clientReducer,
  login: loginReducer,
  signup: signupReducer,
  selling: sellingReducer,
  chat: chatReducer,
  news: newsReducer,
  quotation: quotationReducer,
  currency: currencyReducer,
  dashboard: dashboardReducer,
  notification: notificationReducer,
  policyAgreed: agreementReducer,
  payment: PaymentReducer,
  category: categoryReducer

});
export default persistReducer(persistConfig, rootReducer);
