import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducers from "./Store/Reducers/rootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import WebSocketProvider from "./WebSocket";

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

const store = createStore(rootReducers, compose(applyMiddleware(thunk)));
// const persistor=persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <WebSocketProvider>
      {/* <PersistGate persistor={persistor}> */}
      <App />
      {/* </PersistGate> */}
    </WebSocketProvider>
  </Provider>,
  document.getElementById("root")
);
