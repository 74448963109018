import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hostedAPI, outletRefID } from '../../../constants';
export default ({ setLoading }) => {
    const state = useSelector(state => state.payment.payment)
    const style = {
        main: {
            padding: "15px"
        } /* the style for the main wrapper div around the card input*/,
        base: {} /* this is the default styles that comes with it */,
        input: {
            padding: "5px",
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "rgb(227, 230, 239)",
            color: "#000"
        } /* custom style options for the input fields */,
        invalid: {
            padding: "5px",
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#f00",
            color: "#f00"
        } /* custom input invalid styles */
    };
    const dispatch = useDispatch()

    const onSuccess = () => {
        setLoading(false)
        // let data = document.getElementById('mount-id').innerHTML
        // console.log(document.getElementById('mount-id').innerHTML)
        dispatch({ type: "CREATE_FORM", paylaod: document.getElementById('mount-id').innerHTML })
    }
    const onFail = () => {
        console.log("fail")
        setLoading(false)
    }
    const apiKey = hostedAPI;
    const outletRef = outletRefID;
    useEffect(() => {
        if (state === null) {
            setLoading(true)
            console.log("did mount")
            window.NI.mountCardInput('mount-id'/* the mount id*/, {
                style, // Style configuration you can pass to customize the UI
                apiKey, // API Key for WEB SDK from the portal
                outletRef, // outlet reference from the portal
                onSuccess, // Success callback if apiKey validation succeeds
                onFail, // Fail callback if apiKey validation fails
                onChangeValidStatus: ({
                    isCVVValid,
                    isExpiryValid,
                    isNameValid,
                    isPanValid
                }) => {
                    console.log({ isCVVValid, isExpiryValid, isNameValid, isPanValid });
                }
            });
        } else {
            document.getElementById('mount-id').innerHTML = state
        }
    }, [window])

    return (
        <>
            <div id="mount-id" style={{ height: "225px" }}>
            </div>
        </>
    )
}
