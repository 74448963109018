import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { PageBanner } from "../content/element/page-banner";
import PreHeader from "../layout/pre-header";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Row, Col } from "reactstrap";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Badge } from "reactstrap";

import ReactStars from "react-rating-stars-component";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

const YourOrders = ({ history }) => {
  const [modal, setModal] = useState(false);
  const [modalOrderDetails, setModalOrderDetails] = useState(false);
  const [modalSellerContact, setModalSellerContact] = useState(false);
  const [amount, setAmount] = useState(35);
  const [activeTabDate, setActiveTabDate] = useState("1");

  const toggleDate = (tab) => {
    if (activeTabDate !== tab) setActiveTabDate(tab);
  };

  const ratingChanged1 = (newRating) => {
  };
  const ratingChanged2 = (newRating) => {
  };
  const ratingChanged3 = (newRating) => {
  };

  const ModalRateOrder = (props) => {
    const toggle = () => {
      setModal(!modal);
    };
    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modal}
          toggle={toggle}
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Give a Rating</ModalHeader>
          <ModalBody style={{ padding: "20px" }}>
            <Typography>*indicates required fields</Typography>
            <br />
            <Typography>
              Seller<h6> Tommy A Car Parts</h6>
            </Typography>{" "}
            <br />
            <br />
            <Row>
              <Col sm="7">
                <h5>Item Descripition</h5>
                <Typography>How accurately they were described?</Typography>
              </Col>
              <Col sm="5">
                <ReactStars
                  count={5}
                  onChange={ratingChanged1}
                  size={24}
                  activeColor="#ffd700"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="7">
                <h5>Communication</h5>
                <Typography>
                  Did the seller communicate well with you?
                </Typography>
              </Col>
              <Col sm="5">
                <ReactStars
                  count={5}
                  onChange={ratingChanged2}
                  size={24}
                  activeColor="#ffd700"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="7">
                <h5>Shipping Time</h5>
                <Typography>How quickly the seller ship the item?</Typography>
              </Col>
              <Col sm="5">
                <ReactStars
                  count={5}
                  onChange={ratingChanged3}
                  size={24}
                  activeColor="#ffd700"
                />
              </Col>
            </Row>
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="light" onClick={toggle}>
              Maybe later
            </Button>{" "}
            <Button size="sm" color="primary" onClick={toggle}>
              Submit
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const ModalOrderDetails = (props) => {
    const toggle = () => {
      setModalOrderDetails(!modalOrderDetails);
    };
    return (
      <div>
        Total AED222 . 299206 AED35.99 AED35.99
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modalOrderDetails}
          toggle={toggle}
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Order Details</ModalHeader>
          <ModalBody style={{ padding: "20px" }}>
            <Typography>
              <h1>Order Details</h1>
            </Typography>
            <br />
            <br />
            <Row>
              <Col sm="2">
                <img
                  src="/assets/img/stepperimg/gilan.png"
                  alt="item"
                  style={{ width: "100px" }}
                />
              </Col>
              <Col sm="4">
                <Typography>
                  <h6>Castrol EDGE Engine Oil 5W-30 5 Litre Item SKU</h6>
                </Typography>
              </Col>
            </Row>

            <br />

            <Row>
              <Col>
                <FormGroup>
                  <Label>Order Placed</Label>
                  <Typography>2020-02-16</Typography>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label> Order Number</Label>
                  <Typography>232-123-123</Typography>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Total</Label>
                  <Typography>AED222</Typography>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label> Seller</Label>
                  <Typography> Tommy A car Parts</Typography>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Deliver</Label>
                  <Typography>To Mary Clock</Typography>
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="primary" onClick={toggle}>
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const ModalSellerContact = (props) => {
    const toggle = () => {
      setModalSellerContact(!modalSellerContact);
    };

    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modalSellerContact}
          toggle={toggle}
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Contact Seller</ModalHeader>
          <ModalBody style={{ padding: "20px" }}>
            <Typography></Typography>
            <br />
            <br />
            <Row>
              <Col>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="message">Message</Label>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Write you message here.."
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="light" onClick={toggle}>
              Close
            </Button>{" "}
            <Button size="sm" color="primary" onClick={toggle}>
              Contact
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const allOrders = () => {
    return (
      <Row>
        <Col xs="12" sm="12">
          <div>
            <div
              style={{
                padding: "5px",
              }}
            >
              <Row>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Placed</p>
                    <h6>2020-02-16</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Number</p>
                    <h6>232-123-123</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Total</p>
                    <h6>AED222</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Seller</p>
                    <h6>Tommy A car Parts</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Deliver To</p>
                    <h6>Mary Clock</h6>
                  </Typography>
                </Col>
                <Col></Col>
                <Col sm="4" style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalSellerContact(true);
                    }}
                  >
                    Contact Seller
                  </Button>{" "}
                  <Button
                    style={{ marginLeft: "5px" }}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      history.push("/order-details");
                    }}
                  >
                    View Order Details
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <img
                        src="/assets/img/stepperimg/gilan.png"
                        alt="item"
                        style={{ width: "100px" }}
                      />
                    </Col>
                    <Col>
                      <Typography style={{ textAlign: "left" }}>
                        <h6>Castrol EDGE Engine Oil 5W-30 5 Litre</h6>
                        <p>Item SKU. 299206</p>
                      </Typography>
                    </Col>
                    <Col sm="3">
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Badge color="info" pill>
                        Preparing Order
                      </Badge>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <br />
            <br />
            <div
              style={{
                padding: "5px",
              }}
            >
              <Row>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Placed</p>
                    <h6>2020-02-16</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Number</p>
                    <h6>232-123-123</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Total</p>
                    <h6>AED222</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Seller</p>
                    <h6>Tommy A car Parts</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Deliver To</p>
                    <h6>Mary Clock</h6>
                  </Typography>
                </Col>
                <Col></Col>
                <Col sm="4" style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalSellerContact(true);
                    }}
                  >
                    Contact Seller
                  </Button>
                  <Button
                    style={{ marginLeft: "5px" }}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalOrderDetails(true);
                    }}
                  >
                    View Order Details
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <img
                        src="/assets/img/stepperimg/gilan.png"
                        alt="item"
                        style={{ width: "100px" }}
                      />
                    </Col>
                    <Col>
                      <Typography style={{ textAlign: "left" }}>
                        <h6>Castrol EDGE Engine Oil 5W-30 5 Litre</h6>
                        <p>Item SKU. 299206</p>
                      </Typography>
                    </Col>
                    <Col sm="3">
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Badge color="info" pill>
                        Preparing Order
                      </Badge>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const inProgressOrders = () => {
    return (
      <Row>
        <Col xs="12" sm="12">
          <div>
            <div
              style={{
                padding: "5px",
              }}
            >
              <Row>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Placed</p>
                    <h6>2020-02-16</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Number</p>
                    <h6>232-123-123</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Total</p>
                    <h6>AED222</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Seller</p>
                    <h6>Tommy A car Parts</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Deliver To</p>
                    <h6>Mary Clock</h6>
                  </Typography>
                </Col>
                <Col></Col>
                <Col sm="4" style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalSellerContact(true);
                    }}
                  >
                    Contact Seller
                  </Button>
                  <Button
                    style={{ marginLeft: "5px" }}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalOrderDetails(true);
                    }}
                  >
                    View Order Details
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <img
                        src="/assets/img/stepperimg/gilan.png"
                        alt="item"
                        style={{ width: "100px" }}
                      />
                    </Col>
                    <Col>
                      <Typography style={{ textAlign: "left" }}>
                        <h6>Castrol EDGE Engine Oil 5W-30 5 Litre</h6>
                        <p>Item SKU. 299206</p>
                      </Typography>
                    </Col>
                    <Col sm="3">
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Badge color="warning" pill>
                        Order Inprogress
                      </Badge>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <br />
          </div>
        </Col>
      </Row>
    );
  };

  const deliveredOrders = () => {
    return (
      <Row>
        <Col xs="12" sm="12">
          <div>
            <div
              style={{
                padding: "5px",
              }}
            >
              <Row>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Placed</p>
                    <h6>2020-02-16</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Number</p>
                    <h6>232-123-123</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Total</p>
                    <h6>AED222</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Seller</p>
                    <h6>Tommy A car Parts</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Deliver To</p>
                    <h6>Mary Clock</h6>
                  </Typography>
                </Col>
                <Col></Col>
                <Col sm="4" style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalSellerContact(true);
                    }}
                  >
                    Contact Seller
                  </Button>
                  <Button
                    style={{ marginLeft: "5px" }}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalOrderDetails(true);
                    }}
                  >
                    View Order Details
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <img
                        src="/assets/img/stepperimg/gilan.png"
                        alt="item"
                        style={{ width: "100px" }}
                      />
                    </Col>
                    <Col>
                      <Typography style={{ textAlign: "left" }}>
                        <h6>Castrol EDGE Engine Oil 5W-30 5 Litre</h6>
                        <p>Item SKU. 299206</p>
                      </Typography>
                    </Col>
                    <Col sm="3">
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col sm="3">
                      <Badge color="success" pill>
                        Delivered Order
                      </Badge>
                      <Button
                        style={{ marginLeft: "5px" }}
                        color="light"
                        size="sm"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        Rate
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <br />
            <br />
            <div
              style={{
                padding: "5px",
              }}
            >
              <Row>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Placed</p>
                    <h6>2020-02-16</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Number</p>
                    <h6>232-123-123</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Total</p>
                    <h6>AED222</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Seller</p>
                    <h6>Tommy A car Parts</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Deliver To</p>
                    <h6>Mary Clock</h6>
                  </Typography>
                </Col>
                <Col></Col>
                <Col sm="4" style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalSellerContact(true);
                    }}
                  >
                    Contact Seller
                  </Button>
                  <Button
                    style={{ marginLeft: "5px" }}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalOrderDetails(true);
                    }}
                  >
                    View Order Details
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <img
                        src="/assets/img/stepperimg/gilan.png"
                        alt="item"
                        style={{ width: "100px" }}
                      />
                    </Col>
                    <Col>
                      <Typography style={{ textAlign: "left" }}>
                        <h6>Castrol EDGE Engine Oil 5W-30 5 Litre</h6>
                        <p>Item SKU. 299206</p>
                      </Typography>
                    </Col>
                    <Col sm="3">
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col sm="3">
                      <Badge color="success" pill>
                        Delivered Order
                      </Badge>
                      <Button
                        style={{ marginLeft: "5px" }}
                        color="light"
                        size="sm"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        Rate
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const cancelledOrders = () => {
    return (
      <Row>
        <Col xs="12" sm="12">
          <div>
            <div
              style={{
                padding: "5px",
              }}
            >
              <Row>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Placed</p>
                    <h6>2020-02-16</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Order Number</p>
                    <h6>232-123-123</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Total</p>
                    <h6>AED222</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Seller</p>
                    <h6>Tommy A car Parts</h6>
                  </Typography>
                </Col>
                <Col>
                  <Typography style={{ fontSize: "10px" }}>
                    <p style={{ marginBottom: "0px" }}>Deliver To</p>
                    <h6>Mary Clock</h6>
                  </Typography>
                </Col>
                <Col></Col>
                <Col sm="4" style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalSellerContact(true);
                    }}
                  >
                    Contact Seller
                  </Button>
                  <Button
                    style={{ marginLeft: "5px" }}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setModalOrderDetails(true);
                    }}
                  >
                    View Order Details
                  </Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <img
                        src="/assets/img/stepperimg/gilan.png"
                        alt="item"
                        style={{ width: "100px" }}
                      />
                    </Col>
                    <Col>
                      <Typography style={{ textAlign: "left" }}>
                        <h6>Castrol EDGE Engine Oil 5W-30 5 Litre</h6>
                        <p>Item SKU. 299206</p>
                      </Typography>
                    </Col>
                    <Col sm="3">
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Typography>AED35.99</Typography>
                    </Col>
                    <Col>
                      <Badge color="danger" pill>
                        Cancelled Order
                      </Badge>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      {/* Header section start */}


      {/* Header section end */}
      <section className="blog-area blog-grid section-padding-strict section-bg">
        <div className="container-fluid section-padding-all-listing">
          <div style={{ visibility: "hidden" }}>
            {ModalRateOrder()}
            {/* {ModalOrderDetails()} */}
            {ModalSellerContact()}
          </div>

          <Typography className="" style={{ textAlign: "left" }}>
            <h2>Your Orders</h2>
            <br />
          </Typography>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "1",
                })}
                onClick={() => {
                  toggleDate("1");
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "2",
                })}
                onClick={() => {
                  toggleDate("2");
                }}
              >
                In Progress
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "3",
                })}
                onClick={() => {
                  toggleDate("3");
                }}
              >
                Delivered
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontSize: "13px" }}
                className={classnames({
                  active: activeTabDate === "4",
                })}
                onClick={() => {
                  toggleDate("4");
                }}
              >
                Cancelled
              </NavLink>
            </NavItem>
          </Nav>
          <br />
          <Row>
            <Col>
              <Typography>
                <h6>3 order placed in last 3 months</h6>
              </Typography>
            </Col>
            <Col sm="8"></Col>
          </Row>
          <br />

          <TabContent activeTab={activeTabDate}>
            <TabPane tabId="1">{allOrders()}</TabPane>
            <TabPane tabId="2">{inProgressOrders()}</TabPane>
            <TabPane tabId="3">{deliveredOrders()}</TabPane>
            <TabPane tabId="4">{cancelledOrders()}</TabPane>
          </TabContent>
        </div>
      </section>

    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps)(YourOrders);
