import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { GetHomeListing } from "../../../../Store/action/listingActions";

import { numberWithCommas, stringToUrl } from "../../../../utils";
import ReviewListingCard from "./reviewListingCard";

class ReviewListingGrid extends Component {
  componentDidMount() {
    this.props.getHomeListing();
  }

  render() {
    const setting = {
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const listings = this.props.list.homeListing;
    const { isLoading } = this.props.list;
    const customerArry = [
      "assets/img/person1.png",
      "assets/img/person2.png",
      "assets/img/person1.png",
      "assets/img/person2.png",
    ];
    return (
      <Fragment>
        {/* {!isLoading ? (
          <p>Loading...</p>
        ) : ( */}
        <Slider {...setting}>
          {customerArry.map((value, index) => {
            let imgSrc = value;
            let data = {
              imgSrc,
            };
            return (
              <div key={index}>
                <ReviewListingCard {...data} />
              </div>
            );
          })}
        </Slider>
        {/* )} */}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    getHomeListing: () => dispatch(GetHomeListing()),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(ReviewListingGrid);
