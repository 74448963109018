import React, { Component, Fragment } from "react";

export class SectionTitle extends Component {
  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <div className="d-flex align-items-center">
                <div>
                  <h1
                    className="mb-0 about_us_title"
                    style={{
                      color: this.props.color ? this.props.color : "#fff",
                    }}
                  >
                    {this.props.title}
                  </h1>
                </div>
                {this.props.title_extra && (
                  <>
                    <div className="about-us-empty-div"></div>
                    <div>
                      <h1 className="mb-0 about_us_title">
                        {this.props.title_extra}
                      </h1>
                    </div>
                  </>
                )}
              </div>

              <p
                style={{
                  color: this.props.color ? this.props.color : "#fff",
                  fontWeight: "500",
                }}
                className="mt-1 mb-0"
              >
                {" "}
                {this.props.content}
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
