import React from "react";
import { useSelector, useDispatch } from "react-redux";
const ShowDynamicCurrency = (props) => {
  const { currency } = useSelector((state) => {
    return {
      currency: state.currency.currency
    };
  });
  return (
    <span>
      {currency}
    </span>
  );
};

export default ShowDynamicCurrency;
