import React, { Fragment, Component } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { popUp } from "../../../../utils";

class BuyerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyerList: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    axios
      .get("/api/users/get-buyer-list")
      .then((res) => {
        this.setState({ buyerList: res.data, loading: false });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  handleOnItemSelected(index) {
    if (this.props.onItemSelected) {
      const buyer = this.state.buyerList[index];
      this.props.onItemSelected(buyer);
    }
  }

  render() {
    return (
      <div style={{ maxHeight: "90vh", overflow: "auto" }}>
        {this.state.loading ? (
          "LOADING"
        ) : (
          <div>
            {this.state.buyerList && (
              <table className="table table-fixed-header">
                <thead>
                  <tr>
                    <th>#</th>
                    <th></th>
                    <th>Buyer</th>
                    <th>Listings</th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: "70vh" }}>
                  {this.state.buyerList &&
                    this.state.buyerList.map((buyer, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <NavLink
                              to={`/stores/buyer-details/${buyer._id}`}
                              onClick={(e) => {
                                this.handleOnItemSelected(index);
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <img
                                src={
                                  buyer.avatar ||
                                  "/assets/img/author-profile.jpg"
                                }
                                alt="AuthorImage"
                                width={120}
                              />
                            </NavLink>
                          </td>
                          <td>
                            <NavLink
                              to={`/stores/buyer-details/${buyer._id}`}
                              onClick={(e) => {
                                this.handleOnItemSelected(index);
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <h2>{buyer.details.company_name}</h2>
                            </NavLink>
                            <p>
                              Registered on{" "}
                              {moment(buyer.date)
                                .tz("Asia/Dubai")
                                .format("DD/MM/YYYY")}
                            </p>
                          </td>
                          <td>{buyer.listings.length} Items</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    );
  }
}

BuyerList.propTypes = {
  onItemSelected: PropTypes.func,
};

export default BuyerList;
