import React from "react";

const DigitizationContent = () => {
  return (
    <div className="benefits-seller-content mt-4">
      <ul>
        <li>Virtual storefront</li>
        <li>Real-time inventory</li>
        <li>Sealess online experience</li>
        <li>Live chat</li>
        <li>Digital request for quotes</li>
      </ul>
    </div>
  );
};

export default DigitizationContent;
