import React, { useState } from "react";
import {
	SideBySideMagnifier,
	Magnifier,
	GlassMagnifier,
	MagnifierContainer,
	MagnifierPreview,
	MagnifierZoom,
	MOUSE_ACTIVATION,
	TOUCH_ACTIVATION,
} from "react-image-magnifiers";
import Slider from "react-slick";
import IsExpress from "../common/IsExpress";
import Consolidation from "../common/Consolidation";
import classes from "classnames";
import ReactImageMagnify from "react-image-magnify";
import TextOverlay from "../common/TextOverlay";
import Picoverlay from "../common/Picoverlay";

const Gallery = ({ data, business_model }) => {
	const pagination = [];
	if (data && data.length > 0) {
		data.map((item) => {
			pagination.push(item);
		});
	}
	const [fixed, setfixed] = useState(false);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		appendDots: (dots) => (
			<div
				style={{
					backgroundColor: "#ddd",
					borderRadius: "10px",
					padding: "10px",
				}}
			>
				<ul style={{ margin: "0px" }}> {dots} </ul>
			</div>
		),

		customPaging: function (i) {
			return (
				<div className="listing-subimages">
					{pagination[i] && (
						<img
							src={pagination[i] || "/assets/img/product-default.jpg"}
							alt={`product-images-${pagination[i]}`}
							key={`product-images-${pagination[i]}`}
							width="100%"
							width="100%"
							height="100%"
						/>
					)}
				</div>
			);
		},
	};

	return (
		<div className="gallery-wrapper">
			<Slider {...settings}>
				{data?.map((img, i) => {
					return (
						<>
							<ReactImageMagnify
								{...{
									smallImage: {
										alt: "Product Image",
										isFluidWidth: true,
										src: img,
									},
									largeImage: {
										src: img,
										width: 1200,
										height: 1800,
									},
									lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
									enlargedImagePosition: "over",
								}}
							/>
						</>
					);
				})}
			</Slider>
			{/* <IsExpress
				business_model={business_model}
				right="-2px"
				height="145px"
				bottom="-35px"
			/> */}
			{/* <TextOverlay
				business_model={business_model}
				left="20px"
				right="650px"
				height="27px"
				bottom="485px"
				borderRadius="50px"
			/> */}
			<TextOverlay
				business_model={business_model}
				style={{ top: 40, left: 50 }}
			// left="20px"
			// right="6px"
			// height="27px"
			// bottom="200px"
			// borderRadius="50px"
			/>
		</div>
	);
};

export default Gallery;
