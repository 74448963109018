import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { PageBanner } from "../content/element/page-banner";
import PreHeader from "../layout/pre-header";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Row, Col } from "reactstrap";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import PageTitle from "../content/element/PageTitle";
import SimpleReactValidator from "simple-react-validator";
import AddForm from "../content/element/AddForm";
import Select from "react-select";
import Pending from "../content/element/UserStatus/Pending";

import { Button } from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";
// import { Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { Table } from "reactstrap";
// import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
// import classnames from "classnames";
// import { Badge } from "reactstrap";
// import ReactStars from "react-rating-stars-component";
// import Stepper from "./../common/StepperOrderDetails";
// import AddressModal from "../content/element/modal/address-modal";
// import { postcss } from "postcss-preset-env";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

import { popUp } from "../../utils";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
    },
  },
}))(InputBase);

const YourAddress = ({ history }) => {
  const [modal, setModal] = useState(false);
  const [modalAddNewAddress, setModalAddNewAddress] = useState(false);
  const [modalEditNewAddress, setModalEditNewAddress] = useState(false);
  const [modalRemoveNewAddress, setModalRemoveNewAddress] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostCode] = useState("");
  const [contact, setContact] = useState("");
  const [addressCheck, setAddressCheck] = useState("");
  const [fieldsCheckMsg, setFieldsCheckMsg] = useState("");
  const [fieldsCheck, setFieldsCheck] = useState(false);
  const [userAddress, setUserAddress] = useState();
  const [singleAddress, setSingleAddress] = useState({});
  const [codes, setCodes] = useState("");
  const [code, setCode] = useState("971");
  const [error, setError] = React.useState(false);

  const [emirate, setEmirates] = useState({ label: "", value: "" });
  const [region, setRegion] = useState({ label: "", value: "" });
  const [location, setLocation] = useState({ label: "", value: "" });

  const [stateData, setStateData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [locationData, setLocationData] = useState([]);

  const [errorMsg, setErrorMsg] = useState(true);
  const [contactChecked, setContactChecked] = useState(false);
  const [, forceUpdate] = useState();

  const validator = React.useRef(
    new SimpleReactValidator({
      className: "text-danger",
      validators: {
        firstName: {
          message: "Name must be greater than 3 characters",
          rule: (val) => {
            return val.length > 3;
          },
          required: true,
        },
        lastName: {
          message: "Name must be greater than 4 characters",
          rule: (val) => {
            return val.length > 4;
          },
          required: true,
        },
        contact: {
          message: "Invalid contact number",
          rule: (val) => {
            return val.length == 12;
          },
        },
      },
    })
  );

  React.useEffect(() => {
    axios
      .get("https://restcountries.com/v2/all")
      .then((res) => {
        setCodes(res.data);
      })
    // .catch((e) =>
    //   popUp("There was a problem with server. Please try again.", "error")
    // );

    axios
      .get("/api/products/get-state")
      .then((res) => {
        setStateData(res.data);
      })
    // .catch((e) =>
    //   popUp("There was a problem with server. Please try again.", "error")
    // );

    axios
      .get("/api/products/get-regions")
      .then((res) => {
        setRegionData(res?.data?.regions);
      })
    // .catch((e) =>
    //   popUp("There was a problem with server. Please try again.", "error")
    // );

    axios
      .get("/api/products/a-city")
      .then((res) => {
        setLocationData(res.data);
      })
    // .catch((e) =>
    //   popUp("There was a problem with server. Please try again.", "error")
    // );
  }, []);

  const handleChangeState = (e) => {
    setEmirates({ label: e.value, value: e.value });
    setRegion({ label: "", value: "" });
    setLocation({ label: "", value: "" });
  };

  const handleChangeRegion = (e) => {
    setRegion({ label: e.value, value: e.value });
    setLocation({ label: "", value: "" });
  };

  const ModalAddNewAddress = (props) => {
    const toggle = () => {
      setModalAddNewAddress(!modalAddNewAddress);
    };
    const filteredRegions =
      regionData &&
      regionData.length &&
      regionData.filter((item) => {
        return item?.state?.name === emirate.value;
      });

    console.log(regionData, "region")
    const filteredCities =
      locationData &&
      locationData.length &&
      locationData.filter((item) => {
        return item?.region?.name === region.value;
      });

    const locationOption =
      filteredCities &&
      filteredCities?.length &&
      filteredCities?.map((item) => {
        return { label: item?.name, value: item?.name };
      });

    const stateOption =
      stateData?.state &&
      stateData.state.length &&
      stateData.state.map((state) => {
        return { label: state?.name, value: state?.name };
      });

    const regionOption =
      filteredRegions &&
      filteredRegions.length &&
      filteredRegions.map((region) => {
        return { label: region.name, value: region.name };
      });

    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modalAddNewAddress}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Add new address</ModalHeader>
          <Typography
            className=""
            style={{ textAlign: "left", marginLeft: "10px", marginTop: "10px" }}
          >
            <Label
              className={
                fieldsCheck ? "display-validMsg" : "not-display-validMsg"
              }
            >
              Indicates required fields
            </Label>
          </Typography>
          <ModalBody style={{ padding: "20px" }}>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  // onBlur={validator.current.showMessageFor("firstName")}
                  />
                  {validator.current.message(
                    "firstName",
                    firstName,
                    "firstName|required"
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="emirate">Emirates</Label>
                  <Select
                    options={stateOption ? stateOption : []}
                    value={emirate}
                    onChange={(e) => {
                      handleChangeState(e);
                    }}
                  />
                  {/* <select
                    className="form-control"
                    name="emirate"
                    defaultValue="Select Emirate"
                    id="emirate"
                    value={emirate}
                    onChange={handleChangeState}
                    required
                  >
                    <option value="" disabled>
                      Select Emirate
                    </option>
                    {stateData &&
                      stateData?.state?.map((state, index) => {
                        return (
                          <option value={state?.name}>{state?.name}</option>
                        );
                      })}
                  </select> */}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="region">Regions</Label>
                  <Select
                    options={regionOption ? regionOption : []}
                    value={region}
                    onChange={(e) => {
                      handleChangeRegion(e);
                    }}
                  />
                  {/* <select
                    className="form-control"
                    name="region"
                    defaultValue="Select region"
                    id="region"
                    value={region}
                    onChange={handleChangeRegion}
                    required
                  >
                    <option value="" disabled>
                      Select Region
                    </option>
                    {filteredRegions &&
                      filteredRegions?.map((state, index) => {
                        return (
                          <option value={state?.name}>{state?.name}</option>
                        );
                      })}
                  </select> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="location">Location</Label>
                  <Select
                    options={locationOption ? locationOption : []}
                    value={location}
                    onChange={(e) => {
                      setLocation({ label: e.value, value: e.value });
                    }}
                  />
                  {/* <select
                    className="form-control"
                    name="location"
                    defaultValue="Select location"
                    id="location"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    required
                  >
                    <option value="" disabled>
                      Select Location
                    </option>
                    {filteredCities &&
                      filteredCities.map((state, index) => {
                        return (
                          <option value={state?.name}>{state?.name}</option>
                        );
                      })}
                  </select> */}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="postCode">Post Code</Label>
                  <Input
                    type="text"
                    name="postCode"
                    id="postCode"
                    placeholder="Post Code"
                    value={postCode}
                    onChange={(e) => {
                      setPostCode(e.target.value);
                    }}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="contact">Contact No</Label>
                  <PhoneInput
                    defaultcountry={"ae"}
                    value={contact}
                    placeholder="+971 123456789"
                    onChange={(contact) => setContact(contact)}
                  />
                  {error && (
                    <p className="error-msg">contact number is not valid</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>{" "}
            <Button
              // disabled={contactChecked == false}
              color="primary"
              onClick={() => {
                submitAddress(toggle, contact);
              }}
            >
              Add
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const ModalEditNewAddress = (props) => {
    async function fetchSingleAddress() {
      let response = await axios.get(
        `/api/address/edit-address/${modalEditNewAddress?.id}`
      );
      setSingleAddress(response?.data?.data);
    }
    useEffect(() => {
      if (modalEditNewAddress?.id) fetchSingleAddress();
    }, [modalEditNewAddress?.id]);
    useEffect(() => {
      if (singleAddress) {
        setFirstName(singleAddress.first_name ? singleAddress.first_name : "");
        setLastName(singleAddress.last_name ? singleAddress.last_name : "");
        setAddress(singleAddress.address ? singleAddress.address : "");
        setSuburb(singleAddress.suburb ? singleAddress.suburb : "");

        setPostCode(singleAddress.postcode ? singleAddress.postcode : "");
        setContact(singleAddress.contact ? singleAddress.contact : "");
        setEmirates(
          singleAddress.state
            ? { label: singleAddress.state, value: singleAddress.state }
            : { label: "", value: "" }
        );
        setRegion(
          singleAddress.region
            ? { label: singleAddress.region, value: singleAddress.region }
            : { label: "", value: "" }
        );
        setLocation(
          singleAddress.location
            ? { label: singleAddress.location, value: singleAddress.location }
            : { label: "", value: "" }
        );
      }
    }, [
      singleAddress,
      setFirstName,
      setLastName,
      setAddress,
      setSuburb,
      setEmirates,
      setLocation,
      setRegion,
      setPostCode,
    ]);

    const toggle = () => {
      setModalEditNewAddress(!modalEditNewAddress);
    };
    const filteredRegions =
      regionData &&
      regionData.length &&
      regionData.filter((item) => {
        return item?.state?.name === emirate.value;
      });
    const filteredCities =
      locationData &&
      locationData.length &&
      locationData.filter((item) => {
        return item?.region?.name === region.value;
      });

    //   const formattedCities =
    // sortedCities &&
    // sortedCities.length > 0 &&
    // sortedCities.map((item) => {
    //   return { label: item?.name, value: item?.name };
    // });

    const locationOption =
      filteredCities &&
      filteredCities?.length &&
      filteredCities?.map((item) => {
        return { label: item?.name, value: item?.name };
      });

    const stateOption =
      stateData?.state &&
      stateData.state.length &&
      stateData.state.map((state) => {
        return { label: state?.name, value: state?.name };
      });

    const regionOption =
      filteredRegions &&
      filteredRegions.length &&
      filteredRegions.map((region) => {
        return { label: region.name, value: region.name };
      });

    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modalEditNewAddress !== false}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader
            style={{ position: "relative", height: 50 }}
            toggle={toggle}
          >
            <div style={{ position: "absolute", right: 20 }}>Edit address</div>
          </ModalHeader>
          <ModalBody style={{ padding: "20px" }}>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  {validator.current.message(
                    "firstName",
                    firstName,
                    "required|firstName"
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                  {validator.current.message(
                    "lastName",
                    lastName,
                    "required|lastName"
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                  {validator.current.message("address", address, "required")}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="emirate">Emirates</Label>

                  <Select
                    options={stateOption ? stateOption : []}
                    value={emirate}
                    onChange={(e) => {
                      handleChangeState(e);
                    }}
                  />
                  {validator.current.message("emirate", emirate, "required")}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="region">Regions</Label>

                  <Select
                    options={regionOption ? regionOption : []}
                    value={region}
                    onChange={(e) => {
                      handleChangeRegion(e);
                    }}
                  />
                  {validator.current.message("region", region, "required")}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="location">Location</Label>

                  <Select
                    options={locationOption ? locationOption : []}
                    value={location}
                    onChange={(e) => {
                      setLocation({ label: e.value, value: e.value });
                    }}
                  />
                  {validator.current.message("location", location, "required")}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="postCode">PO Box</Label>
                  <Input
                    type="text"
                    name="postCode"
                    id="postCode"
                    placeholder="Post Code"
                    value={postCode}
                    onChange={(e) => {
                      setPostCode(e.target.value);
                    }}
                  />
                  {validator.current.message("postCode", postCode, "required")}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="contact">Contact No</Label>
                  <PhoneInput
                    country={"ae"}
                    onlyCountries={["ae"]}
                    value={contact}
                    placeholder="+971 123456789"
                    onChange={(contact) => setContact(contact)}
                  />
                  {validator.current.message(
                    "contact",
                    contact,
                    "required|contact"
                  )}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                editAddress(toggle, contact);
              }}
            >
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const ModalRemoveNewAddress = (props) => {
    const toggle = () => {
      setModalRemoveNewAddress(!modalRemoveNewAddress);
    };
    const handleDeleteAddress = () => {
      axios
        .delete(`/api/address/${modalRemoveNewAddress?.id}`)
        .then((res) => {
          if (res.status === 204) {
            popUp("Your address was deleted successfully.");
            // window.location.reload();
            toggle();
            fetchAddress();
          }
        })
        .catch((err) => {
          // popUp("There was a problem with server. Please try again.", "error");
        });
    };

    return (
      <div>
        <Dialog
          open={modalRemoveNewAddress}
          toggle={toggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ position: "relative" }}>
            <div
              onClick={toggle}
              style={{
                position: "absolute",
                left: 15,
                top: 0,
                fontSize: 22,
                cursor: "pointer",
              }}
            >
              x
            </div>
            <div
              style={{
                width: "100%",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <HighlightOffRoundedIcon
                style={{
                  fontSize: 80,
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "rgb(243, 146, 0)",
                }}
              />
              <h6>Are you sure?</h6>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ textAlign: "center", color: "#686a6b" }}>
                Do you really want to delete address
                <br />
                <span style={{ color: "rgb(35, 36, 35)", fontWeight: 600 }}>
                  {modalRemoveNewAddress.state} {modalRemoveNewAddress.add}?
                </span>
                <br />
                This process cannot be undone.
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              paddingRight: "20%",
              paddingLeft: "20%",
              marginBottom: 10,
            }}
          >
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#1d71b8", width: 150 }}
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteAddress}
              style={{
                backgroundColor: "rgb(243, 146, 0)",
                color: "#fff",
                width: 150,
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const handleContactChange = () => {
    axios.get(`http://apilayer.net/api/validate?access_key=${process.env.REACT_APP_NUM_VERIFY_API_KEY}&number=${code + contact}`)
      .then((res) => {
        if (res?.data?.valid) {
          setContactChecked(true);
          setErrorMsg(true);
        } else {
          setErrorMsg(false);
        }
      })
      .catch((e) =>
      // popUp("There was a problem with server. Please try again.", "error")
      { }
      );
  };

  const submitAddress = (toggle, number) => {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (validator.current.allValid()) {
      alert("working...");
    } else {
      return false;
    }
    if (
      firstName == "" ||
      lastName == "" ||
      address == "" ||
      emirate == "" ||
      location == "" ||
      postCode == "" ||
      !contact.length == 8
      // addressCheck == ""
    ) {
      // setFieldsCheckMsg("Please fill the require fields!");
      setError(true);
      setFieldsCheck(true);
    } else {
      setError(false);
      setFieldsCheck(false);
      const data = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        state: emirate,
        region: region,
        location: location,
        postcode: postCode,
        contact: "+" + code + contact,
        addressCheck,
        user: currentUser?._id,
      };
      axios
        .post("/api/address/create-user-address", data)
        .then((res) => {
          popUp("Your address was added successfully");
        })
        .catch((err) => {
          { }
        });
      toggle();
    }
  };

  const editAddress = (toggle, number) => {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (validator.current.allValid()) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        state: emirate.value,
        region: region.value,
        location: location.value,
        postcode: postCode,
        contact: contact,
        user: currentUser?._id,
      };
      axios
        .put(`/api/address/edit-address/${modalEditNewAddress?.id}`, data)
        .then((res) => {
          if (res?.data) {
            popUp("Your address was added successfully.");
            toggle();
            fetchAddress();
          }
        })

    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  async function fetchAddress() {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    let response = await axios.get(
      `/api/address/get-user-address?user_id=${currentUser?._id}`
    );

    setUserAddress(response?.data?.addresses.reverse());
    setDisplay(
      response?.data?.addresses.find((add) => add.default_address == true)?.data
        ?._id
    );
  }

  useEffect(() => {
    fetchAddress();
  }, []);

  const [selected, setSelected] = useState(false);

  const selectedAddressCard = (id) => {
    setSelected(true);
  };
  const [display, setDisplay] = useState("");
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  const showButton = ({ e, id }) => {
    e.preventDefault();
    setDisplay({ display: id });
  };

  const hideButton = (e) => {
    e.preventDefault();
    setDisplay("notdisplayed");
  };

  const [default_address, setDefault_address] = React.useState("");
  const [clicked, setclicked] = React.useState(false);
  const [disabledBtn, setDisableBtn] = React.useState(false);

  const handleAddFormModal = (res) => {
    console.log(res, "response")
    setIsOpenAddModal(res);
    fetchAddress();
  };

  const handleClicked = (id) => {
    setDisableBtn(true);
    let body = JSON.stringify({ default_address: true });
    axios
      .patch(`/api/address/update-user-address/${id}`, body)
      .then((res) => {
        setDefault_address(id);
        popUp("Your address was successfully set as default");
      })
      .catch((e) => {
        // popUp("There was a problem with server. Please try again.", "error");
      });

    fetchAddress();
    setDisableBtn(false);
  };

  const handleEdit = (obj) => {
    setModalEditNewAddress(obj);
  };

  const currentUser = JSON.parse(localStorage.getItem("login"));

  if (currentUser && currentUser.status === "Pending") return <Pending />;

  return (
    <Fragment>



      <PageBanner />

      <AddForm
        handleAddFormModal={handleAddFormModal}
        isOpenAddModal={isOpenAddModal}
        stateData={stateData}
        locationData={locationData}
        regionData={regionData}
        fetchAddress={fetchAddress}
      />

      <section className="pt-2 pb-5" style={{ backgroundColor: "#F6F7FC" }}>
        <div className="container">
          <PageTitle title="My Address" />
          <div className="address-modal">
            {ModalEditNewAddress()}
            {ModalRemoveNewAddress()}
          </div>

          <Row className="mt-2">
            {userAddress &&
              userAddress.length > 0 &&
              userAddress.map((item, index) => {
                return (
                  <Col md="4" key={index}>
                    <div
                      onMouseEnter={(e) => showButton({ e, id: index })}
                      onMouseLeave={(e) => hideButton(e)}
                      className={`card-address ${item?.default_address && "default_address"
                        }`}
                      style={{ border: "1px solid #ddd", marginBottom: "20px" }}
                    >
                      <div className="user-details-container">
                        <div className="user-image">
                          <img src="/assets/img/avatar.png" alt="avatar" />
                        </div>

                        <div className="user-details">
                          <div className="user-name">
                            {item?.first_name} {item?.last_name}
                          </div>
                          <p>
                            {item?.postcode} {item?.suburb} {item?.address}{" "}
                            {item?.state}
                            <br /> {item?.contact}
                          </p>
                        </div>
                      </div>
                      <hr className="address-hr" />
                      <div className="user-details-footer">
                        {item.default_address ? (
                          ""
                        ) : (
                          <Button
                            size="sm"
                            color="#FFF"
                            onClick={() => {
                              handleClicked(item._id);
                            }}
                            disabled={disabledBtn}
                            className="btn-address"
                            style={{ marginRight: "0px", marginLeft: "auto" }}
                          >
                            Set Default
                          </Button>
                        )}
                        <Button
                          size="sm"
                          color="#FFF"
                          onClick={() => {
                            handleEdit({
                              showEditModal: true,
                              id: item?._id,
                            });
                          }}
                          style={
                            item.default_address
                              ? { marginLeft: "auto" }
                              : { marginLeft: "0px" }
                          }
                          className="btn-address"
                        >
                          Edit
                        </Button>
                        <Button
                          size="sm"
                          color="#FFF"
                          onClick={() => {
                            setModalRemoveNewAddress({
                              showMoadl: true,
                              id: item?._id,
                              add: item?.address,
                              state: item?.state,
                            });
                          }}
                          style={{ marginLeft: "0px" }}
                          className="btn-address"
                        >
                          Remove
                        </Button>

                        <div
                          color="primary"
                          className={
                            item.default_address ? "address-true" : "d-none"
                          }
                        >
                          <p style={{ color: "#fff" }}>Default</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            <Col md="3">
              <div
                className="add-address-container"
                onClick={() => {
                  handleAddFormModal(true);

                }}
              >
                <div className="add-image-icon">
                  {" "}
                  <img src="/assets/img/add-icon.png" alt="add_address_icon" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>


    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps)(YourAddress);
