import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

import { SectionTitle } from "../content/element/section-title";
import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import { PageBanner } from "../content/element/page-banner";
import InputDropdown from "../content/element/input-dropdown";
import {
  countrList,
  businessModal,
} from "../../constants";
import CloseIcon from "@material-ui/icons/Close";
import { popUp, validateFile } from "../../utils";

class EditParts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      makeList: [],
      modelList: [],
      price: null,
      currency: "AED",
      quantity: null,
      imageArray: [],
      originalImages: [],
      pic: null,
      category: null,
      subCategory: null,
      partName: null,
      partHSCode: null,
      partBrand: null,
      partSKU: null,
      type: null,
      fittingPosition: null,
      description: null,
      additionalNotes: null,
      submitLoading: false,
      makes: [],
      models: [],
      allTireSubCategories: [],
      showTireSubCategories: false,
      subCategoryTire: null,
      heightDimension: null,
      widthDimension: null,
      depthDimension: null,
      weight: null,
      countryOrigin: null,
      clickCollect: false,
      delivery: false,
      categories: [],
      subCategories: [],
      tireSubCategories: [],
      business_model: "",
      makeYear: null,
      utqg: null,
      serviceDescription: null,
      rimSize: null,
      amp: null,
      voltage: null,
      tiresCategoriesId: []

    };
    this.validator = new SimpleReactValidator();
    this.handleChangeNumeric = this.handleChangeNumeric.bind(this);
    this.handleUnitOptionChange = this.handleUnitOptionChange.bind(this);
    this.openFileDlg = this.openFileDlg.bind(this);
    this.uploadPic = this.uploadPic.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeSubCategory = this.handleChangeSubCategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleChangeMake = this.handleChangeMake.bind(this);
    this.handleChangeModel = this.handleChangeModel.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleAdditionalNotesChange =
      this.handleAdditionalNotesChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.getModelList = this.getModelList.bind(this);
  }


  getTireSubCategories = () => {
    let ids = []

    axios.get('/api/subcategories/get-tire-subcategories').then(res => {
      this.setState({ allTireSubCategories: res.data.data });
      res?.data?.data?.map(item => ids.push(item?._id))

    }
    );
    this.setState({ tiresCategoriesId: ids })
  }


  componentDidMount() {
    this.getTireSubCategories()
    // axios
    //   .get("/api/listing/get-by-id?id=" + this.props.match.params.id)
    //   .then((res) => {
    //     axios
    //       .get("/api/categories/all")
    //       .then((cat) => {
    //         let categories = cat.data.map((data) => ({
    //           ...data,
    //           label: data.name,
    //           value: data._id,
    //         }));
    //         axios
    //           .get("/api/subcategories/" + res.data.category)
    //           .then((subCat) => {
    //             this.setState({
    //               categories,
    //               subCategories: subCat.data.map((data) => ({
    //                 ...data,
    //                 label: data.name,
    //                 value: data._id,
    //               })),
    //             });


    //           });
    //       })

    //     let makeList = res.data.makeList;
    //     makeList = makeList.map((data) => {
    //       return { label: data.name, value: data.id_car_make, _id: data._id };
    //     });
    //     let modelList = res.data.modelList;
    //     modelList = modelList.map((data) => {
    //       return { label: data.name, value: data.id_car_model, _id: data._id };
    //     });

    //     this.setState({ ...this.state, ...res.data, makeList, modelList, showTireSubCategories: this.state.tiresCategoriesId.includes(res.data?.subCategory) });


    //     let tiresubcats = this.state.allTireSubCategories.filter(s => s.category_id === res.data?.subCategory).map(s => ({ value: s._id, label: s.name }))
    //     this.setState({ tireSubCategories: tiresubcats })
    //   })
    //   .catch((err) => {
    //     popUp("There was a problem with server. Please try again.");
    //   });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allCategories !== this.props.allCategories) {

      axios
        .get("/api/listing/get-by-id?id=" + this.props.match.params.id)
        .then((res) => {
          let categories = this.props.allCategories.length > 0 && this.props.allCategories.map((data) => ({
            ...data,
            label: data.name,
            value: data._id,
          }));
          // this.setState({ categories });
          // axios
          //   .get("/api/subcategories/" + res.data.category)
          //   .then((subCat) => {

          let result = this.props.allCategories.find(
            (cat) => cat._id === res.data.category
          );

          this.setState({
            categories,
            subCategories: result?.subCategories.map((data) => ({
              ...data,
              label: data.name,
              value: data._id,
            })),
          });


          // });


          let makeList = res.data.makeList;
          makeList = makeList.map((data) => {
            return { label: data.name, value: data.id_car_make, _id: data._id };
          });
          let modelList = res.data.modelList;
          modelList = modelList.map((data) => {
            return { label: data.name, value: data.id_car_model, _id: data._id };
          });

          this.setState({ ...this.state, ...res.data, makeList, modelList, showTireSubCategories: this.state.tiresCategoriesId.includes(res.data?.subCategory) });


          let tiresubcats = this.state.allTireSubCategories.filter(s => s.category_id === res.data?.subCategory).map(s => ({ value: s._id, label: s.name }))
          this.setState({ tireSubCategories: tiresubcats })
        })
        .catch((err) => {
          console.log({ err })
          popUp("There was a problem with server. Please try again.");
        });
    }


  }

  getModelList(makes) {
    axios
      .post("/api/listing/get-models", { makes })
      .then((res) => {
        const list = res.data.map((data) => {
          return { label: data.name, value: data.id_car_model, _id: data._id };
        });
        this.setState({ modelList: list });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  handleChangeCategory = (selectedOption, fieldAttr) => {
    this.handleChangeSelect(selectedOption, fieldAttr);
    this.setState({ subCategory: null, showTireSubCategories: false, subCategoryTire: null, rimSize: null, utqg: null, serviceDescription: null });
    // axios
    //   .get("/api/subcategories/" + selectedOption.value)
    //   .then((res) => {
    //     this.setState({
    //       subCategories: res.data.map((data) => ({
    //         ...data,
    //         label: data.name,
    //         value: data._id,
    //       })),
    //     });
    //   })
    //   .catch(() =>
    //     popUp("There was a problem with server. Please try again.")
    //   );
    let result = this.props.allCategories.find(
      (cat) => cat._id === selectedOption.value
    );

    // result &&
    //   axios.get(`/api/subcategories/${result?._id}`).then((res) => {
    // console.log("res", this.state.activeCategory.value)

    this.setState({
      subCategories: result.subCategories.map((data) => ({
        ...data,
        label: data.name,
        value: data._id,
      })),
    });
  };

  handleChangeMake(options) {
    if (!options) {
      this.setState({ makes: null });
      return;
    }
    const make_ids = options.map((o) => {
      return o.value;
    });
    const makes = options.map((o) => o._id);
    this.setState({ makes });
    this.getModelList(make_ids);
  }

  handleChangeModel(options) {
    if (!options) {
      this.setState({ models: null });
      return;
    }
    const models = options.map((o) => o._id);
    this.setState({ models });
  }

  handleChangeNumeric(name, value) {
    this.setState({ [name]: value });
  }

  handleUnitOptionChange(name, value) {
    this.setState({ [name]: value });
  }

  openFileDlg(e) {
    e.preventDefault();
    this.upload.click();
  }

  uploadPic(e) {
    e.preventDefault();
    // let file = e.target.files[0];
    // let pic = Object.assign(file, {
    //   preview: URL.createObjectURL(file)
    // });
    // this.setState({pic});

    if (e.target.files) {
      const files = Array.from(e.target.files);
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          this.setState({ pic: images });
          // this.setState({
          //   originalImages: [...this.state.originalImages, ...images],
          // });
          let data = new FormData();
          files.forEach((file) => data.append(`listing`, file));
          console.log(this.state.imageArray);
          axios.post("/api/imageupload?isListing=true", data).then((res) => {
            this.setState({
              imageArray: [...this.state.imageArray, ...res.data],
            });
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  // handleChangeSelect(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  handleChangeSelect(selectedOption, fieldAttr) {
    this.setState({ [fieldAttr.name]: selectedOption.value });
  }

  handleChangeCheckbox(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleChangeSubCategory(option) {
    this.setState({ subCategory: option });
    let isIncludes = this.state.tiresCategoriesId.includes(option.value);
    if (isIncludes) {
      this.setState({ showTireSubCategories: true })
      let tiresubcats = this.state.allTireSubCategories.filter(s => s.category_id === option.value).map(s => ({ value: s._id, label: s.name }))
      this.setState({ tireSubCategories: tiresubcats })
    } else {
      this.setState({ showTireSubCategories: false, subCategoryTire: null, rimSize: null, utqg: null, serviceDescription: null })
    }
  }

  handleInput(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDescriptionChange(val) {
    this.setState({
      description: val,
    });
  }

  handleAdditionalNotesChange(val) {
    this.setState({
      additionalNotes: val,
    });
  }

  handleSeriveInfoChange = (val) => {
    this.setState({
      serviceDescription: val,
    });
  }


  handleSubmit(e) {
    this.setState({ submitLoading: true });

    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ submitLoading: true });
      let formData = {};
      formData = {
        list_id: this.props.match.params.id,
        price: this.state.price && this.state.price,
        currency: this.state.currency,
        quantity: this.state.quantity,
        category: this.state.category,
        subCategory: this.state?.subCategory?.value || this.state?.subCategory || null,
        subCategoryTire: this.state?.subCategoryTire?.value || this.state?.subCategoryTire || null,
        partName: this.state.partName,
        partHSCode: this.state.partHSCode,
        partSKU: this.state.partSKU,
        partBrand: this.state.partBrand,
        type: this.state.type,
        description: this.state.description,
        additionalNotes: this.state.additionalNotes,
        fittingPosition: this.state.fittingPosition,
        makes: this.state.makes,
        models: this.state.models,
        heightDimension: parseFloat(this.state.heightDimension),
        widthDimension: parseFloat(this.state.widthDimension),
        weight: parseFloat(this.state.weight),
        depthDimension: parseFloat(this.state.depthDimension),
        countryOrigin: this.state.countryOrigin,
        clickCollect: this.state.clickCollect,
        delivery: this.state.delivery,
        user: this.props.login._id,
        business_model: this.state.business_model,
        pic: this.state.imageArray[0],
        imageArray: this.state.imageArray,
        makeYear: this.state.makeYear,
        utqg: this.state.utqg,
        serviceDescription: this.state.serviceDescription,
        rimSize: this.state.rimSize,
        amp: this.state.amp,
        voltage: this.state.voltage,
      };
      axios
        .post(`/api/listing/update`, formData)
        .then((res) => {
          this.setState({ submitLoading: false });
          popUp("Successfully updated");
          this.props.history.push("/my-listings");
        })
        .catch((err) => {
          popUp("There was a problem with server. Please try again.");
          this.setState({ submitLoading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ submitLoading: false });
    }
  }

  closeImage = async (imageURI) => {
    const newArray = this.state.imageArray.filter((item, i) => {
      return i !== imageURI;
    });
    const url = this.state.imageArray.find((item, i) => {
      return i === imageURI;
    });
    if (url) {
      await axios
        .delete("/api/imageupload/", { data: { url } })
        .then((res) => {
          this.setState({ imageArray: newArray });
        })
        .catch((err) => console.log(err));
    }
  };

  render() {
    const fitting_position = ["Front", "Rear"];
    const formattedFittingPosition = fitting_position.map((type) => {
      return { label: type, value: type };
    });

    const part_type = ["Genuine", "Aftermarket", "TBA"];
    const formattedPartType = part_type.map((type) => {
      return { label: type, value: type };
    });
    let { categories, subCategories, subCategory, subCategoryTire, showTireSubCategories, tireSubCategories } = this.state;
    return (
      <Fragment>

        <PageBanner title="Sell your spare part" />
        <section className="sell-part-wrapper section-bg">
          <div className="container">
            <SectionTitle color title="Edit Product" />

            <div className="atbd_content_module mt-4">
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>
                    <span className="la la-user"></span>General Information
                  </h4>
                </div>
              </div>
              <div className="atbdb_content_module_contents">
                <form className="form-vertical">
                  <div className="form-group text-center">
                    {/* {
                        this.state.pic && (
                          <img src={typeof this.state.pic === "string" ? this.state.pic : this.state.pic.preview} onClick={this.openFileDlg}  width="300" alt="pic" />
                        )
                      } */}
                    <div className="photo-container">
                      {this.state?.imageArray?.map((imageURI, k) => (
                        <div key={k} className="image-upload-div">
                          <div
                            className="image-cross-icon-div"
                            onClick={() => this.closeImage(k)}
                          >
                            <CloseIcon style={{ fontSize: 22 }} />
                          </div>
                          <img
                            key={k}
                            className="photo-uploaded m-3"
                            src={imageURI}
                            width="300"
                            style={{ width: "150px", height: "150px" }}
                            alt="pic"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="custom-file-upload">
                      <input
                        name="imageArray"
                        id="customUpload"
                        type="file"
                        ref={(ref) => (this.upload = ref)}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          let isLt5M = validateFile(e.target.files[0]);
                          if (!isLt5M) {
                            e.target.value = "";
                            return;
                          }
                          this.uploadPic(e);
                        }}
                      />
                      <label
                        htmlFor="customUpload"
                        className="btn btn-sm btn-primary mt-3"
                        onClick={this.openFileDlg}
                      >
                        Upload Picture
                      </label>
                    </div>
                    {/* <div className="text-danger">
                        {this.validator.message(
                          "pic",
                          this.state.pic,
                          `required`
                        )}
                    </div> */}
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>Part Name:</label>
                      <input
                        type="text"
                        name="partName"
                        className="form-control"
                        onChange={this.handleInput}
                        value={this.state.partName || ""}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "partName",
                          this.state.partName,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Part Brand:</label>
                      <input
                        type="text"
                        name="partBrand"
                        className="form-control"
                        onChange={this.handleInput}
                        value={this.state.partBrand || ""}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "partBrand",
                          this.state.partBrand,
                          `required`
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>Category</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix="react-select"
                        name="category"
                        placeholder="Choose Category..."
                        options={categories}
                        onChange={this.handleChangeCategory}
                        value={
                          this.state.category && this.state.categories.length
                            ? {
                              label: this.state.categories.find(
                                (c) => c._id === this.state.category
                              )?.name,
                              value: this.state.category,
                            }
                            : null
                        }
                      />

                      {/* <select
                        className="form-control"
                        name="category"
                        id="category"
                        onChange={this.handleChangeSelect}
                        value={this.state.category || ""}
                      >
                        <option value="" disabled>
                          Select Category
                        </option>
                        {categories.map((cat, index) => {
                          return (
                            <option value={cat.value} key={index}>
                              {cat.value}
                            </option>
                          );
                        })}
                      </select> */}

                      <div className="text-danger">
                        {this.validator.message(
                          "category",
                          this.state.category,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Sub Category</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix="react-select"
                        name="make"
                        placeholder="Choose..."
                        onChange={(option) =>
                          this.handleChangeSubCategory(option)
                        }
                        options={subCategories}
                        value={
                          this.state.category
                            ? subCategories.find(
                              (x) => x.value === this.state.subCategory
                            )
                            : null
                        }
                      />
                    </div>
                    {showTireSubCategories && <div className="col-md-12">
                      <label>Sub Category Tire<span style={{ color: 'red' }}>*</span></label>
                      <Select
                        className={`react-select`}
                        classNamePrefix="react-select"
                        name="subCategoryTire"
                        placeholder="Choose Category..."
                        options={tireSubCategories}
                        // value={subCategoryTire}
                        value={
                          this.state.subCategory
                            ? tireSubCategories.find(
                              (x) => x.value === this.state.subCategoryTire
                            )
                            : null
                        }
                        onChange={(e) => { this.setState({ subCategoryTire: e }) }}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "subCategoryTire",
                          this.state.subCategoryTire,
                          `required`
                        )}
                      </div>
                    </div>}
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>Part HS Code:</label>
                      <input
                        type="text"
                        name="partHSCode"
                        className="form-control"
                        onChange={this.handleInput}
                        value={this.state.partHSCode || ""}
                      />
                      {/* <div className="text-danger">
                        {this.validator.message(
                          "partHSCode",
                          this.state.partHSCode,
                          `required`
                        )}
                      </div> */}
                    </div>
                    <div className="col-md-6">
                      <label>Part SKU:</label>
                      <input
                        type="text"
                        name="partSKU"
                        disabled={true}
                        className="form-control"
                        onChange={this.handleInput}
                        value={this.state.partSKU || ""}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "partSKU",
                          this.state.partSKU,
                          `required`
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Type:</label>
                    <Select
                      className={`react-select`}
                      classNamePrefix="react-select"
                      name="type"
                      // isDisabled={true}
                      placeholder="Choose Type..."
                      options={formattedPartType ? formattedPartType : []}
                      onChange={this.handleChangeSelect}
                      value={
                        this.state.type
                          ? {
                            label: this.state.type,
                            value: this.state.type,
                          }
                          : null
                      }
                    />

                    {/* <select
                      name="type"
                      className="form-control"
                      onChange={this.handleChangeSelect}
                      value={this.state.type || ""}
                    >
                      <option value="" disabled>
                        Select Type
                      </option>
                      <option value="Genuine">Genuine</option>
                      <option value="Alternative">Alternative</option>
                    </select> */}

                    <div className="text-danger">
                      {this.validator.message(
                        "type",
                        this.state.type,
                        `required`
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Description:</label>
                    <ReactQuill
                      theme="snow"
                      value={this.state.description}
                      onChange={this.handleDescriptionChange}
                    />
                    <div className="text-danger">
                      {this.validator.message(
                        "description",
                        this.state.description,
                        `required`
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="atbd_content_module">
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>
                    <span className="la la-user"></span>Pricing & Availability
                    (required)
                  </h4>
                </div>
              </div>
              <div className="atbdb_content_module_contents">
                <form className="form-vertical">
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>Price:</label>
                      <InputDropdown
                        m_name="price"
                        o_name="currency"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="AED"
                        value={this.state.price}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "price",
                          this.state.price,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Quantity Available:</label>
                      <NumberFormat
                        value={this.state.quantity}
                        className={`form-control`}
                        thousandSeparator={true}
                        onValueChange={(values) =>
                          this.setState({ quantity: values.floatValue })
                        }
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "quantity",
                          this.state.quantity,
                          `required`
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="atbd_content_module">
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>
                    <span className="la la-user"></span>Fitting & Compatibility
                    (optional)
                  </h4>
                </div>
              </div>
              <div className="atbdb_content_module_contents">
                <form className="form-vertical">
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>Make:</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix="react-select"
                        placeholder="Choose..."
                        name="make"
                        onChange={this.handleChangeMake}
                        options={this.state.makeList}
                        isMulti={true}
                        value={
                          this.state.makeList
                            ? this.state?.makeList?.filter((x) =>
                              this.state?.makes?.includes(x._id)
                            )
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Model:</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix="react-select"
                        placeholder="Choose..."
                        name="model"
                        onChange={this.handleChangeModel}
                        options={this.state.modelList}
                        isMulti={true}
                        value={
                          this.state.modelList
                            ? this.state.modelList.filter((x) =>
                              this.state.models.includes(x._id)
                            )
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Fitting Position:</label>
                    <Select
                      className={`react-select`}
                      classNamePrefix="react-select"
                      placeholder="Choose position..."
                      name="fittingPosition"
                      onChange={this.handleChangeSelect}
                      options={
                        formattedFittingPosition ? formattedFittingPosition : []
                      }
                      value={
                        this.state.fittingPosition
                          ? {
                            label: this.state.fittingPosition,
                            value: this.state.fittingPosition,
                          }
                          : null
                      }
                    />

                    {/* <select
                      name="fittingPosition"
                      className="form-control"
                      value={this.state.fittingPosition || ""}
                      onChange={this.handleChangeSelect}
                    >
                      <option value="" disabled>
                        Select Fitting Position
                      </option>
                      <option value="Front">Front</option>
                      <option value="Rear">Rear</option>
                    </select> */}
                  </div>
                </form>
              </div>
            </div>
            <div className="atbd_content_module">
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>
                    <span className="la la-user"></span>Details (optional)
                  </h4>
                </div>
              </div>
              <div className="atbdb_content_module_contents">
                <form className="form-vertical">
                  {/* <div className="form-group">
                    <label>Country of Origin:</label>
                    <Select
                      className={`react-select`}
                      classNamePrefix="react-select"
                      placeholder="Choose Country of Origin..."
                      name="countryOrigin"
                      onChange={this.handleChangeSelect}
                      options={countrList.map((country) => {
                        return { label: country.label, value: country.label };
                      })}
                      value={
                        this.state.countryOrigin
                          ? {
                            label: this.state.countryOrigin,
                            value: this.state.countryOrigin,
                          }
                          : null
                      }
                    />
                  </div> */}
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>Height Dimension:</label>
                      <InputDropdown
                        m_name="heightDimension"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="MM"
                        value={this.state.heightDimension}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Width Dimension:</label>
                      <InputDropdown
                        m_name="widthDimension"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="MM"
                        value={this.state.widthDimension}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>Depth Dimension:</label>
                      <InputDropdown
                        m_name="depthDimension"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="MM"
                        value={this.state.depthDimension}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Weight:</label>
                      <InputDropdown
                        m_name="weight"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="Grams"
                        value={this.state.weight}
                      />
                    </div>
                    {/* start */}
                    {showTireSubCategories && <div className="col-md-6">
                      <label>RIM Size</label>
                      <InputDropdown
                        m_name="rimSize"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="RIM"
                        value={this.state.rimSize}
                      />
                    </div>}
                    <div className="col-md-6">
                      <label>Amp</label>
                      <InputDropdown
                        m_name="amp"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="amp/hr"
                        value={this.state.amp}
                      />
                    </div><div className="col-md-6">
                      <label>Voltage</label>
                      <InputDropdown
                        m_name="voltage"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="volt"
                        value={this.state.voltage}
                      />
                    </div>
                    {showTireSubCategories && <div className="col-md-6">
                      <label>UTQG</label>
                      <InputDropdown
                        m_name="utqg"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="utqg"
                        value={this.state.utqg}
                      />
                    </div>}
                    <div className="col-md-6">
                      <label>Make Year</label>
                      <InputDropdown
                        m_name="makeYear"
                        o_name="unit"
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption="YYYY"
                        value={this.state.makeYear}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Country of Origin</label>

                      <Select
                        className={`react-select`}
                        classNamePrefix="react-select"
                        placeholder="Choose Country of Origin..."
                        name="countryOrigin"
                        onChange={this.handleChangeSelect}
                        options={countrList.map((country) => {
                          return { label: country.label, value: country.label };
                        })}
                        value={
                          this.state.countryOrigin
                            ? {
                              label: this.state.countryOrigin,
                              value: this.state.countryOrigin,
                            }
                            : null
                        }
                      />
                    </div>
                    {/* end */}
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <label>Additional Notes:</label>
                      <ReactQuill
                        theme="snow"
                        value={this.state.additionalNotes}
                        onChange={this.handleAdditionalNotesChange}
                      />
                      {showTireSubCategories && <div className="col-md-12">
                        <label>Service Description</label>
                        <ReactQuill
                          theme="snow"
                          value={this.state.serviceDescription}
                          onChange={this.handleSeriveInfoChange}
                        />
                      </div>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="atbd_content_module">
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>
                    <span className="la la-shopping-cart"></span>Shipping
                    Options
                  </h4>
                </div>
              </div>
              <div className="atbdb_content_module_contents">
                <form className="form-vertical">
                  <div className="form-group row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Select business Model:</label>
                        <Select
                          className={`react-select`}
                          classNamePrefix="react-select"
                          placeholder="Choose business Model..."
                          name="business_model"
                          onChange={this.handleChangeSelect}
                          options={businessModal?.map((business_model) => {
                            return {
                              label: business_model,
                              value: business_model,
                            };
                          })}
                          value={
                            this.state.business_model
                              ? {
                                label: this.state.business_model,
                                value: this.state.business_model,
                              }
                              : null
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "business_model",
                            this.state.business_model,
                            `required`
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="form-group mt-5 text-center">
              <button
                disabled={this.state.submitLoading}
                className="btn btn-primary btn-lg listing_submit_btn"
                onClick={this.handleSubmit}
              >
                {this.state.submitLoading && (
                  <i className="las la-spinner la-spin mr-2"></i>
                )}
                Update your listing
              </button>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selling: state.selling,
    login: state.login,
    allCategories: state.category.allCategories,

  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(EditParts);
