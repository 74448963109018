import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export const SkeletonList = () => {
  function Looper({ children, times }) {
    const keys = [...Array(times).keys()];
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {keys.map((item) => (
          <div className={"listing-card-grid"} key={item}>
            {children}
          </div>
        ))}
      </div>
    );
  }
  return (
    <Looper times={12}>
      <div className="atbd_single_listing ">
        <article className="atbd_single_listing_wrapper">
          <Skeleton height={250} />
          <div className="atbd_listing_info">
            <h4 className="atbd_listing_title">
              <Skeleton width={180} height={15} />
            </h4>
            <div className="rating-group">
              <div className="d-flex">
                <Skeleton width={100} height={15} />
              </div>
              <span className="review-value text-muted">
                <Skeleton width={100} height={15} />
              </span>
            </div>
            <p>
              <Skeleton width={130} height={15} />
            </p>
            <div className="price-group">
              <div>
                <Skeleton width={80} height={15} />
              </div>
              <div>
                <Skeleton width={80} height={15} />
              </div>
            </div>
          </div>
        </article>
      </div>
    </Looper>
  );
};