import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { FormGroup, Label, Input } from "reactstrap";
import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import { PageBanner } from "../content/element/page-banner";
import ListingCardGrid from "../content/element/card/new-card-listing-grid-my";
import { Range } from "rc-slider";
import axios from "axios";
import {
  GetListingsCount,
  GetMyListings,
  GetMyListingsInPagination,
} from "../../Store/action/listingActions";
import Nodata from "../content/element/Nodata";
import Loader from "../content/element/Loader";
import Select from "react-select";
import PageTitle from "../content/element/PageTitle";
import Pending from "../content/element/UserStatus/Pending";
import Pagination from "@material-ui/lab/Pagination";
import { SLIDER_CHANGE_FACTOR } from "../../constants";
class MyListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _listing: [],
      loading: true,
      activeCategory: "",
      activeSubCategory: "",
      partSearchKeyword: "",
      business_model: "",
      stock_count: "",
      price: [0, 50000000],
      quantity: [0, 1000000],
      page: 1,
      size: 18,
      listing_count: 0,
      categories: [],
      subCategories: [],
      dynamicFilter: {
        minQuantity: 0,
        maxQuantity: 50000,
        minPrice: 0,
        maxPrice: 50000,
      },
    };
    this.sliderChange = this.sliderChange.bind(this);
    this.sliderAfterChange = this.sliderAfterChange.bind(this);
    this.sliderQntChange = this.sliderQntChange.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.initilize = this.initilize.bind(this)
  }

  initilize() {
    // axios.get(`/api/categories/all`).then((res) => {
    //   this.setState({ categories: res.data });
    //   this.setState({ isCatLoading: false });
    // });
    axios.get(`/api/listing/get-filter`).then((res) => {
      this.setState({ dynamicFilter: res.data });
      this.setState({
        quantity: [res.data.minQuantity, res.data.maxQuantity],
        price: [res.data.minPrice, res.data.maxPrice],
      });
    });

    let filter = {
      category: this.state.activeCategory,
      subCategory: this.state.activeSubCategory,
      partSearchKeyword: this.state.partSearchKeyword,
      business_model: this.state.business_model.value,
      stock_count: this.state.stock_count,
      max_price: this.state.price[1],
      min_price: this.state.price[0],
      max_stock_count: this.state.quantity[1],
      min_stock_count: this.state.quantity[0],
    };
    let isInitalRender = true;
    this.props.getListingInPagination(this.state.page, this.state.size, filter);

    // this.props.getListingsCount(filter, isInitalRender);
  }



  sliderChange(e) {
    this.setState({ price: e, page: 1 });
  }
  sliderQntChange(e) {
    this.setState({ quantity: e, page: 1 });
  }
  sliderAfterChange() {
    let filter = {
      category: this.state.activeCategory.value,
      subCategory: this.state.activeSubCategory.value,
      partSearchKeyword: this.state.partSearchKeyword,
      business_model: this.state.business_model.value,
      stock_count: this.state.stock_count,
      max_price: this.state.price[1],
      min_price: this.state.price[0],
      max_stock_count: this.state.quantity[1],
      min_stock_count: this.state.quantity[0],
    };

    this.props.getListingInPagination(this.state.page, this.state.size, filter);
    // this.props.getListingsCount(filter);
  }



  resetFilter() {
    this.setState(
      {
        _listing: [],
        loading: true,
        activeCategory: "",
        activeSubCategory: "",
        partSearchKeyword: "",
        business_model: "",
        stock_count: "",
        price: [0, 50000000],
        quantity: [0, 1000000],
        page: 1,
        size: 18,
        listing_count: 0,
        categories: [],
        subCategories: [],
        dynamicFilter: {
          minQuantity: 0,
          maxQuantity: 50000,
          minPrice: 0,
          maxPrice: 50000,
        },
      },
      () => {
        this.initilize()
      }
    );
  }

  componentDidMount() {
    this.initilize()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list.listing !== this.props.list.listing) {
      const { isLoading, listing, listing_count_pagination } = this.props.list;
      this.setState({
        _listing: listing,
        loading: isLoading,
      });
      this.setState({ dynamicFilter: prevState.dynamicFilter });
    }

    if (prevState.activeCategory !== this.state.activeCategory) {
      let result = this.props.allCategories.find(
        (cat) => cat._id === this.state.activeCategory.value
      );

      // result &&
      //   axios.get(`/api/subcategories/${result?._id}`).then((res) => {
      // console.log("res", this.state.activeCategory.value)
      this.setState({ subCategories: result.subCategories });
      // });
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
  handleChangeSubCategory = (option) => {
    this.setState({ activeSubCategory: option, page: 1 }, () => {
      let filter = {
        category: this.state.activeCategory.value,
        subCategory: this.state.activeSubCategory.value,
        partSearchKeyword: this.state.partSearchKeyword,
        business_model: this.state.business_model.value,
        stock_count: this.state.stock_count,
        max_price: this.state.price[1],
        min_price: this.state.price[0],
        max_stock_count: this.state.quantity[1],
        min_stock_count: this.state.quantity[0],
      };
      this.props.getListingInPagination(
        this.state.page,
        this.state.size,
        filter
      );
      // this.props.getListingsCount(filter);
    });
  };
  handleChangeBusinessModel = (option) => {
    this.setState({ business_model: option, page: 1 }, () => {
      let filter = {
        category: this.state.activeCategory.value,
        subCategory: this.state.activeSubCategory.value,
        partSearchKeyword: this.state.partSearchKeyword,
        business_model: this.state.business_model.value,
        stock_count: this.state.stock_count,
        max_price: this.state.price[1],
        min_price: this.state.price[0],
        max_stock_count: this.state.quantity[1],
        min_stock_count: this.state.quantity[0],
      };
      this.props.getListingInPagination(
        this.state.page,
        this.state.size,
        filter
      );
      // this.props.getListingsCount(filter);
    });
  };
  handleChangePartSkuOrName = (e) => {
    this.setState({ partSearchKeyword: e.target.value, page: 1 }, () => {
      let filter = {
        category: this.state.activeCategory.value,
        subCategory: this.state.activeSubCategory.value,
        partSearchKeyword: this.state.partSearchKeyword,
        business_model: this.state.business_model.value,
        stock_count: this.state.stock_count,
        max_price: this.state.price[1],
        min_price: this.state.price[0],
        max_stock_count: this.state.quantity[1],
        min_stock_count: this.state.quantity[0],
      };
      this.props.getListingInPagination(
        this.state.page,
        this.state.size,
        filter
      );
      // this.props.getListingsCount(filter);
    });
  };
  handleChangeStockCount = (e) => {
    this.setState({ stock_count: e.target.value, page: 1 }, () => {
      let filter = {
        category: this.state.activeCategory.value,
        subCategory: this.state.activeSubCategory.value,
        partSearchKeyword: this.state.partSearchKeyword,
        business_model: this.state.business_model.value,
        stock_count: this.state.stock_count,
        max_price: this.state.price[1],
        min_price: this.state.price[0],
        max_stock_count: this.state.quantity[1],
        min_stock_count: this.state.quantity[0],
      };
      this.props.getListingInPagination(
        this.state.page,
        this.state.size,
        filter
      );
      // this.props.getListingsCount(filter);
    });
  };

  handleChangeCategory = (option) => {
    this.setState(
      {
        activeCategory: option,
        activeSubCategory: "",
        page: 1,
      },
      () => {
        let filter = {
          category: this.state.activeCategory.value,
          subCategory: this.state.activeSubCategory.value,
          partSearchKeyword: this.state.partSearchKeyword,
          business_model: this.state.business_model.value,
          stock_count: this.state.stock_count,
          max_price: this.state.price[1],
          min_price: this.state.price[0],
          max_stock_count: this.state.quantity[1],
          min_stock_count: this.state.quantity[0],
        };
        this.props.getListingInPagination(
          this.state.page,
          this.state.size,
          filter
        );
        // this.props.getListingsCount(filter);
      }
    );
  };

  handleChangePagination = (event, value) => {
    let filter = {
      category: this.state.activeCategory.value,
      subCategory: this.state.activeSubCategory.value,
      partSearchKeyword: this.state.partSearchKeyword,
      business_model: this.state.business_model.value,
      stock_count: this.state.stock_count,
      max_price: this.state.price[1],
      min_price: this.state.price[0],
      max_stock_count: this.state.quantity[1],
      min_stock_count: this.state.quantity[0],
    };
    this.setState(
      {
        page: value,
      },
      () => {
        this.props.getListingInPagination(value, this.state.size, filter);
      }
    );
  };

  render() {

    console.log(this.state.activeCategory, "result")

    const sortedArrayCategories = this.state.categories.sort((a, b) =>
      a?.name.localeCompare(b.name)
    );
    const sortedArraySubCategories =
      this.state.activeCategory &&
      this.state.subCategories &&
      this.state.subCategories.sort((a, b) => a?.name.localeCompare(b.name));

    const { isLoading, listing, isInitalRender } = this.props.list;
    const { t } = this.props;

    // if (isLoading) return null;
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser && currentUser?.status === "Pending") return <Pending />;

    // if (isLoading) return <Loader header="true" />;

    // if (this.props.list.listing_count_pagination === 0 && !isLoading) {
    //   return (
    //     <Nodata
    //       text="You have not made any listing yet. They will show here as soon as you make your item for sale"
    //       header={true}
    //     />
    //   );
    // }
    return (
      <Fragment>


        {/* <Header className="menu--light" /> */}
        <PageBanner title={t("my_listings_title")} />
        <section className="listing-cards mt-2 mb-5">
          <div className="container">
            <PageTitle title="My Listing" />
            <div className="row mt-2">
              <div className="col-lg-12">
                <div className=" order-lg-0 order-0 mt-5 pb-5 mt-lg-0">
                  <div className="listings-sidebar">
                    <div className="search-area default-ad-search pb-3">
                      <div
                        className="row search-area-header s mt-3"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="filterBY-cat mt-3 col-md-4">
                          <FormGroup>
                            <Label for="location">Category</Label>
                            <Select
                              id="activeCategory"
                              className={`react-select`}
                              classNamePrefix="react-select"
                              placeholder="Choose Category..."
                              name="activeCategory"
                              value={this.state.activeCategory}
                              onChange={(selectedOption) =>
                                this.handleChangeCategory(selectedOption)
                              }
                              options={
                                this.props.allCategories.length > 0 &&
                                this.props.allCategories.map((item) => {
                                  return {
                                    label: item.name,
                                    value: item._id,
                                  };
                                })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="filterBY-subcat mt-3 col-md-4">
                          <FormGroup>
                            <Label for="location">Sub Category</Label>
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              placeholder="Choose SubCategory..."
                              onChange={(option) =>
                                this.handleChangeSubCategory(option)
                              }
                              isDisabled={!this.state.activeCategory}
                              value={this.state.activeSubCategory}
                              options={
                                sortedArraySubCategories &&
                                sortedArraySubCategories.map((item) => {
                                  return {
                                    label: item.name,
                                    value: item._id,
                                  };
                                })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="filterBY- mt-3 col-md-4">
                          <FormGroup>
                            <Label for="location">Part Name/Sku/Number</Label>
                            <Input
                              type="search"
                              name="partSukOrName"
                              id="part-sku-name-search"
                              placeholder="Enter Part SKU/Number"
                              value={this.state.partSearchKeyword}
                              onChange={(e) =>
                                this.handleChangePartSkuOrName(e)
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="filterBY- mt-3 col-md-4">
                          {/* {console.log(this.state.quantity)} */}
                          <Label for="location">Quantity Filter</Label>

                          <div className="range ml-2 mt-2">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span onClick={
                                () => {
                                  let initalVal = this.state.quantity[1]
                                  let afterDecreament = initalVal - SLIDER_CHANGE_FACTOR

                                  if (afterDecreament > 0) {
                                    let quantity = [
                                      this.state.quantity[0],
                                      afterDecreament
                                    ]
                                    this.setState({ quantity, page: 1 }, () => {
                                      this.sliderAfterChange()
                                    })
                                  }
                                }
                              }
                                className="las la-minus-circle"
                                style={{ marginRight: '15px', fontSize: '18px', cursor: 'pointer' }}></span>
                              <Range
                                style={{ width: 150 }}
                                min={
                                  this.state.dynamicFilter.minQuantity &&
                                  this.state.dynamicFilter.minQuantity
                                }
                                max={
                                  this.state.dynamicFilter.maxQuantity &&
                                  this.state.dynamicFilter.maxQuantity
                                }
                                value={this.state.quantity}
                                marks
                                onChange={this.sliderQntChange}
                                onAfterChange={this.sliderAfterChange}
                              />
                              <span
                                onClick={
                                  () => {
                                    let initalVal = this.state.quantity[0]
                                    let afterIncreament = initalVal + SLIDER_CHANGE_FACTOR
                                    if (afterIncreament < this.state.dynamicFilter.maxQuantity) {
                                      let quantity = [
                                        afterIncreament,
                                        this.state.quantity[1]
                                      ]
                                      this.setState({ quantity, page: 1 }, () => {
                                        this.sliderAfterChange()
                                      })
                                    }
                                  }
                                }
                                className="las la-plus-circle" style={{ marginLeft: '15px', fontSize: '18px', cursor: 'pointer' }}></span>
                            </div>
                            <span>{`Min:${this.state.quantity[0]} - Max:${this.state.quantity[1]}`}</span>
                          </div>
                        </div>
                        <div className="filterBY-business-model mt-3 col-md-4">
                          <FormGroup>
                            <Label for="location">Business Model</Label>
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              placeholder="Choose Business model..."
                              onChange={(option) =>
                                this.handleChangeBusinessModel(option)
                              }
                              value={this.state.business_model}
                              options={[
                                "Pure Play",
                                "Consolidation",
                                "Fulfilment",
                              ].map((item) => {
                                return {
                                  label: item,
                                  value: item,
                                };
                              })}
                            />
                          </FormGroup>
                        </div>

                        <div
                          className="filterBy-price-div mt-3 col-md-4 d-flex"
                          style={{ height: "100%", width: "auto" }}
                        >
                          <div>
                            <Label for="location">Price Filter</Label>
                            <div className="range ml-2 mt-2">
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span onClick={
                                  () => {
                                    let initalVal = this.state.price[1]
                                    let afterDecreament = initalVal - SLIDER_CHANGE_FACTOR

                                    if (afterDecreament > 0) {
                                      let price = [
                                        this.state.price[0],
                                        afterDecreament
                                      ]
                                      this.setState({ price, page: 1 }, () => {
                                        this.sliderAfterChange()
                                      })
                                    }
                                  }
                                }
                                  className="las la-minus-circle"
                                  style={{ marginRight: '15px', fontSize: '18px', cursor: 'pointer' }}></span>
                                <Range
                                  style={{ width: 150 }}
                                  min={
                                    this.state.dynamicFilter.minPrice &&
                                    this.state.dynamicFilter.minPrice
                                  }
                                  max={
                                    this.state.dynamicFilter.maxPrice &&
                                    this.state.dynamicFilter.maxPrice
                                  }
                                  value={this.state.price}
                                  marks
                                  onChange={this.sliderChange}
                                  onAfterChange={this.sliderAfterChange}
                                /><span
                                  onClick={
                                    () => {
                                      let initalVal = this.state.price[0]
                                      let afterIncreament = initalVal + SLIDER_CHANGE_FACTOR
                                      if (afterIncreament < this.state.dynamicFilter.maxPrice) {
                                        let price = [
                                          afterIncreament,
                                          this.state.price[1]
                                        ]
                                        this.setState({ price, page: 1 }, () => {
                                          this.sliderAfterChange()
                                        })
                                      }
                                    }
                                  }
                                  className="las la-plus-circle" style={{ marginLeft: '15px', fontSize: '18px', cursor: 'pointer' }}></span>
                              </div>
                              <span>{`Min:${this.state.price[0]} - Max:${this.state.price[1]}`}</span>
                            </div>
                          </div>
                          <button
                            className="mt-3 btn-xs btn btn-primary m-4"
                            onClick={(e) => this.resetFilter(e)}
                            style={{
                              fontSize: 10,
                              height: 30,
                              width: "auto",
                              marginLeft: 20,
                            }}
                          >
                            RESET FILTERS
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              isLoading ?
                <div className="row">
                  <Loader />
                </div> :
                this.props.list.listing_count_pagination === 0 ?
                  <Nodata
                    text="You have not made any listing yet. They will show here as soon as you make your item for sale"
                  /> :
                  <div className="row">
                    <Fragment>
                      <ListingCardGrid size={6} list={this.state._listing} />
                    </Fragment>
                  </div>
            }
            <div className="row" style={{ marginLeft: 2 }}>
              {/* pagination */}

              {/* <Pagination
                count={Math.ceil(
                  this.props.list.listing_count_pagination / this.state.size
                )}
                color="primary"
                defaultPage={this.state.page}
                onChange={this.handleChangePagination}
              /> */}
              {/* {this.props.list.loading_count ? (
                "Loading..."
              ) : ( */}
              <Pagination
                count={Math.ceil(
                  this.props.list.listing_count_pagination / this.state.size
                )}
                color="primary"
                page={this.state.page}
                onChange={this.handleChangePagination}
              />
              {/* )} */}
            </div>
          </div>
        </section>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    allCategories: state.category.allCategories
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    getMyListing: () => dispatch(GetMyListings()),
    getListingsCount: (filter, isInitalRender = false) =>
      dispatch(GetListingsCount(filter, isInitalRender)),
    getListingInPagination: (page, size, filter) =>
      dispatch(GetMyListingsInPagination(page, size, filter)),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(MyListing);
