import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import Header from "../../layout/header";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import { PageBanner } from "../../content/element/page-banner";
import SearchLocationInput from "../../common/SearchLocationInput";
import SimpleReactValidator from "simple-react-validator";
import { LogInAc } from "../../../Store/action/loginActions";
import { popUp, validateFile } from "../../../utils";
import Select from "react-select";

import { garageTypes, emiratesOptions } from "../../../constants";
import ReactGA from 'react-ga4';


class RegisterBuyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emirate: null,
      garage_type: null,
      garage_name: null,
      garage_address: null,
      garage_area: null,
      opening_hours: null,
      closing_hours: null,
      trade_license_no: null,
      trade_license_upload: null,
      vat_registration_no: null,
      vat_certification_upload: null,
      owner_manager_name: null,
      owner_manager_emirates_id: null,
      emirates_id: null,
      goods_receiver_name: null,
      submitLoading: false,
      stateData: [],
      listOfAllTradeNumberInSys: [],
      listOfAllVatNumberInSys: [],
    };
    this.validator = new SimpleReactValidator({
      validators: {
        tradeUniqueCheck: {
          // name the rule
          message: "Already registered with this Trade Registration number.",
          rule: (val, params, validator) => {
            if (!this.state.listOfAllTradeNumberInSys.includes(val)) {
              return true;
            }
            return false;
          },
          required: true,
        },
        vatUniqueCheck: {
          // name the rule
          message: "Already registered with this Vat Registration number.",
          rule: (val, params, validator) => {
            if (!this.state.listOfAllVatNumberInSys.includes(val)) {
              return true;
            }
            return true
            // return false;
          },
          required: false,
        },
        tradeOrVatRegexCheck: {
          // name the rule
          message:
            "The :attribute registration number should be between 4 to 15 digits only.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[a-zA-Z0-9]{4,15}$/);
          }
        },
      },
    });
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setSelectedFile = this.setSelectedFile.bind(this);
    this.setAddress = this.setAddress.bind(this);
  }

  componentDidMount() {
    axios
      .get("/api/products/get-regions")
      .then((res) => {
        this.setState({ stateData: res?.data?.regions });
      })
      .catch((e) =>
        popUp("There was a problem with server. Please try again.")
      );
    axios
      .get(`/api/users/get-all-trade-no?type=BUYER`)
      .then((res) => {
        this.setState({ listOfAllTradeNumberInSys: res?.data });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/api/users/get-all-vat-no?type=BUYER`)
      .then((res) => {
        this.setState({ listOfAllVatNumberInSys: res?.data });
      })
      .catch((err) => {
        console.log(err);
      });
    if (!this.props.signup.accountType) {
      this.props.history.push("/register");
      return;
    }
    if (!this.props.signup.email) {
      this.props.history.push("/register/verify-email");
      return;
    }
    if (!this.props.signup.phone) {
      this.props.history.push("/register/verify-phone");
      return;
    }
    if (!this.props.signup.password) {
      this.props.history.push("/register/password");
      return;
    }
  }
  setAddress(address) {
    this.setState({ garage_address: address });
  }

  handleSelect(selectedOption, fieldAttr) {
    this.setState({ [fieldAttr.name]: selectedOption.value });
  }

  handleInput(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
  setSelectedFile(file, name) {
    if (file) {
      let data = new FormData();
      data.append(`listing`, file);
      axios
        .post("/api/imageupload", data)
        .then((res) => {
          this.setState({ [name]: res.data });
        })
        .catch((error) => {
          this.setState({ [name]: "" });
          console.error(error);
        });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ submitLoading: true });
      const fulladdress =
        this.state.garage_area + "," + this.state.garage_address;
      const details = {
        emirate: this.state.emirate,
        garage_type: this.state.garage_type,
        garage_name: this.state.garage_name,
        garage_address: fulladdress,
        opening_hours: this.state.opening_hours,
        closing_hours: this.state.closing_hours,
        trade_license_no: this.state.trade_license_no,
        vat_registration_no: this.state.vat_registration_no,
        owner_manager_name: this.state.owner_manager_name,
        goods_receiver_name: this.state.goods_receiver_name,
        trade_license_upload: this.state.trade_license_upload,
        vat_certification_upload: this.state.vat_certification_upload,
        owner_manager_emirates_id: this.state.owner_manager_emirates_id,
        emirates_id: this.state.emirates_id,
      };

      let obj = {
        password: this.props.signup.password,
        email: this.props.signup.email,
        phone: this.props.signup.phone,
        role: "BUYER",
        details: details,
      };
      axios
        .post(`/api/users/signup`, obj)
        .then((res) => {
          popUp("You have successfully registered as buyer.");
          this.setState({ submitLoading: false });
          ReactGA.event({
            category: 'User',
            action: 'Buyer Registered'
          });
          window.location.href = "/thankyou-register";
        })
        .catch((err) => {
          popUp("There was a problem with server. Please try again.");
          this.setState({ submitLoading: false });
          // this.setState({errMsg: err.response.data.message});
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Fragment>

        {/* <PageBanner title="CREATE A BUYER ACCOUNT" /> */}

        <section className="add-listing-wrapper border-bottom section-bg section-padding-strict">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-user"></span>Mandatory
                        Information
                      </h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents mb-5">
                    <form action="/">
                      <div className="form-group">
                        <label htmlFor="emirate" className="form-label">
                          Emirate <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          className={`react-select`}
                          classNamePrefix="react-select"
                          name="emirate"
                          id="emirate"
                          placeholder="Choose Emirate..."
                          options={emiratesOptions?.map((emirate) => {
                            return {
                              label: emirate,
                              value: emirate,
                            };
                          })}
                          onChange={this.handleSelect}
                        />

                        {/* <div className="select-basic">
                          <select
                            className="form-control"
                            name="emirate"
                            defaultValue={""}
                            id="emirate"
                            onChange={this.handleSelect}
                          >
                            <option value="" disabled>
                              Select Emirate
                            </option>
                            <option value="Abu Dhabi">Abu Dhabi</option>
                            <option value="Ajman">Ajman</option>
                            <option value="Dubai">Dubai</option>
                            <option value="Fujairah">Fujairah</option>
                            <option value="Ras Al Khaimah">
                              Ras Al Khaimah
                            </option>
                            <option value="Sharjah">Sharjah</option>
                            <option value="Umm Al Quwain">Umm Al Quwain</option>
                          </select>
                        </div> */}
                        <div className="text-danger">
                          {this.validator.message(
                            "emirate",
                            this.state.emirate,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="garage-address" className="form-label">
                          Garage Address <span style={{ color: 'red' }}>*</span>
                        </label>
                        <div className="row">
                          <div className="col-md-4">
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              name="garage_area"
                              id="garage_area"
                              placeholder="Choose Garage Area"
                              options={this.state?.stateData?.map((type) => {
                                return {
                                  label: type.name,
                                  value: type.name,
                                };
                              })}
                              onChange={this.handleSelect}
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "garage_area",
                                this.state.garage_area,
                                `required`
                              )}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <SearchLocationInput
                              setAddress={this.setAddress}
                              databaseaddress={""}
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "garage_address",
                                this.state.garage_address,
                                `required`
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="garage-type" className="form-label">
                          Garage Type <span style={{ color: 'red' }}>*</span>
                        </label>

                        <Select
                          className={`react-select`}
                          classNamePrefix="react-select"
                          name="garage_type"
                          id="garage-type"
                          placeholder="Choose Garage Type..."
                          options={garageTypes?.map((type) => {
                            return {
                              label: type,
                              value: type,
                            };
                          })}
                          onChange={this.handleSelect}
                        />

                        {/* <div className="select-basic">
                          <select
                            className="form-control"
                            name="garage_type"
                            defaultValue={""}
                            id="garage-type"
                            onChange={this.handleSelect}
                          >
                            <option value="" disabled>
                              Select Garage Type
                            </option>
                            <option value="Authorized Garages">
                              Authorized Garages
                            </option>
                            <option value="Gained/Third-party Garages">
                              Gained/Third-party Garages
                            </option>
                            <option value="Independent Mom & Pop Garages">
                              Independent Mom & Pop Garages
                            </option>
                          </select>
                        </div> */}
                        <div className="text-danger">
                          {this.validator.message(
                            "garage_type",
                            this.state.garage_type,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="garage-name" className="form-label">
                          Garage Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="garage-name"
                          placeholder="Enter Garage Name"
                          value={this.state.garage_name || ""}
                          name="garage_name"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "garage_name",
                            this.state.garage_name,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="opening-hours" className="form-label">
                          Opening Hours <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="time"
                          id="opening-hours"
                          className="form-control directory_field"
                          value={this.state.opening_hours || ""}
                          name="opening_hours"
                          onChange={this.handleInput}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "opening_hours",
                            this.state.opening_hours,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="closing-hours" className="form-label">
                          Closing Hours <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="time"
                          id="closing-hours"
                          className="form-control directory_field"
                          value={this.state.closing_hours || ""}
                          name="closing_hours"
                          onChange={this.handleInput}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "closing_hours",
                            this.state.closing_hours,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="trade-license-no"
                          className="form-label"
                        >
                          Trade License No <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="trade-license-no"
                          placeholder="Enter Trade Licnse No"
                          value={this.state.trade_license_no || ""}
                          name="trade_license_no"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "trade",
                            this.state.trade_license_no,
                            `required|tradeUniqueCheck`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="trade-license-upload"
                          className="form-label"
                        >
                          Trade License Upload <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="trade-license-upload"
                          onChange={(e) => {
                            let isLt5M = validateFile(e.target.files[0])
                            if (!isLt5M) {
                              e.target.value = ''
                              return
                            }
                            this.setSelectedFile(
                              e.target.files[0],
                              "trade_license_upload"
                            )
                          }
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "trade_license_upload",
                            this.state.trade_license_upload,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="owner-manager-name"
                          className="form-label"
                        >
                          Owner/Manager Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="owner-manager-name"
                          placeholder="Enter Name"
                          value={this.state.owner_manager_name || ""}
                          name="owner_manager_name"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "owner_manager_name",
                            this.state.owner_manager_name,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="goods-receiver-name"
                          className="form-label"
                        >
                          Name of Goods Receiver <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="goods-receiver-name"
                          placeholder="Enter Name"
                          value={this.state.goods_receiver_name || ""}
                          name="goods_receiver_name"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "goods_receiver_name",
                            this.state.goods_receiver_name,
                            `required`
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  {/*<!-- ends: .atbdb_content_module_contents -->*/}
                </div>
                {/*<!-- ends: .atbd_content_module -->*/}
              </div>
              {/*<!-- ends: .col-lg-10 -->*/}

              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-user"></span>Optional Information
                      </h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents mb-5">
                    <div className="form-group">
                      <label htmlFor="vat-reg-no" className="form-label">
                        VAT Registration No <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="vat-reg-no"
                        placeholder="Enter Registration No"
                        value={this.state.vat_registration_no || ""}
                        name="vat_registration_no"
                        onChange={this.handleInput}
                        required
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "vat",
                          this.state.vat_registration_no,
                          `tradeOrVatRegexCheck|vatUniqueCheck`
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="vat-cretificate-upload"
                        className="form-label"
                      >
                        VAT Certificate Upload
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="vat-cretificate-upload"
                        onChange={(e) => {
                          let isLt5M = validateFile(e.target.files[0])
                          if (!isLt5M) {
                            e.target.value = ''
                            return
                          }
                          this.setSelectedFile(
                            e.target.files[0],
                            "vat_certification_upload"
                          )
                        }
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="owner-manager-emirates-id"
                        className="form-label"
                      >
                        Owner/Manager Emirates ID / Passport No
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="owner-manager-emirates-id"
                        onChange={(e) => {
                          let isLt5M = validateFile(e.target.files[0])
                          if (!isLt5M) {
                            e.target.value = ''
                            return
                          }
                          this.setSelectedFile(
                            e.target.files[0],
                            "owner_manager_emirates_id"
                          )
                        }
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="emirates-id-upload"
                        className="form-label"
                      >
                        Emirates ID Upload
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="emirates-id-upload"
                        onChange={(e) => {
                          let isLt5M = validateFile(e.target.files[0])
                          if (!isLt5M) {
                            e.target.value = ''
                            return
                          }
                          this.setSelectedFile(e.target.files[0], "emirates_id")
                        }
                        }
                      />
                    </div>

                    <div className="atbd_term_and_condition_area custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="listing_t"
                        value="1"
                        id="listing_t"
                      />
                      <label
                        htmlFor="listing_t"
                        className="not_empty custom-control-label"
                      >
                        By Clicking, you are accepting{" "}
                        <NavLink to="/terms">
                          the terms and conditions and privacy policy
                        </NavLink>
                      </label>
                    </div>
                    <div className="btn_wrap list_submit m-top-25">
                      <button
                        type="submit"
                        disabled={
                          this.state.submitLoading === false ? false : true
                        }
                        className="btn btn-primary btn-lg listing_submit_btn"
                        onClick={this.handleSubmit}
                      >
                        {this.state.submitLoading && (
                          <i className="las la-spinner la-spin mr-2"></i>
                        )}
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- ends: .add-listing-wrapper -->*/}

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    login: (data) => dispatch(LogInAc(data)),
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(RegisterBuyer);
