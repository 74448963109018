import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import SearchImageSKU from "./modal/search-image-sku";
import { withRouter } from "react-router";

const noAction = (e) => e.preventDefault();

class AdvSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      showModal:false,
      error:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    noAction(e);
    this.setState({ error:false});
    const {value} = e.target || {}
    
    if (value.match(/^[\w\s\.\/\,\"\'\-]+$/) || value === '' ) {
      this.setState({ [e.target.name]: value });  
    }
  }

  uploadFile = (e) => {
    this.setState({...this.state, showModal:true})
  }
  onClose = () => {
    this.setState({...this.state, showModal:false})
  }
  handleSubmit=(e)=>{

    e.preventDefault()
 
    const {search} = this.state
    if(!search){   this.setState({ error:true}); console.log("error"); return}
    // const {history} = this.props
   this.props.history.push(`/all-listings?search=${search?.trim()}`)
   this.props.history.go()
  }

  render() {
    const { t } = this.props;
    const { search, showModal,error } = this.state;
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>

        <SearchImageSKU showModal={showModal} onClose={this.onClose}/>
        <div className="directory_content_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12  d-lg-block">
                <div className="search-spare-part">
                  <div className={error?"search_form search_form search_img search_form_error search_form_error search_img":"search_form search_form search_img"}>
                 
                  <div className="atbd_seach_button_wrapper_start">
                    <button
                      type="button"
                      onClick={this.uploadFile}
                      className="btn btn-md btn-primary btn_search d-flex p-2"
                      style={{
                        backgroundColor: " #F39200",
                        borderColor: "#F39200",
                      }}
                    >
                      <i className="las la-camera" />
                    </button>
                  </div>
                    <input
                      type="text"
                      value={search || ""}
                      name="search"
                      onChange={(e)=>this.handleChange(e)}
                      placeholder={"Find spare parts.."}
                      className={error ? "error" : ""}
                  
                    />
                    <div className="atbd_seach_button_wrapper">
                      <button
                        onClick={(e)=>this.handleSubmit(e)}
                        className="btn btn-md btn-primary btn_search d-flex p-2"
                        style={{
                          backgroundColor: " #F39200",
                          borderColor: "#F39200",
                        }}
                      >
                        <i className="las la-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col only-mobile">
                <div className="mobile-search">
                <div className={error?"search_form search_form search_img search_form_error search_form_error search_img":"search_form search_form search_img"}>
                
                    <div className="input-search-mobile">
                      <input
                        className="mobile-search-by-part-input"
                        type="text"
                        value={search || ""}
                        name="search"
                        onChange={this.handleChange}
                        placeholder={t("Find spare parts..")}
                      />
                      <button  onClick={()=>this.handleSubmit()}>
                        
                        <SearchIcon style={{ fontSize: 24, marginTop: 0 }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        </form>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
  
)(withRouter(AdvSearch));
