import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import getPrice from "../../getPrice";
import { cbm, ProductImage } from "../../utils";

export default function OrderListCard({
	data,
	getShippingPrice,
	handleDeliveryOption,
	price,
	shippingAddress,
}) {
	let after = moment().tz("Asia/Dubai").format("HH:mm:ss");
	let checkExpressCutoff = moment(after, "hh:mm:ss").isBefore(
		moment("18:00:00", "hh:mm:ss")
	);
	let checkSameDayCutOff = moment(after, "hh:mm:ss").isBefore(
		moment("20:00:00", "hh:mm:ss")
	);
	function getStatusDate() {
		switch (data.delivery_option) {
			case "express":
				return "Today";
			// return moment().tz("Asia/Dubai").format("DD MMMM YYYY");
			case "same_day":
				return moment().tz("Asia/Dubai").format("DD MMMM YYYY");
			case "next_day":
				return "1-3 Days Estimated Delivery";
			// if (checkSameDayCutOff) {
			//   return moment()
			//     .tz("Asia/Dubai")
			//     .add("1", "day")
			//     .format("DD MMMM YYYY");
			// } else {
			//   return moment()
			//     .tz("Asia/Dubai")
			//     .add("2", "day")
			//     .format("DD MMMM YYYY");
			// }
		}
	}

	const handleShipping = (selectedOption, id) => {
		handleDeliveryOption(selectedOption.value, id);
		getShippingPrice(getPrice(selectedOption.value), selectedOption.value, id);
	};

	let shippingOptions = [];
	if (
		checkExpressCutoff &&
		data.business_model === "Fulfilment" &&
		(shippingAddress?.state === "Dubai" || shippingAddress?.state === "Sharjah" || shippingAddress?.state === "Abu Dhabi")
	) {
		shippingOptions = [
			{ label: "Express", value: "express" },
			// { label: "Same Day", value: "same_day" },
			{ label: "Standard", value: "next_day" },
		];
	} else if (checkSameDayCutOff) {
		shippingOptions = [
			// { label: "Express", value: "express" },

			{ label: "Standard", value: "next_day" },
		];
	} else {
		shippingOptions = [{ label: "Standard", value: "next_day" }];
	}
	let url = data?.pic || data.imageArray?.[0];
	return (
		<tr>
			<td width="75">
				<ProductImage
					avatar={url}
					className="item-image"
					alt="Image"
					style={{ height: "50px", width: "50px", objectFit: "contain" }}
				/>
			</td>
			<td style={{ width: "220px" }}>
				{data.partName} <strong>({data.partSKU})</strong>
				<br />
				Qty - {price}
			</td>

			<td style={{ verticalAlign: "inherit" }}>
				<Select
					className={`react-select`}
					classNamePrefix="react-select"
					options={shippingOptions}
					onChange={(selectedOption) => {
						handleShipping(selectedOption, data._id);
					}}
					defaultValue={
						data?.delivery_option
							? shippingOptions.find(
									(opt) => opt.value === data.delivery_option
							  )
							: { label: "Standard", value: "next_day" }
					}
				/>
			</td>

			<td style={{ verticalAlign: "inherit" }}>{getStatusDate()}</td>

		</tr>
	);
}
