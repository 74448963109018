
import React from 'react'
import { NavLink } from 'react-router-dom';
import Address from "../../content/element/Address";

export default function Step2({ addresses, setSelectedAddress, selectedAddress, setIsOpenEditModal, setEditedAddressId, handleAddFormModal, handleEditFormModal, getAddress }) {
    return (
        <div className="shipping-step">
            <div
                style={{
                    display: "flex",
                    backgroundColor: "transparent",
                    flexWrap: "wrap",
                    padding: "10px 0",
                }}
            >
                {addresses &&
                    addresses.map((address, i) => {
                        return (
                            <Address
                                data={address}
                                getShippingAddress={(add) =>
                                    setSelectedAddress(add)
                                }
                                key={i}
                                selectedAddress={selectedAddress}
                                setIsOpenEditModal={setIsOpenEditModal}
                                setEditedAddressId={setEditedAddressId}

                            />
                        );
                    })}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "300px",
                }}
                className="checkout-location"
            >
                <div style={{ width: "60%", padding: "20px 0" }}>
                    <h3>Delivery Details</h3>
                    <div style={{ marginTop: "10px" }}>
                        <b>{selectedAddress?.address}</b>
                    </div>
                    <p style={{ marginTop: "10px" }}>
                        {selectedAddress?.country} {selectedAddress?.state}{" "}
                        {selectedAddress?.suburb}{" "}
                        {selectedAddress?.postcode}
                    </p>
                    <NavLink
                        to="#"
                        className="btn btn-primary"
                        onClick={() => { handleAddFormModal(true); console.log("falls") }}
                    >
                        Add Address
                    </NavLink>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                width="500"
                                height="300"
                                id="gmap_canvas"
                                src={`https://maps.google.com/maps?q=${selectedAddress?.location}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
