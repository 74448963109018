import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";

import SellerListings from "./page/seller-listings";
// import ListingDetails from "./page/listing-details";
import ListingDetails from "./page/newlisting-details";

import ListingDetailsView from "./page/listing-details-view";
import TrackingOrder from "./page/Trackingorder";

// import Thankyou from "./page/thank-you";
import Thankyou from "./page/Thankyou/index";
import ThankyouRegister from "./page/ThankyouRegister/index";
import Credits from "./page/Credits/index";
import MyListing from "./page/my-listing";
import MyReview from "./page/my-review";
// import MyCallbacks from "./page/my-callbacks";
import SearchTiresBatteries from "./page/TiresBatteries/index";

import Index from "./page/index";
import AllListing from "./page/all-listing";
import Register from "./page/register/index";
import RegisterSeller from "./page/register/register-seller";
import RegisterBuyer from "./page/register/register-buyer";
import VerifyEmail from "./page/register/verify-email";
import VerifyPhone from "./page/register/verify-phone";
import PasswordForm from "./page/register/password-form";
import AddParts from "./page/add-parts";
import EditParts from "./page/edit-parts";
import HowItWorks from "./page/how-it-works";
import AboutUs from "./page/about-us";
import ContactUs from "./page/contact-us";
import ImageRecog from "./page/image-reco";
import Faqs from "./page/faqs";
import Privacy from "./page/privacy";
import Terms from "./page/terms";
import News from "./page/news";
import Shipping from "./page/shipping";
import SellerOrder from "./content/element/my-order/seller-order";
import YourOrdersBuyer from "./page/your-orders-buyer";
import YourOrderSeller from "./page/your-orders-seller";
import YourAddress from "./page/your-address";
import OrderDetails from "./page/order-details";
import NewsDetails from "./page/news-details";
import SellerDetails from "./page/seller-details";
import SellerList from "./page/SellerDetails/Index";
import { LogOut } from "../Store/action/logoutAction";

import BecomeBuyer from "./page/BecomeBuyer";
import BecomeSeller from "./page/BecomeSeller";
import SearchVin from "./page/search-vin";
import Checkout from "./page/Checkout/index";
import Chats from "./page/chats";
import RequestPart from "./page/request-part";
import MyPayment from "./page/Payment";

import MyProfile from "./page/my-profile";
import MyStore from "./page/my-store";
import MyDashboard from "./page/my-dashboard";
import MyOrder from "./page/my-order";
import MySales from "./page/my-sales";
import Settings from "./page/settings";
import Quotation from "./page/quotation";
import EinvoiceMobile from "./page/EinvoiceMobile";
import RfqDetails from "./content/element/quotation/RFQDetail";

import MyMessages from "./content/element/Message";
import BuyerDeliveries from "./content/element/deliveries/Buyer-Deliveries";
import SellerDeliveries from "./content/element/deliveries/Seller-Deliveries";

import FAQPage from "./page/FAQ";
import TermsAndConditions from "./page/TermsAndConditions";
import RefundPrivacy from "./page/ReefundPrivacy";

import PrivacyPolicy from "./page/PrivacyPolicy";

import "../i18n";
import MyRequest from "./page/MyRequest";
import axios from "axios";
import { baseUrl } from "../constants";
import PolicyAggremementModal from "./common/PolicyAggremementModal";
import NotFound from "./page/NotFound";
import OnlinePayment from "./page/OnlinePayment";
import ThreeDsPayment from "./page/ThreeDsPayment";
import Indexz from "./content/element/AddForm";
import JSONInfo from "./page/JSONInfo";
import Disscussion from "./discussion/Disscussion";
import { SetDefaultAddress } from "../Store/action/loginActions";
import { getBannerList } from "../Store/action/listingActions";
import { getAllCategories } from "../Store/action/categoryActions";
import ReactGA from "react-ga4";
import PreHeader from "./layout/pre-header";
import Header from "./layout/header";
import Footer from "./layout/footer";
import { getNotificationInital } from "../Store/action/notificationAction";
import { NotifyToastWithButtons } from "./common/ToastNotifier/ToastNotifier";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

axios.defaults.baseURL = baseUrl;

const BuyerPrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem("login"));
        if (!currentUser || currentUser.role !== "BUYER") {
          // localStorage.clear();
          return (
            <Redirect
              to={{ pathname: "/register", state: { from: props.location } }}
            />
          );
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

const SellerPrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem("login"));
        if (!currentUser || currentUser.role !== "SELLER") {
          // localStorage.clear();
          return (
            <Redirect
              to={{ pathname: "/register", state: { from: props.location } }}
            />
          );
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem("login"));

        if (!currentUser) {
          // localStorage.clear();
          return (
            <Redirect
              to={{ pathname: "/register", state: { from: props.location } }}
            />
          );
        }
        // authorised so return component
        return <Component {...props} currentUser={currentUser} />;
      }}
    />
  );
};

const RestrictedRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem("login"));

        if (currentUser) {
          // localStorage.clear();
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
        // authorised so return component
        return <Component {...props} currentUser={currentUser} />;
      }}
    />
  );
};

class App extends Component {
  componentDidMount() {
    this.props.GetBannerList();
    this.props.getAllCategories();
    // 	const login = localStorage.getItem("login");
    // const user = JSON.parse(login);

    const currentUser = JSON.parse(localStorage.getItem("login"));
    currentUser?._id &&
      axios
        .get(`/api/users/get-user/${currentUser._id}`)
        .then((res) => {
          if (res.data.status === "Blocked") {
            this.props.logOutdata(null);
            NotifyToastWithButtons(
              "Your account has been suspended.Please contact buyparts admin"
            );
          }
          if (res.data.isDeleted) {
            this.props.logOutdata(null);
            NotifyToastWithButtons(
              "Your account has been deleted.Please contact buyparts admin"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    // console.log("BuyerPrivateRoute", process.env.REACT_APP_GOOGLE_ANALYTICS);
    if (currentUser?._id) {
      this.props.getNotificationInital(currentUser?._id);
    }
    ReactGA.send("pageview");
  }
  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Router basename={process.env.PUBLIC_URL}>
          <Disscussion />
          {/* <PolicyAggremementModal /> */}
          <PreHeader />
          <Header />
          <Switch>
            <Route exact path="/" component={Index} />
            <Route path="/how-it-works" component={HowItWorks} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/become-buyer" component={BecomeBuyer} />
            <Route path="/become-seller" component={BecomeSeller} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/get-vin" component={ImageRecog} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/refundprivacy" component={RefundPrivacy} />
            <Route path="/faqs" component={FAQPage} />
            <Route path="/terms" component={TermsAndConditions} />
            <Route path="/thankyou" component={Thankyou} />
            <Route path="/thankyou-register" component={ThankyouRegister} />
            <Route path="/all-listings" component={AllListing} />
            <Route path="/parts-search-vin" component={SearchVin} />
            <Route path="/tires-batteries" component={SearchTiresBatteries} />
            <Route path="/news" component={News} />
            <Route path="/json-info" component={JSONInfo} />

            <Route path="/spare-parts/:category">
              <Route
                exact
                path="/spare-parts/:category"
                component={AllListing}
              />
              <Route
                exact
                path="/spare-parts/:category/:subcategory"
                component={AllListing}
              />
            </Route>
            <Route
              path="/order/einvoice-mobile/:order_id/:seller_id"
              component={EinvoiceMobile}
            />
            <Route path="/car-parts/:make" component={AllListing} />
            <Route
              exact
              path="/spare-part-details/:info/:id"
              component={ListingDetails}
            />

            <Route
              exact
              path="/spare-part-details/:info"
              component={ListingDetails}
            />

            <Route path="/news-details/:id" component={NewsDetails} />

            <RestrictedRoute path="/register" exact component={Register} />
            <RestrictedRoute
              path="/register/seller"
              component={RegisterSeller}
            />
            <RestrictedRoute path="/register/buyer" component={RegisterBuyer} />
            <RestrictedRoute
              path="/register/verify-email"
              component={VerifyEmail}
            />
            <RestrictedRoute
              path="/register/verify-phone"
              component={VerifyPhone}
            />
            <RestrictedRoute
              path="/register/password"
              component={PasswordForm}
            />
            <SellerPrivateRoute
              path="/seller-deliveries"
              component={SellerDeliveries}
            />
            <BuyerPrivateRoute
              path="/buyer-deliveries"
              component={BuyerDeliveries}
            />

            <BuyerPrivateRoute path="/credits" component={Credits} />

            <SellerPrivateRoute path="/sell-your-parts" component={AddParts} />
            <SellerPrivateRoute path="/edit-parts/:id" component={EditParts} />
            <PrivateRoute
              exact
              path="/stores/:slug"
              component={SellerDetails}
            />
            <PrivateRoute path="/stores" component={SellerList} />
            <PrivateRoute path="/checkout" component={Checkout} />

            <PrivateRoute path="/request-part" component={RequestPart} />
            <PrivateRoute path="/my" component={MyMessages} />
            <PrivateRoute path="/orders/view/:slug" component={TrackingOrder} />
            <PrivateRoute path="/rfq/view/:id" component={RfqDetails} />
            <SellerPrivateRoute path="/payments" component={MyPayment} />
            <SellerPrivateRoute path="/my-listings" component={MyListing} />
            <PrivateRoute path="/my-profile" component={MyProfile} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/my-store" component={MyStore} />
            <PrivateRoute path="/my-dashboard" component={MyDashboard} />
            <PrivateRoute path="/my-sales" component={MySales} />
            <PrivateRoute path="/my-order" component={MyOrder} />
            <PrivateRoute path="/quotations" component={Quotation} />
            <PrivateRoute path="/my-request" component={MyRequest} />
            {/* <PrivateRoute path="/tracking-order" component={TrackingOrder} /> */}

            {/* <Route path="/my-favorites" component={MyFavorites} /> */}
            {/* <PrivateRoute path="/my-callbacks" component={MyCallbacks} />  */}
            {/* <PrivateRoute path="/cart" component={Cart} /> */}
            <PrivateRoute path="/shipping" component={Shipping} />
            <PrivateRoute
              path="/your-orders-buyer"
              component={YourOrdersBuyer}
            />
            <PrivateRoute
              path="/your-orders-seller"
              component={YourOrderSeller}
            />
            <PrivateRoute path="/order-details" component={OrderDetails} />
            {/* <Route path="/order-details" component={OrderDetails} /> */}
            <PrivateRoute path="/reviews" component={MyReview} />
            {/* private routes */}
            <SellerPrivateRoute path="/payments" component={MyPayment} />
            <SellerPrivateRoute path="/my-listings" component={MyListing} />
            <PrivateRoute path="/my-profile" component={MyProfile} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/my-store" component={MyStore} />
            <PrivateRoute path="/my-dashboard" component={MyDashboard} />
            {/* <PrivateRoute path="/my-message" component={MyMessages} /> */}
            <PrivateRoute path="/my-order" component={MyOrder} />
            <PrivateRoute path="/quotations" component={Quotation} />
            <PrivateRoute path="/my-request" component={MyRequest} />
            <PrivateRoute path="/your-address" component={YourAddress} />
            <PrivateRoute path="/payment" component={OnlinePayment} />
            <PrivateRoute path="/3ds-payment" component={ThreeDsPayment} />
            {/* <PrivateRoute path="/reviews" component={MyReview} /> */}
            <PrivateRoute path="/messages" component={Chats} />
            <SellerPrivateRoute path="/sell-your-parts" component={AddParts} />
            {/* <Route path="/my-favorites" component={MyFavorites} />
          <Route path="/my-callbacks" component={MyCallbacks} /> */}
            {/* <PrivateRoute path="/reviews" component={MyReview} /> */}
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </>
    );
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    logOutdata: (login) => dispatch(LogOut(login)),
    setDefaultAddress: (address) => dispatch(SetDefaultAddress(address)),
    GetBannerList: () => dispatch(getBannerList()),
    getAllCategories: () => dispatch(getAllCategories()),
    getNotificationInital: (userId) => dispatch(getNotificationInital(userId)),
  };
};
export default connect(null, mapDispatchToProp)(App);
