import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

class Promise extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="promise-item">
              <div className="promise-item-heading">
                <div className="promise-image-container">
                  <img src={`/assets/img/featured/1.png`} alt="promise" />
                </div>

                <div>
                  <div className="promise-title" style={{}}>
                    OEM AND AFTERMARKET
                  </div>
                  <p
                    style={{
                      color: "#1a0451",
                      marginTop: 7,
                      display: "block",
                      paddingLeft: 15,
                      lineHeight: 1.2,
                    }}
                  >
                    Our Seller's stock most spare parts at the best price
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="promise-item">
              <div className="promise-item-heading">
                <div className="promise-image-container">
                  <img src={`/assets/img/featured/3.png`} alt="promise" />
                </div>
                <div className="title">
                  <div className="promise-title">Sparepart experts</div>
                  <p
                    style={{
                      color: "#1a0451",
                      marginTop: 7,
                      display: "block",
                      paddingLeft: 15,
                      lineHeight: 1.2,
                    }}
                  >
                   Numerous Experts at your service anytime  
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="promise-item">
              <div className="promise-item-heading">
                <div className="promise-image-container">
                  <img src={`/assets/img/featured/2.png`} alt="promise" />
                </div>
                <div>
                  <div className="promise-title">Nationwide Delivery</div>
                  <p
                    style={{
                      color: "#1a0451",
                      marginTop: 7,
                      display: "block",
                      paddingLeft: 15,
                      lineHeight: 1.2,
                    }}
                  >
                    Get your spareparts delivered anywhere in the U.A.E.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="promise-item">
              <div className="promise-item-heading">
                <div className="promise-image-container">
                  <img
                    src={`/assets/img/featured/4.png`}
                    alt="promise"
                    style={{
                      width: 60,
                      height: 60,
                      position: "relative",
                      top: "-10px",
                    }}
                  />
                </div>
                <div>
                  <div className="promise-title">Fast Delivery</div>
                  <p
                    style={{
                      color: "#1a0451",
                      marginTop: 7,
                      display: "block",
                      paddingLeft: 15,
                      lineHeight: 1.2,
                    }}
                  >
                    From express deliveries to next day deliveries
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Promise);
