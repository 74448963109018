import React, { Fragment, Component, useEffect } from "react";
import { withTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import axios from "axios";
import { popUp } from "../../utils";

export default () => {
  return (
    <Fragment>

      <iframe src="http://52.65.208.255/" name="iframe_all"
        frameBorder="0" style={{ height: 'calc(100vh - 200px)', width: '100vw' }} ></iframe>
    </Fragment>
  );
}
