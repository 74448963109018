const initState = {
  payment: null
};

const PaymentReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_FORM":
      return {
        ...state,
        payment: action.payload
      };
    default:
      return state;
  }
};
export default PaymentReducer;
