import React, { useEffect, useState } from "react";
import { numberWithCommas, stringToUrl } from "../../../../utils";
import { NavLink, useHistory } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import axios from "axios";
import NotLoggedInModal from "../modal/not-logged-modal";
import IsExpress from "../../../common/IsExpress";
import Consolidation from "../../../common/Consolidation";
import TextOverlay from "../../../common/TextOverlay";

import { useSelector, useDispatch } from "react-redux";
const ListingCardSeller = ({
	link,
	imageArray,
	badge,
	title,
	type,
	price,
	addToCart,
	setPathname,
	quantity,
	_id,
	business_model,
	partSKU,
}) => {
	const { currency, conversionRate } = useSelector((state) => {
		return {
			currency: state.currency.currency,
			conversionRate: state.currency.conversionRate,
		};
	});
	const [star, setStar] = useState(0);
	const [errMsg, setErrorMsg] = React.useState("");
	const [image, setImage] = useState(
		imageArray ? imageArray[0] : "/assets/img/product-default.jpg"
	);
	const history = useHistory();
	const [isLogin, setIsLogin] = React.useState(false);

	function handleError() {
		setImage("/assets/img/product-default.jpg");
	}

	// useEffect(() => {
	//   axios
	//     .get("/api/review/" + _id)
	//     .then((data) => {
	//       if (data.data.length > 0) {
	//         const reviewData = data?.data
	//           .map((review) => review.star)
	//           .filter((x) => x !== undefined);

	//         let stars = reviewData.reduce((total, star) => total + star, 0);
	//         setStar(stars / reviewData.length);
	//       } else {
	//         setStar(0);
	//       }
	//     })
	//     .catch((err) => setStar(0));
	// }, [_id]);
	const currentUser = JSON.parse(localStorage.getItem("login"));

	const handleAddToClick = (id) => {
		if (currentUser) {
			addToCart(id);
			setPathname(history.location.pathname)
			localStorage.setItem('filterPath', JSON.stringify(history.location.pathname))
		} else {
			setErrorMsg(
				"You need to register as a buyer to be able to make an order on BuyParts24"
			);
			setIsLogin(true);
		}
	};

	return (
		<div
			className="col-lg-4 col-xl-4 col-sm-4 col-md-4"
			style={{ marginBottom: 30 }}
		>
			<NotLoggedInModal showModal={isLogin} onClose={setIsLogin} msg={errMsg} />
			<div className="atbd_single_listing ">
				<article className="atbd_single_listing_wrapper">
					<figure className="atbd_listing_thumbnail_area">
						<div className="atbd_listing_image">
							<NavLink to={`/spare-part-details/${link}`}>
								<img
									onError={handleError}
									src={image || "/assets/img/product-default.jpg"}
									alt="Listing"
								/>
								<TextOverlay business_model={business_model} />

							</NavLink>
						</div>
						<div className="atbd_thumbnail_overlay_content">
							<ul className="atbd_upper_badge">
								<li>
									<span
										className={
											"text-capitalize atbd_badge atbd_badge_" +
											badge.replace(" ", "_")
										}
									>
										{badge}
									</span>
								</li>
							</ul>
						</div>
					</figure>
					<div className="atbd_listing_info">
						<h4 className="atbd_listing_title">
							<NavLink to={`/buy-spare-parts/${link}`}>{title}</NavLink>
						</h4>
						<div className="rating-group">
							<div className="d-flex" style={{ margin: "5px 0px" }}>
								{/* <StarRatingComponent
                  name="rate2"
                  editing={false}
                  renderStarIcon={() => <i className="la la-star" />}
                  renderStarIconHalf={() => (
                    <i
                      className="la la-star-half-alt"
                      style={{ color: "#ffb400" }}
                    />
                  )}
                  starColor="#ffb400"
                  emptyStarColor={"#cecece"}
                  starCount={5}
                  value={parseInt(star)}
                /> 
                <span className="rating-value">
                  {parseFloat(star).toFixed(1)}
                </span>*/}
								<p style={{ fontSize: 12, fontWeight: 600, margin: 0 }}>
									SKU{" "}
									<span
										style={{
											color: "black",
											fontSize: 12,
											fontWeight: 600,
										}}
									>
										{partSKU}
									</span>
								</p>
							</div>
							<span className="review-value text-muted">{type}</span>
						</div>
						{/* <div style={{ textAlign: "start" }}>
              <p style={{ fontSize: 12, fontWeight: 600 }}>
                SKU{" "}
                <span
                  style={{
                    color: "black",
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                >
                  {partSKU}
                </span>
              </p>
            </div> */}
						<div className="price-group">
							{quantity && quantity > 0 ? (
								<>
									<p className="symbol mr-1">
										{currency}
										<span className="price">
											{/* {numberWithCommas(price.toFixed(2))} */}
											{numberWithCommas(
												(price * (conversionRate ? conversionRate : 1)).toFixed(
													2
												)
											)}
										</span>
									</p>
									{/* <button
                className="btn cart-btn"
                onClick={() => handleAddToClick(_id)}
              >
                Add
              </button> */}
									<button
										className="btn cart-btn"
										onClick={() => handleAddToClick(_id)}
									// disabled={role == "SELLER"}
									>
										Add
									</button>
								</>
							) : (
								<p style={{ fontSize: 12, fontWeight: 600, margin: 2 }}>
									Out of stock.
								</p>
							)}
						</div>
					</div>
				</article>
			</div>
		</div>
	);
};
export default ListingCardSeller;
