import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { PageBanner } from "../../content/element/page-banner";
import PreHeader from "../../layout/pre-header";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { Row, Col } from "reactstrap";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

const Confirmation = (props) => {
  return (
    <Fragment>
      {/* Header section start */}
      {/*  
      <Header /> */}
      {/* Header section end */}
      <section className="blog-area blog-grid section-padding-strict section-bg">
        <div className="container">
          <Typography className="" style={{ textAlign: "" }}>
            <h2>Order placed successfully</h2>
            <p>
              We’ll also send a confirmation email once your order has shipped.{" "}
            </p>
          </Typography>
          <br />
          <Row>
            <Col xs="3" sm="3">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Typography className="" style={{ textAlign: "left" }}>
                  <h6>Order Number</h6>
                  <p>234-234-234</p>
                </Typography>
              </div>
            </Col>
            <Col xs="3" sm="3">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Typography className="" style={{ textAlign: "left" }}>
                  <h6>Order Dete</h6>
                  <p>2020/20/1</p>
                </Typography>
              </div>
            </Col>
            <Col xs="3" sm="3">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Typography className="" style={{ textAlign: "left" }}>
                  <h6>Shipping Details</h6>
                  <p>
                    ary McDonald 101/90 Mary St, Surry Hills New South Wales
                    2000 Australia
                  </p>
                  <p>Deliver today</p>
                </Typography>
              </div>
            </Col>
            <Col xs="3" sm="3">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Typography className="" style={{ textAlign: "left" }}>
                  <h6>Payment Type</h6>
                  <p>VISA</p>
                  <p>XXXXXXXXXXXX0206</p>
                </Typography>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps)(Confirmation);
