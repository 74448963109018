import React from 'react'
import Header from '../../layout/header';
import PreHeader from '../../layout/pre-header';
import Footer from '../../layout/footer';
import { NavLink, useHistory } from 'react-router-dom';
import qs from "query-string";
import { useSelector } from 'react-redux';

const ThankYou = () => {
    const history = useHistory()
    const orderId = qs.parse(history.location.search).order
    const { pathname } = useSelector(state => state.list);

    const filteredPath = localStorage.getItem("filterPath");

    return (

        <>
            <div className="container mt-5 mb-5">
                <div className="thankyou-container">
                    <div className="thankyou-imageicon">
                        <img src="/assets/img/success.png" alt="success-png" />
                    </div>
                    <div className="thankyou-meta">
                        <h2>Purchase Success! Thanks for your order.</h2>

                        <p>BuyParts24 is coordinating with the sellers to make your order processed as soon as possible. Your order number is <b>{orderId}.</b>
                            <br />, you will be receiving an email shortly about it. You can track the progress of your order in My Orders page. </p>


                        {/* <p>Thanks for you order at buyparts. Your order will be processed as soon as possible<br /> make sure you have your order number , which is <b>{uuidv4().replace("-","").slice(0,8).toUpperCase()}.</b><br />
                        you will be rceving an email sortly with invoice from your order
                    </p> */}

                    </div>
                    <div className="thankyou-btn-container ">

                        <NavLink to="/" className="thankyou-back-to-home-btn" >
                            <img src="/assets/img/path.png" alt="path_icon" style={{ marginRight: '12px', verticalAlign: "initial" }} />
                            Back to home
                        </NavLink>
                        <NavLink to="/my-order" className="thankyou-back-to-home-btn" >
                            <img src="/assets/img/path.png" alt="path_icon" style={{ marginRight: '12px', verticalAlign: "initial" }} />
                            My Orders
                        </NavLink>
                        <NavLink to={pathname ? pathname : filteredPath?.slice(1, -1)} className="thankyou-back-to-home-btn" >
                            <img src="/assets/img/path.png"
                                alt="path_icon" style={{ marginRight: '12px', verticalAlign: "initial" }} />
                            Continue Shopping
                        </NavLink>
                    </div>
                </div>

            </div>


        </>
    )
}

export default ThankYou
