import React, { Component } from "react";
import Stepper from "./../common/Stepper";
import PreHeader from "../layout/pre-header";
import Header from "../layout/header";
import Footer from "../layout/footer";

class shopping extends Component {
  render() {
    return (
      <div>


        <Stepper />

      </div>
    );
  }
}

export default shopping;
