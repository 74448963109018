import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { PageBanner } from '../../content/element/page-banner';
import * as xlsx from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { updateBulkCart } from '../../../Store/action/listingActions';
import { bulkUploadUsers } from '../../../constants';
import { popUp } from '../../../utils';

const CheckoutEmpty = () => {
  const uploadRef = useRef();

  const [isUploading, setIsUploading] = useState(false);

  const login = useSelector((state) => state.login);

  const dispatch = useDispatch();
    
  const openFileDlg = () => {
    uploadRef.current.click();
  }

  const uploadOrder = (e) => {
    e.preventDefault();
    setIsUploading(true);

    let file = e.target.files[0];

    if(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);

            if(json?.length === 0) {
                popUp("Please upload the file with some data.");
                return;
            }

            if(!json[0]?.partSKU || !json[0]?.orderQty || !json[0]?.price) {
                popUp("Please upload the file in proper format.");
                return;
            }
            dispatch(updateBulkCart(json))
        };
        reader.readAsArrayBuffer(file);
    }

    setIsUploading(false);
    uploadRef.current.value = null;
  }

  
  return (
    <>
    <PageBanner />
    {
        bulkUploadUsers.includes(login?.email) &&
        <div 
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                margin: "3rem -1rem"
            }}
        >
            <div className="custom-file-upload">
                <input
                    id="customUpload"
                    type="file"
                    ref={uploadRef}
                    style={{ display: "none" }}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                    // let isLt5M = validateFile(e.target.files[0])
                    // if (!isLt5M) {
                    //   e.target.value = ''
                    //   return
                    // }
                    uploadOrder(e)
                    }}
                />
                <button
                    htmlFor="customUpload"
                    className="btn btn-sm btn-primary text-white"
                    onClick={openFileDlg}
                    disabled={isUploading}
                    >
                    {isUploading && (
                        <i className="las la-spinner la-spin mr-2"></i>
                    )}
                    Upload Bulk Order
                </button>

                <button
                    htmlFor="customUpload"
                    className="btn btn-sm btn-secondary text-white ml-2"
                    disabled={isUploading}
                    >
                        <a style={{color: "#fff"}} href="/bulk-order-sample.xlsx" target="_blank" download>
                            Download Excel Sample
                        </a>
                </button>
            </div>
        </div>
    }
    <div
        style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "90vh",
        }}
            >
            <img
                src="assets/img/emptymessage.png"
                alt="No-data-image"
                width="30%"
            />{" "}
            <div
                style={{
                fontWeight: "700",
                fontSize: "18px",
                color: "#1e71b8",
                width: "50%",
                textAlign: "center",
                }}
            >
                Sorry, There is no item in your cart
            </div>
        </div>
    {/* {header && <Footer />} */}
  </>
  )
}

export default CheckoutEmpty