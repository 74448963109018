export const SET_CURRENCY = "SET_CURRENCY";
export const SET_CURRENCY_RATE = "SET_CURRENCY_RATE";

export const setCurrencyType = (price) => {
  return async (dispatch) => {
    dispatch({ type: SET_CURRENCY, payload: price });
  };
};
export const setCurrencyRate = (rate) => {
  return async (dispatch) => {
    dispatch({ type: SET_CURRENCY_RATE, payload: rate });
  };
};
