import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import { Typography } from "@material-ui/core";


export default function IconLabelButtons() {
    const history = useHistory();

    // button:active {
    //     outline: none;
    //     border: none;
    //     }

    //     button:focus {outline:0;}
    return (
        <div className="directory_content_area">
            <div className="row">
                <div className="col-lg-12  d-lg-block" >
                    <div className="search-spare-part" style={{ width: "fit-content" }}>
                        <div className={"search_form search_form search_img"}
                            style={{ backgroundColor: " #F39200" }} onClick={() => history.push("/tires-batteries")} >
                            <div className="atbd_seach_button_wrapper_start" aria-disabled>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary btn_search d-flex p-2"
                                    style={{
                                        backgroundColor: " #F39200",
                                        borderColor: "#F39200",
                                    }}
                                >
                                    <i className="las la-search" />
                                </button>
                            </div>
                            <input
                                onClick={() => history.push("/tires-batteries")}
                                style={{ cursor: "pointer" }}
                                type="text"
                                value={"Tires & Batteries"}
                                name="search"
                                placeholder={"Find spare parts.."}
                                className={"text-white"}
                                disabled

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

