import React from "react";
import InvoiceTax from "./index";
import "./style.css";

import { useReactToPrint } from "react-to-print";

import { Modal, ModalBody, ModalHeader } from "reactstrap";

const TaxModal = ({ isTaxModal, setIsTaxModal, data, groupproducts }) => {
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Modal
      isOpen={isTaxModal}
      toggle={() => setIsTaxModal(false)}
      center
      style={{ maxWidth: "65rem" }}
    >
      <ModalHeader
        toggle={() => setIsTaxModal(false)}
        style={{ width: "100%" }}
      >
        <div
          className="primary-btn"
          style={{ float: "right" }}
          onClick={handlePrint}
        >
          Print
        </div>
      </ModalHeader>
      <ModalBody>
        <div ref={componentRef}>
          <InvoiceTax data={data} groupproducts={groupproducts} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TaxModal;
