import React, { Fragment, Component } from "react";
import { compose } from "redux";
import { history } from 'react-router-dom'
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Modal from "react-awesome-modal";
import { ToastContainer, toast } from "react-toastify";
import queryString from "query-string";
import Gallery from "../carousel/gallery";
import StarRatingComponent from "react-star-rating-component";
import { clickablePopUp, getCartLength, popUp } from "../../utils";
import { setPathname, UpdateCart } from "../../Store/action/listingActions";
import {
  NewQuotation,
  UpdateQuotationItems,
  ClearQuotation,
} from "../../Store/action/quotationActions";
import PreHeader from "../layout/pre-header";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { BreadcrumbCategory } from "../content/element/breadcrumb";
import { ListingFetures } from "../content/element/listing-features";
import { SellerInfo } from "../content/element/widget";
import CallbackDetails from "../content/element/modal/callback-details";
import ListingStickyFooter from "../content/element/listing-sticky-footer";
import CardListingGridSimilar from "../content/element/card/card-listing-grid-similar";

import { SetLoading } from "../../Store/action/listingActions";
import { SetActiveRoom, Initialize } from "../../Store/action/chatActions";
import { AddToCart } from "../../Store/action/listingActions";
import { NavLink } from "react-router-dom";

class ListingDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: null,
      listing_user: null,
      seller_listing: 0,
      modalIsOpen: false,
      similarListings: null,
      listingDetailTabIndex: 1,
      showQuotationConfirm: false,
    };
    this.validator = new SimpleReactValidator();
    this.getListing = this.getListing.bind(this);
    this.startChat = this.startChat.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.qtyDecrement = this.qtyDecrement.bind(this);
    this.qtyIncrement = this.qtyIncrement.bind(this);
  }

  startChat(e) {
    e.preventDefault();
    const { listing, listing_user } = this.state;
    if (this.props.login._id === listing_user._id) return;
    axios
      .post("/api/chat-rooms/add", {
        buyer_id: this.props.login._id,
        listing_id: listing._id,
        seller_id: listing_user._id,
      })
      .then((res) => {
        const { activeRoomId } = res.data;
        this.props.chatRoomInitialize().then(() => {
          this.props.setChatRoom(activeRoomId).then(() => {
            this.props.history.push("/messages");
          });
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  getListingSKU() {
    const sku = this.props.match.params;
    return sku;
  }

  componentDidMount() {
    this.getListing(this.getListingSKU());
  }

  componentDidUpdate() {
    const { listing } = this.state;

    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (
      listing &&
      listing.partSKU.toLowerCase() !== this.getListingSKU() &&
      params.api !== "true"
    ) {
      this.setState({ listing: null }, () => {
        this.getListing(this.getListingSKU());
      });
    }
  }

  getListing(sku) {
    this.props.setLoading(true);
    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    const info = this.props.match.params.info.split("-");
    const carID = info[info.length - 1];
    const articleNumber = info[info.length - 2];
    axios
      .get(`/api/info/get-article-detail?articleNumber=${sku}`)
      .then((res) => {
        let { bp24, articles } = res.data
        const newListings = articles.filter((d) => {
          return d.articleNumber.toLowerCase() === articleNumber;
        });
        let newListing;
        if (newListings.length > 0) {
          newListing = {
            category: "",
            clickCollect: "",
            countryOrigin: "",
            currency: "",
            date: "",
            delivery: "",
            depthDimension: "",
            description:
              newListings[0].genericArticles[0].genericArticleDescription,
            fittingPosition: "",
            heightDimension: "",
            hide: "",
            makes: "",
            models: "",
            partBrand: newListings[0].mfrName,
            partHSCode: "",
            partName: `${newListings[0].mfrName} ${newListings[0].genericArticles[0].genericArticleDescription}`,
            partSKU: "",
            pic: newListings[0].images[0]?.imageURL800,
            price: "",
            quantity: "",
            subCategory: "",
            type: "",
            user: "",
            weight: "",
            widthDimension: "",
            __v: "",
            _id: "",
          };
        }
        this.setState({ listing: newListing });
        this.props.setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        popUp("There was a problem with server. Please try again.");
      });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  qtyIncrement(e, id) {
    e.preventDefault();
    const items = this.props.list.itemsInCart;
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (!items.includes(id)) {
      axios
        .get(
          `/api/listing/check-buyability?emirate=${currentUser?.details?.emirate}&list=${id}`
        )
        .then((res) => {
          const { canbuy } = res.data;
          if (canbuy) {
            items.push(id);
            this.props.updateCart(items);
          } else {
            popUp("This seller do not sell in your emirate.");
          }
        })
        .catch((err) => {
          popUp("This seller do not sell in your emirate.");
        });
    } else {
      items.push(id);
      this.props.updateCart(items);

    }

    // items.push(id);
    // this.props.updateCart(items);
  }
  qtyDecrement(e, id) {
    e.preventDefault();
    const items = this.props.list.itemsInCart;
    const index = items.indexOf(id);
    if (index >= 0) items.splice(index, 1);
    this.props.updateCart(items);
  }
  chooseTab(e, index) {
    e.preventDefault();
    this.setState({ listingDetailTabIndex: index });
  }

  requestForQuotation() {
    const { listing_user, listing } = this.state;
    let { quotationSeller, quotationItems } = this.props.quotation;

    if (quotationSeller == null) {
      this.handleNewQuotation();
    } else if (quotationSeller !== listing_user._id) {
      //alert do you want to continue;
      if (
        window.confirm(
          "The seller is different from previous seller, this action will replace existing quotation. \n Do you want to Continue?"
        )
      ) {
        this.handleNewQuotation();
      }
    } else {
      const index = quotationItems.findIndex(
        (x) => x.listingId === listing._id
      );
      if (index < 0) {
        quotationItems.push({
          listingId: listing._id,
          name: listing.partName,
          price: listing.price,
          qty: 1,
          pic: listing.pic,
        });
        popUp("You have created new quotation, view it now?", "View", "/quotations", this.props.history)
      } else {
        quotationItems[index].qty = quotationItems[index].qty + 1;
        popUp(' Quotation modified, view it now?', "View", "/quotations", this.props.history)
      }

      this.props.updateQuotationItems({
        quotationItems,
      });
    }
  }

  handleNewQuotation() {
    const { listing_user, listing } = this.state;
    let { quotationSeller, quotationItems } = this.props.quotation;

    quotationSeller = listing_user._id;
    quotationItems = [
      {
        listingId: listing._id,
        name: listing.partName,
        price: listing.price,
        qty: 1,
        pic: listing.pic,
      },
    ];

    this.props.newQuotation({
      quotationSeller,
      quotationItems,
    });
    popUp("You have created new quotation, view it now?", "View", "/quotations", this.props.history)
   
  }

  inQuotation() {
    const { listing } = this.state;
    const { quotationItems } = this.props.quotation;

    const index = quotationItems.findIndex((x) => x.listingId === listing._id);
    if (index >= 0) {

      return quotationItems[index].qty;
    }

    return 0;
  }

  render() {
    const { modalIsOpen, listing, listing_user, seller_listing } = this.state;
    const { isLoading } = this.props.list;
    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let url =
      listing.pic ||
      listing.imageArray?.[0] ||
      "assets/img/product-default.jpg";
    return (
      <Fragment>
        <LoadingOverlay active={isLoading} spinner text="Loading listing...">

          {!params.api && (
            <BreadcrumbCategory
              title={listing ? listing.partName : ""}
              category={listing ? listing.category : ""}
              subCategory={listing ? listing.subCategory : ""}
            />
          )}
          {/* <PageBanner title="Listing Details"/> */}
          <section className="directory_listiing_detail_area single_area section-bg pt-4 pb-5">
            {listing && (
              <div className="container-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="atbd_content_module atbd_listing_gallery">
                        <div className="atbdb_content_module_contents new-atdbd-module">
                          {/* <div className="gallery-wrapper"> */}
                          {params.api == "true" && (
                            <img src={url} width="100%" alt="Listing" />
                          )}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="widget">
                        <div className="
                        ">
                          <div className="mb-4 w-100">
                            <h1 className="mb-2" style={{ color: "#272b41" }}>
                              {listing.partName}
                            </h1>
                            <h1 style={{ color: "#F39200" }}>
                              <small style={{ fontSize: "70%" }}>AED </small>
                              {listing.price}
                            </h1>
                          </div>
                          <div className="rating-group row">
                            <div className="col-md-6">
                              <div className="d-flex">
                                <StarRatingComponent
                                  name="rate2"
                                  editing={false}
                                  renderStarIcon={() => (
                                    <i className="la la-star" />
                                  )}
                                  renderStarIconHalf={() => (
                                    <i
                                      className="la la-star-half-alt"
                                      style={{ color: "#ffb400" }}
                                    />
                                  )}
                                  starColor="#ffb400"
                                  emptyStarColor={"#cecece"}
                                  starCount={5}
                                  value={3.5}
                                />
                                <span className="rating-value">3.5</span>
                              </div>
                            </div>
                            <div className="col-md-6 pl-2">
                              <span className="review-value text-muted">
                                760 reviews from buyers
                              </span>
                            </div>
                          </div>
                          {params.api !== "true" ? (
                            <div className="d-flex justify-content-between">
                              <div className="w-50 d-flex justify-content-around align-items-center p-1">
                                <button
                                  className="btn checkout-qty border bg-white"
                                  onClick={(e) =>
                                    this.qtyDecrement(e, listing._id)
                                  }
                                >
                                  -
                                </button>
                                <span className="border h-100 w-100 justify-content-center d-flex align-items-center bg-white">
                                  {getCartLength(
                                    this.props.list.itemsInCart,
                                    listing._id
                                  )}
                                </span>
                                <button
                                  className="btn checkout-qty border bg-white"
                                  onClick={(e) =>
                                    this.qtyIncrement(e, listing._id)
                                  }
                                >
                                  +
                                </button>
                              </div>
                              <div className="w-50 p-1">
                                <button
                                  className="btn btn-block"
                                  style={{
                                    backgroundColor: "#f39200",
                                    color: "white",
                                  }}
                                  onClick={(e) =>{
                                    this.props.addToCart(listing._id);
                                    localStorage.setItem('filterPath', JSON.stringify(this.props.location.pathname))
                                    this.props.setPathname(this.props.location.pathname)
                                  }}
                                >
                                  ADD TO CART
                                </button>
                              </div>
                            </div>
                          ) : (
                            <button className="btn btn-primary btn-block mt-4">
                              On Request
                            </button>
                          )}
                          <div className="seller-info d-flex mt-4 border-top pt-2">
                            <div className="seller-auth-info w-50">
                              <p className="mb-0" style={{ color: "#272b41" }}>
                                <span className="la la-user"></span>Seller Info:
                              </p>
                              <span className="h6" style={{ color: "#272b41" }}>
                                {listing_user?.details?.company_name}
                              </span>
                            </div>
                            <div className="seller-auth-rating w-50">
                              <span className="author-rating bg-success">
                                4.5<i className="la la-star"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <!-- ends: .atbd_widget_title --> */}
                      </div>

                      <div className="atbd_widget_contact">
                        {listing_user?.phone && (
                          <div className="d-flex flex-wrap">
                            <div className="w-50 p-1">
                              <a
                                href={`tel: ${listing_user.phone}`}
                                className="btn btn-primary btn-sm"
                              >
                                <i className="la la-phone" />
                                {formatPhoneNumberIntl(
                                  "+" + listing_user.phone
                                )}
                              </a>
                            </div>
                            <div className="w-50 p-1">
                              <a
                                href={`https://api.whatsapp.com/send?phone=${listing_user.phone}`}
                                className="btn btn-primary btn-sm"
                                target="blank"
                                rel="noopener noreferrer"
                              >
                                <i className="la la-whatsapp" />
                                Contact on Whatsapp
                              </a>
                            </div>
                            <div className="flex-break"></div>
                            <div className="w-100 p-1">
                              <button
                                className="btn btn-block"
                                style={{
                                  backgroundColor: "#f39200",
                                  color: "white",
                                }}
                                onClick={(e) => this.requestForQuotation()}
                              >
                                {this.inQuotation() > 0
                                  ? "Add Quotation Quantity (" +
                                  this.inQuotation() +
                                  ")"
                                  : "Request for Quotation"}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5 listing-detail-navbar">
                  <div className="col-lg-12">
                    <div className="listing-detail-nav">
                      <ul className="nav">
                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 1
                            ? "active"
                            : ""
                            }`}
                        >
                          <a
                            className="nav-link"
                            href="#!"
                            onClick={(e) => this.chooseTab(e, 1)}
                          >
                            Description
                          </a>
                        </li>
                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 2
                            ? "active"
                            : ""
                            }`}
                        >
                          <a
                            className="nav-link"
                            href="#!"
                            onClick={(e) => this.chooseTab(e, 2)}
                          >
                            Specification
                          </a>
                        </li>
                        <li
                          className={`nav-item ${this.state.listingDetailTabIndex === 3
                            ? "active"
                            : ""
                            }`}
                        >
                          <a
                            className="nav-link"
                            href="#!"
                            onClick={(e) => this.chooseTab(e, 3)}
                          >
                            Reviews
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-12">
                    <div className="listing-detail-content container">
                      {this.state.listingDetailTabIndex === 1 ? (
                        <div className="atbd_content_module atbd_listing_details">
                          <div className="atbd_content_module__tittle_area">
                            <div className="atbd_area_title">
                              <h4>
                                <span className="la la-file-text-o"></span>
                                Description
                              </h4>
                            </div>
                          </div>
                          <div className="atbdb_content_module_contents">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: listing.description.replaceAll("\n", "<br/>"),
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        this.state.listingDetailTabIndex === 2 && (
                          <ListingFetures listing={listing} />
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-12 mb-5">
                    <div className="container">
                      <h2 className="text-warning">
                        We think you might find these products interesting
                      </h2>
                      <CardListingGridSimilar
                        listings={this.state.similarListings}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
          <Modal
            visible={modalIsOpen}
            width="600"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <CallbackDetails
              listing_id={listing ? listing._id : null}
              closeModal={this.closeModal}
            />
          </Modal>
          <ListingStickyFooter listing={listing} />
        </LoadingOverlay>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    quotation: state.quotation,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    setLoading: (data) => dispatch(SetLoading(data)),
    setChatRoom: (data) => dispatch(SetActiveRoom(data)),
    chatRoomInitialize: () => dispatch(Initialize()),
    addToCart: (data) => dispatch(AddToCart(data)),
    updateCart: (data) => dispatch(UpdateCart(data)),
    newQuotation: (data) => dispatch(NewQuotation(data)),
    updateQuotationItems: (data) => dispatch(UpdateQuotationItems(data)),
    clearQuotation: (data) => dispatch(ClearQuotation(data)),
    setPathname: (pathname) => dispatch(setPathname(pathname))
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(ListingDetailsView);
