import React, { Fragment, Component } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-awesome-modal";
import SellerList from "./seller-list";

import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import BuyerList from "./buyer-list";
import { popUp } from "../../../../utils";

class EditResponseQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: null,
      sellerId: null,
      seller: null,
      quotationFile: null,
      quotationFileName: null,
      buyerFileName: null,
      sellerFileName: null,
      sellerNotes: null,
      modalSellerOpen: false,
    };

    this.validator = new SimpleReactValidator();
  }

  reloadState() {
    this.setState({
      sellerId: null,
      seller: null,
      quotationFile: null,
      quotationFileName: null,
      buyerNotes: null,
      modalSellerOpen: false,
    });
  }


  submit = () => {
    const { sellerId, quotationFile, sellerNotes } = this.state;

    if (!quotationFile) {
      alert("you must select quotation file");
      return;
    }



    var formData = new FormData();

    formData.append("quotationFile", quotationFile);
    formData.append("_id", this.props._id);
    formData.append("sellerNotes", this.props.editnotes);

    axios
      .post(
        "/api/products/upload-quotation?user_id=" + this.props.login._id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        this.reloadState();
        if (this.props.onFinished) this.props.onFinished();
        popUp("Successfully Updated RFQ Response");
        // setTimeout(()=>{
        //   window.location.reload()
        // },[1500])

      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  };

  render() {

    return (
      <div className="p-3">
        <div className="d-flex mb-2 justify-content-between">
          <h4>
            Update New Quotation Response by Excel file
          </h4>
          <a
            href="/assets/buyparts24_rfq_template.xls"
            className="btn btn-default btn-xs"
          >
            Download Excel Template
          </a>
        </div>
        {/* {this.props.buyerId} */}
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="quotationFile" className="form-label">
                Quotation Response File
              </label>
              {this.state.quotationFileName ? (
                <p className="text-ellipse">
                  <a href={this.state.quotationFileName} _target="blank">
                    {this.state.quotationFileName}
                  </a>
                </p>
              ) : (
                <input
                  type="file"
                  className="form-control"
                  id="quotationFile"
                  onChange={(e) =>
                    this.setState({ quotationFile: e.target.files[0] })
                  }
                />
              )}
            </div>
          </div>
          {/* <div className="col-12">
            <div className="form-group">
              <label htmlFor="sellerNotes" className="form-label">
                Notes
              </label>
              <textarea
                value={this.state.sellerNotes ||this.props.editnotes}
                className="form-control"
                rows="3"
                onChange={(e) => this.setState({ sellerNotes: e.target.value })}
              />
            </div>
          </div> */}
          <div className="col-12 d-flex justify-content-end">
            <button className="btn btn-primary btn-xs" onClick={this.submit}>
              {this.state.number ? "Update Request" : "Submit Quotations"}
            </button>
          </div>
        </div>
        <Modal
          visible={this.state.modalSellerOpen}
          width="800"
          effect="fadeInUp"
        >
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-default"
              onClick={() => this.props.closemodal}
            >
              X
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

EditResponseQuotation.propTypes = {
  onFinished: PropTypes.func,
};

EditResponseQuotation.propTypes = {
  loadWithId: PropTypes.string,
};

EditResponseQuotation.propTypes = {
  onDataLoaded: PropTypes.func,
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(
  EditResponseQuotation
);
