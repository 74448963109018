import React from "react";

const OperationContent = () => {
  return (
    <div className="benefits-seller-content mt-4">
      <ul>
        <li>Informed pricing strategy & improved margins</li>
        <li>Business insights & dashboards</li>
        <li>Manage promotions and offers</li>
        <li>Streamlined deliveries</li>
        <li>Seamless order management</li>
        <li>Returns management</li>
      </ul>
    </div>
  );
};

export default OperationContent;
