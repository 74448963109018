import React from 'react'
import { useSelector } from 'react-redux';
import PaymentSafetyBatchTooltip from "./PaymentSafetyBatchTooltip";
export default function Step4({ selectedDelivery, payOnCard, selectedCreditCard, wallet, payByWallet, handleCard, handleCash, handleWallet, disabled }) {
  const { isBulkUpload } = useSelector(state => state.list);
  return (
    <div className="payment-step">
      <div className="payment-container bg-white" style={{ padding: "1rem 0px " }}>
        <div className="shipping-content d-flex flex-wrap">

          <div className={`${selectedDelivery === true ? "selected-card" : ""} payment-option-payby-cash`} onClick={handleCash}>
            <input type="radio" checked={selectedDelivery === true} style={{ marginRight: "27px" }} />
            <span style={{ fontSize: "22px", color: "#4C5036" }}>
              Pay by Cash/Card <br />Payment on Delivery
            </span>
          </div>

          {
            !isBulkUpload &&
            <div className={`${selectedCreditCard === true ? "selected-card" : ""} payment-option-payby-cash`} onClick={handleCard} style={{ position: "relative" }}>
              <input type="radio" checked={payOnCard === true} />
              <span style={{ fontSize: "22px", color: "#4C5036", width: "140px", marginLeft: "27px", }}>
                Pay by<br />credit card
              </span>
              <div className="card-logo-container">
                <img src="/assets/img/Visa.png" />
                <img src="/assets/img/Maestro.png" style={{ marginTop: "10px" }} />
              </div>
              <PaymentSafetyBatchTooltip />
            </div>
          }

          {wallet?._id && wallet?.amount > 0 ? <div className={`${payByWallet === true ? "selected-card" : ""} payment-option-payby-cash`} onClick={handleWallet}>
            <input type="radio" checked={payByWallet === true} />
            <span style={{ fontSize: "22px", color: "#4C5036", width: "140px", marginLeft: "27px", }}>
              Pay by<br />Wallet
            </span>
            <div className="card-logo-container">
              <img src="/assets/img/payment-icons/credit.png" style={{ height: 50, width: 50 }} />
            </div>
          </div> : null}


        </div>
      </div>

    </div>
  )
}
