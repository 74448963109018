import React, { useState, useEffect } from "react";
import Header from "../../layout/header";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import { PageBanner } from "../../content/element/page-banner";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment-timezone";
import MaterialTable from "material-table";
import { popUp, UserAvatar } from "../../../utils";
import PageTitle from "../../content/element/PageTitle";
import CssTextField from "../../content/element/SearchField";
import ShowDynamicCurrency from "../../common/ShowDynamicCurrency";
import ShowDynamicPrice from "../../common/ShowDynamicPrice";
import { TablePagination } from "@material-ui/core";
import { useHistory } from "react-router";
const headerStyle = {
  backgroundColor: "#1a0451",
  fontSize: "14px",
  color: "#fff !important",
  fontWeight: 800,
  fontFamily: "Montserrat",
};
const Credits = () => {
  const [keyword, setKeyword] = useState("");
  const [logs, setLogs] = useState([]);
  const [wallet, setWallet] = useState({});
  const [initalLogs, setInitalLogs] = useState([]);
  const [logCount, setLogCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const login = useSelector((state) => state.login);

  const getWalletLogs = () => {
    const newPage = page + 1;
    setIsLoading(true);
    axios
      .get(
        `/api/wallet/wallet-log-user/${login._id}?page=${newPage}&limit=${limit}&search=${keyword}`
      )
      .then((res) => {
        setLogs(res.data.walletLog);
        // setInitalLogs(res.data.walletLog)
        setLogCount(res.data.total);
        setWallet(res.data.wallet);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        popUp("Something went wrong", "Reload page", "/", history);
      });
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowPerPage = (rowsPerPage) => {
    setPage(0);
    setLimit(rowsPerPage);
  };

  const handleSearch = (e) => {
    // if (e.target.value === '') {
    //     setLogs(initalLogs);
    // } else {
    //     let filter = initalLogs.filter(({ type, amount, user, remarks, createdAt, reason }) => {
    //         let str = `${user?.phone} ${user?.email} ${user?.name} ${user?.details?.garage_name} ${type} ${amount} ${remarks} ${reason} ${moment(createdAt).tz('Asia/Dubai')
    //             .format('DD/MM/YYYY')}`;
    //         return str.toLowerCase().search(e.target.value.toLowerCase()) > -1;
    //     });
    //     setLogs(filter);
    // }
    setKeyword(e.target.value);
    setPage(0);
  };

  useEffect(() => {
    login?._id && getWalletLogs();
  }, [login, page, limit, keyword]);

  const columns = [
    {
      title: <span className="tryit">Type</span>,
      field: "type",
      headerStyle,
      render: ({ type, _id: id }) => {
        const myElement = document.getElementById(`credit-debit${id}`);
        if (myElement) {
          if (type === "credit") {
            myElement.parentElement.parentElement.classList.add("credit-color");
          } else {
            myElement.parentElement.parentElement.classList.add("debit-color");
          }
        }
        return <div id={`credit-debit${id}`}>{type}</div>;
      },
    },
    {
      title: <span className="tryit">Amount</span>,
      field: "amount",
      headerStyle,
      render: (log) => {
        return (
          <p>
            <ShowDynamicCurrency />
            &nbsp;
            <ShowDynamicPrice price={log.amount} />
          </p>
        );
      },
    },
    {
      title: <span className="tryit">Updated By</span>,
      field: "updatedBy",
      headerStyle,
      render: ({ updatedBy: user, type }) => {
        return (
          <div style={{ display: "flex" }}>
            {user?.avatar ? (
              <figure>
                <UserAvatar
                  avatar={user?.avatar}
                  style={{
                    width: "50px",
                    height: "50px",
                    marginRight: "10px",
                    borderRadius: "50%",
                  }}
                />
              </figure>
            ) : null}
            <figcaption>
              <div className="user-name">
                {user?.details?.garage_name || user?.name}
              </div>
              <div className="user-designation">{user?.email}</div>
              <div className="user-designation">{user?.phone}</div>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: <span className="tryit">Remarks</span>,
      field: "remarks",
      headerStyle,
    },
    {
      title: <span className="tryit">Reason</span>,
      field: "reason",
      headerStyle,
    },
    {
      title: <span className="tryit">Date</span>,
      field: "createdAt",
      headerStyle,
      render: ({ createdAt }) => (
        <p>{moment(createdAt).tz("Asia/Dubai").format("DD/MM/YYYY")}</p>
      ),
    },
  ];

  return (
    <>
      <PageBanner />

      <section className="mt-2 mb-5 bgcolor-white ">
        <div className="container">
          <PageTitle title="My Credits" />
          <div className="dashboard-search-box">
            <div className="ml-auto mt-auto">
              <strong style={{ color: "#272b41" }}>
                Available Credits: <ShowDynamicCurrency />
                &nbsp;
                <ShowDynamicPrice price={wallet.amount} />
              </strong>
            </div>

            <div className="ml-auto">
              <CssTextField
                id="standard-basic"
                value={keyword}
                onChange={handleSearch}
                label="Search..."
              />
            </div>
            <div>
              <i className="las la-search la-search search-icon-datatable"></i>
            </div>
          </div>
          <div className="row  ">
            <div className="col-lg-12 ">
              <MaterialTable
                style={{ zIndex: 0 }}
                title={null}
                key={logCount}
                data={logs}
                columns={columns}
                onChangeRowsPerPage={handleChangeRowPerPage}
                isLoading={isLoading}
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <img
                        src="/assets/img/nodata.png"
                        className="datatableimage"
                      />
                    ),
                  },
                }}
                options={{
                  search: false,
                  rowStyle: (x) => {
                    if (x.tableData.id % 2) {
                      return { backgroundColor: "#f2f2f2" };
                    }
                  },
                  paging: true,
                  pageSize: 20,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [20, 50, 100],
                  sorting: false,
                }}
                components={{
                  Pagination: (props) => (
                    <TablePagination
                      {...props}
                      count={logCount}
                      page={page}
                      rowsPerPage={limit}
                      onChangePage={handleChangePage}
                      component="div"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Credits;
