import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import StarRatingComponent from "react-star-rating-component";
import Select from "react-select";
import { FormGroup, Label, Input, Button } from "reactstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import { Download } from "react-feather";

import {
  AddToCart,
  handleBulkDelete,
  SetVisibility,
} from "../../../../Store/action/listingActions";
import { numberWithCommas, stringToUrl, ProductImage } from "../../../../utils";
import ConfirmModal from "../modal/confirmation";
import ConfirmBulkDelete from "../modal/ConfirmationBulkDelete";
import IsExpress from "../../../common/IsExpress";
import Consolidation from "../../../common/Consolidation";
import ShowDynamicCurrency from "../../../common/ShowDynamicCurrency";
import ShowDynamicPrice from "../../../common/ShowDynamicPrice";

class CardListingGrid extends Component {
  state = {
    arrId: [],
    shwomodal: false,
    action: null,
    CSVLink: "",
  };

  componentDidMount() {
    this.downloadCSV();
  }

  downloadCSV = async () => {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    let res = await axios.get(`/api/listing/get-listing-csv/${currentUser._id}`);
    console.log(res.data);
    if (res.data) {
      this.setState({ CSVLink: res.data });
    }
  };
  downloadURI = (uri) =>{
  var link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', 'download');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
  handleProductDelete = (id) => {
    let newArr = [...this.state.arrId, id];
    this.setState({ arrId: newArr });
  };
  handleRemoveProductSelection = (id) => {
    let newArr = this.state.arrId.filter((item) => {
      return item !== id;
    });
    this.setState({ arrId: newArr });
  };
  openConfirm = () => {
    this.setState({ shwomodal: true });
  };
  deleteBulk = () => {
    this.props.handleBulkDelete(this.state.arrId, this.state.action);
  };
  handleClose = () => {
    this.setState({ showModal: false, action: "" });
  };

  handleChangeCategory = (selectedvalue) => {
    this.setState({ action: selectedvalue?.value });
    this.setState({ showModal: true });
  };

  render() {
    const { size } = this.props;
    const listings = this.props.list;
    const { isLoading } = this.props.list;
    const className =
      size === 3
        ? "col-lg-6 col-xl-3 col-sm-6 col-md-4 mb-4"
        : size === 6
          ? "col-lg-2 col-xl-2 col-sm-6 col-md-4 mb-4"
          : "col-lg-4 col-sm-6 mb-4";

    const options = [
      { label: "Delete Items", value: "delete" },
      { label: "Hide Items", value: "hide" },
      { label: "Unhide Items", value: "unhide" },
    ];

    return (
      <Fragment>
        <div className="col-md-12">
          <ConfirmBulkDelete
            showModal={this.state.showModal}
            onClose={() => this.setState({ showModal: false })}
            eventFunc={this.deleteBulk}
            action={this.state.action}
            handleClose={this.handleClose}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <FormControlLabel
                value="end"
                checked={
                  listings.length === this.state.arrId.length ? true : false
                }
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({ arrId: listings.map((l) => l._id) });
                      } else {
                        this.setState({ arrId: [] });
                      }
                    }}
                    color="primary"
                  />
                }
                label={
                  <span style={{ position: "relative", top: "3px" }}>
                    Select All
                  </span>
                }
                labelPlacement="end"
              />
            </div>

            {this.state.arrId.length > 0 && (
              <div
                className="bulk-delete-product ml-auto"
                style={{
                  textAlign: "right",
                  marginBottom: 30,
                }}
              >
                {/* <button
                className="bulk-delete-btn"
                style={{
                  border: 0,
                  // position: "sticky",
                  // top: 60,
                  // zIndex: 999,
                  background: "#f39200",
                  padding: "12px 20px",
                  color: "#fff",
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
                onClick={() => this.setState({ showModal: true })}
              >
                Delete Items
              </button>
              <button
                className="bulk-delete-btn ml-2"
                style={{
                  border: 0,
                  // position: "sticky",
                  // top: 60,
                  // zIndex: 999,
                  background: "#f39200",
                  padding: "12px 20px",
                  color: "#fff",
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
                onClick={() => this.setState({ showModal: true })}
              >
                Hide Items
              </button><button
                className="bulk-delete-btn ml-2"
                style={{
                  border: 0,
                  // position: "sticky",
                  // top: 60,
                  // zIndex: 999,
                  background: "#f39200",
                  padding: "12px 20px",
                  color: "#fff",
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
                onClick={() => this.setState({ showModal: true })}
              >
                Unhide Items
              </button> */}

                <FormGroup style={{ width: 200 }}>
                  <Select
                    id="action"
                    className={`react-select`}
                    classNamePrefix="react-select"
                    defaultValue={this.state.action}
                    style={{ width: 200 }}
                    onChange={(selectedOption) =>
                      this.handleChangeCategory(selectedOption)
                    }
                    options={options}
                  />
                </FormGroup>
              </div>
            )}

            <div>
              <Button
                disabled={this.state.CSVLink ? false : true}
                size="sm"
                color="primary"
                style={{ backgroundColor: "#fff" }}
                className={'btn-export'}
                outline
                onClick={() => this.downloadURI(this.state.CSVLink)}
              >
                  <Download size={14} />
                  Export to CSV
              </Button>
            </div>
          </div>
        </div>
        {!isLoading &&
          listings.map((value, index) => {
            const {
              pic,
              imageArray,
              partName,
              partSKU,
              price,
              _id,
              date,
              hide,
              quantity,
              business_model,
            } = value;
            const title = `${partName}`;
            const badge =
              moment.duration(moment().diff(moment(date))).asHours() <= 48
                ? "new listing"
                : "";
            const link =
              stringToUrl(partName) + "--" + stringToUrl(partSKU) + `/${_id}`;
            let url =
              pic || imageArray?.[0] || "assets/img/product-default.jpg";
            return (
              <div className={className} key={index}>
                <div
                  className={`${hide ? "disabled" : ""} atbd_single_listing`}
                >
                  <article
                    className={
                      quantity && quantity < 10
                        ? "atbd_single_listing_wrapper quanitylessthan10"
                        : "atbd_single_listing_wrapper"
                    }
                  >
                    <figure
                      className="atbd_listing_thumbnail_area"
                      style={{ position: "relative" }}
                    >
                      <NavLink to={`/spare-part-details/${link}`}>
                        <div className="atbd_listing_image">
                          <ProductImage avatar={url} alt="Listing" />
                          <IsExpress
                            business_model={business_model}
                            right="-76px"
                            bottom="0px"
                          />
                          <Consolidation business_model={business_model} width="61px" right="15px" bottom="-29px" />

                        </div>
                      </NavLink>
                      {quantity < 1 && (
                        <img
                          src="/assets/img/os1.png"
                          style={{
                            height: "45%",
                            width: "50%",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                          }}
                        />
                      )}
                      <div className="atbd_thumbnail_overlay_content">
                        <ul className="atbd_upper_badge">
                          <li>
                            <span
                              className={
                                "text-capitalize atbd_badge atbd_badge_" +
                                badge.replace(" ", "_")
                              }
                            >
                              {badge}
                            </span>
                          </li>
                        </ul>
                      </div>
                      {this.state.arrId.includes(_id) ? (
                        <button
                          className="bulk-delete-product-item bulk-selected"
                          onClick={() => this.handleRemoveProductSelection(_id)}
                        >
                          <i class="las la-check"></i>
                        </button>
                      ) : (
                        <button
                          className="bulk-delete-product-item p-1"
                          onClick={() => this.handleProductDelete(_id)}
                        >
                          <i
                            class="las la-check-circle"
                            style={{ fontSize: 25 }}
                          ></i>
                        </button>
                      )}{" "}
                    </figure>
                    <div className="atbd_listing_info">
                      <h4 className="atbd_listing_title">
                        <NavLink to={`/spare-part-details/${link}`}>
                          {title}
                        </NavLink>
                      </h4>
                      <div className="rating-group">
                        <StarRatingComponent
                          name="rate2"
                          editing={false}
                          renderStarIcon={() => <i className="la la-star" />}
                          renderStarIconHalf={() => (
                            <i
                              className="la la-star-half-alt"
                              style={{ color: "#ffb400" }}
                            />
                          )}
                          starColor="#ffb400"
                          emptyStarColor={"#cecece"}
                          starCount={5}
                          value={3.5}
                        />
                        {/* <span className="rating-value">3.5</span> */}
                        <span className="review-value text-muted">
                          {value.type}
                        </span>
                      </div>
                      <div className="price-group">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>
                            <p style={{ fontSize: 12, fontWeight: 600 }}>
                              SKU{" "}
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {value.partSKU}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p style={{ fontSize: 12, fontWeight: 600 }}>
                              Stock{" "}
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {quantity}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div
                          className="symbol mr-auto"
                          style={{ marginTop: "-10px", marginBottom: "10px" }}
                        >
                          {/* {currency} */}
                          <ShowDynamicCurrency />
                          <span className="price">
                            {/* {numberWithCommas(price.toFixed(2))} */}
                            <ShowDynamicPrice price={price} />
                          </span>
                        </div>

                        <div
                          className="d-flex align-items-center"
                          style={{ margin: "0" }}
                        >
                          <NavLink
                            className="btn cart-btn"
                            to={`/edit-parts/${_id}`}
                            title="Edit"
                            style={{
                              padding: "0 5px",
                              marginRight: "5px",
                              fontWeight: "400",
                              backgroundColor: "#1d71b8",
                            }}
                          >
                            <i
                              className="la la-pencil"
                              style={{ fontSize: "16px" }}
                            ></i>
                            Edit
                          </NavLink>
                          <button
                            className={`btn cart-btn`}
                            onClick={() =>
                              this.props.setVisibility(
                                _id,
                                hide && hide === true ? true : false
                              )
                            }
                            title={hide && hide === true ? "Unhide" : "Hide"}
                            style={{ padding: "0 5px", fontWeight: "400" }}
                          >
                            {hide && hide === true ? (
                              <>
                                {" "}
                                <i
                                  className="las la-eye-slash"
                                  style={{ fontSize: "16px" }}
                                ></i>
                                Un hide{" "}
                              </>
                            ) : (
                              <>
                                <i
                                  className="las la-eye"
                                  style={{ fontSize: "16px" }}
                                ></i>
                                hide{" "}
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            );
          })}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    currency: state.currency,
    // list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    setVisibility: (id, visibility) => dispatch(SetVisibility(id, visibility)),
    handleBulkDelete: (id, action) => dispatch(handleBulkDelete(id, action)),
 
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(CardListingGrid);
