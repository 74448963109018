import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import TotalRevenue from "../content/element/TotalRevenue/TotalRevenue";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { PageBanner } from "../content/element/page-banner";
import Orders from "../content/element/Orders/Orders";
import TopSellingProducts from "../content/element/TopSellingProducts/TopSellingProducts";
import RevenueByCategories from "../content/element/RevenueByCategories/RevenueByCategories";
import LastOrder from "../content/element/LastOrder/LastOrder";
import RevenueByBuyer from "../content/element/RevenueByBuyer/RevenueByBuyer";
import { sellerDashboardData } from "../../Store/action/dashboardAction";
import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import { Card, Row, Col } from "reactstrap";
import { Typography } from "@material-ui/core";
import { popUp } from "../../utils";
import PageTitle from "../content/element/PageTitle";
import Pending from "../content/element/UserStatus/Pending";

class BuyerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardFor: "SELLER",
      orders: [],
      activeTab: "1",
      activeTabDate: "1",
      modal: false,
      messagesType: "AllMessages",
    };
  }

  componentDidMount() {
    let id = JSON.parse(localStorage.login)?._id;
    axios
      .get("/api/order/get-seller-order?user_id=" + id)
      .then((res) => {
        this.setState({ orders: res.data.orders });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  render() {
    const { isLoading } = this.props.list;
    const { t } = this.props;
    const { orders } = this.state;
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser && currentUser.status === "pending") return <Pending />;

    return (
      <Fragment>
        <LoadingOverlay active={isLoading} spinner text={t("loading_listing")}>

          {/* <Header className="menu--light" /> */}
          <PageBanner title="Dashboard" />
          <section style={{ backgroundColor: "#F5F7FC" }} className="pt-2 pb-5">
            <div className="container">
              <PageTitle title="My Dashboard" />
              <Row className="mt-2">
                <Col sm="12">
                  {/* <TotalRevenue />

                  <Row className="mt-4">
                    <Orders />
                    <TopSellingProducts />
                  </Row> */}

                  <div className="mt-4">
                    <LastOrder />
                  </div>
                </Col>
              </Row>
            </div>
          </section>


        </LoadingOverlay>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    dashboard: state.dashboard,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(BuyerDashboard);
