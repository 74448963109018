import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { Card, Row, Col } from "reactstrap";
import { Typography, Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { numberWithCommas, UserAvatar } from "../../../../utils";
import { CircularProgress } from "@material-ui/core";
import ShowDynamicPrice from "../../../common/ShowDynamicPrice";
import ShowDynamicCurrency from "../../../common/ShowDynamicCurrency";
const LastOrder = () => {
  const [lastOrders, setLastOrders] = useState([]);
const [isLoading, setIsLoading] = useState(false)
  const getlastOrders = async (id) => {
    let url =
      currentUser?.role === "SELLER"
        ? `/api/order/recent-orders?seller_id=${id}`
        : `/api/order/recent-orders?buyer_id=${id}`;
    let res = await axios.get(url);
    setLastOrders(res.data);
    setIsLoading(false)
  };

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("login"));
  }, [localStorage]);

  useEffect(() => {
    // const currentUser = JSON.parse(localStorage.getItem("login"))
    setIsLoading(true)
    getlastOrders(currentUser._id);
  }, [currentUser]);

  const getStatus = (status) => {
    if (status == "Preparing") {
      return "preparing";
    }
    if (status == "PENDING") {
      return "pending";
    }
    if (status == "cancelled") {
      return "cancelled";
    }
    if (status == "Out for Delivery") {
      return "Out for Delivery";
    }
    if (status == "issued") {
      return "issued";
    }
    if (status == "delivered") {
      return "delivered";
    }
  };

  return (
    <>
      <div>
        <Card>
          <Grid
            container
            justify="space-between"
            className="dashboard-container-header"
            style={{ paddingBottom: "20px" }}
          >
            <Grid>
              <h6>
                {currentUser.role === "SELLER" ? "Last Sales" : "Last Orders"}
              </h6>
            </Grid>
          </Grid>
          {
            !isLoading ?  lastOrders.map((item, k) => {
            return (
              <Row
                style={{ padding: "10px 20px" }}
                key={k}
                className="lastorder-contiainer"
              >
                <Col style={{ textAlign: "left" }}>
                  <Row>
                    <Col
                      sm="4"
                      style={{
                        justifyContent: "center",
                        width: "50px",
                        height: "50px",
                        margin: "auto 0",
                      }}
                      className="last-sale-image"
                    >
                    <UserAvatar
                        avatar={item.user?.avatar ||
                          item.seller?.avatar}
                        style={{
                          width: "88%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                    />
                    </Col>
                    <Col md="8">
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#212529",
                          fontWeight: "bold",
                        }}
                        className="dash-table-color"
                      >
                        {item?.user?.name ? item.user.name : "Various Seller"}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "rgb(33, 37, 41)",
                          // fontWeight: "600",
                        }}
                        className="dash-table-color"
                      >
                        {moment(item.order_date)
                          .tz("Asia/Dubai")
                          .format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "rgb(33, 37, 41)",
                          // fontWeight: "600",
                        }}
                        className="dash-table-color"
                      >
                        {moment(item.order_date)
                          .tz("Asia/Dubai")
                          .format("hh:mm a")}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col className="content-center">
                  <Typography
                    style={{ fontSize: "12px", color: "#212529" }}
                    align="center"
                    className="dash-table-color"
                  >
                    {item?.order_id}
                  </Typography>
                </Col>
                {/* <Col className="content-center ">
                  <div
                    className={`${getStatus(
                      item?.status
                    )}-order-status-one dash-table-color`}
                    style={{ height: 20 }}
                  >
                    {getStatus(item?.status)}
                  </div>
                </Col> */}

                <Col className="content-center" style={{ textAlign: "right" }}>
                  <Typography
                    style={{
                      fontSize: "12px",
                      // fontWeight: "bold",
                      color: "#212529",
                    }}
                    align="center"
                  >
                    <span
                      className="dash-table-color"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                    >
                      <ShowDynamicCurrency/>
                    </span>{" "}
                    <ShowDynamicPrice price={item.total_price}/>
                  
                  </Typography>
                </Col>
                <Col className="content-center" style={{ textAlign: "center" }}>
                  <NavLink to={`/orders/view/${item?._id}`}>
                    <div className="primary-btn">View Order</div>
                  </NavLink>
                </Col>
                {/* <br /> */}
              </Row>
            );
          }): <div style={{textAlign:"center", margin:"30px 0px"}}> 
              <CircularProgress/>
          </div>
        } 
        </Card>
      </div>
    </>
  );
  // }
};

export default LastOrder;
