import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";

const SellerListingCard = ({ data, selectedRating }) => {
  const imgRef = useRef(null);

  const imgError = (name) => {
    imgRef.current.src = "/assets/img/user-default.png";
  };

  if (selectedRating && selectedRating > (data?.rating ? data.rating : 0)) {
    return <></>;
  }

  return (
    <>
      <div
        className="atbd_single_listing atbd_listing_list"
        style={{ marginBottom: "15px" }}
      >
        <article className="atbd_single_listing_wrapper">
          <figure
            className="atbd_listing_thumbnail_area"
            style={{ background: "#E6E6E6" }}
          >
            <div className="atbd_listing_image">
              <Link to={"/stores/" + data.slug}>
                <img
                  src={
                    data?.avatar === null ||
                    data?.avatar === "null" ||
                    data.avatar === undefined
                      ? "/assets/img/user-default.png"
                      : data?.avatar
                  }
                  ref={imgRef}
                  onError={() => imgError(data.name)}
                  alt={data.name}
                  className="listing-card-seller"
                />
              </Link>
            </div>
            {/*<!-- ends: .atbd_listing_image -->*/}
            <div className="atbd_thumbnail_overlay_content">
              <ul className="atbd_upper_badge">
                <li>
                  <span
                    className={
                      "text-capitalize atbd_badge atbd_badge_" + "badge"
                    }
                  ></span>
                </li>
              </ul>
            </div>
          </figure>

          <div className="atbd_listing_info">
            <div className="atbd_content_upper">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h4 className="atbd_listing_title">
                    <NavLink to={"/stores/" + data.slug}>
                      {data.details.company_name}
                    </NavLink>
                  </h4>

                  <div className="atbd_listing_data_list">
                    <ul>
                      <li>
                        <p>
                          <span className="la la-map-marker"></span>
                          {data.details.company_address}
                        </p>
                      </li>

                      <li>
                        <p>
                          <span className="la la-flag"></span>
                          {data.details.emirate}
                        </p>
                      </li>

                      <li>
                        <p>
                          <span className="la la-calendar-check-o"></span>
                          Registered on{" "}
                          {moment(data.date)
                            .tz("Asia/Dubai")
                            .format("DD/MM/YYYY")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="atbd_listing_meta"
                  style={{ display: "block", marginTop: "0px" }}
                >
                  {data?.rating && data.rating !== 0 ? (
                    <span
                      className="atbd_meta atbd_listing_rating"
                      style={{
                        background: "#1d71b8",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i style={{ marginLeft: 0 }} className="la la-star"></i>
                      &nbsp;
                      {data.rating}
                    </span>
                  ) : (
                    <>
                      <span>No Review Yet</span>
                    </>
                  )}
                  {/* <div style={{ marginTop: "18px" }}>
                    <p>
                      <span className="la la-calendar-check-o"></span>
                      Listing:{" "}
                      {
                        data?.listings?.filter((item) => {
                          return item.hide !== true;
                        }).length
                      }
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default SellerListingCard;
