import React from "react";
import { PageBanner } from "../content/element/page-banner";
import Header from "../layout/header/index";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import MyReviewCard from "../content/element/card/my-review-card";
import Pending from "../content/element/UserStatus/Pending";

const MyReview = () => {
  const currentUser = JSON.parse(localStorage.getItem("login"));

  if (currentUser && currentUser.status === "Pending") return <Pending />;
  return (
    <>

      {/* <Header className="menu--light" /> */}
      <PageBanner />
      <div className="container mt-2 mb-5 bgcolor-white">
        <MyReviewCard />
      </div>

    </>
  );
};

export default MyReview;
