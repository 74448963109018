import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from 'react-i18next';
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import { popUp } from "../../../../utils";

export const validatePassword = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

const noAction = (e) => e.preventDefault();
class PasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      passwordErr: false

    };
    this.validator = new SimpleReactValidator();
    this.setStateFromInput = this.setStateFromInput.bind(this);
    this.continue = this.continue.bind(this);
  }

  setStateFromInput(e) {
    noAction(e);
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ passwordErr: false })

  }

  continue(e) {
    noAction(e);
    if (this.validator.allValid()) {
      if (validatePassword.test(this.state.password)) {
        this.setState({ passwordErr: false })

          axios.post("/api/users/update-password", { method: this.props.method, identity: this.props.identity, password: this.state.password })
            .then((res) => {
              this.props.goNext();
            }).catch((err) => {
              popUp("There was a problem with server. Please try again.");
          })
      }
      else {
        this.setState({ passwordErr: true })
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    let { password, confirmPassword } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <h2 className="welcome">Forgot your password?</h2>
        <p className="text-center mt-3">
          {/* {t("auth_please_fill_password")} */}
          Please enter a new password to access your account.
        </p>
        <form action="/" id="signup-form">
          <div className="form-group">
            <input
              type="password"
              name="password"
              value={password}
              onChange={this.setStateFromInput}
              className="form-control"
              placeholder={t("auth_password_placeholder")}
              required
            />
            <div className="text-danger">
              {this.validator.message(
                "password",
                password,
                `required`
              )}
            </div>
          </div>
          <div className="form-group">
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.setStateFromInput}
              className="form-control"
              placeholder={t("auth_confirm_password_placeholder")}
              required
            />
            <div className="text-danger" style={{ textAlign: 'center' }}>
              {this.validator.message(
                "confirmPassword",
                confirmPassword,
                `required|in:${password}`,
                { messages: { in: t("auth_confirm_password_validation_error") } }
              )}
            </div>
            {this.state?.passwordErr && <div className="text-danger" style={{ textAlign: 'center' }}>
              The Password must contain:
              Minimum eight characters,
              at least one uppercase letter,
              one lowercase letter,
              one number and one special character.
            </div>}
          </div>

          <button
            type="button"
            onClick={(e) => this.props.goPrev()}
            className="btn btn-continue  mr-2"
          >
            {t("auth_previous")}
          </button>
          <button
            type="submit"
            onClick={this.continue}
            className="btn btn-continue"
          >
            {t("auth_continue")}
          </button>
        </form>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
  };
};
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProp))(PasswordForm);
