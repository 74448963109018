import React from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

export default function PaymentSafetyBatchTooltip() {
  const useStylesBootstrap = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: "#807d7d",
      fontSize: '12px'
    },
    arrow: {
      color: "#807d7d",
    }
  }));
  const tooltipClass = useStylesBootstrap();
  return (
    <div>
      <Tooltip
        classes={tooltipClass}
        arrow
        leaveDelay={500}
        placement="bottom"
        title="The payment options offered are designed with your security in mind"
      >
        <img
          style={{ position: 'absolute', top: '5px', right: '5px' }}
          width={20}
          height={20}
          alt="badgeIcon"
          src="./assets/img/icons/safety_badge_icon.png"
        />
      </Tooltip>
    </div>
  )
}