import React from "react";

const SellerBenefitsCard = ({ title, handleCardClick, active, imgSrc }) => {
  return (
    <div className="benefits-seller-container">
      <div
        className="benefits-seller-image"
        onClick={() => handleCardClick(title)}
      >
        <img src={imgSrc} alt="buyparts-access" />
      </div>

      <div className="benefits-seller-text">{title}</div>
      {active === title && <div className="benefits-seller-triangle" />}
    </div>
  );
};

export default SellerBenefitsCard;
