import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link, Redirect} from "react-router-dom";
import{ useHistory } from "react-router-dom";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

export default function ConfirmModalLogout({ showModal, onClose,eventFunc,e}) {
  const history = useHistory();
  const handelRegister = () => {
  history.push("/register")
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{position:'relative'}}>
            <div onClick={handleClose} style={{position:'absolute',right:10,top:0,cursor:'pointer'}}>
                <a href="#" style={{fontSize:20}} className="offcanvas-menu__close">
                    x
                </a>
            </div>
            <div style={{width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:10}}>
                <HighlightOffRoundedIcon style={{fontSize:80,marginLeft:'auto',marginRight:'auto',color:'#dc3545'}}/>
                <h6>Are you sure?</h6>
            </div>

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                
                <div style={{textAlign:'center'}}>Do you really want to Signout?<br/>This process cannot be undone.</div>  

          </DialogContentText>
        </DialogContent>
        <DialogActions style={{paddingRight:'10%',paddingLeft:'10%',marginBottom:10}}>
            <a href="#" className="offcanvas-menu__close">
             <Button variant="contained" style={{backgroundColor:'grey',color:'#fff',width:110}} onClick={handleClose}>
                    Cancel
             </Button>
            </a>
          <Button variant="contained" onClick={()=>{eventFunc(e)}} style={{backgroundColor:'#dc3545',color:'#fff',width:150}}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
