import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

const makeList = [
  "lexus",
  "mitsubishi",
  "nissan",
  "audi",
  "volkswagen",
  "toyota",
  "hyundai",
  "kia",
  "bmw",
  "renault",
  // "chevrolet",
  // "ford",
  // "honda",
  // "infiniti",
  // "landrover",
  // "mazda",
  // "mercedes",
];

class BrowseByMake extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <div className="category-list">
          {makeList.map((make, index) => {
            return (
              <div className={`category-card-wrapper make-${index}`} key={index}>
                <NavLink
                  className="category-card"
                  to={`/car-parts/${make
                    .toLocaleLowerCase()
                    .replace(" ", "-")}`}
                  style={{ boxShadow: "none" }}
                >
                  <img
                    src={`/assets/img/make-logos/spare-parts-${make.toLowerCase()}.png`}
                    alt=""
                  />
                </NavLink>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

export default BrowseByMake;
