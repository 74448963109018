import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import { numberWithCommas } from "../../../utils";
import { useSelector } from "react-redux";

const OrangeRadio = withStyles({
	root: {
		color: "#3f51b5",
		"&$checked": {
			color: "#3f51b5",
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function WalletPaymentModal({
	isOpen,
	onClose,
	setWalletAmount,
	wallet,
	max,
	setPartialPaymentType,
	partialPaymentType,
	walletAmount,
	setPayByWallet,
	handleCash,
	setDisabled,
	_handleCash,
}) {
	const [amt, setAmt] = useState(0);
	const [err, setErr] = useState(false);
	const [walletAmtErr, setWalletAmtErr] = useState(false);
	const [anotherPaymentMethod, setAnotherPaymentMethod] = useState("");

	const { isBulkUpload } = useSelector(state => state.list);

	const handleChange = (event) => {
		setAnotherPaymentMethod(event.target.value);
	};
	const payNow = () => {
		if (Number(wallet?.amount) >= amt * 1) {
			if (amt * 1 <= max) {
				if (amt * 1 === max) {
					setDisabled(true);
					setPartialPaymentType("");
				} else {
					setDisabled(false);
					setPartialPaymentType(anotherPaymentMethod);
				}
				if (amt * 1 === 0) {
					setDisabled(false);
					setPayByWallet(false);
					setPartialPaymentType("");
					handleCash();
				}
				setWalletAmount(amt * 1);
				onClose();
			}

			if (amt * 1 > max) {
				setErr(true);
			}
		} else {
			setWalletAmtErr(true);
		}
	};

	useEffect(() => {
		if (isOpen) {
			setAnotherPaymentMethod(partialPaymentType || "cash_on_delivery");
			setPartialPaymentType("");
			// setDisabled(false)
			setAmt(walletAmount);
			setErr(false);
			setWalletAmtErr(false);
		}
	}, [isOpen]);

	const _onClose = () => {
		setDisabled(false);
		setWalletAmount(0);
		setPayByWallet(false);
		onClose();
		_handleCash();
	};

	return (
		<>
			<Dialog
				aria-labelledby="customized-dialog-title"
				open={isOpen}
				fullWidth={true}
				scroll={"paper"}
			>
				<DialogTitle id="customized-dialog-title" onClose={_onClose}>
					Wallet Payment
				</DialogTitle>
				<DialogContent dividers>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<strong>
								Available amount: AED {numberWithCommas(wallet?.amount?.toFixed(2) || 0)}
							</strong>
						</div>

						<div style={{ display: "flex" }}>
							<div style={{ paddingRight: "10px", paddingTop: "20px" }}>
								<strong>AED</strong>
							</div>

							<TextField
								type="number"
								value={amt}
								onChange={(e) => {
									let newAmt = e.target.value;
									setAmt(newAmt);
									if (Number(wallet?.amount) >= newAmt * 1) {
										if (newAmt * 1 > max) {
											setErr(true);
										} else {
											setErr(false);
											setWalletAmtErr(false);
										}
									} else {
										setWalletAmtErr(true);
									}
								}}
								id="standard-basic"
								label="Amount To Pay"
							/>
							{err && (
								<p style={{ color: "red" }}>
									Amount should be less than or equal to {max?.toFixed(2)}
								</p>
							)}
							{walletAmtErr && (
								<p style={{ color: "red" }}>
									Amount should be less than or equal to wallet available
									amount.
								</p>
							)}
						</div>
					</div>

					{amt * 1 > 0 && amt * 1 < max ? (
						<div>
							<FormControl component="fieldset">
								<FormLabel focused={false} component="legend">
									Pay Partial Amount With:
								</FormLabel>
								<RadioGroup
									row
									aria-label="gender"
									name="gender1"
									value={anotherPaymentMethod}
									onChange={handleChange}
								>
									<FormControlLabel
										value="cash_on_delivery"
										control={<OrangeRadio />}
										label="Cash On Delivery"
									/>
									{
										!isBulkUpload && 
										<FormControlLabel
											value="online_payment"
											control={<OrangeRadio />}
											label="Online Payment"
										/>
									}
								</RadioGroup>
							</FormControl>
						</div>
					) : null}
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={payNow}
						variant="contained"
						color="primary"
					>
						Pay
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
