import { GET_DASHBOARD_DATA } from "../action/dashboardAction";
const initState = {
    products : []
};
const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                products: action.payload
            }
        default:
            return state;
    }
};
export default dashboardReducer;
