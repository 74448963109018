const initState = {
  quotationSeller: localStorage.getItem("quotationSeller") || null,
  quotationItems: JSON.parse(localStorage.getItem("quotationItems")) || [],
};

const listingReducer = (state = initState, action) => {
  switch (action.type) {
    case "NEW_QUOTATION": {
      const { quotationSeller, quotationItems } = action.data;

      localStorage.setItem("quotationSeller", quotationSeller);
      localStorage.setItem("quotationItems", JSON.stringify(quotationItems));
      return {
        ...state,
        quotationSeller,
        quotationItems,
      };
    }
    case "UPDATE_QUOTATION_ITEMS": {
      const { quotationItems, quotationSeller } = action.data;

      localStorage.setItem("quotationItems", JSON.stringify(quotationItems));
      return {
        ...state,
        quotationSeller,
        quotationItems,
      };
    }
    case "CLEAR_QUOTATION": {
      const quotationSeller = "";
      const quotationItems = [];

      localStorage.setItem("quotationSeller", quotationSeller);
      localStorage.setItem("quotationItems", JSON.stringify(quotationItems));
      return {
        ...state,
        quotationSeller,
        quotationItems,
      };
    }
    default:
      return state;
  }
};

export default listingReducer;
