import React, {useState, useEffect} from 'react'

const QtyInput = ({value, changeCartQty, listing}) => {
    const [quantity, setQuantity] = useState(null)

    useEffect(() => {
        setQuantity(value)
    }, [value])

    console.log(quantity,"quantity")

  return (
    <input id="standard-basic" 
    className='qty-input'
    onChange={(e) =>{
        e.preventDefault();
        let qty = e.target.value * 1;
        if(qty < 1){
            return setQuantity(null)
        }
        
        if (qty > listing?.quantity ) {
            return;
        }
        setQuantity(qty)
    }} 
    onBlur={(e)=>{
        // console.log({e:e.target})
        let qty = quantity * 1;
        if( qty < 1) {
            qty = 1;
            setQuantity(qty)
        }
        changeCartQty(qty, listing._id)
    }}
    type='number' 
    value={quantity}
    />
  )
}

export default QtyInput