import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { SectionTitle } from "../content/element/section-title";
import Promise from "../content/element/promise";
import BrowseByCategory from "../content/element/browse-by-category";
import BrowseByMake from "../content/element/browse-by-make";
import Newsletter from "../content/element/newsletter";
import Loader from "../content/element/Loader";
import Leaderboard from "../content/element/leaderboard";
import { getBannerList } from "../../Store/action/listingActions";
import { FaRegCommentDots } from "react-icons/fa";
import { NavLink } from "react-router-dom";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seoTextExpand: false,
      conversionRate: "",
      loading: false,
      bannersList: [],
    };
    this.toggleSeoText = this.toggleSeoText.bind(this);
  }

  toggleSeoText(e) {
    e.preventDefault();
    const { seoTextExpand } = this.state;
    this.setState({ seoTextExpand: !seoTextExpand });
  }

  // async componentDidUpdate(prevState, prevProps) {
  //   const { currency } = this.props.currency;
  //   if (currency !== prevState?.currency?.currency) {
  //     this.setState({ loading: true });
  //     await fetch(
  //       `https://v6.exchangerate-api.com/v6/90eccd4a9aa0f4cd2409095c/pair/AED/${currency}`
  //     )
  //       .then((res) => res.json())
  //       .then((data) =>
  //         this.setState({ conversionRate: data?.conversion_rate })
  //       );
  //     this.setState({ loading: false });
  //   }
  // }

  render() {
    const { t } = this.props;
    if (this.state.loading) return <Loader />;
    let top_leaderboard_home_page = this.props.list?.bannersList?.find((banner) => banner.category === "top_leaderboard_home_page")

    let bottom_leaderboard_home_page = this.props?.list?.bannersList?.find((banner) => banner.category === "bottom_leaderboard_home_page")
    let slider = this.props?.list?.bannersList?.filter((banner) => banner.category === "billboard_home_banner")?.sort((a, b) => a.position - b.position)

    return (
      <Fragment>
        <section>
          <div className="container">
            <div
              id="homepage-carousel"
              className="carousel carousel-div slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                {slider?.map((banner, index) => {
                  return <li
                    key={index}
                    data-target="#homepage-carousel"
                    data-slide-to={index}
                    className={index === 0 ? "active" : ""}
                  ></li>
                })}
              </ol>
              
              <div className="carousel-inner">
                {this.props?.list?.bannersList ? (
                  slider?.map((banner, index) => {
                    return (
                      <>
                        <div
                          className={
                            index === 0
                              ? `carousel-item active `
                              : `carousel-item`
                          }
                        >
                          <a href={banner?.banner_link || '#'}
                            style={{ display: 'block' }}
                          >
                            <img
                              src={banner?.banner_upload}
                              className="d-block w-100"
                              alt="..."
                            />
                          </a>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <div className="carousel-item active">
                      <img
                        src="/assets/img/home-page-banner/home-page-banner-1.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/assets/img/home-page-banner/home-page-banner-2.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/assets/img/home-page-banner/home-page-banner-3.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/assets/img/home-page-banner/home-page-banner-4.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </>
                )}
              </div>
              <a
                className="carousel-control-prev"
                href="#homepage-carousel"
                role="button"
                data-slide="prev"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                  style={{ height: 22, width: 22 }}
                >
                  <path
                    fill-rule="evenodd"
                    fill="#000"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </a>
              <a
                className="carousel-control-next"
                href="#homepage-carousel"
                role="button"
                data-slide="next"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                  style={{ height: 22, width: 22 }}
                >
                  <path
                    fill="#000"
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>

        {/* Promise section start */}
        <section className="promise">
          <Promise />
        </section>

        {/* Promise section end */}

        {top_leaderboard_home_page && (
          <Leaderboard banner={top_leaderboard_home_page} />
        )
        }

        {/* Browse By Type section start */}
        <section className='browse-category-wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <h1
                        className="mb-0 about_us_title"
                      >
                        {t('home_browse_by_category_title')}
                      </h1>
                    </div>
                  </div>

                  <p
                    style={{ color: "#1a0451", fontWeight: "500" }}
                    className="mt-1 mb-0"
                  >
                    {t('home_browse_by_category_content')}
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <BrowseByCategory />
            </div>
          </div>
        </section>
        {/* Browse By Type section end */}

        {/* search by makes start */}
        <section className='browse-category-wrapper makes-category'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <h1 className="mb-0 about_us_title" >
                        {t("home_browse_by_make_title")}
                      </h1>

                    </div>
                  </div>

                  <p
                    style={{ color: "black", fontWeight: "500" }}
                    className="mt-1 mb-0"
                  >
                    {t('home_browse_by_make_content')}
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <BrowseByMake />
            </div>
          </div>
        </section>
        {/* search by make end */}

        {bottom_leaderboard_home_page && (
          <Leaderboard banner={bottom_leaderboard_home_page} />
        )
        }

        {/* Need Any Help start */}
        <div className="need-helps" style={{ padding: "20px 0", background: "#f5f5f7" }} >
          <div className="container d-flex justify-content-between align-items-center">
            <p
              style={{ color: "#1a0451", fontWeight: "500", fontSize: "18px" }}
              className="mt-1 mb-0 mr-2"
            >
              We are always here to help.
            </p>
            <div
              className="nav-item"
              style={{
                fontSize: "10px",
                padding: "0px 5px",
                fontWeight: "400",
                backgroundColor: "#1e71b8",
                height: "32px",
              }}
            >
              <NavLink
                className="nav-link "
                to={"/contact"}
                style={{ color: "white", fontSize: 12, flexWrap: "nowrap", whiteSpace: "nowrap", }}
              >
                <FaRegCommentDots size={15} className="mr-2" />
                Need Help? Ask our Support
              </NavLink>
            </div>
          </div>
        </div>
        {/* Need Any Help start */}

        {/* Newsletter section start */}
        <section className='newsletter' style={{ padding: '30px 0', background: "#ffffff", backgroundColor: "#ffffff" }}>
          <div className='container'>
            <SectionTitle
              title='Newsletter'
              content='And benefit from our special offers'
            />
            <Newsletter />
          </div>
        </section>
        {/* Newsletter section end */}

      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    currency: state.currency,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    GetBannerList: () => dispatch(getBannerList()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProp),
  withTranslation()
)(Index);
