import React from "react";

const DeliveryContent = () => {
  return (
    <div className="benefits-buyer-content mt-4">
      <ul>
        <li>Call Centre Support</li>
        <li>Reliable, on-time delivery</li>
        <li>Epress delivery option</li>
        <li>Delivery Status Tracking</li>
      </ul>
    </div>
  );
};

export default DeliveryContent;
