import axios from "axios";
import { baseUrl } from "../../constants";
import { popUp } from "../../utils";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"

export const sellerDashboardData = () => {
  return async (dispatch, getState) => {
    const login = getState().login;
    try {
      const response = await axios.get(
        `${baseUrl}/api/dashboard?user_id=${login._id}`
      );
      dispatch({ type: GET_DASHBOARD_DATA, payload: response.data.data.products });
    } catch (err) {
      popUp("There was a problem with server. Please try again.");
    }
  };
};