import React, { Fragment, Component, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { PageBanner } from "../content/element/page-banner";
import axios from "axios";
import RSC from "react-scrollbars-custom";
import moment from "moment-timezone";
import $ from "jquery";
import Header from "../layout/header";
import Footer from "../layout/footer";
import PreHeader from "../layout/pre-header";
import { WebSocketContext } from "../../WebSocket";
import LoadingOverlay from "react-loading-overlay";
import Pending from "../content/element/UserStatus/Pending";
import {
  UpdateRooms,
  UpdateActiveRoom,
  AddMessage,
  GetConversations,
  Initialize,
} from "../../Store/action/chatActions";
import NoData from "../../components/content/element/Nodata";

import { numberWithCommas, popUp, stringToUrl, UserAvatar } from "../../utils";
import Alert from "@material-ui/lab/Alert";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DISSCUSSION_LISTING_ID } from "../../constants";

const noAction = (e) => e.preventDefault();
const getChatTime = (momentDate) => {
  const REFERENCE = moment().tz("Asia/Dubai"); // fixed just for testing, use moment();
  const TODAY = REFERENCE.clone().startOf("day");
  const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
  if (momentDate.isSame(TODAY, "d"))
    return "Today " + momentDate.format("h:mm A");
  if (momentDate.isSame(YESTERDAY, "d"))
    return "Yesterday " + momentDate.format("h:mm A");
  else return momentDate.format("DD/MM/YYYY - h:mm A");
};

const styles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

class Chats extends Component {
  static contextType = WebSocketContext;
  constructor(props) {
    super(props);
    this.state = {
      listing: null,
      members: [],
      activeUser: this.props.chat.activeUser,
      conversations: [],
      message: null,
      connectedUsers: [],
      isTyping: false,
      typingTimeout: 0,
      typingUsers: [],
      queryId: false,
      allUsers: [],
      searchText: "",
      orderID: "",
    };

    this.handleChatRoom = this.handleChatRoom.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleInputMessage = this.handleInputMessage.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.setMessageRead = this.setMessageRead.bind(this);
    this.handleBackUserList = this.handleBackUserList.bind(this);
  }

  componentDidMount() {
    if (this.props?.chat?.rooms?.length > 0) {
      if (this.props?.location?.state?.detail == null) {
        this.props
          .chatRoomInitialize()
          .then((res) =>
            this.handleChatRoom(null, this.props?.chat?.rooms?.[0]?.roomId)
          );
        this.scrollToBottom();
      } else {
        this.props
          .chatRoomInitialize()
          .then((res) =>
            this.handleChatRoom(null, this.props?.location?.state?.detail)
          );
        this.scrollToBottom();
        this.setState({ orderID: this.props?.location?.state?.orderId });
      }
    }
  }
  componentWillUnmount() {
    this.props.updateActiveChatRoom(null);
  }

  scrollToBottom() {
    if (this.messagesEnd) this.messagesEnd.scrollToBottom();
  }

  setMessageRead(id) {
    axios
      .post("/api/message/set-read", {
        id,
      })
      .then((res) => {
        return "";
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  handleChatRoom(e, roomId) {
    this.setState({ setactive: false });
    if (this.state.status === "true") {
      this.setState({ orderID: "" });
    }
    if (e) noAction(e);
    let { rooms } = this.props.chat;
    const foundIndex = rooms.findIndex((x) => x.roomId === roomId);
    rooms[foundIndex].unreadMsgCnt = 0;

    this.props.updateChatRooms(rooms);
    this.props.updateActiveChatRoom(rooms[foundIndex]);
    this.props.getRoomConversations(rooms[foundIndex]?.roomId).then(() => {
      this.scrollToBottom();
    });
    if ($(window).width() <= 991) {
      $(".chat-window").addClass("chat-slide");
    }
  }

  handleInputMessage(e) {
    const self = this;
    // const context = this.context;

    const { isTyping, typingTimeout } = this.state;
    // const { activeRoom } = this.props.chat;
    // const senderId = this.props.login._id;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if (isTyping === false) {
      this.setState({ isTyping: true });
      // context.startTyping(senderId, activeRoom.userId, activeRoom.roomId);
    }
    this.setState({
      typingTimeout: setTimeout(function () {
        self.setState({ isTyping: false });
        // context.stopTyping(senderId, activeRoom.userId, activeRoom.roomId);
      }, 1000),
    });

    this.setState({ message: e.target.value });
  }

  sendMessage(e) {
    noAction(e);
    const context = this.context;
    const { message } = this.state;
    const { activeRoom } = this.props.chat;
    if (!message) return;

    let { rooms } = this.props.chat;
    const foundIndex = rooms.findIndex((x) => x.roomId === activeRoom.roomId);
    rooms[foundIndex].lastMsg = message;

    this.props.updateChatRooms(rooms);

    this.setState({ isTyping: false, message: "" });
    context.sendMessage(
      activeRoom.roomId,
      this.props.login._id,
      activeRoom.userId,
      message
    );
    this.props.addNewMessage({
      roomId: activeRoom.roomId,
      sender: this.props.login._id,
      message,
    });

    if (rooms[foundIndex].roomId == rooms[0].roomId) {
      this.scrollToBottom();
    } else {
      this.props
        .chatRoomInitialize()
        .then((res) =>
          this.handleChatRoom(null, this.props?.chat?.rooms?.[0].roomId)
        );
      this.scrollToBottom();
    }
    this.props.updateActiveChatRoom(rooms[0]);

    this.props.getRoomConversations(rooms[0]?.roomId).then((res) => {
      this.scrollToBottom();
    });
    this.scrollToBottom();
  }

  handleBackUserList(e) {
    noAction(e);
    if ($(window).width() <= 991) {
      $(".chat-window").removeClass("chat-slide");
    }
  }
  updateInput = async (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleSearch = (e) => e.preventDefault();
  render() {
    const { message } = this.state;
    const { rooms, activeRoom, onlineUsers, messages } = this.props.chat;
    const link =
      stringToUrl(activeRoom?.listing?.partName) +
      "-" +
      stringToUrl(activeRoom?.listing?.partSKU);

    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser && currentUser.status === "Pending") return <Pending />;

    if (!this.props?.chat?.rooms?.length)
      return (
        <NoData
          text="You don't have any message yet. You will find your discussions with support and sellers here."
          header={true}
        />
      );

    return (
      <Fragment>

        <PageBanner />

        <section className="mt-2 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="chat-window">
                  {/* Begin Chat Room List */}
                  <div className="chat-cont-left">
                    {/* <div className="chat-header">
                      <span>Chats</span>
                    </div> */}
                    <form className="chat-search" onSubmit={this.handleSearch}>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i className="las la-search"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          name="searchText"
                          value={this.state.searchText}
                          onChange={this.updateInput}
                        />
                      </div>
                    </form>

                    <div className="chat-users-list">
                      {this.props?.chat?.rooms?.length > 0 ? (
                        <div
                          className="chat-scroll"
                          style={{ overflowX: "hidden" }}
                        >
                          {/* {console.log(rooms)} */}
                          {rooms
                            // .filter((x) => x?.listing?._id !== DISSCUSSION_LISTING_ID)
                            .filter((obj) => {
                              return obj.userName
                                ?.toLowerCase()
                                .includes(this.state?.searchText?.toLowerCase()
                                )
                            }
                            )
                            .map((room, index) => {
                              return (
                                <a
                                  href="#!"
                                  className={`media ${activeRoom &&
                                      activeRoom.roomId === room.roomId
                                      ? "active"
                                      : ""
                                    }`}
                                  key={index}
                                  onClick={(e) =>
                                    this.handleChatRoom(e, room.roomId)
                                  }
                                >
                                  <div className="media-img-wrap">
                                    <div
                                      className={`avatar ${onlineUsers && onlineUsers[room.userId]
                                          ? "avatar-online"
                                          : "avatar-away"
                                        }`}
                                    >
                                      <UserAvatar avatar={room?.userAvatar}
                                        alt="UserImage"
                                        className="avatar-img rounded-circle" />
                                    </div>
                                  </div>
                                  <div className="media-body">
                                    <div>
                                      <div
                                        className={`user-name ${room.unreadMsgCnt > 0
                                            ? "font-weight-bolder"
                                            : ""
                                          }`}
                                      >
                                        {room.userName}
                                      </div>
                                      {room?.listing?._id === DISSCUSSION_LISTING_ID ? <div className="user-last-chat">

                                        <span>General Disscussion</span>

                                      </div>
                                        : <div className="user-last-chat">
                                          <span
                                            className={`text-primary ${room.unreadMsgCnt > 0
                                                ? "font-weight-bolder"
                                                : ""
                                              }`}
                                          >
                                            <span
                                              style={{
                                                textOverflow: "ellipsis",
                                                width: "120px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                              }}
                                            >
                                              {room.listing?.partName}
                                            </span>{" "}
                                            - AED{" "}
                                            {numberWithCommas(
                                              room.listing?.price
                                            )}{" "}
                                          </span>
                                        </div>}
                                    </div>
                                    <div>
                                      {room.lastTime && (
                                        <div className="last-chat-time block">
                                          {getChatTime(
                                            moment(room.lastTime).tz(
                                              "Asia/Dubai"
                                            )
                                          )}
                                        </div>
                                      )}
                                      {room.unreadMsgCnt !== 0 && (
                                        <div className="badge badge-primary badge-pill">
                                          UNREAD
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </a>
                              );
                            })}
                        </div>
                      ) : (
                        <div style={{ textAlign: "center", paddingTop: 30 }}>
                          {/* <img
                            src="./assets/img/nodata.png"
                            alt="no_room"
                            className="noroom"
                          /> */}
                          <Alert severity="info">
                            <Typography color="error" gutterBottom>
                              <strong>No Chatrooms to Display !</strong>
                            </Typography>
                          </Alert>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End Chat Room List */}

                  {/* Begin Conversation Content */}
                  <div className="chat-cont-right">
                    {/* chat header */}
                    <div className="chat-header">
                      <a
                        id="back_user_list"
                        href="#!"
                        className="back-user-list"
                      >
                        <i
                          className="las la-angle-left"
                          onClick={this.handleBackUserList}
                        ></i>
                      </a>
                      {activeRoom && (
                        <div className="media">
                          <div className="media-img-wrap">
                            <div
                              className={`avatar ${onlineUsers && onlineUsers[activeRoom.userId]
                                  ? "avatar-online"
                                  : "avatar-away"
                                }`}
                            >
                              {this.props.login?.role.toLowerCase() ===
                                "buyer" ? (
                                // <img
                                //   src={"/assets/img/logo-icon.png"}
                                //   alt="buyparts logo"
                                //   className="avatar-img rounded-circle"
                                //   />

                                <Avatar
                                  alt="buyparts"
                                  src="/assets/img/favicon-buyparts24.svg"
                                />
                              ) : (
                                <UserAvatar
                                  avatar={activeRoom?.userAvatar}
                                  alt="UserImage"
                                  className="avatar-img rounded-circle" />
                              )}
                            </div>
                          </div>
                          <div className="media-body">
                            <div className="user-name">
                              {this.props.login?.role.toLowerCase() === "buyer"
                                ? "BuyParts24 Admin"
                                : activeRoom.userName}
                            </div>
                            <div className="user-status">
                              {onlineUsers && onlineUsers[activeRoom.userId]
                                ? "Online"
                                : "Away"}
                            </div>
                          </div>
                        </div>
                      )}
                      {activeRoom && (
                        <div className="chat-options">
                          {/* {
                            activeRoom.userPhone && (
                              <a
                                href={`tel:+${activeRoom.userPhone}`}
                                className="phone-icon"
                                onClick={(e) => noAction(e)}
                              >
                                <i className="la la-phone"></i>
                              </a>
                            )
                          } */}
                          {activeRoom?.listing?._id === DISSCUSSION_LISTING_ID ? <p>General Disscussion</p> : <NavLink
                            to={`/spare-part-details/${link}/${activeRoom?.listing?._id}`}
                            className="btn btn-primary go-listing"
                          >
                            {activeRoom?.listing?.partName} - {" AED "}
                            {numberWithCommas(activeRoom?.listing?.price)}
                          </NavLink>}

                          {this.props?.location?.state?.productId ==
                            activeRoom?.listing?._id ? (
                            <NavLink
                              to={`/orders/view/${this.state?.orderID}`}
                              className={
                                this.state?.orderID === ""
                                  ? "d-none"
                                  : "ml-4 btn btn-primary go-listing"
                              }
                            >
                              <span className="ml-2">
                                {this.state?.orderID === "" ? (
                                  ""
                                ) : (
                                  <span className="orderid-ref">
                                    #
                                    {this.state?.orderID
                                      .slice(0, 8)
                                      .toUpperCase()}
                                  </span>
                                )}
                              </span>
                            </NavLink>
                          ) : null}
                          {/* <NavLink
                            to={`/spare-part-details/${activeRoom?.listing?.partName}`}
                            className="btn btn-primary go-listing--responsive"
                          >
                            <i className="la la-car"></i>
                          </NavLink> */}
                        </div>
                      )}
                    </div>
                    {/* end of chat header */}

                    {/* chat body */}
                    <div className="chat-body">
                      {messages && messages.length > 0 ? (
                        <RSC
                          className="chat-scroll"
                          style={{ height: "calc(80vh - 210px)" }}
                          ref={(el) => {
                            this.messagesEnd = el;
                          }}
                        >
                          {activeRoom && (
                            <ul className="list-unstyled">
                              {messages &&
                                messages.length > 0 &&
                                messages.map((conv, index) => {
                                  const isSent =
                                    conv.sender === this.props.login._id
                                      ? true
                                      : false;
                                  return (
                                    <li
                                      className={`media ${isSent ? "sent" : "received"
                                        }`}
                                      key={index}
                                    >
                                      {isSent === false && (
                                        <div className="avatar">
                                          <img
                                            src={
                                              activeRoom.userAvatar
                                                ? `${activeRoom.userAvatar}`
                                                : "/assets/img/avatar.png"
                                            }
                                            alt="UserImage"
                                            className="avatar-img rounded-circle"
                                          />
                                        </div>
                                      )}
                                      <div className="media-body">
                                        <div className="msg-box">
                                          <div>
                                            <p>{conv.message}</p>
                                            <ul className="chat-msg-info">
                                              <li>
                                                <div className="chat-time">
                                                  <span>
                                                    {getChatTime(
                                                      moment(conv.createdAt).tz(
                                                        "Asia/Dubai"
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              {/* {typingUsers.find(o => o.sender === activeRoom.userId && o.listing_id === activeRoom.listing._id) && (
                              <li className="media received">
                                <div className="avatar">
                                  <img
                                    src={`${activeRoom.userAvatar}`}
                                    alt="User Image"
                                    className="avatar-img rounded-circle"
                                  />
                                </div>
                                <div className="media-body">
                                  <div className="msg-box">
                                    <div>
                                      <div className="msg-typing">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )} */}
                            </ul>
                          )}
                        </RSC>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#fff",
                            textAlign: "center",
                            height: "60vh",
                          }}
                        >
                          <img
                            src="./assets/img/nodata.png"
                            alt="empty_message"
                            className="emptyMessage"
                            style={{ height: "250px", width: "auto" }}
                          />
                        </div>
                      )}
                    </div>
                    {/**end of chat body */}

                    {/* chat footer */}
                    <div className="chat-footer">
                      {activeRoom && (
                        <form onSubmit={this.sendMessage}>
                          <div className="input-group">
                            {/* <div className="input-group-prepend">
                              <div className="btn-file btn">
                                <i className="las la-paperclip"></i>
                                <input type="file" />
                              </div>
                            </div> */}
                            <input
                              type="text"
                              name="message"
                              className="input-msg-send form-control"
                              placeholder="Type something"
                              value={message || ""}
                              onChange={this.handleInputMessage}
                            />
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn msg-send-btn"
                                onClick={this.sendMessage}
                              >
                                <i className="fab fa-telegram-plane"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                    {/* end of chat footer */}
                  </div>
                  {/* End Conversation Content */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    chat: state.chat,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    updateChatRooms: (data) => dispatch(UpdateRooms(data)),
    updateActiveChatRoom: (data) => dispatch(UpdateActiveRoom(data)),
    addNewMessage: (data) => dispatch(AddMessage(data)),
    getRoomConversations: (roomId) => dispatch(GetConversations(roomId)),
    chatRoomInitialize: () => dispatch(Initialize()),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(Chats);