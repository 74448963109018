import React, { useState, useEffect } from "react";
import PreHeader from "../../../layout/pre-header/index";
import Header from "../../../layout/header";
import { PageBanner } from "../page-banner";
import Footer from "../../../layout/footer/index";
import moment from "moment-timezone";
import MaterialTable from "material-table";

import axios from "axios";
import { NavLink } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import NoData from "../Nodata";
import Loader from "../Loader";
import { popUp } from "../../../../utils";

import { Button, Tooltip } from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CssTextField from "../SearchField";
import PageTitle from "../PageTitle";
import Pending from "../UserStatus/Pending";

const SellerDeliveries = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchField, setSearchField] = useState("");

  async function fetchMyAPI() {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    setLoader(true);
    try {
      let response = await axios.get(
        `api/order/get-seller-order?user_id=${currentUser?._id}`
      );
      setDeliveries(response?.data?.orders);
    } catch (err) {
      popUp("There was a problem with server. Please try again.");
    }

    setLoader(false);
  }

  useEffect(() => {
    fetchMyAPI();
  }, []);

  const getItems = (order) => {
    {
      order?.listings?.map((listing, listingIndex) => {
        if (listingIndex < 1)
          return (
            <>
              <div
                className="listing-item d-inline-block p-1 border rounded-circle"
                key={listingIndex}
                style={{ width: 40, height: 40 }}
              >
                <img
                  src={
                    listing?.imageArray?.[0] ||
                    "/assets/img/product-default.jpg"
                  }
                  alt={listing?.partName}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </div>
              {order?.listings?.length > 1 ? (
                <span
                  className="badge badge-light rounded-circle border p-1"
                  key={listingIndex}
                  style={{
                    width: 40,
                    height: 40,
                    lineHeight: "30px",
                    textAlign: "center",
                  }}
                >
                  +{order?.listings?.length}
                </span>
              ) : (
                <></>
              )}
            </>
          );
      });
    }
  };

  const getStatus = (status) => {
    if (status == "Preparing") {
      return "preparing";
    }
    if (status == "PENDING") {
      return "pending";
    }
    if (status == "cancelled") {
      return "cancelled";
    }
    if (status == "Out for Delivery") {
      return "Out for Delivery";
    }
    if (status == "issued") {
      return "issued";
    }
    if (status == "delivered") {
      return "delivered";
    }
  };
  const getPriceComma = (str) => {
    return str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = [
    {
      title: <span className="tryit">Order Id</span>,
      field: "",
      render: (deliveries) => deliveries?._id.slice(0, 8).toUpperCase(),
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Order Date</span>,
      field: "",
      render: (deliveries) =>
        moment(deliveries?.order_date).tz("Asia/Dubai").format("DD/MM/YYYY"),
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Order By</span>,
      field: "user.name",
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Item</span>,
      field: "",
      render: (deliveries) => getItems(deliveries),
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Pick up or Delivery</span>,
      field: "",
      render: (deliveries) =>
        deliveries?.delivery_option === 0 ? "Pick up" : "Delivery",
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },

    {
      title: <span className="tryit">Cost</span>,
      field: "total_price",
      render: (order) => `AED ${getPriceComma(Math.round(order?.total_price?.toFixed(2)))} `,
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    // {
    //   title: <span className="tryit">Status</span>,
    //   field: "",
    //   headerStyle: {
    //     backgroundColor: "#1a0451",
    //     color: "#fff",
    //     fontSize: "14px",
    //     fontWeight: 800,
    //     fontFamily: "Montserrat",
    //   },
    //   render: (deliveries) => (
    //     <div className={`${getStatus(deliveries?.status)}-order-status-one`}>
    //       {getStatus(deliveries?.status)}
    //     </div>
    //   ),
    // },
    {
      title: <span className="tryit">View</span>,
      field: "",
      render: (deliveries) => (
        // <Tooltip arrow={true} leaveDelay={1000}  placement="top-start" title="View Order">
        // <NavLink to={`/orders/view/${deliveries?._id}`}>
        //   <button type="button" className="btn btn-primary" style={{width:50,padding:1}}>
        //       <VisibilityOutlinedIcon style={{fontSize:22}}/>
        //   </button>
        //  </NavLink>
        // </Tooltip>
        <NavLink to={`/orders/view/${deliveries?._id}`}>
          <div className="primary-btn">View Order</div>
        </NavLink>
      ),
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
  ];

  const filteredOrders = deliveries?.filter((item) => {
    return item?.order_id?.toLowerCase().includes(searchField?.toLowerCase());
  });

  if (loader) return <Loader header="true" />;
  const currentUser = JSON.parse(localStorage.getItem("login"));

  if (currentUser && currentUser.status === "Pending") return <Pending />;

  if (!deliveries.length)
    return (
      <NoData
        text="You have not made any sales and don't have any deliveries yet. They will show here as soon as you make your first sale."
        header={true}
      />
    );

  return (
    <>

      <PageBanner />
      <section className="mt-2  mb-5">
        <div className="container">
          <PageTitle title="My Deliveries" />
          <div className="dashboard-search-box">
            <div className="ml-auto">
              <CssTextField
                id="standard-basic"
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                label="Search by ID"
              />
            </div>
            <div>
              <i className="las la-search la-search search-icon-datatable"></i>
            </div>
          </div>

          <MaterialTable
            title={null}
            style={{ zIndex: 0 }}
            data={filteredOrders}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <img
                    src="/assets/img/nodata.png"
                    className="datatableimage"
                  />
                ),
              },
            }}
            columns={columns}
            isLoading={loader}
            options={{
              search: false,
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#f2f2f2" };
                }
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [20, 50, 100],
            }}
          />
        </div>
      </section>
    </>
  );
};

export default SellerDeliveries;
