import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import AdvSearch from "../../content/element/advance-search";
import AdvSearchVIN from "../../content/element/advance-search-vin";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import { LogOut } from "../../../Store/action/logoutAction";
import { LogInAc } from "../../../Store/action/loginActions";
import NewNavbar from "../navbar/newNavbar";
import ConfirmModalLogout from "../../content/element/modal/logout";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import IconLabelButtons from "./style";

import NotificationModal from "../../content/element/modal/NotificationModal";
import { UserAvatar } from "../../../utils";
import LoginModal from "../../content/Login/LoginModal";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategory: "Transmission Parts",
      displayOthersCategoryDiv: false,
      displayAllCategoryDiv: false,
      showModal: false,
      passevent: "",
      unreadCount: [],
      anchorlEl: null,
      isModalOpen: false,
    };
    this.authorArea = React.createRef();
    this.handleCategoryHover = this.handleCategoryHover.bind(this);
    this.handleDisplayOthersCategoryDiv =
      this.handleDisplayOthersCategoryDiv.bind(this);
    this.handleDisplayAllCategoryDiv =
      this.handleDisplayAllCategoryDiv.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.toggle = this.toggle.bind(this);
    
  }

  componentDidMount() {
    this.setState({
      unreadCount: this.props?.chat?.rooms.filter(
        (room) => room.unreadMsgCnt == 1
      ),
    });
  }


	handleClick = () => {
		this?.authorArea?.current?.classList?.toggle("active");
	};

  handleCategoryHover(e, category) {
    e.preventDefault();
    this.setState({ activeCategory: category });
  }

  handleReset(e) {
    e.preventDefault();
    this.setState({
      displayOthersCategoryDiv: false,
      displayAllCategoryDiv: false,
    });
  }

  handleDisplayOthersCategoryDiv(
    e,
    bool,
    categoryValue = "Transmission Parts"
  ) {
    e.preventDefault();
    this.setState({
      displayOthersCategoryDiv: bool,
      displayAllCategoryDiv: false,
    });
    this.setState({ activeCategory: categoryValue });
  }

  handleDisplayAllCategoryDiv(e, bool, categoryValue = "Transmission Parts") {
    e.preventDefault();
    this.setState({
      displayOthersCategoryDiv: false,
      displayAllCategoryDiv: bool,
    });
    this.setState({ activeCategory: categoryValue });
  }

  handleCloseModal = () => {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }));
  };

  handleConfirm = (e) => {
    this.setState({
      showModal: true,
      passevent: e,
    });
  };
  handleCloseNotification = (e) => {
    this.setState({ anchorEl: null });
  };
  handleClickNotification = (event) => {
    if (this.state.anchorlEl == null) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.setState({ anchorEl: null });
    }
  };

  //toggle for login
  toggle = (e) => {
    e.preventDefault();
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  settingtActiveCategory = () => {
    this.setState({ activeCategory: "" });
  };

  handleSearchContainer = () => {
	this.setState({ openSelectSearchOption: !this.state.openSelectSearchOption });
	this.setState({ searchByParts: false });
	this.setState({ searchByVin: false });

}

  closeMenu = (route) => {
    // var oc_menu = $(".offcanvas-menu__contents");
    this.props.history.push(route);
    // $(".close-menu-modal").on("click", function (e) {
    // 	oc_menu.removeClass("active");
    // 	e.preventDefault();
    // });
  };
  render() {
    const currentUser = JSON.parse(localStorage.getItem("login"));

	const logOut = (e) => {
		e.preventDefault();
		this.props.logOutdata(null);
		this.setState({ showModal: false });
		window.location.href = "/";
	  };

		const { t } = this.props;
		return (
			<section className="mainmenu-wrapper">
				<div className="menu-area menu--light">
					<div className="top-menu-area">
						<div className="container-fluid px-0">
							<ConfirmModalLogout
								showModal={this.state.showModal}
								onClose={this.handleCloseModal}
								eventFunc={logOut}
								e={this.state.passevent}
							/>
							<div className="row " onMouseEnter={(e) => this.handleReset(e)}>
								<div className="col-lg-12 menu-content">
									<div className="container-fluid padding-nav" style={{ padding: 15 }}
										onMouseEnter={this.settingtActiveCategory}>
										<div className="menu-fullwidth d-flex justify-content-between align-item-center">
											<div className="logo-wrapper ">
												<div className="logo logo-top">
													<NavLink to="/">
														<img src="/assets/img/logo1.svg"
															alt="logoImage" width="180" />
													</NavLink>
												</div>
											</div>

											<div className="responsive-view" >
												<div className="d-flex justify-content-between align-item-center">
													<AdvSearch />
													<span className="mt-3" style={{ color: "#f39200" }}>
														OR
													</span>
													<AdvSearchVIN />
													<IconLabelButtons />

												</div>
											</div>

											{/* for mobile responsive view */}
											<div className="menu-right"
												style={{ gap: this.props.login === null ? 20 : 0 }}>
												<div style={{ color: "#fff", }}
													className="notification-view-icons">
													<NotificationModal />
												</div>

												<div className="search-view" >
													<div className=" d-inline-flex">
														<div style={{ color: "#fff", marginLeft: "15px" }}>
															<NotificationModal />
														</div>
														<div style={{ color: "#fff" }} className="search-container">
															<SearchIcon
																style={{ fontSize: 30, cursor: 'pointer', top: 0 }}
																onClick={this.handleSearchContainer}
															/>
														</div>
														{/* <div style={{ color: "#fff" }}
														className="ml-2 search-container">
														<SearchAction />
													</div> */}
													</div>
												</div>
												{this.props.login === null && (
													<div className="author-area" ref={this.authorArea} onClick={this.handleClick} style={{ top: 5 }}>
														<div className="author__access_area">
															<ul className="d-flex list-unstyled align-items-center">
																<i className="las la-user"></i>
																<li style={{ marginLeft: "5px" }}>
																	<a
																		href=" "
																		className="access-link"
																		onClick={this.toggle}
																		// data-toggle="modal"
																		// data-target="#login_modal"
																	>
																		{t("login")}
																	</a>

																	<LoginModal
																		isModalOpen={this.state.isModalOpen}
																		toggle={this.toggle}
																	/>
																	<span style={{ color: "#fff" }}>or</span>
																	<NavLink
																		to="/register"
																		className="access-link"
																	>
																		{t("register")}
																	</NavLink>
																	</li>
															</ul>

														</div>
													</div>
												)}

												{this.props.login !== null && (
													<div className={"offcanvas-menu"}>
														<a
															href="#!"
															className="offcanvas-menu__user"
															style={{ marginLeft: 0 }}
														>
															{this.props?.chat?.rooms &&
																this.props?.chat?.rooms.map((i) => {
																	return i.unreadMsgCnt;
																}).length > 0 &&
																this.props?.chat?.rooms
																	.map((i) => {
																		return i.unreadMsgCnt;
																	})
																	?.reduce(
																		(previousScore = 0, currentScore = 0) =>
																			previousScore + currentScore
																	) > 0 && (
																	<span className="badge unread-msg">
																		{this.props?.chat?.rooms
																			?.map((i) => {
																				return i.unreadMsgCnt;
																			})
																			?.reduce(
																				(previousScore = 0, currentScore = 0) =>
																					previousScore + currentScore
																			)}
																	</span>
																)}
															<i className="la la-bars"></i>
															<UserAvatar
																avatar={this.props.login.avatar}
																alt="AvatarImage"
																className="imageAvatar"
																width="50"
																height="50"
															/>
														</a>
														{this.props.login.role == "BUYER" &&
															((this.props.list.itemsInCart &&
															this.props.list.itemsInCart.length > 0) ||
															this.props.list.bulkCartListCount !==0 ) && (
																<NavLink
																	to="/checkout"
																	className="offcanvas-menu__cart"
																>
																	<img
																		src="/assets/img/cart-icon.svg"
																		alt="Cart"
																	/>{" "}
																	{this.props.list.itemsInCart.length || this.props.list.bulkCartListCount}
																</NavLink>
															)}
														<div className="offcanvas-menu__contents">
															<a href=" " className="offcanvas-menu__close">
																<i className="la la-times-circle"></i>
															</a>
															<div className="author-avatar">
																<UserAvatar
																	avatar={this.props.login.avatar}
																	alt="AvatarImage"
																	className="imageAvatar"
																	style={{
																		objectFit: "cover",
																		objectPosition: "center",
																		height: 150,
																		width: 150,
																		borderRadius: "100%",
																	}}
																/>

																<span className="d-inline-block w-100 font-weight-bold mb-1">
																	<i className="la la-map-marker"></i>{" "}
																	{this.props.login &&
																		this.props.login?.details?.emirate}
																</span>
																{this.props.login.role === "SELLER" && (
																	<p className="font-weight-bold">
																		{this.props.login.listing_count} spare parts
																	</p>
																)}
															</div>
															{this.props.login.role === "SELLER" && (
																<ul className="list-unstyled">
																	<li className="group-title">Your Account</li>
																	<li className="close-menu-modal" >
																		<NavLink to="" onClick={() => this.closeMenu("/my-dashboard")}>
																			Dashboard
																		</NavLink>
																	</li>
																	<li className="close-menu-modal">
																		<NavLink to="/my-profile" onClick={() => this.closeMenu("/my-profile")}>Profile</NavLink>
																	</li>
																	<li className="close-menu-modal">
																		<NavLink to="/my-store" onClick={() => this.closeMenu("/my-store")}>My Store</NavLink>
																	</li>

																	<li className="close-menu-modal">
																		<NavLink to="/faqs" onClick={() => this.closeMenu("/faqs")}>Help</NavLink>
																	</li>
																	<li className="group-title">
																		Your Inventory
																	</li>
																	<li className="close-menu-modal">
																		<NavLink to="/my-listings" onClick={() => this.closeMenu("/my-listings")}>
																			Listings
																		</NavLink>
																	</li>
																	<li className="close-menu-modal">
																		<NavLink to="/sell-your-parts" onClick={() => this.closeMenu("/sell-your-parts")}>
																			Add New
																		</NavLink>
																	</li>
																	<li className="close-menu-modal">
																		<NavLink to="/messages" onClick={() => this.closeMenu("/messages")}>
																			Discussion
																			{this.props?.chat?.rooms &&
																				this.props?.chat?.rooms.map((i) => {
																					return i.unreadMsgCnt;
																				}).length > 0 &&
																				this.props?.chat?.rooms
																					.map((i) => {
																						return i.unreadMsgCnt;
																					})
																					?.reduce(
																						(
																							previousScore = 0,
																							currentScore = 0
																						) => previousScore + currentScore
																					) > 0 && (
																					<span className="badge unread-msg">
																						{this.props?.chat?.rooms
																							?.map((i) => {
																								return i.unreadMsgCnt;
																							})
																							?.reduce(
																								(
																									previousScore = 0,
																									currentScore = 0
																								) =>
																									previousScore + currentScore
																							)}
																					</span>
																				)}
																		</NavLink>
																	</li>
																	<li className="group-title">Your Orders</li>
																	<li className="close-menu-modal">
																		<NavLink to="/my-order" onClick={() => this.closeMenu("/my-order")}>Orders</NavLink>
																	</li>
																	<li className="close-menu-modal">
																		<NavLink to="/payments" onClick={() => this.closeMenu("/payments")}>Payments</NavLink>
																	</li>
																	<li className="log-out">
																		<NavLink to="#">
																			<div onClick={logOut}>
																				<i className="la la-power-off mr-2"></i>
																				{t("menu_signout")}
																			</div>
																		</NavLink>
																	</li>
																</ul>
															)}

                              {this.props.login.role === "BUYER" && (
                                <ul className="list-unstyled">
                                  <li className="group-title">Your Account</li>
                                  <li className="close-menu-modal">
                                    <NavLink
                                      to="/my-dashboard"
                                      onClick={() =>
                                        this.closeMenu("/my-dashboard")
                                      }
                                    >
                                      Dashboard
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="/my-profile">Profile</NavLink>
                                  </li>
                                  <li className="close-menu-modal">
                                    <NavLink
                                      to="/credits"
                                      onClick={() => this.closeMenu("/credits")}
                                    >
                                      Your Credits
                                    </NavLink>
                                  </li>
                                  <li className="close-menu-modal">
                                    <NavLink
                                      to="/faqs"
                                      onClick={() => this.closeMenu("/faqs")}
                                    >
                                      Help
                                    </NavLink>
                                  </li>
                                  <li className="group-title">Your Messages</li>
                                  <li className="close-menu-modal">
                                    <NavLink
                                      to="/messages"
                                      onClick={() =>
                                        this.closeMenu("/messages")
                                      }
                                    >
                                      Discussion
                                      {this.props?.chat?.rooms &&
                                        this.props?.chat?.rooms.map((i) => {
                                          return i.unreadMsgCnt;
                                        }).length > 0 &&
                                        this.props?.chat?.rooms
                                          .map((i) => {
                                            return i.unreadMsgCnt;
                                          })
                                          ?.reduce(
                                            (
                                              previousScore = 0,
                                              currentScore = 0
                                            ) => previousScore + currentScore
                                          ) > 0 && (
                                          <span className="badge unread-msg">
                                            {this.props?.chat?.rooms
                                              ?.map((i) => {
                                                return i.unreadMsgCnt;
                                              })
                                              ?.reduce(
                                                (
                                                  previousScore = 0,
                                                  currentScore = 0
                                                ) =>
                                                  previousScore + currentScore
                                              )}
                                          </span>
                                        )}
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="/quotations">
                                      Quotations
                                    </NavLink>
                                  </li>
                                  <li className="group-title">Your Orders</li>
                                  <li className="close-menu-modal">
                                    <NavLink
                                      to="/my-order"
                                      onClick={() =>
                                        this.closeMenu("/my-order")
                                      }
                                    >
                                      Orders
                                    </NavLink>
                                  </li>
                                  <li className="close-menu-modal">
                                    <NavLink
                                      to="/your-address"
                                      onClick={() =>
                                        this.closeMenu("/your-address")
                                      }
                                    >
                                      Addresses
                                    </NavLink>
                                  </li>
                                  <li className="close-menu-modal">
                                    <NavLink
                                      to="/my-request"
                                      onClick={() =>
                                        this.closeMenu("/my-request")
                                      }
                                    >
                                      MyRequest
                                    </NavLink>
                                  </li>

																	<li className="log-out">
																		<NavLink to="#">
																			<div onClick={logOut}>
																				<i className="la la-power-off mr-2"></i>
																				{t("menu_signout")}
																			</div>
																		</NavLink>
																	</li>
																</ul>
															)}
														</div>
														{/*<!-- ends: .author-info -->*/}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className={`search-option-container ${this.state.openSelectSearchOption && "active-option"}  `}>
								<div className={`${this.state.searchByParts && "active-selected"}`}
									onClick={() => {
										this.setState({ searchByParts: !this.state.searchByParts })
										this.setState({ searchByVin: false })
									}}> Search by spare part</div>

								<div
									className={`search-by-vin  ${this.state.searchByVin && "active-selected"}`}
									onClick={() => {
										this.setState({ searchByVin: !this.state.searchByVin })
										this.setState({ searchByParts: false })
									}}
								>Search by vin</div>

								<div
									onClick={() => {
										this.props.history.push("/tires-batteries")
										this.setState({ searchByVin: false })
										this.setState({ searchByParts: false })
										this.setState({ openSelectSearchOption: false })

									}}
								>Tires & Batteries</div>
							</div>

							<div className="search-container-mobile">
								<div className={`mobile-search-by-parts ${this.state.searchByParts && "active-search-by-parts"}`}>
									<AdvSearch />
								</div>
								<div className={`mobile-search-by-vin ${this.state.searchByVin && "active-search-by-vin"}`}>
									<AdvSearchVIN />
								</div>
							</div>

							<NewNavbar
								{...this.state}
								handleDisplayOthersCategoryDiv={
									this.handleDisplayOthersCategoryDiv
								}
								handleDisplayAllCategoryDiv={this.handleDisplayAllCategoryDiv}
								handleReset={this.handleReset}
								handleCategoryHover={this.handleCategoryHover}
								settingtActiveCategory={this.settingtActiveCategory}
							/>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		login: state.login,
		chat: state.chat,
		list: state.list,
		cart: state.cart,
	};
};
const mapDispatchToProp = (dispatch) => {
  return {
    logOutdata: (login) => dispatch(LogOut(login)),
    loginAction: (login) => dispatch(LogInAc(login)),
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(withRouter(Header));
