import React from "react";

const SavingContent = () => {
  return (
    <div className="benefits-buyer-content mt-4">
      <ul>
        <li>Reduced procurement lead times</li>
        <li>No lost revenues due to product unavailability</li>
        <li>Revenue growth through Business Intelligence</li>
      </ul>
    </div>
  );
};

export default SavingContent;
