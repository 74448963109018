import React from "react";

const AccessContent = () => {
  return (
    <div className="benefits-seller-content mt-4">
      <ul>
        <li>Increased market access</li>
        <li>Revenue uplift</li>
        <li>Increased customer outreach</li>
        <li>Additional distribution channel</li>
      </ul>
    </div>
  );
};

export default AccessContent;
