import React, { useState } from "react";
import Header from "../../layout/header";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import data from "./data.json";
import "./index.css";

const FAQ = () => {
  let indexPlus;

  const [active, setActive] = useState(0);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(index);
  };

  const indexCount = (index) => {
    indexPlus = index + 1;
    return indexPlus;
  };
  return (
    <>


      <div
        className="breadcrumb-wrapper cover"
        style={{
          backgroundImage: 'url("/assets/img/section-banner.jpg")',
          height: 200,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="inner-container">
          <div style={{ minWidth: "1024px" }}>
            <h1>FAQs</h1>
          </div>
        </div>
      </div>
      <div className="content-wrapper mt-5">
        <div className="inner-container">
          <div>
            <div className="pending-content">
              We are currently building this page
            </div>
            <div className="pending-content-meta">Come back soon.</div>
            <div className="pending-image-container mt-5">
              <img src="/assets/img/pending.png" alt="pending-image" />
            </div>
          </div>
          {/* <h1>Your questions answered</h1>
          <div className="faq-list-wrapper">
            <form>
              {data.map((tab, index) => (
                <div key={index} className="faq-list">
                  <h3>
                    <button
                      onClick={(e) => eventHandler(e, index)}
                      className={active === index ? "active" : "inactive"}
                      aria-expanded={active === index ? "true" : "false"}
                      aria-controls={"sect-" + indexCount(index)}
                      aria-disabled={active === index ? "true" : "false"}
                      tabIndex={indexCount(index)}
                    >
                      <span
                        className={active === index ? "plus" : "minus"}
                      ></span>
                      <span className="title-wrapper"> {tab.title} </span>
                    </button>
                  </h3>
                  <div
                    id={"sect-" + indexCount(index)}
                    className={active === index ? "panel-open" : "panel-close"}
                  >
                    {tab.description}
                  </div>
                </div>
              ))}
            </form>
          </div> */}
        </div>
      </div>


    </>
  );
};

export default FAQ;
