import React, { useEffect } from "react";
import { Row, Col, Button } from "reactstrap";

const Index = ({
  data,
  getShippingAddress,
  selectedAddress,
  setIsOpenEditModal,
  setEditedAddressId,
}) => {
  const [selected, setSelected] = React.useState(false);
  const handleEdit = (id) => {
    setIsOpenEditModal(true);
    setEditedAddressId(id);
  };
  return (
    <>
      <Col
        sm={12}
        md={6}
        lg={6}
        style={{
          //   padding: "5px",
          //  marginBottom: 60,
          //  backgroundColor: "#F6F7FC",
          //   marginLeft: '10px',
          position: "relative",
          cursor: "pointer",

          //   border: "1px solid black",
        }}
        className={`card-address-main`}
        onClick={() => getShippingAddress(data)}
      >
        {/* <img src="/assets/img/tick.png"  className={` ${data.default_address ? 'checkedactive' : 'checkedhide'} address-success-icon`}/> */}
        <Row
          style={{
            margin: "2px",
            marginBottom: "0px",
            paddingTop: "10px",
            backgroundColor: "#f6f7fc",
            borderRadius: "5px",
            position: "relative",
            minHeight: "120px",
          }}
          className={`${
            selectedAddress?._id === data?._id && "selected-address-card"
          }`}
        >
          <>
            <Col sm="2">
              <img
                src="/assets/img/avatar.png"
                alt="avatar"
                style={{ width: "60px" }}
              />
            </Col>

            <Col sm="10">
              <h6 style={{ marginLeft: "30px", marginTop: "0px" }}>
                {data?.first_name} {data?.last_name}
              </h6>
              <p style={{ marginLeft: "30px" }}>
                {data?.postcode} {data?.suburb} {data?.address} {data?.state}
                <br /> {data?.contact}
              </p>
            </Col>
          </>

          <hr className="address-hr" />
          <div className="user-details-footer" style={{ marginLeft: "auto" }}>
            <Button
              size="sm"
              color="#FFF"
              onClick={() => {
                handleEdit(data?._id);
              }}
              className="btn-address"
            >
              Edit
            </Button>
          </div>

          <div
            color="primary"
            className={data.default_address ? "address-true" : "d-none"}
          >
            <p style={{ color: "#fff" }}>Default</p>
          </div>
          {/* // className={`address-checkbox ${display?.display==index && "displayed"}`}  */}
        </Row>
      </Col>
    </>
  );
};

export default Index;
