import React from "react";

const index = ({ active, setActive }) => {
  return (
    <div className="benefits-buyer-container">
      <div
        className="benefits-buyer-image"
        onClick={() => setActive("DELIVERY")}
      >
        <img
          src="/assets/img/become-buyer/delivery.png"
          alt="buyparts-delivery"
        />
      </div>

      <div className="benefits-buyer-text">Delivery</div>
      {active === "DELIVERY" && <div className="benefits-seller-triangle" />}
    </div>
  );
};

export default index;
