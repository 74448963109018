import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone";

const index = ({ data }) => {
  return (
    <>
      <div className="atbd_content_module atbd_review_module">
        <div
          className="atbdb_content_module_contents "
          style={{ padding: "0rem" }}
        >
          {data.map((data, index) => {
            return (
              <div id="client_review_list" key={index}>
                <div className="atbd_single_review atbdp_static">
                  <div className="atbd_review_top">
                    <div className="atbd_avatar_wrapper">
                      <div className="atbd_review_avatar">
                        <img
                          alt=""
                          src="./assets/img/review-author-thumb.jpg"
                          className="avatar avatar-32 photo"
                        />
                      </div>
                      <div className="atbd_name_time">
                        <p>{data.user.name}</p>
                        <span className="review_time">
                          {moment(data.createdAt).tz('Asia/Dubai').fromNow(true)}
                        </span>
                      </div>
                    </div>
                    <div className="atbd_rated_stars">
                      <ul>
                        {Array(data.star)
                          .fill("_")
                          .map((_, i) => (
                            <li key={i}>
                              <span className="rate_full_star"></span>
                            </li>
                          ))}
                        {data.star < 5 ? (
                          <>
                            {Array(5 - data.star)
                              .fill("_")
                              .map(() => (
                                <li>
                                  <span
                                    className="rate_active"
                                    style={{ fontSize: "19px" }}
                                  ></span>
                                </li>
                              ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="review_content">
                    <p>{data.comment}</p>
                  </div>
                </div>
              </div>
            );
          })}
          {/* 
          <div className="review_pagination">
            <ul className="pagination">
              <li className="page-item">
                <NavLink className="page-link" to="/at_demo">
                  <span className="la la-angle-left"></span>
                </NavLink>
              </li>
              <li className="page-item">
                <NavLink className="page-link" to="/at_demo">
                  1
                </NavLink>
              </li>
              <li className="page-item active">
                <NavLink className="page-link" to="/at_demo">
                  2
                </NavLink>
              </li>
              <li className="page-item">
                <NavLink className="page-link" to="/at_demo">
                  3
                </NavLink>
              </li>
              <li className="page-item">
                <NavLink className="page-link" to="/at_demo">
                  <span className="la la-angle-right"></span>
                </NavLink>
              </li>
            </ul>
          </div>
       */}
        </div>
      </div>
    </>
  );
};

export default index;
