import React from "react";
import PageTitle from "../../content/element/PageTitle";
import { Tooltip } from "@material-ui/core";
export default ({ step }) => (
  <div className="cart-top-head container" style={{ textAlign: "left" }}>
    {(step === 1 && (
      // <h1 className="cart-top-head-title">YOUR CART</h1>
      <PageTitle title="Your Cart" />
    )) ||
      (step === 2 && (
        // <h1 className="cart-top-head-title">SHIPPING OPTIONS</h1>
        <PageTitle title="Shipping Options" />
      )) ||
      (step === 3 && (
        // <h1 className="cart-top-head-title">YOUR ADDRESS</h1>
        <PageTitle title="Your Address" />
      )) ||
      (step === 4 && (
        // <h1 className="cart-top-head-title">YOUR PAYMENTS</h1>
        <PageTitle title="Your Payments"
         />
      )) ||
      (step === 5 && (
        // <h1 className="cart-top-head-title">ORDER CONFIRMATION</h1>
        <PageTitle title="Order Confirmation" />
      ))}
  </div>
);
