import React from "react";
import Header from "../../layout/header";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import { Link } from "react-router-dom";

const RefundPrivacy = () => {
  return (
    <>

      <div
        className="breadcrumb-wrapper cover innerpage-banner"
        style={{ backgroundImage: 'url("/assets/img/section-banner.jpg")' }}
      >
        <div className="container">
          <h1>RETURN & REFUND Policy</h1>
        </div>
      </div>
      <div
        className="content-wrapper privacy-policy-wrapper"
        style={{ paddingBottom: 0 }}
      >
        <div className="container">

          <div
            className="termsandconditions-content"
            style={{ paddingTop: 30 }}
          >
            <h3 style={{ color: 'red' }}>COVID-19 AMENDMENT - Return pickup service may not be available in various regions.</h3>
            <p style={{ textAlign: "justify" }}>
              BuyPart24 is a B2B marketplace, primarily dealing with automotive spare parts related products.
              A number of the products sold on the marketplace are non-returnable and non-refundable.
              You should read the return / refund policy applicable to the product you are purchasing at
              the time of placing your order. Our general refund and return policy is set out below.
              If you believe you are eligible to return your purchased products, please send an email
              to {" "}
              <Link to="https://www.helpdesk@buyparts24.com/">
                helpdesk@buyparts24.com
              </Link>
              {" "}
              with your order number and details about the product you wish
              to return. We will respond at the earliest with instructions for how to return items from
              your order.

            </p>
            <ul>
              <li>
                Return of your purchase and refund requests may be initiated within 3 days from the date of delivery of the relevant goods. All items must still be in new, unopened condition unless otherwise specified in this Policy.
              </li>
              <li>
                Some products due to their nature, are marked as "NON-RETURNABLE PRODUCT".  In such cases, the same will be displayed prominently on the product page. BuyParts24 will not accept return of these products irrespective of the reason for return. There are no returns on electrical parts, electrical test equipment and clearance items.
              </li>
              <li>
                If the product has been used, and/or is not in a re-saleable condition, the return may not be approved, unless there is a manufacturing defect.
              </li>
              <li>
                If the product is not functional due to a manufacturing defect, then the original box and all other items in the packaging, must be returned together with the product.

              </li>
              <li>Once you initiate the return and it is accepted by us, our logistics partner will pick the product from your specified location. We will also pay the return shipping costs if the return is a result of our error i.e. (you received an incorrect or defective product, etc.).</li>
              <li>
                For locations which are not supported for return pickups, the product has to be shipped back to us by the customer. You will be advised the same at the time of making your request.
              </li>
              <li>Please note that shipping charges paid for by you are not refundable.
              </li>
              <li>All our refunds are in the form of store credit notes which could be used for your future purchases; or bank transfer or credit card, deducting the shipping fees and other transaction-related charges for the return. You should expect to receive your refund within four weeks of giving your package to our logistic partner.
              </li>
              <li>
                You must carefully pack the product intact to avoid any damage in transit.
              </li>
              <li>
                The refund will only be approved and initiated once we receive the product back in re- saleable condition along with the original packaging.
              </li>
              <li>
                BuyParts24 shall reserve the right to unapproved a return of a product and/or a refund to the customer if the product returned does not meet any of its guidelines or conditions.

              </li>
              <li>
                If the product is not eligible to be returned, a Customer Service executive will contact you to determine your desired course of action.
              </li>
            </ul>
            <p>
              <b>CANCELLATION POLICY</b>
            </p>
            <p>
              You can choose to cancel your order any time 24 hours before your scheduled delivery. If the cancellation is needed after the dispatch of the order, you will need to initiate a return request.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPrivacy;
