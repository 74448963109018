import React from "react";

const ReviewListingCard = ({ imgSrc }) => {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center about-review-item"
        style={{ marginRight: 20 }}
      >
        <div className="review-listing-img">
          <img
            src={imgSrc || "/assets/img/product-default.jpg"}
            alt="review-listing"
            className="review-listing-img"
            style={{ marginTop: "5px" }}
          />
        </div>
        <div className="ml-3">
          <div className="text-left">
            <p className="testimonal-text">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet,
              cons ectetuer adipiscing elit,sed diam nonummy nibh
            </p>
          </div>
          <div className="mt-4 text-left">
            <strong
              style={{
                fontSize: "14px",
                fontWeight: "800",
                color: "rgb(26 4 81)",
              }}
            >
              Lorem, ipsum.
            </strong>
            <br />
            <span>
              <i
                style={{
                  color: "rgb(26 4 81)",
                  fontSize: "12px",
                }}
              >
                dolor sit amet,
              </i>
            </span>
            <br />
            <span>
              <i
                style={{
                  color: "rgb(26 4 81)",
                  fontSize: "12px",
                }}
              >
                consectetuer adipiscing
              </i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewListingCard;
