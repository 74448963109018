import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import { Button } from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { popUp } from "../../../../utils";
import Select from "react-select";

const Index = (props) => {
  const { isOpenEditModal, handleEditFormModal, editedAddressId, fetchAddress, stateData, locationData, regionData } = props;
  const [singleAddress, setSingleAddress] = useState({});
  const [firstName, setFirstName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [contact, setContact] = useState("");
  const [emirate, setEmirates] = useState({ label: "", value: "" });
  const [region, setRegion] = useState({ label: "", value: "" });
  const [location, setLocation] = useState({ label: "", value: "" });

  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [_, forceUpdate] = useState();


  async function fetchSingleAddress() {
    let response = await axios.get(
      `/api/address/edit-address/${editedAddressId}`
    );
    setSingleAddress(response?.data?.data);
  }
  useEffect(() => {
    if (editedAddressId) fetchSingleAddress();
  }, [editedAddressId]);

  useEffect(() => {
    if (singleAddress) {
      setFirstName(singleAddress.first_name ? singleAddress.first_name : "");
      setLastName(singleAddress.last_name ? singleAddress.last_name : "");
      setAddress(singleAddress.address ? singleAddress.address : "");
      setSuburb(singleAddress.suburb ? singleAddress.suburb : "");

      setPostCode(singleAddress.postcode ? singleAddress.postcode : "");
      setContact(singleAddress.contact ? singleAddress.contact : "");
      setEmirates(
        singleAddress.state
          ? { label: singleAddress.state, value: singleAddress.state }
          : { label: "", value: "" }
      );
      setRegion(
        singleAddress.region
          ? { label: singleAddress.region, value: singleAddress.region }
          : { label: "", value: "" }
      );
      setLocation(
        singleAddress.location
          ? { label: singleAddress.location, value: singleAddress.location }
          : { label: "", value: "" }
      );
    }
  }, [
    singleAddress,
    setFirstName,
    setLastName,
    setAddress,
    setSuburb,
    setEmirates,
    setLocation,
    setRegion,
    setPostCode,
  ]);



  const handleChangeState = (e) => {
    setEmirates({ label: e.value, value: e.value });
    setRegion({ label: "", value: "" });
    setLocation({ label: "", value: "" });
  };

  const handleChangeRegion = (e) => {
    setRegion({ label: e.value, value: e.value });
    setLocation({ label: "", value: "" });
  };

  const filteredRegions =
    regionData &&
    regionData.length &&
    regionData.filter((item) => {
      return item?.state?.name === emirate.value;
    });

  const stateOption =
    stateData?.state &&
    stateData.state.length &&
    stateData.state.map((state) => {
      return { label: state?.name, value: state?.name };
    });

  const regionOption =
    filteredRegions &&
    filteredRegions.length &&
    filteredRegions.map((region) => {
      return { label: region.name, value: region.name };
    });

  const filteredCities =
    locationData &&
    locationData.length &&
    locationData.filter((item) => {
      return item?.region?.name === region.value;
    });
  const sortedCities =
    filteredCities &&
    filteredCities.length > 0 &&
    filteredCities.sort((a, b) => a.name.localeCompare(b.name));

  const formattedCities =
    sortedCities &&
    sortedCities.length > 0 &&
    sortedCities.map((item) => {
      return { label: item?.name, value: item?.name };
    });
  const validator = React.useRef(
    new SimpleReactValidator({
      className: "text-danger",
      validators: {
        firstName: {
          message: "Name must be greater than 2 characters",
          rule: (val) => {
            return val.length >= 2;
          },
          required: true,
        },
        lastName: {
          message: "Name must be greater than 2 characters",
          rule: (val) => {
            return val.length >= 2;
          },
          required: true,
        },
      },
    })
  );

  const submitAddress = () => {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (!invalidPhoneNumber && validator.current.allValid()) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        state: emirate.value,
        region: region.value,
        location: location.value,
        postcode: postCode,
        contact: contact,
        user: currentUser?._id,
      };
      axios
        .put(`/api/address/edit-address/${editedAddressId}`, data)
        .then((res) => {
          if (res?.data) {
            popUp("Your address was added successfully.");
            fetchAddress();
            handleEditFormModal(false);
          }
        })
        .catch((err) => popUp("There was a problem with the server"));
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const handleBlur = async () => {
    const res = await axios.get(
      `http://apilayer.net/api/validate?access_key=${process.env.REACT_APP_NUM_VERIFY_API_KEY}&number=${contact}`
    );
    if (res?.data?.valid === false) {
      setInvalidPhoneNumber(true);
    } else {
      setInvalidPhoneNumber(false);
    }
  };

  useEffect(() => {
    if (!isOpenEditModal) {
      validator.current.hideMessages();
    }
  }, [isOpenEditModal]);
  return (
    <>
      <Modal
        isOpen={isOpenEditModal}
        toggle={() => handleEditFormModal(false)}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalHeader
          style={{ position: "relative", height: 50 }}
          toggle={() => handleEditFormModal(false)}
        >
          <div style={{ position: "absolute", right: 20 }}>Edit address</div>
        </ModalHeader>
        <ModalBody style={{ padding: "20px" }}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  required={true}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />

                {validator.current.message(
                  "firstName",
                  firstName,
                  "required|firstName"
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
                {validator.current.message(
                  "lastName",
                  lastName,
                  "required|lastName"
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="address">Address</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
                {validator.current.message("address", address, "required")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="emirate">Emirates</Label>


                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={stateOption ? stateOption : []}
                  value={emirate}
                  onChange={(e) => {
                    handleChangeState(e);
                  }}
                />

                {validator.current.message("emirate", emirate, "required")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="region">Regions</Label>

                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={regionOption ? regionOption : []}
                  value={region}
                  onChange={(e) => {
                    handleChangeRegion(e);
                  }}
                />

                {validator.current.message("region", region, "required")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="location">Location</Label>
                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={formattedCities ? formattedCities : []}
                  value={location}
                  onChange={(e) => {
                    setLocation({ label: e.value, value: e.value });
                  }}
                />
                {validator.current.message("location", location, "required")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="postCode">PO Box</Label>
                <Input
                  type="text"
                  name="postCode"
                  id="postCode"
                  placeholder="Post Code"
                  value={postCode}
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                />
                {validator.current.message("postCode", postCode, "alpha_num_space")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="contact">Contact No</Label>
                <PhoneInput
                  name="contact"
                  country={"ae"}
                  onlyCountries={["ae"]}
                  value={contact}
                  placeholder="+971 123456789"
                  onBlur={handleBlur}
                  onChange={(contact) => setContact(contact)}
                />

                {validator.current.message("contact", contact, "required")}
                {invalidPhoneNumber && (
                  <div className="text-danger">Invalid Contact Number.</div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => handleEditFormModal(false)}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={submitAddress}>
            Save
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default React.memo(Index);
