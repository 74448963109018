import * as yup from "yup";
import { string as YupString, ref as YupRef, object as YupObject } from "yup";

export const emailValidationSchema = () => {
  let schema = {
    email: yup.mixed().required("Email is required"),
  };
  return yup.object().shape(schema);
};

export const phoneValidationSchema = () => {
  let schema = {
    newPassword: YupString()
      .required("New Password is Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .mixed()
      .required("confirm password is required")
      .oneOf(
        [YupRef("newPassword")],
        "Confirm Password must be same as new PassWord"
      ),
  };
  return yup.object().shape(schema);
};

export const otpValidationSchema = () => {
  let schema = {
    otp: yup.mixed().required("OTP is required"),
  };
  return yup.object().shape(schema);
};
