import axios from "axios";

export const categories = [
  {
    img: "/assets/img/category/cat-batteries.jpg",
    value: "Batteries & Tires",
    _id: "Batteries & Tires",
    name: "Batteries & Tires",
  },
  {
    img: "/assets/img/category/cat-brakeparts.jpg",
    value: "Brake Parts",
    _id: "Brake Parts",
    name: "Brake Parts",
  },
  {
    img: "/assets/img/category/cat-engineparts.jpg",
    value: "Engine Parts",
    _id: "Engine Parts",
    name: "Engine Parts",
  },
  {
    img: "/assets/img/category/cat-fuelparts.jpg",
    value: "Fuel Parts",
    _id: "Fuel Parts",
    name: "Fuel Parts",
  },
  {
    img: "/assets/img/category/cat-interioraccessories.jpg",
    value: "Interior & Exterior Accessories",
    _id: "Interior & Exterior Accessories",
    name: "Interior & Exterior Accessories",
  },
  {
    img: "/assets/img/category/cat-lubricant.jpg",
    value: "Lubricants & Fluids",
    _id: "Lubricants & Fluids",
    name: "Lubricants & Fluids",
  },
  {
    img: "/assets/img/category/cat-suspensions.jpg",
    value: "Suspension Parts",
    _id: "Suspension Parts",
    name: "Suspension Parts",
  },
  {
    img: "/assets/img/category/cat-transmission.jpg",
    value: "Transmission Parts",
    _id: "Transmission Parts",
    name: "Transmission Parts",
  },
  {
    img: "/assets/img/category/cat-steering.jpg",
    value: "Steering Parts",
    _id: "Steering Parts",
    name: "Steering Parts",
  },
  {
    img: "/assets/img/category/cat-lighting.jpg",
    value: "Lighting",
    _id: "Lighting",
    name: "Lighting",
  },
  {
    img: "/assets/img/category/cat-exhaust.jpg",
    value: "Exhaust Systems",
    _id: "Exhaust Systems",
    name: "Exhaust Systems",
  },
  {
    img: "/assets/img/category/cat-hvac.jpg",
    value: "HVAC & Air System",
    _id: "HVAC & Air System",
    name: "HVAC & Air System",
  },
  {
    img: "/assets/img/category/cat-body.jpg",
    value: "Body & Chassis Parts",
    _id: "Body & Chassis Parts",
    name: "Body & Chassis Parts",
  },
  {
    img: "/assets/img/category/cat-electrical.jpg",
    value: "Electricals",
    _id: "Electricals",
    name: "Electricals",
  },
  {
    img: "/assets/img/category/cat-others.jpg",
    value: "Others",
    _id: "Others",
    name: "Others",
  },
  {
    img: "/assets/img/category/cat-service.jpg",
    value: "Service Parts",
    _id: "Service Parts",
    name: "Service Parts",
  },
];

const serviceParts = [
  { label: "Filters", value: "Filters" },
  { label: "Lubricants & Fluids", value: "Lubricants & Fluids" },
  { label: "Belt Drive", value: "Belt Drive" },
  { label: "Vacuum Pump", value: "Vacuum Pump" },
  { label: "Spark/Glow Ignition", value: "Spark/Glow Ignition" },
  { label: "Bearings/Accessories", value: "Bearings/Accessories" },
  { label: "Screws/ Nuts/Screw Joints", value: "Screws/ Nuts/Screw Joints" },
  { label: "Washers/Rings", value: "Washers/Rings" },
  {
    label: "Brake Parts - Brake Disc/Drum/Shoes/Pads",
    value: "Brake Parts - Brake Disc/Drum/Shoes/Pads",
  },
  {
    label: "Brake Parts - Brake Pads FR/RR",
    value: "Brake Parts - Brake Pads FR/RR",
  },
];
const engineParts = [
  { label: "Belt Drive", value: "Belt Drive" },
  {
    label: "Cylinder Head/Attachment Parts",
    value: "Cylinder Head/Attachment Parts",
  },
  { label: "Engine Timing", value: "Engine Timing" },
  { label: "Spark/Glow Ignition", value: "Spark/Glow Ignition" },
  { label: "Piston & Parts", value: "Piston & Parts" },
  {
    label: "Bearing/Gasket/Connecting Rods",
    value: "Bearing/Gasket/Connecting Rods",
  },
  { label: "Engine Parts - Others", value: "Engine Parts - Others" },
];
const fuelParts = [
  { label: "Carburettor System", value: "Carburettor System" },
  { label: "Fuel Parts - Others", value: "Fuel Parts - Others" },
  { label: "Fuel Pump", value: "Fuel Pump" },
  { label: "Fuel Tank/Accessories", value: "Fuel Tank/Accessories" },
  { label: "Hoses/ Lines", value: "Hoses/ Lines" },
  { label: "Injection System Parts", value: "Injection System Parts" },
  { label: "Flywheel", value: "Flywheel" },
];
const transmissionParts = [
  { label: "Clutch Cylinder", value: "Clutch Cylinder" },
  {
    label: "Transmission Parts - Others",
    value: "Transmission Parts - Others",
  },
  { label: "Clutch Cover/Disc", value: "Clutch Cover/Disc" },
  { label: "Bearings", value: "Bearings" },
];
const electricals = [
  { label: "Sensors & Actuators", value: "Sensors & Actuators" },
  { label: "Starter/Alternator", value: "Starter/Alternator" },
  { label: "Electric Motor", value: "Electric Motor" },
  { label: "Harness & Installation", value: "Harness & Installation" },
  { label: "Electricals - Others", value: "Electricals - Others" },
];
const suspensionParts = [
  { label: "Suspension Parts - Others", value: "Suspension Parts - Others" },
  { label: "Shock Absorbers", value: "Shock Absorbers" },
  { label: "Arm", value: "Arm" },
  { label: "Stabilizaer & Parts", value: "Stabilizaer & Parts" },
  { label: "Joints/Rods/Bushes", value: "Joints/Rods/Bushes" },
];
const steeringParts = [
  { label: "Steering Parts - Others", value: "Steering Parts - Others" },
  { label: "Steering Box/Column", value: "Steering Box/Column" },
  { label: "Steering Wheel", value: "Steering Wheel" },
  { label: "Steering Tie Rod", value: "Steering Tie Rod" },
];
const bodyParts = [
  { label: "Body & Chassis - Others", value: "Body & Chassis - Others" },
  { label: "Body Panels", value: "Body Panels" },
  { label: "Front Fairing/Grille", value: "Front Fairing/Grille" },
  {
    label: "Hatches/Bonnets/Doors/ Sunroof/Folding Roof",
    value: "Hatches/Bonnets/Doors/Sunroof/Folding Roof",
  },
  { label: "FR/RR Bumper parts", value: "FR/RR Bumper parts" },
  { label: "Driveshaft/Driveline", value: "Driveshaft/Driveline" },
];
const brakeParts = [
  { label: "Brake Parts - Others", value: "Brake Parts - Others" },
  { label: "Brake Hoses", value: "Brake Hoses" },
  { label: "Brake Lines", value: "Brake Lines" },
  {
    label: "Brake Master Cylinder Parts",
    value: "Brake Master Cylinder Parts",
  },
  { label: "Latches/Plugs/Fittings", value: "Latches/Plugs/Fittings" },
  { label: "Sensors", value: "Sensors" },
  { label: "Brake Hose", value: "Brake Hose" },
  { label: "Brake Pad Wear Sensors", value: "Brake Pad Wear Sensors" },
  { label: "Valves", value: "Valves" },
];
const batteriesParts = [
  { label: "Electricals - Others", value: "Electricals - Others" },
  { label: "Starter/Alternator", value: "Starter/Alternator" },
  { label: "Tires", value: "Tires" },
  { label: "Batteries & Components", value: "Batteries & Components" },
  { label: "Battery Chargers", value: "Battery Chargers" },
  { label: "Battery Maintenance", value: "Battery Maintenance" },
];
const interiorAccessories = [
  { label: "Accessories - General", value: "Accessories - General" },
  { label: "Carrier Equipment", value: "Carrier Equipment" },
  { label: "Comfort Systems", value: "Comfort Systems" },
  {
    label: "Information/ Communication Systems",
    value: "Information/ Communication Systems",
  },
  { label: "Interior Equipment", value: "Interior Equipment" },
  { label: "Load Securing", value: "Load Securing" },
  { label: "Locking System", value: "Locking System" },
  { label: "Security Systems", value: "Security Systems" },
];
const lubricantParts = [
  { label: "Additive", value: "Additive" },
  {
    label: "Lubricants & Fluids - Others",
    value: "Lubricants & Fluids - Others",
  },
];
const lightingParts = [
  { label: "Headlight & Parts", value: "Headlight & Parts" },
  { label: "Lighting - Others", value: "Lighting - Others" },
  { label: "Lights/ Parts ", value: "Lights/ Parts " },
  { label: "Bulbs ", value: "Bulbs " },
  { label: "Light Switch/ Lever ", value: "Light Switch/ Lever " },
];
const exhaustSystem = [
  { label: "Baffle", value: "Baffle" },
  { label: "Catalytic Converter", value: "Catalytic Converter" },
  { label: "Exhaust Pipes", value: "Exhaust Pipes" },
  { label: "Heat Shield", value: "Heat Shield" },
  { label: "Mounting", value: "Mounting" },
  { label: "Sensor/Probe", value: "Sensor/Probe" },
  { label: "Silencer", value: "Silencer" },
  { label: "Soot/Particulate Filter", value: "Soot/Particulate Filter" },
  { label: "Urea Injection", value: "Urea Injection" },
];
const hvacAirSystem = [
  {
    label: "Intercoolers/Compressor Parts",
    value: "Intercoolers/Compressor Parts",
  },
  {
    label: "Hoses/Connectors/Pipes/Gaskets",
    value: "Hoses/Connectors/Pipes/Gaskets",
  },
  { label: "HVAC & Air System - Others", value: "HVAC & Air System - Others" },
  { label: "Valves/Controls", value: "Valves/Controls" },
  { label: "Fans & Parts", value: "Fans & Parts" },
  { label: "Thermostat & Parts", value: "Thermostat & Parts" },
];
const others = [
  { label: "Chemical Products", value: "Chemical Products" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "Instruments & Tools", value: "Instruments & Tools" },
  { label: "Adhesives/Tapes", value: "Adhesives/Tapes" },
  { label: "Assortment", value: "Assortment" },
  { label: "Cables/Fuses/Installation", value: "Cables/Fuses/Installation" },
  { label: "Clips/Covers/Clamps/Chains", value: "Clips/Covers/Clamps/Chains" },
  { label: "Hooks/Shackles/Staples", value: "Hooks/Shackles/Staples" },
  { label: "Hoses/Pipes/Connectors", value: "Hoses/Pipes/Connectors" },
  { label: "Joints/Drives", value: "Joints/Drives" },
  {
    label: "Nipples/Distributors/Valves",
    value: "Nipples/Distributors/Valves",
  },
  { label: "Pins/Rivets/Sleeves", value: "Pins/Rivets/Sleeves" },
  { label: "Repair Kits", value: "Repair Kits" },
  { label: "Signs", value: "Signs" },
  { label: "Springs/Splints", value: "Springs/Splints" },
];

export const topModals = [
  { label: "Audi", value: "audi" },
  { label: "BMW", value: "bmw" },
  { label: "Chevrolet", value: "chevrolet" },
  { label: "Ford", value: "ford" },
  { label: "Honda", value: "honda" },
  { label: "Hyundai", value: "hyundai" },
];

export const subCategories = {
  "Batteries & Tires": batteriesParts,
  "Brake Parts": brakeParts,
  "Engine Parts": engineParts,
  "Fuel Parts": fuelParts,
  "Interior & Exterior Accessories": interiorAccessories,
  "Lubricants & Fluids": lubricantParts,
  "Suspension Parts": suspensionParts,
  "Transmission Parts": transmissionParts,
  "Steering Parts": steeringParts,
  Lighting: lightingParts,
  "Exhaust Systems": exhaustSystem,
  "HVAC & Air System": hvacAirSystem,
  "Body & Chassis Parts": bodyParts,
  Electricals: electricals,
  Others: others,
  "Service Parts": serviceParts,
};

export const countrList = [
  { label: "Afghanistan", code: "AF" },
  { label: "land Islands", code: "AX" },
  { label: "Albania", code: "AL" },
  { label: "Algeria", code: "DZ" },
  { label: "American Samoa", code: "AS" },
  { label: "AndorrA", code: "AD" },
  { label: "Angola", code: "AO" },
  { label: "Anguilla", code: "AI" },
  { label: "Antarctica", code: "AQ" },
  { label: "Antigua and Barbuda", code: "AG" },
  { label: "Argentina", code: "AR" },
  { label: "Armenia", code: "AM" },
  { label: "Aruba", code: "AW" },
  { label: "Australia", code: "AU" },
  { label: "Austria", code: "AT" },
  { label: "Azerbaijan", code: "AZ" },
  { label: "Bahamas", code: "BS" },
  { label: "Bahrain", code: "BH" },
  { label: "Bangladesh", code: "BD" },
  { label: "Barbados", code: "BB" },
  { label: "Belarus", code: "BY" },
  { label: "Belgium", code: "BE" },
  { label: "Belize", code: "BZ" },
  { label: "Benin", code: "BJ" },
  { label: "Bermuda", code: "BM" },
  { label: "Bhutan", code: "BT" },
  { label: "Bolivia", code: "BO" },
  { label: "Bosnia and Herzegovina", code: "BA" },
  { label: "Botswana", code: "BW" },
  { label: "Bouvet Island", code: "BV" },
  { label: "Brazil", code: "BR" },
  { label: "British Indian Ocean Territory", code: "IO" },
  { label: "Brunei Darussalam", code: "BN" },
  { label: "Bulgaria", code: "BG" },
  { label: "Burkina Faso", code: "BF" },
  { label: "Burundi", code: "BI" },
  { label: "Cambodia", code: "KH" },
  { label: "Cameroon", code: "CM" },
  { label: "Canada", code: "CA" },
  { label: "Cape Verde", code: "CV" },
  { label: "Cayman Islands", code: "KY" },
  { label: "Central African Republic", code: "CF" },
  { label: "Chad", code: "TD" },
  { label: "Chile", code: "CL" },
  { label: "China", code: "CN" },
  { label: "Christmas Island", code: "CX" },
  { label: "Cocos (Keeling) Islands", code: "CC" },
  { label: "Colombia", code: "CO" },
  { label: "Comoros", code: "KM" },
  { label: "Congo", code: "CG" },
  { label: "Congo, The Democratic Republic of the", code: "CD" },
  { label: "Cook Islands", code: "CK" },
  { label: "Costa Rica", code: "CR" },
  { label: `Cote D"Ivoire`, code: "CI" },
  { label: "Croatia", code: "HR" },
  { label: "Cuba", code: "CU" },
  { label: "Cyprus", code: "CY" },
  { label: "Czech Republic", code: "CZ" },
  { label: "Denmark", code: "DK" },
  { label: "Djibouti", code: "DJ" },
  { label: "Dominica", code: "DM" },
  { label: "Dominican Republic", code: "DO" },
  { label: "Ecuador", code: "EC" },
  { label: "Egypt", code: "EG" },
  { label: "El Salvador", code: "SV" },
  { label: "Equatorial Guinea", code: "GQ" },
  { label: "Eritrea", code: "ER" },
  { label: "Estonia", code: "EE" },
  { label: "Ethiopia", code: "ET" },
  { label: "Falkland Islands (Malvinas)", code: "FK" },
  { label: "Faroe Islands", code: "FO" },
  { label: "Fiji", code: "FJ" },
  { label: "Finland", code: "FI" },
  { label: "France", code: "FR" },
  { label: "French Guiana", code: "GF" },
  { label: "French Polynesia", code: "PF" },
  { label: "French Southern Territories", code: "TF" },
  { label: "Gabon", code: "GA" },
  { label: "Gambia", code: "GM" },
  { label: "Georgia", code: "GE" },
  { label: "Germany", code: "DE" },
  { label: "Ghana", code: "GH" },
  { label: "Gibraltar", code: "GI" },
  { label: "Greece", code: "GR" },
  { label: "Greenland", code: "GL" },
  { label: "Grenada", code: "GD" },
  { label: "Guadeloupe", code: "GP" },
  { label: "Guam", code: "GU" },
  { label: "Guatemala", code: "GT" },
  { label: "Guernsey", code: "GG" },
  { label: "Guinea", code: "GN" },
  { label: "Guinea-Bissau", code: "GW" },
  { label: "Guyana", code: "GY" },
  { label: "Haiti", code: "HT" },
  { label: "Heard Island and Mcdonald Islands", code: "HM" },
  { label: "Holy See (Vatican City State)", code: "VA" },
  { label: "Honduras", code: "HN" },
  { label: "Hong Kong", code: "HK" },
  { label: "Hungary", code: "HU" },
  { label: "Iceland", code: "IS" },
  { label: "India", code: "IN" },
  { label: "Indonesia", code: "ID" },
  { label: "Iran, Islamic Republic Of", code: "IR" },
  { label: "Iraq", code: "IQ" },
  { label: "Ireland", code: "IE" },
  { label: "Isle of Man", code: "IM" },
  { label: "Israel", code: "IL" },
  { label: "Italy", code: "IT" },
  { label: "Jamaica", code: "JM" },
  { label: "Japan", code: "JP" },
  { label: "Jersey", code: "JE" },
  { label: "Jordan", code: "JO" },
  { label: "Kazakhstan", code: "KZ" },
  { label: "Kenya", code: "KE" },
  { label: "Kiribati", code: "KI" },
  { label: "Korea, Republic of", code: "KR" },
  { label: "Kuwait", code: "KW" },
  { label: "Kyrgyzstan", code: "KG" },
  { label: `Lao People"S Democratic Republic`, code: "LA" },
  { label: "Latvia", code: "LV" },
  { label: "Lebanon", code: "LB" },
  { label: "Lesotho", code: "LS" },
  { label: "Liberia", code: "LR" },
  { label: "Libyan Arab Jamahiriya", code: "LY" },
  { label: "Liechtenstein", code: "LI" },
  { label: "Lithuania", code: "LT" },
  { label: "Luxembourg", code: "LU" },
  { label: "Macao", code: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { label: "Madagascar", code: "MG" },
  { label: "Malawi", code: "MW" },
  { label: "Malaysia", code: "MY" },
  { label: "Maldives", code: "MV" },
  { label: "Mali", code: "ML" },
  { label: "Malta", code: "MT" },
  { label: "Marshall Islands", code: "MH" },
  { label: "Martinique", code: "MQ" },
  { label: "Mauritania", code: "MR" },
  { label: "Mauritius", code: "MU" },
  { label: "Mayotte", code: "YT" },
  { label: "Mexico", code: "MX" },
  { label: "Micronesia, Federated States of", code: "FM" },
  { label: "Moldova, Republic of", code: "MD" },
  { label: "Monaco", code: "MC" },
  { label: "Mongolia", code: "MN" },
  { label: "Montenegro", code: "ME" },
  { label: "Montserrat", code: "MS" },
  { label: "Morocco", code: "MA" },
  { label: "Mozambique", code: "MZ" },
  { label: "Myanmar", code: "MM" },
  { label: "Namibia", code: "NA" },
  { label: "Nauru", code: "NR" },
  { label: "Nepal", code: "NP" },
  { label: "Netherlands", code: "NL" },
  { label: "Netherlands Antilles", code: "AN" },
  { label: "New Caledonia", code: "NC" },
  { label: "New Zealand", code: "NZ" },
  { label: "Nicaragua", code: "NI" },
  { label: "Niger", code: "NE" },
  { label: "Nigeria", code: "NG" },
  { label: "Niue", code: "NU" },
  { label: "Norfolk Island", code: "NF" },
  { label: "Northern Mariana Islands", code: "MP" },
  { label: "Norway", code: "NO" },
  { label: "Oman", code: "OM" },
  { label: "Pakistan", code: "PK" },
  { label: "Palau", code: "PW" },
  { label: "Palestinian Territory, Occupied", code: "PS" },
  { label: "Panama", code: "PA" },
  { label: "Papua New Guinea", code: "PG" },
  { label: "Paraguay", code: "PY" },
  { label: "Peru", code: "PE" },
  { label: "Philippines", code: "PH" },
  { label: "Pitcairn", code: "PN" },
  { label: "Poland", code: "PL" },
  { label: "Portugal", code: "PT" },
  { label: "Puerto Rico", code: "PR" },
  { label: "Qatar", code: "QA" },
  { label: "Reunion", code: "RE" },
  { label: "Romania", code: "RO" },
  { label: "Russian Federation", code: "RU" },
  { label: "RWANDA", code: "RW" },
  { label: "Saint Helena", code: "SH" },
  { label: "Saint Kitts and Nevis", code: "KN" },
  { label: "Saint Lucia", code: "LC" },
  { label: "Saint Pierre and Miquelon", code: "PM" },
  { label: "Saint Vincent and the Grenadines", code: "VC" },
  { label: "Samoa", code: "WS" },
  { label: "San Marino", code: "SM" },
  { label: "Sao Tome and Principe", code: "ST" },
  { label: "Saudi Arabia", code: "SA" },
  { label: "Senegal", code: "SN" },
  { label: "Serbia", code: "RS" },
  { label: "Seychelles", code: "SC" },
  { label: "Sierra Leone", code: "SL" },
  { label: "Singapore", code: "SG" },
  { label: "Slovakia", code: "SK" },
  { label: "Slovenia", code: "SI" },
  { label: "Solomon Islands", code: "SB" },
  { label: "Somalia", code: "SO" },
  { label: "South Africa", code: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", code: "GS" },
  { label: "Spain", code: "ES" },
  { label: "Sri Lanka", code: "LK" },
  { label: "Sudan", code: "SD" },
  { label: "Suriname", code: "SR" },
  { label: "Svalbard and Jan Mayen", code: "SJ" },
  { label: "Swaziland", code: "SZ" },
  { label: "Sweden", code: "SE" },
  { label: "Switzerland", code: "CH" },
  { label: "Syrian Arab Republic", code: "SY" },
  { label: "Taiwan, Province of China", code: "TW" },
  { label: "Tajikistan", code: "TJ" },
  { label: "Tanzania, United Republic of", code: "TZ" },
  { label: "Thailand", code: "TH" },
  { label: "Timor-Leste", code: "TL" },
  { label: "Togo", code: "TG" },
  { label: "Tokelau", code: "TK" },
  { label: "Tonga", code: "TO" },
  { label: "Trinidad and Tobago", code: "TT" },
  { label: "Tunisia", code: "TN" },
  { label: "Turkey", code: "TR" },
  { label: "Turkmenistan", code: "TM" },
  { label: "Turks and Caicos Islands", code: "TC" },
  { label: "Tuvalu", code: "TV" },
  { label: "Uganda", code: "UG" },
  { label: "Ukraine", code: "UA" },
  { label: "United Arab Emirates", code: "AE" },
  { label: "United Kingdom", code: "GB" },
  { label: "United States", code: "US" },
  { label: "United States Minor Outlying Islands", code: "UM" },
  { label: "Uruguay", code: "UY" },
  { label: "Uzbekistan", code: "UZ" },
  { label: "Vanuatu", code: "VU" },
  { label: "Venezuela", code: "VE" },
  { label: "Viet Nam", code: "VN" },
  { label: "Virgin Islands, British", code: "VG" },
  { label: "Virgin Islands, U.S.", code: "VI" },
  { label: "Wallis and Futuna", code: "WF" },
  { label: "Western Sahara", code: "EH" },
  { label: "Yemen", code: "YE" },
  { label: "Zambia", code: "ZM" },
  { label: "Zimbabwe", code: "ZW" },
];

export const emiratesOptions = [
  "Abu Dhabi",
  "Ajman",
  "Dubai",
  "Fujairah",
  "Ras Al Khaimah",
  "Sharjah",
  "Umm Al Quwain",
];

export const garageTypes = ["Fleet & Corporate", "Chained/Third-party [Independent] Garages", "Independent Mom & Pop Garages", "Spare Parts & Traders", "Gas Station Network", "Tyre & Brake Outlets"];

export const baseUrl = process.env.REACT_APP_BASE_URL;
// export const baseUrl = 'http://testapi.buyparts24.com';

export const SLIDER_CHANGE_FACTOR = 10

export const DISSCUSSION_LISTING_ID = process.env.REACT_APP_DISCUSSION_LISTING_ID;


export const businessModal = [
  "Pure Play",
  "Consolidation",
  "Fulfilment"
]

export const returnReasons = [
  { label: "Excess quantity ordered", value: "Excess quantity ordered" },
  { label: "Defective goods", value: "Defective goods" },
  { label: "Goods shipped too late", value: "Goods shipped too late" },
  { label: "Product specifications are incorrect", value: "Product specifications are incorrect" },
  { label: "Wrong items shipped entirely", value: "Wrong items shipped entirely" },
  { label: "Changed of mind", value: "Changed of mind" },
  { label: "Wrong product ordered", value: "Wrong product ordered" },
  { label: "Better product price found elsewhere", value: "Better product price found elsewhere" }
];


export const outletRefID = process.env.REACT_APP_OUTLET_ID
export const hostedAPI = process.env.REACT_APP_HOSTED_SESSION_ID
export const paymentUrl = process.env.REACT_APP_PAYMET_URL


export const bulkUploadUsers = ["lube.retail@adnocdistribution.ae"];


// let ids = []
// axios.get(`${baseUrl}/api/subcategories/get-tire-categories`).then(res => res?.data?.data?.map(item => ids.push(item?._id)))
// export const TIRES_CATEGORY_IDS = ids
// export const TIRES_CATEGORY_IDS = ['61e525951f3a4da7aa478df0','61e525951f3a4da7aa478df1','61e525951f3a4da7aa478df2']