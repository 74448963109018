import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import StarRatingComponent from "react-star-rating-component";
import moduleName from "../../../../";
import {
  GetHomeListing,
  AddToCart,
} from "../../../../Store/action/listingActions";

import { numberWithCommas, stringToUrl } from "../../../../utils";
import LikeListingCard from "./likeListingCard";

const skeletonNumbers = [1, 2, 3, 4];

const ListingSkeleton = () => {
  return skeletonNumbers.map((val) => {
    return (
      <div className="col-lg-6 col-xl-3 col-sm-6 col-md-4" key={val}>
        <div className="atbd_single_listing ">
          <article className="atbd_single_listing_wrapper">
            <figure className="atbd_listing_thumbnail_area">
              <div className="atbd_listing_image">
                <Skeleton height={200} />
              </div>
              <div className="atbd_thumbnail_overlay_content">
                <ul className="atbd_upper_badge">
                  <li>
                    <Skeleton />
                  </li>
                </ul>
              </div>
            </figure>
            <div className="atbd_listing_info">
              <h4 className="atbd_listing_title">
                <Skeleton />
              </h4>
              <div className="price-group">
                <Skeleton />
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  });
};

class LikeListingGrid extends Component {
  render() {
    const setting = {
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const listings = this.props.listings;
    const { isLoading } = this.props.list;
    const currentUser = JSON.parse(localStorage.getItem("login"));

    return (
      <Fragment>
        {isLoading ? (
          <ListingSkeleton />
        ) : (
          <Slider {...setting}>
            {listings.map((value, index) => {
              const { pic, partName, partSKU, price, _id, date, imageArray } =
                value;
              const title = `${partName}`;
              const badge =
                moment.duration(moment().diff(moment(date))).asHours() <= 48
                  ? "new listing"
                  : "";
              const link =
                stringToUrl(partName) + "--" + stringToUrl(partSKU) + `/${_id}`;
              let url =
                pic || imageArray?.[0] || "assets/img/product-default.jpg";
              let data = {
                ...value,
                title,
                badge,
                link,
                url,
                price,
                conversionRate: this.props.currency.conversionRate,
                currency: this.props.currency.currency,
                addToCart: this.props.addToCart,
                role: this.props?.login?.role,
              };
              return (
                <div key={index}>
                  <LikeListingCard {...data} isLoggedIn={currentUser} />
                </div>
              );
            })}
          </Slider>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
    currency: state.currency,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    getHomeListing: () => dispatch(GetHomeListing()),
    addToCart: (data) => {
      dispatch(AddToCart(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(LikeListingGrid);
