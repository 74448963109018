import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	chipStyle: {
		backgroundColor: "#f7af30",
	},
	imgStyle: {
		position: "absolute",
		height: (props) => `${props.height || "25px"} !important`,
		bottom: (props) => props.bottom || "495px",
		right: (props) => props.right || "690px",
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	textStyle: {
		position: "absolute",
		height: (props) => `${props.height || "10px"} `,
		bottom: (props) => props.bottom || "498px",
		right: (props) => props.right || "630px",
		// color: (props) => props.color || "#00FFFF",
	},
}));

const Picoverlay = (props) => {
	const { business_model } = props;
	const classes = useStyles(props);

	let url = "";
	let text = "";
	let color = "";
	// let bgcolor= "";

	if (business_model === "Fulfilment") {
		url = "/assets/img/express-delivery.png";
		text = "Express";
		color = "#000";
		// bgcolor="#F39329"
	} else if (business_model === "Consolidation") {
		url = "/assets/img/consolidation.png";
		text = "Consolidation";
		color = "#fff";
		// bgcolor = "#1D71B9";
	} else {
	}

	return (
		<>
			{/* {business_model === "Fulfilment" && (
				<> */}
			<img
				className={classes.imgStyle}
				src={url}
				alt="express"
				style={{
					objectFit: "contain",
					objectPosition: "center",
					width: "28px",
					// position: "relative",
					// cursor: "pointer",
					// height: "20px",
					// display: "inline-flex",
					borderRadius: "50px",

					border: "1px solid #F39329",
					// position:"relative",
					// right:"225px",
					// bottom:"5px",
					zIndex: "1",
					backgroundColor: "#fff",
				}}
			/>
			<h3
				className={classes.textStyle}
				style={{
					fontSize: "10px",
					objectFit: "contain",
					objectPosition: "center",
					color: { color },
					width: "70px",
					height: "18px",
					backgroundColor: "#F39329",
					// border: "2px solid #00FFFF",
					borderRadius: "25px ",
					paddingTop: "4px",
					paddingLeft: "14px",
				}}
			>
				{" "}
				{text}
			</h3>
			{/* </>
			)} */}
		</>
	);
};

export default Picoverlay;
