import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone";
// import

const NewsBlogGrid = ({ blog }) => {
  return (
    <Fragment>
      <div className="col-lg-4 col-md-6">
        <div className="grid-single mb-5">
          <div className="card post--card shadow-sm">
            <figure>
              <NavLink to={"/news-details/" + blog?._id}>
                <img
                style={{ height: "300px", objectFit:'cover' }}
                  src={blog?.pic || "/assets/img/product-default.jpg"}
                  alt=""
                />
              </NavLink>
            </figure>
            <div className="card-body">
              <h6 style={{ textTransform: "capitalize" }}>
                <NavLink to={"/news-details/" + blog?._id}>
                  {blog?.title.split("").slice(0, 30)}
                </NavLink>
              </h6>
              <ul
                className="post-meta d-flex list-unstyled"
                style={{ paddingTop: 10 }}
              >
                <li>
                  {moment(blog?.createdAt)
                    .tz("Asia/Dubai")
                    .format("DD/MM/YYYY")}
                </li>
                {""}
                <li style={{ paddingLeft: 5 }}>
                  in Industry
                  {/* <NavLink to={"/news-details" + id} onClick={noAction}>
                    
                        </NavLink>*/}
                </li>
              </ul>
              <p>{blog?.description?.replace(/<[^>]+>/g, "").slice(0, 100)}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewsBlogGrid;
