import React from "react";
import { Pie, Chart } from "react-chartjs-2";
Chart.defaults.global.legend = false;

const PieChart = (props) => {
  return (
    <div>
      <Pie
        data={{
          labels: ["Desktop", "Mobile", "Tablets"],
          datasets: [
            {
              //   label: "# votes",
              data: [15, 15, 13],
              backgroundColor: ["#5FC59A", "#5F67EA", "#EB9037"],
              borderColor: ["white"],
              //   borderWidth: 1,
            },
          ],
        }}
        height={200}
        options={{
          maintainAspectRatio: false,
          cutoutPercentage: 65,
        }}
      />
    </div>
  );
};

export default PieChart;
