import React from 'react'
import { getDiscountAmount, ProductImage, UserAvatar } from '../../../utils';
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import ShowDynamicCurrency from '../../common/ShowDynamicCurrency';
import ShowDynamicPrice from '../../common/ShowDynamicPrice';
import { Chip } from '@material-ui/core';

export default function Step5({ selectedAddress, login, formatPhoneNumber, delivery_option, payOnCard, stepForward, list, totalAmount, getCartLength, discountPerUnit, coupon,payByWallet,partialPaymentType }) {
    return (
        <div className="row mt-3">
            <div className="col-lg-12">
                <div className="shipping-review">
                    <div>
                        <h3>
                            Shipping To {selectedAddress?.first_name}{" "}
                            {selectedAddress?.last_name}
                        </h3>
                        <p style={{ color: "grey" }}>
                            Please check before you finalize your order
                        </p>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "50px",
                            }}
                        >
                            <UserAvatar
                                avatar={login.avatar}
                                style={{
                                    height: 70,
                                    width: 70,
                                    borderRadius: "100%",
                                }}
                            />
                            <div className="name-div-review">
                                <div
                                    style={{
                                        display: "flex",
                                        lineHeight: "23px",
                                        marginTop: 8,
                                    }}
                                    className="checkout-user-details"
                                >
                                    <h3>
                                        {selectedAddress?.first_name}
                                        {""}
                                        {selectedAddress?.last_name}
                                    </h3>
                                    {selectedAddress && selectedAddress?.contact && (
                                        <div>
                                            <h6
                                                style={{
                                                    color: "grey",
                                                    marginLeft: 20,
                                                    marginTop: 3,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {formatPhoneNumber(
                                                    selectedAddress.contact
                                                )}
                                                <CallRoundedIcon
                                                    style={{
                                                        fontSize: 16,
                                                        marginLeft: 10,
                                                        marginTop: "-3px",
                                                    }}
                                                />
                                            </h6>
                                        </div>
                                    )}
                                </div>

                                {selectedAddress && (
                                    <div style={{ display: "flex", marginTop: 0 }}>
                                        <HomeRoundedIcon style={{ fontSize: 20 }} />
                                        <p
                                            style={{
                                                color: "grey",
                                                marginTop: 6,
                                                marginLeft: 3,
                                                fontSize: "14px",
                                            }}
                                        >
                                            {selectedAddress?.state &&
                                                `${selectedAddress?.state},`}{" "}
                                            {selectedAddress?.region &&
                                                `${selectedAddress?.region},`}{" "}
                                            {selectedAddress?.location &&
                                                `${selectedAddress?.location},`}{" "}
                                            {selectedAddress?.address &&
                                                `${selectedAddress?.address}`}
                                        </p>
                                    </div>
                                )}
                            </div>
                            {selectedAddress && (
                                <button
                                    style={{
                                        marginLeft: "auto",
                                        border: "none",
                                        height: 40,
                                        borderRadius: 5,
                                        textAlign: "center",
                                    }}
                                    onClick={(e) => stepForward(e, 2)}
                                >
                                    <img
                                        src="/assets/img/edit.png"
                                        style={{
                                            height: 20,
                                            width: 20,
                                            marginBottom: 5,
                                            marginLeft: 5,
                                            marginRight: 10,
                                        }}
                                    />
                                    Edit Info
                                </button>
                            )}
                        </div>
                    </div>
                    <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                    <div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: 20,
                            }}
                            className="row"
                        >
                            <div
                                className="col-lg-12"
                                style={{ lineHeight: 20 }}
                            >
                                <h6>Products Shipping Option</h6>
                                {
                                    <div>
                                        {delivery_option.length > 0 &&
                                            delivery_option.map((item, i) => {
                                                let url =
                                                    item.pic ||
                                                    item.imageArray?.[0] ||
                                                    "assets/img/product-default";

                                                return (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            marginTop: "20px",
                                                        }}
                                                        className="checkout-product-review"
                                                        key={i}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <ProductImage
                                                                avatar={url}
                                                                className="item-image"
                                                                alt="image"
                                                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                            />

                                                            <div
                                                                className="name-div-review"
                                                                style={{
                                                                    marginLeft: 50,
                                                                }}
                                                            >
                                                                <h6
                                                                    style={{
                                                                        textOverflow: "ellipsis",
                                                                        width: "250px",
                                                                    }}
                                                                >
                                                                    {item.partName}
                                                                </h6>
                                                                <p
                                                                    style={{
                                                                        color: "grey",
                                                                    }}
                                                                >
                                                                    {item.partSKU}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h6
                                                                style={{
                                                                    color: "orange",
                                                                    textTransform: "capitalize",
                                                                }}
                                                            >
                                                                <Chip
                                                                    size="small"
                                                                    label={
                                                                        item.delivery_option
                                                                            .split("_")
                                                                            .join(" ") ||
                                                                        "Pick & Collect"
                                                                    }
                                                                />
                                                            </h6>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-payment-review">
                    <div
                        style={{
                            justifyContent: "space-between",
                            display: "flex",
                        }}
                    >
                        <h3>Payment Method</h3>
                        <h5
                            onClick={(e) => stepForward(e, 4)}
                            style={{ color: "#1d71b8", cursor: "pointer" }}
                        >
                            Change Method
                        </h5>
                    </div>
                    <div className="payment-review mt-3">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {payByWallet?(<div style={{ display: "flex", alignItems: "center" }}>
                                    <img
                                        src="/assets/img/payment-icons/credit.png"
                                        style={{
                                            height: 50,
                                            width: 50,
                                            margin: "auto",
                                        }}
                                    />
                                    <h3 style={{ marginLeft: 20, marginTop: 2 }}>
                                      Wallet  Payment, {partialPaymentType==="cash_on_delivery"?"Partial Cash On Delivery":"Partial Online Payment"}
                                    </h3>
                                </div>):!window.paymentSessionId ? (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img
                                        src="/assets/img/payment-icons/cash-on-delivery.png"
                                        style={{
                                            height: 50,
                                            width: 50,
                                            margin: "auto",
                                        }}
                                    />
                                    <h3 style={{ marginLeft: 20, marginTop: 2 }}>
                                        Cash On Delivery 
                                    </h3>
                                </div>
                            ) : (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img
                                        src="/assets/img/payment-icons/credit.png"
                                        style={{
                                            height: 50,
                                            width: 50,
                                            margin: "auto",
                                        }}
                                    />
                                    <h3 style={{ marginLeft: 20, marginTop: 2 }}>
                                        Online Card Payment
                                    </h3>
                                </div>
                            )}

                            {/* <button
                              style={{
                                marginLeft: "auto",
                                border: "none",
                                height: 40,
                                borderRadius: 5,
                              }}
                              onClick={(e) => stepForward(e, 4)}
                            >
                              <img
                                src="/assets/img/edit.png"
                                style={{
                                  height: 20,
                                  width: 20,
                                  marginBottom: 5,
                                  marginLeft: 5,
                                  marginRight: 10,
                                }}
                              />
                              Edit Info
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="items-in-cart-review">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <h3>Items Cart</h3>
                        </div>
                        <button
                            style={{
                                marginLeft: "auto",
                                border: "none",
                                height: 40,
                                borderRadius: 5,
                                textAlign: "center",
                            }}
                            onClick={(e) => stepForward(e, 1)}
                        >
                            <img
                                src="/assets/img/edit.png"
                                style={{
                                    height: 20,
                                    width: 20,
                                    marginBottom: 5,
                                    marginLeft: 5,
                                    marginRight: 10,
                                }}
                            />
                            Edit Cart
                        </button>
                    </div>
                    {delivery_option.map((item) => {
                        let url = item.pic || item.imageArray?.[0];
                        let qty = getCartLength(list.itemsInCart, item);
                        
                        const cartItemPrice =
                            (item.price *
                            qty);
                        totalAmount +=
                            item.price *
                            qty;
                        localStorage.setItem(
                            "totalAmountInCart",
                            totalAmount
                        );

                        let discountAmt = getDiscountAmount((item.price / 1.05), qty, item, discountPerUnit, coupon);
                        let actual_price = (cartItemPrice/1.05) - discountAmt;
                        let tax = (actual_price * 0.05);
                        let total = actual_price + tax;
                        return (
                            <div key={item._id} style={{ marginTop: 20 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                        width: "100%",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "60%",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ProductImage
                                            avatar={url}
                                            className="item-image"
                                            alt="product image"
                                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                        />
                                        <div
                                            className="name-div-review"
                                            style={{ marginLeft: 50 }}
                                        >
                                            <h6
                                                style={{
                                                    textOverflow: "ellipsis",
                                                    width: "250px",
                                                }}
                                            >
                                                {item.partName}
                                            </h6>
                                            <p style={{ color: "grey", margin: 0 }}>
                                                {item.partSKU}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: "30%",
                                            alignItems: "self-start",
                                        }}
                                    >
                                        <p style={{ color: "#4f4f4f", margin: 0 }}>
                                            {getCartLength(list.itemsInCart, item)}{" "}
                                            *{" "}
                                            <span className="mr-1">
                                                <ShowDynamicCurrency />
                                            </span>
                                            <ShowDynamicPrice price={item.price } />
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            width: "10%",
                                            alignItems: "self-start",
                                            textAlign: "left",
                                        }}
                                    >
                                        <h6
                                            style={{
                                                color: "red",
                                                marginRight: 50,
                                            }}
                                        >

                                            <span className="mr-1">
                                                <ShowDynamicCurrency />
                                            </span>
                                            <ShowDynamicPrice price={discountAmt || 0} />
                                        </h6>
                                    </div>


                                    <div
                                        style={{
                                            width: "10%",
                                            alignItems: "self-end",
                                            textAlign: "end",
                                        }}
                                    >
                                        <h6
                                            style={{
                                                color: "#1d71b8",
                                                marginLeft: "auto",
                                            }}
                                        >
                                            <span className="mr-1">
                                                {" "}
                                                <ShowDynamicCurrency />
                                            </span>
                                            <ShowDynamicPrice price={total} />
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}
