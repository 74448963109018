import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import NotLoggedInModal from "../modal/not-logged-modal";
import StarRatingComponent from "react-star-rating-component";
import { numberWithCommas, ProductImage, stringToUrl } from "../../../../utils";
import IsExpress from "../../../common/IsExpress";
import Consolidation from "../../../common/Consolidation";
import ShowDynamicCurrency from "../../../common/ShowDynamicCurrency";
import ShowDynamicPrice from "../../../common/ShowDynamicPrice";
import TextOverlay from "../../../common/TextOverlay";

export default ({
	link,
	url,
	badge,
	title,
	type,
	currency,
	conversionRate,
	addToCart,
	role,
	price,
	isLoggedIn,
	_id,
	quantity,
	business_model,
	partSKU,
}) => {
	const [star, setStar] = useState(0);
	const [showModal, setShowModal] = useState(false);

	// useEffect(() => {
	// 	axios
	// 		.get("/api/review/" + _id)
	// 		.then((data) => {
	// 			if (data.data.length > 0) {
	// 				let stars = data.data.reduce((total, { star }) => total + star, 0);
	// 				setStar(stars / data.data.length);
	// 			} else {
	// 				setStar(0);
	// 			}
	// 		})
	// 		.catch((err) => setStar(0));
	// }, [_id]);
	const handleModal = (e) => {
		setShowModal(true);
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const msg =
		"You need to register as a buyer to be able to make an order on BuyParts24";
	return (
		<div className="listing-card-grid">
			<div className="atbd_single_listing ">
				<article
					className="atbd_single_listing_wrapper"
					style={{ boxShadow: "none" }}
				>
					<figure
						className="atbd_listing_thumbnail_area"
						style={{ border: "1px solid #e3e6ef" }}
					>
						<NavLink to={`/spare-part-details/${link}`}>
							<div className="atbd_listing_image">
								<ProductImage avatar={url} alt="Listing" />
								<TextOverlay business_model={business_model} />
								{/* <IsExpress business_model={business_model} />

								<Consolidation
									business_model={business_model}
								// width="61px"
								// right="15px"
								// bottom="-29px"
								/> */}
							</div>
						</NavLink>
						<div className="atbd_thumbnail_overlay_content">
							<ul className="atbd_upper_badge">
								<li>
									<span
										className={
											"text-capitalize atbd_badge atbd_badge_" +
											badge.replace(" ", "_")
										}
									>
										{badge}
									</span>
								</li>
							</ul>
						</div>
					</figure>
					<div
						className="atbd_listing_info"
						style={{
							border: 0,
							paddingTop: 15,
							paddingLeft: 0,
							paddingRight: 0,
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: 5,
							}}
						>
							<h4 className="atbd_listing_title mb-2" style={{ width: "50%" }}>
								<NavLink
									to={`/spare-part-details/${link}`}
									style={{ color: "#1A0451" }}
								>
									{title}
								</NavLink>
							</h4>
							{isLoggedIn ? <p
								className="symbol mr-1"
								style={{ color: "#1A0451", fontWeight: 700 }}
							>
								<ShowDynamicCurrency />
								{/* {currency} */}
								<span className="price" style={{ fontSize: 15 }}>
									{/* {numberWithCommas(price.toFixed(2))} */}
									<ShowDynamicPrice price={price} />
									{/* {numberWithCommas(
                    (price * (conversionRate ? conversionRate : 1)).toFixed(2)
                  )} */}
								</span>
							</p> : <></>}
						</div>

						<div className="rating-group">
							<div style={{ textAlign: "start" }}>
								<p style={{ fontSize: 12, fontWeight: 600, margin: 0 }}>
									SKU{" "}
									<span
										style={{
											color: "black",
											fontSize: 12,
											fontWeight: 600,
										}}
									>
										{partSKU}
									</span>
								</p>
							</div>
							<div className="price-group">
								{isLoggedIn ? (
									<div>
										{quantity && quantity > 0 ? (
											<button
												className="btn cart-btn"
												onClick={(e) => addToCart(_id)}
											// disabled={role == "SELLER"}
											>
												Add
											</button>
										) : (
											<p style={{ fontSize: 12, fontWeight: 600, margin: 2 }}>
												Out of stock.
											</p>
										)}
									</div>
								) : (
									<button
										className="btn cart-btn"
										onClick={handleModal}
									// disabled={role == "SELLER"}
									>
										Add
									</button>
								)}
							</div>
							{/* <span className="review-value text-muted">{type}</span> */}
						</div>
					</div>
				</article>
			</div>
			<NotLoggedInModal
				showModal={showModal}
				onClose={handleCloseModal}
				msg={msg}
			/>
		</div>
	);
};
