import { SET_CURRENCY, SET_CURRENCY_RATE } from "../action/currencyAction";
const initState = {
  currency: "AED",
  conversionRate: 0,
};

const currencyReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case SET_CURRENCY_RATE:
      return {
        ...state,
        conversionRate: action.payload,
      };
    default:
      return state;
  }
};
export default currencyReducer;
