import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './style.css'
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Initialize, SetActiveRoom } from '../../Store/action/chatActions';
import Avatar from "@material-ui/core/Avatar";
import  {FaWhatsapp} from 'react-icons/fa';
import Chats from './chats';
import { DISSCUSSION_LISTING_ID } from '../../constants';

const Disscussion = () => {
    const [showChatBox, setShowChatBox] = useState(false);
    const [activeRoom, setActiveRoom] = useState(null);
    const [isDisplayNone, setIsDisplayNone] = useState(false);
    const location = useLocation();
    const login = useSelector(state => state.login);
    const dispatch = useDispatch();
    const startDiscussion = () => {
        if (login?._id) {
            setShowChatBox(true);
            axios
                .post("/api/chat-rooms/add", {
                    buyer_id: login._id,
                    listing_id: DISSCUSSION_LISTING_ID,
                })
                .then((res) => {
                    const { activeRoomId } = res.data;
                    setActiveRoom(activeRoomId);
                    dispatch(Initialize()).then(() => {
                        dispatch(SetActiveRoom(activeRoomId)).then(() => {
                        })
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    useEffect(() => {
        let _showChatBox = ['/einvoice-mobile', '/messages'].includes(location.pathname);

        if (_showChatBox) {
            setIsDisplayNone(true);
        } else {
            setIsDisplayNone(false);
        }
    }, [location.pathname])

    if (login === null) return <></>;

    if (showChatBox) {
        return <Chats setShowChatBox={setShowChatBox} activeRoomId={activeRoom} />
    }


    return (
        <>
            <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}` }target='_blank'>
                <FaWhatsapp size={60} fill='white' className="whatsapp-icon" />
            </a>
            <div
                onClick={startDiscussion}
                className={`chat-with-container ${isDisplayNone ? 'd-none' : null}`} >
                <h5>
                    Chat With Me
                </h5>
            </div>
            <div
                onClick={startDiscussion}
                className={`discussion-container ${isDisplayNone ? 'd-none' : null}`} >
                <Avatar
                    //   style={{
                    //     height: '70px',
                    //     width: '70px',
                    //   }}      
                    alt="buyparts"
                    src="/assets/img/favicon-buyparts24.svg"
                />
            </div>
        </>);
};

export default Disscussion;