import moment from "moment";
import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
	chipStyle: {
		backgroundColor: "#f7af30",
	},
	imgStyle: {
		position: "absolute",
		height: (props) => `${props.height || "90px"} !important`,
		bottom: (props) => props.bottom || "-22px",
		right: (props) => props.right || "-95px",
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},
}));

const Consolidation = (props) => {
	const { business_model } = props;
	const classes = useStyles(props);

	return (
		<>
			{/* {business_model === "Consolidation" && (
				<>
					<img
						className={classes.imgStyle}
						src="/assets/img/consolidate.png"
						alt="express"
						style={{
							objectFit: "contain",
							objectPosition: "center",
							width: "25px",

							border: "2px solid #F39329",
							borderRadius: "50% ",
							// position:"relative",
							// right:"225px",
							// bottom:"5px",
							zIndex: "1",
							backgroundColor: "#fff",
						}}
					/>
					<h3
						className={classes.textStyle}
						style={{
							fontSize: "10px",
							objectFit: "contain",
							objectPosition: "center",
							color: "#000",
							width: "70px",
							height: "15px",
							backgroundColor: "#F39329",
							// border: "2px solid #00FFFF",
							borderRadius: "25px ",
							paddingTop: "2px",
							paddingLeft: "8px",
						}}
					>
						{" "}
						Consolidation
					</h3>
				</>
			)} */}
		</>
	);
};

export default Consolidation;
