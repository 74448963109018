import React from "react";
import { SectionTitle } from "../../content/element/section-title";
import Layout from "../../layout";
import Access from "./Access/Access";
import Operations from "./Operations/Operations";
import Digitization from "./Digitization/Digitization";
import Benefits from "../../common/Benefits";
import AccessContent from "./Access/AccessContent";
import OperationContent from "./Operations/OperationContent";
import DigitizationContent from "./Digitization/DigitizationContent";
import Register from "../../content/element/Register/index";
import { popUp } from "../../../utils";
import { NavLink } from "react-router-dom";

const Index = () => {
  const [active, setActive] = React.useState("operations");
  const currentUser = JSON.parse(localStorage.getItem("login"));

  const getContent = (active) => {
    switch (active) {
      case "access":
        return <AccessContent />;
      case "operations":
        return <OperationContent />;
      default:
        return <DigitizationContent />;
    }
  };

  const handleClick = () => {
    if (currentUser) {
      popUp(
        "You currently logged in a buyer please logout and register as a seller"
      );
    }
  };

  const data = [
    "Informed pricing strategy & improved margins",
    "Business insights & dashboards",
    "Manage promotions and offers",
  ];
  return (
    <Layout>
      <div
        className="breadcrumb-wrapper cover"
        style={{
          backgroundImage: 'url("/assets/img/section-banner.jpg")',
          height: 200,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="inner-container">
          <div style={{ minWidth: "1024px" }}>
            <h1>Become a seller</h1>
          </div>
        </div>
      </div>
      <section className="pt-2 pb-4">
        <section className="seller-categories section-spacing">
          <div className="inner-container">
            <SectionTitle
              title="Seller Categories"
              content="MARKETPLACE PLAYERS"
              color="#1a0451 "
            />

            <div className="row mt-4">
              <div className="col-md-4">
                <div className="seller-category-content">
                  <img
                    src="/assets/img/become-seller/seller-categories-1.png"
                    className="seller-category-image"
                  />
                  <div className="mt-3 seller-categories-text">
                    Authorized distributors
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="seller-category-content">
                  <img
                    src="/assets/img/become-seller/seller-categories-2.png"
                    className="seller-category-image"
                  />
                  <div className="mt-3 seller-categories-text">
                    Single and multi-brand entities
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="seller-category-content">
                  <img
                    src="/assets/img/become-seller/seller-categories-3.png"
                    className="seller-category-image"
                  />
                  <div className="mt-3 seller-categories-text">
                    {" "}
                    Trusted suppliers
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sellers-capabilities section-spacing ">
          <div className="inner-container">
            <SectionTitle
              title="Sellers’ Capabilities"
              content="USER EXPERIENCE"
            />
            <div className="row">
              <div className="col mt-4">
                <div className="seller-capabilities-container">
                  <div className="seller-capabilities-block-1">
                    <ul className="seller-capabilities-listing">
                      <li className="seller-capabilities-list">
                        Product listing and price setting
                      </li>
                      <li className="seller-capabilities-list">
                        Marketing and promotions
                      </li>
                      <li className="seller-capabilities-list">
                        Customer services and account management
                      </li>
                      <li className="seller-capabilities-list">
                        Inventory management & exposure
                      </li>
                      <li className="seller-capabilities-list">
                        Order management
                      </li>
                    </ul>
                  </div>
                  <div className="seller-capabilities-block-2">
                    <ul className="seller-capabilities-listing">
                      <li className="seller-capabilities-list">
                        Storefront design & maintenance
                      </li>
                      <li className="seller-capabilities-list">
                        Seller performance dashboard
                      </li>
                      <li className="seller-capabilities-list">
                        Buyer-Seller communication
                      </li>
                      <li className="seller-capabilities-list">
                        Returns management
                      </li>
                      <li className="seller-capabilities-list">
                        Buyer ratings & reviews
                      </li>
                      <li className="seller-capabilities-list">
                        Multiple fulfilment options
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
        <section className="seller-journey section-spacing">
          <div className="inner-container">
            <SectionTitle title="Seller Journey" color="#1a0451 " />

            <div className="seller-journey-container mt-4">
              <ul>
                <li>
                  <div className="seller-journey-content">
                    <div className="seller-journey-step">
                      <div className="step-image-container">
                        <img src="/assets/img/become-seller/profile-registration.png" />
                      </div>
                      <div className="step-title">
                        Profile <br />
                        Registration
                      </div>
                    </div>
                    <div className="seller-journey-text-meta">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                      quis nostrud exerci tation ullamcorper suscipit lobortis
                      nisl ut aliquip ex ea consequat.
                    </div>
                  </div>
                </li>
                <li>
                  <div className="seller-journey-content">
                    <div className="seller-journey-step">
                      <div className="step-image-container">
                        <img src="/assets/img/become-seller/profile-registration.png" />
                      </div>
                      <div className="step-title">
                        Creating the <br />
                        virtual store
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="seller-journey-overlay" />
        </section> */}

        <section className="benefits-for-sellers section-spacing">
          <div className="inner-container">
            <SectionTitle
              title="Benefits for sellers"
              content="VALUE PROPOSITION"
            />
            <div className="row mt-4 hide-mobile">
              <div className="col-md-4">
                <Access active={active} setActive={setActive} />
              </div>
              <div className="col-md-4">
                <Operations active={active} setActive={setActive} />
              </div>
              <div className="col-md-4">
                <Digitization active={active} setActive={setActive} />
              </div>
            </div>
            <div className="benefits-seller-line hide-mobile" />
            <div className="row hide-mobile">
              <div className="col">{getContent(active)}</div>
            </div>

            {/* mobile view */}
            <div className="mobile only-mobile">
              <div className="row">
                <div className="col-sm-12">
                  <Benefits
                    title="Access"
                    data={data}
                    imgSrc="./assets/img/become-seller/access.png"
                  />
                </div>
                <div className=" col-sm-12">
                  <Benefits
                    title="Operations"
                    data={data}
                    imgSrc="./assets/img/become-seller/operations.png"
                  />
                </div>
                <div className="col-sm-12">
                  <Benefits
                    title="Digitization"
                    data={data}
                    imgSrc="./assets/img/become-seller/digitization.png"
                  />
                </div>
              </div>
            </div>

            {/* end */}
          </div>
        </section>

        <section className="choose section-spacing">
          <div className="inner-container">
            <SectionTitle
              title="Choose the way that works"
              content="BUSINESS MODELS - SELLER ENGAGEMENT"
              color="#1a0451 "
            />
            <div className="row mt-4">
              {/*  */}
              <div className="col-md-6">
                <div className="choose-container">
                  <img
                    src="/assets/img/become-seller/consolidation.png"
                    alt="buyparts-consolidation"
                  />
                  <div className="choose-text">Consolidation</div>
                  <ul>
                    <li>Showcase and sell your products</li>
                    <li>
                      We will consolidate orders and deliver to your customers
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="choose-container">
                  <img
                    src="/assets/img/become-seller/fulfillment.png"
                    alt="buyparts-fulfillment"
                  />
                  <div className="choose-text">Fulfilment</div>
                  <ul>
                    <li>Showcase and sell your products</li>
                    <li>We will stock your inventory</li>
                    <li>
                      We will ship directly to your customer, as early as, under
                      3 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="how-to-join section-spacing">
          <div className="inner-container">
            <SectionTitle
              title="How to join as a seller"
              content="JOIN IN 4 EASY STEPS"
            />
            <div className="row">
              <div className="col">
                <div className="how-to-join-container mt-4">
                  <ul>
                    <li>
                      <div className="how-to-join-content">
                        <img
                          src="/assets/img/become-seller/initiate.png"
                          alt="buyparts-initiate"
                          className="how-to-join-image"
                        />
                        <div className="how-to-join-details">
                          <div className="how-to-join-text">initiate</div>
                          <div className="how-to-join-text-meta">
                            Register, create your account and get verified
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="how-to-join-content">
                        <img
                          src="/assets/img/become-seller/upload.png"
                          alt="buyparts-initiate"
                          className="how-to-join-image"
                        />
                        <div className="how-to-join-details">
                          <div className="how-to-join-text">
                            Enlist your product catalog
                          </div>
                          <div className="how-to-join-text-meta">
                            Enlist your product catalog
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="how-to-join-content">
                        <img
                          src="/assets/img/become-seller/sell.png"
                          alt="buyparts-initiate"
                          className="how-to-join-image"
                        />
                        <div className="how-to-join-details">
                          <div className="how-to-join-text">sell</div>
                          <div className="how-to-join-text-meta">
                            Receive ordersand start selling
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="how-to-join-content">
                        <img
                          src="/assets/img/become-seller/manage.png"
                          alt="buyparts-initiate"
                          className="how-to-join-image"
                        />
                        <div className="how-to-join-details">
                          <div className="how-to-join-text">manage</div>
                          <div className="how-to-join-text-meta">
                            Easily manage your purchase cycle leveraging our
                            performance dashboards
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {currentUser && currentUser.role === "SELLER" ? null : (
          <section className="register section-spacing ">
            <div className="inner-container">
              <div className="row mt-4">
                <div className="col">
                  <NavLink
                    to={`${currentUser ? "#" : "/register"}`}
                    onClick={handleClick}
                  >
                    <div className="btn-register">Register as a seller</div>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
        )}
      </section>
    </Layout>
  );
};

export default Index;
