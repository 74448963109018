import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

export class PageBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyer: false,
      user: false,
      userRole: "",
    };
  }
  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser) {
      this.setState({ userRole: currentUser.role });
      this.setState({ user: true });
    }
    if (!currentUser || currentUser.role !== "BUYER") {
      this.setState({ buyer: true });
    }
  }
  render() {
    return (
      <Fragment>
        {this.state.user ? (
          <section className="sub-menu-container ">
            {/* <div className="overlay-dashboard-subnavbar"></div> */}

            {this.state?.userRole == "BUYER" ? (
              <>
                {/* Buyer dashboard user */}

                <div style={{ padingLeft: "50px", width: "100%" }}>
                  <ul
                    className="sub-menu-bar"
                    style={{ justifyContent: "center" }}
                  >
                    <NavLink to="/my-dashboard">
                      <li>Dashboard</li>
                    </NavLink>
                    <NavLink to="/my-profile">
                      <li>My Profile</li>
                    </NavLink>
                    <NavLink to="/messages">
                      <li> Messages</li>
                    </NavLink>
                    <NavLink to="/checkout">
                      <li>My Cart</li>
                    </NavLink>
                    <NavLink to="/my-order">
                      <li>My Orders</li>
                    </NavLink>
                    <NavLink to="/quotations">
                      <li>My Quotations</li>
                    </NavLink>
                    <NavLink to="/your-address">
                      <li>My Addresses</li>
                    </NavLink>

                    <NavLink to="/reviews">
                      <li>My Reviews</li>
                    </NavLink>
                  </ul>
                </div>
              </>
            ) : (
              <>
                {/* Seller Dashboard user */}
                <div style={{ width: "100%" }}>
                  <ul className="sub-menu-bar" style={{ padingLeft: "50px" }}>
                    <NavLink to="/my-dashboard">
                      <li>Dashboard</li>
                    </NavLink>
                    <NavLink to="/my-profile">
                      <li>My Profile</li>
                    </NavLink>
                    <NavLink to="/my-store">
                      <li>My Store</li>
                    </NavLink>
                    <NavLink to="/my-listings">
                      <li>My Listings</li>
                    </NavLink>
                    <NavLink to="/messages">
                      <li> Messages</li>
                    </NavLink>
                    <NavLink to="/my-sales">
                      <li>My Sales</li>
                    </NavLink>
                    <NavLink to="/seller-deliveries">
                      <li>Deliveries</li>
                    </NavLink>
                    <NavLink to="/payments">
                      <li>Payments</li>
                    </NavLink>
                    <NavLink to="/quotations">
                      <li>Quotations</li>
                    </NavLink>
                    <NavLink to="/your-address">
                      <li>My Addresses</li>
                    </NavLink>

                    <NavLink to="/reviews">
                      <li>Reviews</li>
                    </NavLink>
                  </ul>
                </div>
              </>
            )}
          </section>
        ) : null}
      </Fragment>
    );
  }
}
