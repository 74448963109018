import React from "react";
import { SectionTitle } from "../../content/element/section-title";
import Layout from "../../layout";
import Delivery from "./Delivery";
import Digitization from "./Digitization";
import Onestop from "./Onestop";
import Payment from "./Payment";
import Saving from "./Savings";
import Benefits from "../../common/Benefits";
import DeliveryContent from "./Delivery/DeliveryContent";
import DigitizationContent from "./Digitization/DigitizationContent";
import OnestopContent from "./Onestop/OnestopContent";
import PaymentContent from "./Payment/PaymentContent";
import SavingContent from "./Savings/SavingContent";
import { NavLink } from "react-router-dom";
import { popUp } from "../../../utils";
const Index = () => {
  const [active, setActive] = React.useState("PAYMENT");
  const currentUser = JSON.parse(localStorage.getItem("login"));

  const getContent = (active) => {
    switch (active) {
      case "DELIVERY":
        return <DeliveryContent />;
      case "DIGITIZATION":
        return <DigitizationContent />;
      case "ONESTOP":
        return <OnestopContent />;
      case "PAYMENT":
        return <PaymentContent />;
      case "SAVING":
        return <SavingContent />;
      default:
        return null;
    }
  };

  const handleClick = () => {
    if (currentUser) {
      popUp(
        "You currently logged in a seller please logout and register as a buyer"
      );
    }
  };

  const data = [
    "Widest range across brands and categories",
    "Instant parts availability",
    "Verified and trusted part sellers",
  ];

  return (
    <Layout>
      <div
        className="breadcrumb-wrapper cover"
        style={{
          backgroundImage: 'url("/assets/img/section-banner.jpg")',
          height: 200,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="inner-container">
          <div style={{ minWidth: "1024px" }}>
            <h1>Become a Buyer</h1>
          </div>
        </div>
      </div>

      <section className="become-a-buyer pt-2 pb-4">
        <section className="buyer-categories section-spacing">
          <div className="inner-container">
            <SectionTitle
              title="Buyer Categories"
              content="MARKETPLACE PLAYERS"
              color="#1a0451 "
            />
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="buyer-categories-1">
                  <img
                    src="/assets/img/become-buyer/garage-workshops.png"
                    alt="buyparts-garage-workshop"
                    className="buyer-category-image"
                  />
                  <div className="mt-3 buyer-categories-text">
                    Garages and Workshops
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="/assets/img/become-buyer/feet-owner.png"
                  alt="buyparts-feet-owner"
                  className="buyer-category-image"
                />
                <div className="mt-3 buyer-categories-text">
                  Fleet Owners and Operators
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="buyers-capabilities  section-spacing">
          <div className="inner-container">
            <SectionTitle
              title="Buyers’ Capabilities"
              content="USER EXPERIENCE"
            />
            <div className="row">
              <div className="col mt-4">
                <div className="buyer-capabilities-container">
                  <div className="buyer-capabilities-block-1">
                    <ul className="seller-capabilities-listing">
                      <li className="buyer-capabilities-list">
                        Order Management
                      </li>
                      <li className="buyer-capabilities-list">
                        Seamless buying journey
                      </li>
                      <li className="buyer-capabilities-list">
                        Product and price comparison tools
                      </li>
                      <li className="buyer-capabilities-list">
                        Verified buyers including ratings
                      </li>
                      <li className="buyer-capabilities-list">
                        Smart product search through
                      </li>
                      <li className="buyer-capabilities-list">
                        VIN and Vehicle Details
                      </li>
                      <li className="buyer-capabilities-list">
                        Bulk ordering of parts
                      </li>
                    </ul>
                  </div>
                  <div className="buyer-capabilities-block-2">
                    <ul className="seller-capabilities-listing">
                      <li className="buyer-capabilities-list">
                        Multiple delivery options
                      </li>
                      <li className="buyer-capabilities-list">
                        Multiple payment solutions
                      </li>
                      <li className="buyer-capabilities-list">
                        Repeat/re-ordering of products
                      </li>
                      <li className="buyer-capabilities-list">
                        Buyer performance dashboard
                      </li>
                      <li className="buyer-capabilities-list">
                        Returns Management
                      </li>
                      <li className="buyer-capabilities-list">
                        Account management
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
        <section className="buyer-journey section-spacing">
          <div className="inner-container">
            <SectionTitle title="Buyer Journey" color="#1a0451 " />

            <div className="buyer-journey-container mt-4">
              <ul>
                <li>
                  <div className="buyer-journey-content">
                    <div className="buyer-journey-step">
                      <div className="step-image-container">
                        <img src="/assets/img/become-buyer/profile-registration.png" />
                      </div>
                      <div className="step-title">
                        Profile <br />
                        Registration
                      </div>
                    </div>
                    <div className="buyer-journey-text-meta">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                      quis nostrud exerci tation ullamcorper suscipit lobortis
                      nisl ut aliquip ex ea consequat.
                    </div>
                  </div>
                </li>
                <li>
                  <div className="buyer-journey-content">
                    <div className="buyer-journey-step">
                      <div className="step-image-container">
                        <img src="/assets/img/become-buyer/profile-registration.png" />
                      </div>
                      <div className="step-title">
                        Creating the <br />
                        virtual store
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="buyer-journey-overlay" />
        </section> */}

        <section className="benefits-for-buyer section-spacing">
          <div className="inner-container">
            <SectionTitle
              title="Benefits for buyers"
              content="VALUE PROPOSITION"
            />

            <div className="row mt-4 hide-mobile">
              <div className="col">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Onestop active={active} setActive={setActive} />
                  <Payment active={active} setActive={setActive} />
                  <Digitization active={active} setActive={setActive} />
                  <Saving active={active} setActive={setActive} />
                  <Delivery active={active} setActive={setActive} />
                </div>
              </div>
            </div>
            <div className="benefits-buyer-line hide-mobile" />
            <div className="row hide-mobile">
              <div className="col">{getContent(active)}</div>
            </div>

            {/* for mobile */}
            <div className="mobile only-mobile">
              <div className="row">
                <div className="col-sm-12">
                  <Benefits
                    title="One-stop-shop"
                    data={data}
                    imgSrc="./assets/img/become-buyer/one-stop.png"
                  />
                </div>
                <div className="col-sm-12">
                  <Benefits
                    title="Payment"
                    data={data}
                    imgSrc="./assets/img/become-buyer/payment.png"
                  />
                </div>
                <div className="col-sm-12">
                  <Benefits
                    title="Digitization"
                    data={data}
                    imgSrc="./assets/img/become-buyer/digitization.png"
                  />
                </div>
                <div className="col-sm-12">
                  <Benefits
                    title="Savings"
                    data={data}
                    imgSrc="./assets/img/become-buyer/savings.png"
                  />
                </div>
                <div className="col-sm-12">
                  <Benefits
                    title="Delivery"
                    data={data}
                    imgSrc="./assets/img/become-buyer/delivery.png"
                  />
                </div>
              </div>
            </div>

            {/* end */}
          </div>
        </section>

        <section className="how-to-join section-spacing">
          <div className="inner-container">
            <SectionTitle
              title="How to join as a buyer"
              content="JOIN IN 4 EASY STEPS"
            />
            <div className="row">
              <div className="col">
                <div className="how-to-join-container mt-4">
                  <ul>
                    <li>
                      <div className="how-to-join-content">
                        <img
                          src="/assets/img/become-seller/initiate.png"
                          alt="buyparts-initiate"
                        />
                        <div className="how-to-join-details">
                          <div className="how-to-join-text">initiate</div>
                          <div className="how-to-join-text-meta">
                            Register, create your account and get verified
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="how-to-join-content">
                        <img
                          src="/assets/img/become-buyer/browse.png"
                          alt="buyparts-browse"
                        />
                        <div className="how-to-join-details">
                          <div className="how-to-join-text">Browse & shop</div>
                          <div className="how-to-join-text-meta">
                            Easily search and browse through the listings.
                            Select the products you need
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="how-to-join-content">
                        <img
                          src="/assets/img/become-buyer/receive.png"
                          alt="buyparts-initiate"
                        />
                        <div className="how-to-join-details">
                          <div className="how-to-join-text">receive</div>
                          <div className="how-to-join-text-meta">
                            Hassle Free Delivery to your doorstep
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="how-to-join-content">
                        <img
                          src="/assets/img/become-seller/manage.png"
                          alt="buyparts-initiate"
                        />
                        <div className="how-to-join-details">
                          <div className="how-to-join-text">manage</div>
                          <div className="how-to-join-text-meta">
                            Easily manage your purchase cycle leveraging our
                            performance dashboards
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {currentUser && currentUser.role === "BUYER" ? null : (
          <section className="register section-spacing ">
            <div className="inner-container">
              <div className="row mt-4">
                <div className="col">
                  <NavLink
                    to={`${currentUser ? "#" : "/register"}`}
                    onClick={handleClick}
                  >
                    <div className="btn-register">Register as a buyer</div>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
        )}
      </section>
    </Layout>
  );
};

export default Index;
