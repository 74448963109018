import React, { Component, Fragment, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Modal from "react-awesome-modal";
import UploadQuotationModal from "./upload";
import { connect } from "react-redux";
import { compose } from "redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Header from "../../../layout/header";
import PreHeader from "../../../layout/pre-header";
import Footer from "../../../layout/footer";
import { PageBanner } from "../page-banner";
import {
  SetActiveRoom,
  Initialize,
} from "../../../../Store/action/chatActions";

import {
  UpdateQuotationItems,
  ClearQuotation,
} from "../../../../Store/action/quotationActions";
import ResponseQuotation from "../modal/response-quotation";
import { baseUrl } from "../../../../constants";
import MaterialTable from "material-table";
import moment from "moment-timezone";
import Loader from "../Loader";
import { popUp, stringToUrl } from "../../../../utils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

class RfqDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationExcels: [],
      lists: [],
      notes: "",
      modalQuotation: false,
      loadQuotationWithId: null,
      sendBuyer: "",
      quotationList: [],
      quotationListNotes: [],
      openSendQuotationModal: false,
      selectedTabs: "all",
      searchField: "",
      loader: false,
      rfqId: this.props.match.params.id,
    };
    this.handleUploadQuotationModal =
      this.handleUploadQuotationModal.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.startChat = this.startChat.bind(this);
  }

  componentDidUpdate() {
    // this.componentDidMount();
  }

  componentDidMount() {
    this.setState({ loader: true });
    axios
      .get("/api/products/all?user_id=" + this.props.login._id)
      .then((res) => {
        const { data } = res.data;
        this.setState({ lists: data });
        this.setState({ loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
      });
    this.refreshQuotationExcels();
    if (this.props.login.role === "SELLER") {
      this.getSellerData();
    } else {
      this.getBuyerData();
    }
  }

  getSellerData() {
    axios
      .get(`/api/products/get-seller-quotation/${this.props.login._id}`)
      .then((res) => {
        //  const trry=res?.data?.data.reduce((accumulator, currentValue)=> [...accumulator.listing,...currentValue.listing]
        //  )
        const trry = res?.data.data
          .map((item) => {
            return item.listing;
          })
          .reduce(
            (accumulator, currentValue) => [...accumulator, ...currentValue],
            []
          );
        this.setState({
          quotationList: trry.reverse(),
          quotationListNotes: res?.data?.data.reverse(),
        });
      })
      .catch((err) =>
        popUp("There was a problem with server. Please try again.")
      );
  }
  getBuyerData() {
    axios
      .get(`/api/products/get-buyer-quotation/${this.props.login._id}`)
      .then((res) => {
        //  const trry=res?.data?.data.reduce((accumulator, currentValue)=> [...accumulator.listing,...currentValue.listing]
        //  )
        const trry = res?.data.data
          .map((item) => {
            return item.listing;
          })
          .reduce(
            (accumulator, currentValue) => [...accumulator, ...currentValue],
            []
          );
        this.setState({
          quotationList: trry.reverse(),
          quotationListNotes: res?.data?.data.reverse(),
        });
      })
      .catch((err) =>
        popUp("There was a problem with server. Please try again.")
      );
  }
  downloadRequest = (file_name) => {
    axios
      .get("/api/products/download-excel/" + file_name)
      .then((res) => { })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  };

  refreshQuotationExcels() {
    axios
      .get("/api/quotation/list-excel?buyer=" + this.props.login._id)
      .then((res) => {
        if (res.data.list) this.setState({ quotationExcels: res.data.list });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  closeQuotationModal = () => {
    this.setState({
      modalQuotation: false,
      loadQuotationWithId: null,
    });
  };

  openQuotation = (index) => {
    const quotation = this.state.quotationExcels[index];
    this.setState({
      modalQuotation: true,
      loadQuotationWithId: quotation._id,
    });
  };

  handleUploadQuotationModal = (res) => {
    this.setState({ openSendQuotationModal: res });
  };

  getStatus(status) {
    if (status == "Active") {
      return "active";
    }
    if (status == "Pending") {
      return "pending";
    }
  }

  startChat = (pid, sid, oid, bid, sellerid) => {
    const id = this.props?.login?.role === "BUYER" ? sellerid : bid;
    axios
      .post("/api/chat-rooms/add", {
        buyer_id: id,
        listing_id: pid,
        seller_id: sid,
      })
      .then((res) => {
        const { activeRoomId } = res.data;

        this.props.chatRoomInitialize().then(() => {
          this.props.setChatRoom(activeRoomId).then(() => {
            this.props.history.push({
              pathname: "/messages",
              state: { detail: activeRoomId, orderId: oid, productId: pid },
            });
          });
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  };

  render() {
    const { lists } = this.state;

    const findData = this.state.quotationListNotes.find((item) => {
      return item._id === this.state.rfqId;
    });

    const currentUser = JSON.parse(localStorage.getItem("login"));

    const columns1 = [
      {
        title: <span className="tryit">Image</span>,
        field: "",
        render: (lists) => {
          return (
            <div>
              {lists?.listingId?.imageArray[0] ? (
                <img
                  src={lists?.listingId?.imageArray[0]}
                  style={{
                    height: 60,
                    width: 60,
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "100%",
                  }}
                  className="imageAvatar"
                  alt={lists?.listingId?.imageArray[0]}
                />
              ) : (
                <img
                  src={`/assets/img/avatar.png`}
                  style={{
                    height: 80,
                    width: 80,
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "100%",
                  }}
                  className="imageAvatar"
                  alt="Avatar.jpg"
                />
              )}
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Name</span>,
        field: "",
        render: (lists) => {
          return <span>{lists?.listingId?.partName}</span>;
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Quantity</span>,
        field: "",
        render: (lists) => lists?.quantity,
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: (
          <span className="tryit">
            Contact {this.props?.login?.role === "BUYER" ? "Seller" : "Buyer"}
          </span>
        ),
        field: "",
        render: (lists) => {
          return (
            <div>
              <button
                className="btn btn-block mt-2"
                onClick={() =>
                  this.startChat(
                    lists?.listingId?._id,
                    currentUser?._id,
                    findData?._id,
                    findData?.buyer?._id,
                    findData?.seller?._id
                  )
                }
                style={{
                  backgroundColor: "rgb(243, 146, 0)",
                  color: "white",
                  width: 150,
                  height: 35,
                  textAlign: "center",
                }}
              >
                <p style={{ marginTop: 5 }}>Start discussion</p>
              </button>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
    ];

    if (this.state.loader) return <Loader />;
    return (
      <Fragment>

        <PageBanner />
        <UploadQuotationModal
          handleUploadQuotationModal={this.handleUploadQuotationModal}
          openSendQuotationModal={this.state.openSendQuotationModal}
          buyerId={findData?.buyer?._id}
          rfqid={findData?._id}
        />

        {/* <Modal
          visible={this.state.modalQuotation}
          width="800"
          effect="fadeInUp"
        >
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-default mr-auto"
              style={{ height: 50, fontSize: 18, marginLeft: "-5px" }}
              onClick={this.closeQuotationModal}
            >
              X
            </button>
          </div>
          <ResponseQuotation
            closemodal={this.closeQuotationModal}
            loadWithId={this.state.loadQuotationWithId}
            onDataLoaded={() => this.setState({ loadQuotationWithId: null })}
            onFinished={() => {
              this.refreshQuotationExcels();
              this.closeQuotationModal();
            }}
            _id={this.state.sendBuyer && this.state.sendBuyer}
          />
        </Modal> */}

        <section className="mt-2 mb-5">
          <div className="container">
            <div className="row mt-2">
              <div
                className="review-title-header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="d-flex">
                  <div className="review-title-meta ml-3">
                    {" "}
                    RFQ Details :{" "}
                    <span style={{ fontSize: "18px", color: "#7a82a6" }}>
                      {findData?._id && findData?._id.slice(0, 8).toUpperCase()}
                    </span>
                  </div>
                </div>
                <NavLink
                  to="/quotations"
                  className="order-back-to-home-btn ml-auto"
                >
                  <ArrowBackIosIcon
                    style={{
                      marginRight: "5px",
                      fontSize: 16,
                      alignItems: "center",
                      marginTop: 1,
                    }}
                  />
                  Back to Home
                </NavLink>
              </div>

              <div className="col-lg-9">
                <MaterialTable
                  style={{ zIndex: 0 }}
                  title={null}
                  data={findData?.listing}
                  localization={{
                    body: {
                      emptyDataSourceMessage: (
                        <img
                          src="/assets/img/nodata.png"
                          className="datatableimage"
                        />
                      ),
                    },
                  }}
                  columns={columns1}
                  isLoading={this.state.loader}
                  options={{
                    search: false,
                    rowStyle: (x) => {
                      if (x.tableData.id % 2) {
                        return { backgroundColor: "#f2f2f2" };
                      }
                    },
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [20, 50, 100],
                  }}
                />
              </div>
              <div
                className="col-md-3"
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  height: "auto",
                  marginBottom: 0,
                  border: "1px solid rgb(244, 246, 248)",
                }}
              >
                {this.props?.login?.role === "BUYER" ? (
                  <h4>Seller Info:</h4>
                ) : (
                  <h4>Buyer Info:</h4>
                )}
                <div className="mt-3 mb-3 d-flex">
                  {this.props?.login?.role === "BUYER" ? (
                    <>
                      <div>
                        {findData?.seller?.avatar && (
                          <ImageUser img={findData?.seller?.avatar} />
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <h5 style={{ marginTop: 20, marginLeft: 10 }}>
                          {findData?.seller?.name}
                        </h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {findData?.buyer?.avatar && (
                          <ImageUser img={findData?.buyer?.avatar} />
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <h5 style={{ marginTop: 20, marginLeft: 10 }}>
                          {findData?.buyer?.details?.garage_name}
                        </h5>
                      </div>
                    </>
                  )}
                </div>

                <h6 style={{ marginTop: "10px", fontWeight: 600 }}>Notes :</h6>
                <p style={{ fontSize: 14 }}>{findData?.notes}</p>
                <NavLink
                  to="#"
                  className={
                    this.props?.login?.role === "BUYER"
                      ? "d-none"
                      : "btn btn-block"
                  }
                  style={{ backgroundColor: "#1D71B8", color: "white" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleUploadQuotationModal(true);
                  }}
                // onClick={(e) =>
                //   this.setState({
                //     modalQuotation: true,
                //     sendBuyer: lists?._id,
                //   })
                // }
                >
                  Upload Quotations
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    quotation: state.quotation,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    updateQuotationItems: (data) => dispatch(UpdateQuotationItems(data)),
    clearQuotation: (data) => dispatch(ClearQuotation(data)),
    setChatRoom: (data) => dispatch(SetActiveRoom(data)),
    chatRoomInitialize: () => dispatch(Initialize()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(RfqDetails);

const ImageUser = (props) => {
  const [img, setImg] = useState(props.img);

  return (
    <img
      src={img || "/assets/img/user-default.png"}
      style={{
        height: 60,
        width: 60,
        objectFit: "cover",
        objectPosition: "center",
        borderRadius: "100%",
      }}
      onError={() => setImg("/assets/img/avatar.png")}
      className="imageAvatar"
    />
  );
};
