import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import Header from "../../layout/header";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import { PageBanner } from "../../content/element/page-banner";
import { SetPhoneNumber } from "../../../Store/action/signupActions";
import { popUp } from "../../../utils";
import { UpdateUserInfo } from "../../../Store/action/loginActions";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: this.props.login.phone,
      verifyLoading: false,
      otpLoading: false,
      codeSent: false,
      errMsg: "",
      otpCode: "",
      typingTimeout: 0,
      phoneValid: false,
      isContinueEnabled: false,
    };
    this.validator = new SimpleReactValidator();
    this.getOTP = this.getOTP.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.verifyPhoneNumber = this.verifyPhoneNumber.bind(this);
  }

  
  handleChangePhone(phone) {
    this.setState({ codeSent: false });
    let self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      phone: phone,
      phoneValid: false,
      typingTimeout: setTimeout(function () {
        self.verifyPhoneNumber(phone);
      }, 1000),
    });
  }

  handleChangeInput(e) {
    e.preventDefault();
    this.setState({ otpCode: e.target.value });
  }

  getOTP(e) {
    e.preventDefault();
    this.setState({ otpLoading: true, codeSent: false, errMsg: "" });
    axios
      .post(`/api/users/get-otp-by-phone-register`, {
        phone: "+" + this.state.phone,
      })
      .then((res) => {  
        this.setState({
          otpLoading: false,
          codeSent: true,
          isContinueEnabled: true,
        });
      })
      .catch((err) => {
        this.setState({
          otpLoading: false,
          codeSent: false,
          errMsg: err.response.data.message,
        });
      });
  }

  updatePhone(){
      let data={
          phone:this?.state?.phone,
          id:this.props?.login?._id
      }
      axios.patch( `/api/users/update-phone`,data)
      .then((res)=>{
          this.setState({
            verifyLoading: false,
            otpLoading: false,
            codeSent: false,
            errMsg: "",
            otpCode:'',
            typingTimeout: 0,
            phoneValid: false,
            isContinueEnabled: false,      
          })
          popUp("Phone Updated Successfully !");
          this.props.updateUserInfo(res.data.data);
       }).catch((err)=>{
          console.log(err)  
          popUp("Failed to Update Phone!");

      })

  }

  verifyOTP(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ verifyLoading: true, errMsg: "" });
      const identify = "+" + this.state.phone;
      axios
        .post(`/api/users/verify-otp`, {
          identify: identify,
          code: this.state.otpCode,
        })
        .then((res) => {
          if (res.data.message === "success") {
            this.setState({ verifyLoading: false,phone:identify });
            this.updatePhone()
          }
        })
        .catch((err) => {
          this.setState({
            errMsg: err.response.data.message,
            codeSent: false,
            verifyLoading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  verifyPhoneNumber(number) {
    axios
      .get(
        `https://apilayer.net/api/validate?access_key=${process.env.REACT_APP_NUM_VERIFY_API_KEY}&number=${number}`
      )
      .then((res) => {
        this.setState({ phoneValid: res.data.valid });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  render() {
    const { t } = this.props;
    const {
      otpLoading,
      phone,
      codeSent,
      errMsg,
      verifyLoading,
      otpCode,
      phoneValid,
      isContinueEnabled,
    } = this.state;
    return (
      <Fragment>
            <form action="/">
              <div className="form-group  opt-password-wrapper">
                <PhoneInput
                  placeholder={t("auth_phone_placeholder")}
                  name="Phone"
                  value={phone || ""}
                  onChange={(phone) => this.handleChangePhone(phone)}
                  inputStyle={{ border: "none" }}
                />
                <button
                  type="button"
                  disabled={phoneValid && otpLoading === false ? false : true}
                  onClick={this.getOTP}
                  className="btn get-otp-btn"
                  id="get-opt-btn"
                >
                  {otpLoading && (
                    <i className="las la-spinner la-spin mr-2"></i>
                  )}
                  {t("auth_get_otp")}
                </button>
                <div className="text-danger">
                  {this.validator.message("Phone", phone, "required")}
                </div>
              </div>
            </form>
            <div className="opt-password-wrapper form-group">
              <input
                type="text"
                name="otpCode"
                className="form-control"
                placeholder="OTP"
                value={this.state.otpCode || ""}
                onChange={this.handleChangeInput}
              />
              <button
                type="submit"
                disabled={
                  !(isContinueEnabled
                    ? !verifyLoading
                      ? otpCode
                      : false
                    : false)
                }
                onClick={this.verifyOTP}
                className="btn btn-primary text-center pt-3"
              >
                {verifyLoading && (
                  <i className="las la-spinner la-spin mr-2"></i>
                )}
                <p style={{lineHeight:'10px'}}>Update Phone</p>
              </button>
            </div>
            <div className="text-danger">
              {this.validator.message("otp code", otpCode, "required|string")}
            </div>

            {codeSent === true && (
              <p className="text-info text-center">
                We have sent code to {this.state.phone}.{" "}
                <a href=" " onClick={this.getOTP}>
                  Send Again
                </a>
                ?
              </p>
            )}
            {errMsg && (
              <p className="text-danger text-center">
                {errMsg}{" "}
                <span className="text-info">
                  <a href=" " onClick={this.getOTP}>
                   Send Again
                  </a>
                  ?
                </span>
              </p>
            )}
       </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signup: state.signup,
    login: state.login,

  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    setPhoneNumber: (data) => dispatch(SetPhoneNumber(data)),
    updateUserInfo: (data) => dispatch(UpdateUserInfo(data)),

  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(UpdatePassword);
