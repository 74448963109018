import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import Select from 'react-select'
import SimpleReactValidator from 'simple-react-validator'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import axios from 'axios'

import { SectionTitle } from '../content/element/section-title'
import Header from '../layout/header'
import PreHeader from '../layout/pre-header'
import Footer from '../layout/footer'
import { PageBanner } from '../content/element/page-banner'
import InputDropdown from '../content/element/input-dropdown'
import {
  categories,
  subCategories,
  countrList,
  businessModal,
} from '../../constants'
import CloseIcon from '@material-ui/icons/Close'
import UploadProduct from '../page/UploadProduct'
import { popUp, validateFile } from '../../utils'

class AddParts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bulkUploadUrl: '',
      bulkUploadTemplate: '',
      makeList: [],
      modelList: [],
      price: null,
      currency: 'AED',
      quantity: null,
      pic: null,
      imageArray: [],
      originalImages: [],
      category: null,
      subCategory: null,
      partName: null,
      partHSCode: null,
      partBrand: null,
      partSKU: null,
      type: null,
      fittingPosition: null,
      description: null,
      additionalNotes: null,
      submitLoading: false,
      makes: [],
      models: [],
      allTireSubCategories: [],
      showTireSubCategories: false,
      subCategoryTire: null,
      heightDimension: null,
      widthDimension: null,
      depthDimension: null,
      weight: null,
      countryOrigin: null,
      clickCollect: false,
      delivery: false,
      uploadProductModal: false,
      categories: [],
      subCategories: [],
      tireSubCategories: [],
      business_model: '',
      makeYear: null,
      utqg: null,
      serviceDescription: null,
      rimSize: null,
      amp: null,
      voltage: null,
      tiresCategoriesId: [],
    }
    this.validator = new SimpleReactValidator()
    this.handleChangeNumeric = this.handleChangeNumeric.bind(this)
    this.handleUnitOptionChange = this.handleUnitOptionChange.bind(this)
    this.openFileDlg = this.openFileDlg.bind(this)
    this.uploadPic = this.uploadPic.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleChangeSubCategory = this.handleChangeSubCategory.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleChangeMake = this.handleChangeMake.bind(this)
    this.handleChangeModel = this.handleChangeModel.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.handleAdditionalNotesChange =
      this.handleAdditionalNotesChange.bind(this)
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
  }

  getTireSubCategories = async () => {
    let ids = []
    await axios.get('/api/subcategories/get-tire-subcategories').then((res) => {
      this.setState({ allTireSubCategories: res.data.data })
      res?.data?.data?.map((item) => ids.push(item?._id))
    })
    this.setState({ tiresCategoriesId: ids })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allCategories !== this.props.allCategories) {
      let categories =
        this.props.allCategories.length > 0 &&
        this.props.allCategories.map((data) => ({
          ...data,
          label: data.name,
          value: data._id,
        }))
      this.setState({ categories })
    }
  }

  componentDidMount() {
    if (this.props.login?.status.toLowerCase() === 'pending') {
      popUp('Your account is not approved. Please wait for the approval.')
    }
    this.getTireSubCategories()
    this.setState({
      categories: this.props.allCategories?.map((d) => ({
        ...d,
        label: d.name,
        value: d._id,
      })),
    })
    axios
      .get('/api/listing/get-makes')
      .then((res) => {
        const list = res.data.map((data) => ({
          label: data.name,
          value: data.id_car_make,
          _id: data._id,
        }))
        this.setState({ makeList: list })
      })
      .catch(() => {
        popUp('There was a problem with server. Please try again.')
      })

    // axios
    //   .get("/api/categories/all")
    //   .then((res) => {

    // })
    // .catch(() => {
    //   popUp("There was a problem with server. Please try again.");
    // });
  }

  handleChangeMake(options) {
    if (!options) {
      this.setState({ makes: null })
      return
    }
    const make_ids = options.map((o) => {
      return o.value
    })
    axios
      .post('api/listing/get-models', { makes: make_ids })
      .then((res) => {
        const list = res.data.map((data) => {
          return { label: data.name, value: data.id_car_model, _id: data._id }
        })
        const makes = options.map((o) => o._id)
        this.setState({ modelList: list, makes })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
      })
  }

  handleChangeModel(options) {
    if (!options) {
      this.setState({ models: null })
      return
    }
    const models = options.map((o) => o._id)
    this.setState({ models })
  }

  handleChangeNumeric(name, value) {
    this.setState({ [name]: value })
  }

  handleUnitOptionChange(name, value) {
    this.setState({ [name]: value })
  }

  openFileDlg(e) {
    e.preventDefault()
    this.upload.click()
  }

  uploadPic(e) {
    e.preventDefault()
    // let file = e.target.files[0];
    // let pic = Object.assign(file, {
    //   preview: URL.createObjectURL(file)
    // });
    // this.setState({pic});
    if (e.target.files) {
      const files = Array.from(e.target.files)
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.addEventListener('load', (ev) => {
              resolve(ev.target.result)
            })
            reader.addEventListener('error', reject)
            reader.readAsDataURL(file)
          })
        })
      ).then(
        (images) => {
          this.setState({ pic: images })
          // this.setState({
          //   originalImages: [...this.state.originalImages, ...images],
          // });
          let data = new FormData()
          files.forEach((file) => data.append(`listing`, file))
          axios.post('/api/imageupload?isListing=true', data).then((res) => {
            console.log(res.data)
            this.setState({
              imageArray: [...this.state.imageArray, ...res.data],
            })
          })
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }

  handleChangeSelect(selectedOption, fieldAttr) {
    this.setState({ [fieldAttr.name]: selectedOption.value })
  }

  handleChangeCheckbox(e) {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleChangeCategory = (selectedOption, fieldAttr) => {
    this.handleChangeSelect(selectedOption, fieldAttr)
    this.setState({
      subCategory: null,
      showTireSubCategories: false,
      subCategoryTire: null,
      rimSize: null,
      utqg: null,
      serviceDescription: null,
    })
    // axios
    //   .get("/api/subcategories/" + selectedOption.value)
    //   .then((res) => {
    let result = this.props.allCategories.find(
      (cat) => cat._id === selectedOption.value
    )

    // result &&
    //   axios.get(`/api/subcategories/${result?._id}`).then((res) => {
    // console.log("res", this.state.activeCategory.value)

    this.setState({
      subCategories: result.subCategories.map((data) => ({
        ...data,
        label: data.name,
        value: data._id,
      })),
    })
    // })
    // .catch(() =>
    //   popUp("There was a problem with server. Please try again.")
    // );
  }

  handleChangeSubCategory(option) {
    this.setState({ subCategory: option })
    let isIncludes = this.state.tiresCategoriesId.includes(option.value)
    if (isIncludes) {
      this.setState({ showTireSubCategories: true })
      let tiresubcats = this.state.allTireSubCategories
        .filter((s) => s.category_id === option.value)
        .map((s) => ({ value: s._id, label: s.name }))
      this.setState({ tireSubCategories: tiresubcats })
    } else {
      this.setState({
        showTireSubCategories: false,
        subCategoryTire: null,
        rimSize: null,
        utqg: null,
        serviceDescription: null,
      })
    }
  }

  handleInput(e) {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDescriptionChange(val) {
    this.setState({
      description: val,
    })
  }
  handleAdditionalNotesChange(val) {
    this.setState({
      additionalNotes: val,
    })
  }

  handleSeriveInfoChange = (val) => {
    this.setState({
      serviceDescription: val,
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.validator.allValid()) {
      this.setState({ submitLoading: true })
      let formData = {}
      formData = {
        list_id: this.props.match.params.id,
        price: this.state.price && this.state.price,
        currency: this.state.currency,
        quantity: this.state.quantity,
        category: this.state.category,
        subCategory: this.state.subCategory.value,
        subCategoryTire: this.state.subCategoryTire?.value || null,
        partName: this.state.partName,
        partHSCode: this.state.partHSCode,
        partSKU: this.state.partSKU,
        partBrand: this.state.partBrand,
        type: this.state.type,
        description: this.state.description,
        additionalNotes: this.state.additionalNotes,
        fittingPosition: this.state.fittingPosition,
        makes: this.state.makes,
        models: this.state.models,
        heightDimension: parseFloat(this.state.heightDimension),
        widthDimension: parseFloat(this.state.widthDimension),
        weight: parseFloat(this.state.weight),
        depthDimension: parseFloat(this.state.depthDimension),
        countryOrigin: this.state.countryOrigin,
        clickCollect: this.state.clickCollect,
        delivery: this.state.delivery,
        user: this.props.login._id,
        business_model: this.state.business_model,
        pic: this.state.imageArray[0],
        imageArray: this.state.imageArray,
        makeYear: this.state.makeYear,
        utqg: this.state.utqg,
        serviceDescription: this.state.serviceDescription,
        rimSize: this.state.rimSize,
        amp: this.state.amp,
        voltage: this.state.voltage,
      }

      axios
        .post(`/api/listing/new`, formData)
        .then((res) => {
          this.setState({ submitLoading: false })
          popUp('Successfull added a new product.')
          this.props.history.push('/')
        })
        .catch((err) => {
          popUp(err.response.data.message)
          this.setState({ submitLoading: false })
        })
    } else {
      this.validator.showMessages()
      // this.forceUpdate();
      this.setState({ submitLoading: false })
    }
  }

  closeImage = async (imageURI) => {
    const newArray = this.state.imageArray.filter((item, i) => {
      return i !== imageURI
    })
    const url = this.state.imageArray.find((item, i) => {
      return i === imageURI
    })
    if (url) {
      await axios
        .delete('/api/imageupload/', { data: { url } })
        .then((res) => {
          this.setState({ imageArray: newArray })
        })
        .catch((err) => console.log(err))
    }
  }

  handleOpen = ({ bulkUploadTemplate, bulkUploadUrl }) => {
    this.setState({
      uploadProductModal: true,
      bulkUploadUrl,
      bulkUploadTemplate,
    })
  }
  handleClose = () => {
    this.setState({
      uploadProductModal: false,
      bulkUploadUrl: '',
      bulkUploadTemplate: '',
    })
  }

  render() {
    let {
      categories,
      subCategories,
      subCategory,
      subCategoryTire,
      showTireSubCategories,
      tireSubCategories,
    } = this.state

    const part_type = ['Genuine', 'Aftermarket', 'TBA'].map((type) => {
      return { label: type, value: type }
    })
    const fitting_position = ['Front', 'Rear'].map((type) => {
      return { label: type, value: type }
    })

    return (
      <Fragment>
        <UploadProduct
          showModal={this.state.uploadProductModal}
          closeModal={this.handleClose}
          url={this.state.bulkUploadUrl}
          template={this.state.bulkUploadTemplate}
        />
        <PageBanner title='Sell your spare part' />
        <section className='sell-part-wrapper section-bg'>
          <div className='container'>
            <div className='d-flex' style={{ justifyContent: 'space-between' }}>
              <SectionTitle
                title='Spare Parts Details & Description'
                color={true}
              />
              <div className='d-flex'>
                <button
                  className='btn btn-color-blue file_upload_btn mt-4'
                  style={{ height: 50, width: 180, margin: '10px 2px' }}
                  onClick={() =>
                    this.handleOpen({
                      bulkUploadUrl: 'upload-products',
                      bulkUploadTemplate: 'bulk-upload-sample.xlsx',
                    })
                  }
                >
                  Upload Bulk Product
                </button>
                <button
                  className='btn btn-color-blue file_upload_btn mt-4'
                  style={{ height: 50, width: 180, margin: '10px 2px' }}
                  onClick={() =>
                    this.handleOpen({
                      bulkUploadUrl: 'upload-tires',
                      bulkUploadTemplate: 'bulk-upload-tires.xlsx',
                    })
                  }
                >
                  Upload Bulk Tires
                </button>
                <button
                  className='btn btn-color-blue file_upload_btn mt-4'
                  style={{ height: 50, width: 180, margin: '10px 2px' }}
                  onClick={() =>
                    this.handleOpen({
                      bulkUploadUrl: 'upload-batteries',
                      bulkUploadTemplate: 'bulk-upload-batteries.xlsx',
                    })
                  }
                >
                  Upload Bulk Batteries
                </button>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-user'></span>General Information
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group text-center'>
                    {/* {
                        this.state.pic && (
                          <img src={this.state.pic.preview} onClick={this.openFileDlg}  width="300" alt="pic" />
                        )
                      } */}
                    <div className='photo-container'>
                      {this.state.imageArray.map((imageURI, k) => (
                        <div key={k} className='image-upload-div'>
                          <div
                            className='image-cross-icon-div'
                            onClick={() => this.closeImage(k)}
                          >
                            <CloseIcon style={{ fontSize: 22 }} />
                          </div>
                          <img
                            key={k}
                            className='photo-uploaded m-3'
                            src={imageURI}
                            width='300'
                            style={{ width: '150px', height: '150px' }}
                            alt='pic'
                          />
                        </div>
                      ))}
                    </div>

                    <div className='custom-file-upload'>
                      <input
                        id='customUpload'
                        multiple
                        type='file'
                        ref={(ref) => (this.upload = ref)}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          let isLt5M = validateFile(e.target.files[0])
                          if (!isLt5M) {
                            e.target.value = ''
                            return
                          }
                          this.uploadPic(e)
                        }}
                      />
                      <label
                        htmlFor='customUpload'
                        className='btn btn-sm btn-primary mt-3'
                        onClick={this.openFileDlg}
                      >
                        Upload Picture <span style={{ color: 'red' }}>*</span>
                      </label>
                    </div>
                    <div className='text-danger'>
                      {this.validator.message(
                        'pic',
                        this.state.pic,
                        `required`
                      )}
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>
                        Part Name <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        type='text'
                        name='partName'
                        className='form-control'
                        onChange={this.handleInput}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'partName',
                          this.state.partName,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>
                        Part Brand <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        type='text'
                        name='partBrand'
                        className='form-control'
                        onChange={this.handleInput}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'partBrand',
                          this.state.partBrand,
                          `required`
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>
                        Category <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        name='category'
                        placeholder='Choose Category...'
                        options={categories}
                        onChange={this.handleChangeCategory}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'category',
                          this.state.category,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>
                        Sub Category <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        name='make'
                        value={subCategory}
                        placeholder='Choose subcategory...'
                        onChange={(option) =>
                          this.handleChangeSubCategory(option)
                        }
                        options={subCategories}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'subcategory',
                          this.state.subCategory,
                          `required`
                        )}
                      </div>
                    </div>

                    {showTireSubCategories && (
                      <div className='col-md-12'>
                        <label>
                          Sub Category Tire
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          className={`react-select`}
                          classNamePrefix='react-select'
                          name='subCategoryTire'
                          placeholder='Choose Category...'
                          options={tireSubCategories}
                          value={subCategoryTire}
                          onChange={(e) => {
                            this.setState({ subCategoryTire: e })
                          }}
                        />
                        <div className='text-danger'>
                          {this.validator.message(
                            'subCategoryTire',
                            this.state.subCategoryTire,
                            `required`
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>
                        Part HS Code <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        type='text'
                        name='partHSCode'
                        className='form-control'
                        onChange={this.handleInput}
                      />
                      {/* <div className="text-danger">
                        {this.validator.message(
                          "partHSCode",
                          this.state.partHSCode,
                          `required`
                        )}
                      </div> */}
                    </div>
                    <div className='col-md-6'>
                      <label>
                        Part SKU <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        type='text'
                        name='partSKU'
                        className='form-control'
                        onChange={this.handleInput}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'partSKU',
                          this.state.partSKU,
                          `required`
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>
                      Type <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Select
                      className={`react-select`}
                      classNamePrefix='react-select'
                      name='type'
                      placeholder='Choose Type...'
                      options={part_type}
                      onChange={this.handleChangeSelect}
                    />
                    <div className='text-danger'>
                      {this.validator.message(
                        'type',
                        this.state.type,
                        `required`
                      )}
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>
                      Description <span style={{ color: 'red' }}>*</span>
                    </label>
                    <ReactQuill
                      theme='snow'
                      value={this.state.description}
                      onChange={this.handleDescriptionChange}
                    />
                    <div className='text-danger'>
                      {this.validator.message(
                        'description',
                        this.state.description,
                        `required`
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-user'></span>Pricing & Availability
                    (required)
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>
                        Price <span style={{ color: 'red' }}>*</span>
                      </label>
                      <InputDropdown
                        m_name='price'
                        o_name='currency'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='AED'
                        value={this.state.price}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'price',
                          this.state.price,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>
                        Quantity Available{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <NumberFormat
                        value={this.state.quantity}
                        className={`form-control`}
                        thousandSeparator={true}
                        onValueChange={(values) =>
                          this.setState({ quantity: values.floatValue })
                        }
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'quantity',
                          this.state.quantity,
                          `required`
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-user'></span>Fitting & Compatibility
                    (optional)
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Make</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        placeholder='Choose Make...'
                        name='make'
                        onChange={this.handleChangeMake}
                        options={this.state.makeList}
                        isMulti={true}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Model</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        placeholder='Choose model...'
                        name='model'
                        onChange={this.handleChangeModel}
                        options={this.state.modelList}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>Fitting Position</label>
                    <Select
                      className={`react-select`}
                      classNamePrefix='react-select'
                      placeholder='Choose position...'
                      name='fittingPosition'
                      onChange={this.handleChangeSelect}
                      options={fitting_position}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-user'></span>Details (optional)
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  {/* <div className="form-group">
                    <label>Country of Origin</label>
                    <Select
                      className={`react-select`}
                      classNamePrefix="react-select"
                      placeholder="Choose Country of Origin..."
                      name="countryOrigin"
                      onChange={this.handleChangeSelect}
                      options={countrList.map((country) => {
                        return { label: country.label, value: country.label };
                      })}
                    />
                  </div> */}
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Height Dimension</label>
                      <InputDropdown
                        m_name='heightDimension'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='MM'
                        value={this.state.heightDimension}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Width Dimension</label>
                      <InputDropdown
                        m_name='widthDimension'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='MM'
                        value={this.state.widthDimension}
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Depth Dimension</label>
                      <InputDropdown
                        m_name='depthDimension'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='MM'
                        value={this.state.depthDimension}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Weight</label>
                      <InputDropdown
                        m_name='weight'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='Grams'
                        value={this.state.weight}
                      />
                    </div>
                    {/* start */}
                    {showTireSubCategories && (
                      <div className='col-md-6'>
                        <label>RIM Size</label>
                        <InputDropdown
                          m_name='rimSize'
                          o_name='unit'
                          handleChangeNumeric={this.handleChangeNumeric}
                          handleOptionChange={this.handleUnitOptionChange}
                          defaultOption='RIM'
                          value={this.state.rimSize}
                        />
                      </div>
                    )}
                    <div className='col-md-6'>
                      <label>Amp</label>
                      <InputDropdown
                        m_name='amp'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='amp/hr'
                        value={this.state.amp}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Voltage</label>
                      <InputDropdown
                        m_name='voltage'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='volt'
                        value={this.state.voltage}
                      />
                    </div>
                    {showTireSubCategories && (
                      <div className='col-md-6'>
                        <label>UTQG</label>
                        <InputDropdown
                          m_name='utqg'
                          o_name='unit'
                          handleChangeNumeric={this.handleChangeNumeric}
                          handleOptionChange={this.handleUnitOptionChange}
                          defaultOption='utqg'
                          value={this.state.utqg}
                        />
                      </div>
                    )}
                    <div className='col-md-6'>
                      <label>Make Year</label>
                      <InputDropdown
                        m_name='makeYear'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='YYYY'
                        value={this.state.makeYear}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Country of Origin</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        placeholder='Choose Country of Origin...'
                        name='countryOrigin'
                        onChange={this.handleChangeSelect}
                        options={countrList.map((country) => {
                          return { label: country.label, value: country.label }
                        })}
                      />
                    </div>
                    {/* end */}
                    <div className='col-md-12'>
                      <label>Additional Notes</label>
                      <ReactQuill
                        theme='snow'
                        value={this.state.additionalNotes}
                        onChange={this.handleAdditionalNotesChange}
                      />
                      {showTireSubCategories && (
                        <div className='col-md-12'>
                          <label>Service Description</label>
                          <ReactQuill
                            theme='snow'
                            value={this.state.serviceDescription}
                            onChange={this.handleSeriveInfoChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-shopping-cart'></span>Shipping
                    Options
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label>
                          Select business Model{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          className={`react-select`}
                          classNamePrefix='react-select'
                          placeholder='Choose business Model...'
                          name='business_model'
                          id='business_model'
                          onChange={this.handleChangeSelect}
                          options={businessModal?.map((business_model) => {
                            return {
                              label: business_model,
                              value: business_model,
                            }
                          })}
                        />
                        <div className='text-danger'>
                          {this.validator.message(
                            'business_model',
                            this.state.business_model,
                            `required`
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              {/* <div className="atbd_content_module"> */}
              {/* <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>
                    <span className="la la-shopping-cart"></span>Shipping
                    Options
                  </h4>
                  
                </div>
              </div> */}
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group mt-5 text-center'>
                    {this.props.login?.status.toLowerCase() === 'pending' ? (
                      <button
                        className={`btn btn-lg`}
                        style={{ backgroundColor: '#a7a6a6', color: '#ffff' }}
                      >
                        {this.state.submitLoading && (
                          <i className='las la-spinner la-spin mr-2'></i>
                        )}
                        Add your listing
                      </button>
                    ) : (
                      <button
                        className={`btn btn-lg btn-primary`}
                        onClick={this.handleSubmit}
                        disabled={this.state.submitLoading}
                      >
                        {this.state.submitLoading && (
                          <i className='las la-spinner la-spin mr-2'></i>
                        )}
                        Add your listing
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selling: state.selling,
    login: state.login,
    allCategories: state.category.allCategories,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {}
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(AddParts)
