import React from "react";

const Digitization = ({ active, setActive }) => {
  return (
    <div className="benefits-seller-container">
      <div className="benefits-seller-image">
        <img
          src="/assets/img/become-seller/digitization.png"
          alt="buyparts-digitization"
          onClick={() => setActive("digitization")}
        />
      </div>
      <div className="benefits-seller-text">Digitization</div>
      {active === "digitization" && (
        <div className="benefits-seller-triangle" />
      )}
    </div>
  );
};

export default Digitization;
