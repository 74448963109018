import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import LogIn from "../../content/element/modal/signIn";
import ScrollButton from "../../page/ScrollButton";

const noAction = (e) => e.preventDefault();

class Footer extends Component {
  
  render() {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    const { t } = this.props;
    // console.log(this.props.location.pathname)

    return (
      <Fragment>
        <footer className="footer-three footer-grey">
          <div className="footer-top p-bottom-25 p-top-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="widget widget_pages">
                    <h2 className="widget-title"> BuyParts24</h2>
                    <ul className="list-unstyled">
                      <li className="page-item">
                        <NavLink to="/about-us">
                          {t("footer_menu_about_us")}
                        </NavLink>
                      </li>
                      <li className="page-item">
                        <NavLink to="/contact">
                          {t("footer_menu_contact_us")}
                        </NavLink>
                      </li>
                      <li className="page-item">
                        <NavLink to="/news">News</NavLink>
                      </li>
                      <li className="page-item">
                        <NavLink to="/faqs">{t("footer_menu_faq")}</NavLink>
                      </li>
                      <li className="page-item">
                        <NavLink to="/terms">{t("footer_menu_terms")}</NavLink>
                      </li>
                      <li className="page-item">
                        <NavLink to="/privacy">
                          {t("footer_menu_privacy_policy")}
                        </NavLink>
                      </li>
                      <li className="page-item">
                        <NavLink to="/refundprivacy">
                           Return & Refund Policy
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* ends: .col-lg-3 */}

                <div className="col-lg-3 col-sm-6">
                  <div className="widget widget_social">
                    <h2 className="widget-title">{t("footer_connect_us")}</h2>
                    <ul className="list-unstyled social-list">
                      {/* <li>
                        <a href="https://www.whatsapp.com" target="_blank">
                          <span>
                            <img
                              src="/assets/img/footer_social_icons/whatsapp.png"
                              width="30"
                              alt="whatsapp"
                            />
                          </span>
                          Whatsapp
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://www.facebook.com/BuyParts24"
                          target="_blank"
                        >
                          <span>
                            <img
                              src="/assets/img/footer_social_icons/facebook.png"
                              width="30"
                              alt="facebook"
                            />
                          </span>
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/buyparts24"
                          target="_blank"
                        >
                          <span>
                            <img
                              src="/assets/img/footer_social_icons/instagram.png"
                              width="30"
                              alt="instagram"
                            />
                          </span>
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/buyparts24"
                          target="_blank"
                        >
                          <span>
                            <img
                              src="/assets/img/footer_social_icons/twitter.png"
                              width="30"
                              alt="twitter"
                            />
                          </span>
                          Twitter
                        </a>
                      </li>
                      {/* <li>
                        <a href="https://www.telegram.com/" target="_blank">
                          <span>
                            <img
                              src="/assets/img/footer_social_icons/telegram.png"
                              width="30"
                              alt="telegram"
                            />
                          </span>
                          Telegram
                        </a>
                      </li> */}
                      <li>
                        <a
                          href=" https://www.linkedin.com/company/buyparts24/"
                          target="_blank"
                        >
                          <span>
                            <img
                              src="/assets/img/footer_social_icons/linkedin.png"
                              width="30"
                              alt="linkedin"
                            />
                          </span>
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* ends: .widget */}
                </div>

                {/* ends: .col-lg-3 */}
                <div className="col-lg-2 col-sm-6">
                  <div className="widget widget_pages">
                    <h2 className="widget-title">Sections</h2>
                    <ul className="list-unstyled">
                      <li className="page-item">
                        <NavLink to="/all-listings">Buy Parts</NavLink>
                      </li>
                      {currentUser && currentUser.role === "BUYER" ? null : (
                        <li className="page-item">
                          <NavLink to="/become-buyer">Become a Buyer</NavLink>
                        </li>
                      )}
                      {currentUser && currentUser.role === "SELLER" ? null : (
                        <li className="page-item">
                          <NavLink to="/become-seller">Become a Seller</NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                  {/* ends: .widget */}
                </div>
                {/* ends: .col-lg-3 */}

                <div className="col-lg-4 col-sm-6">
                  <div className="widget widget_text">
                    <h2 className="widget-title">BuyParts24</h2>
                    <div className="textwidget">
                      <p style={{ color: "#1b0451", fontWeight: "500" }}>
                        {t("footer_description")}
                      </p>
                    </div>
                  </div>
                  {/* ends: .widget */}
                </div>
              </div>
            </div>
          </div>
          {/* ends: .footer-top */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="footer-bottom--content" >  
                    <div style={{ display: 'flex', alignItems:'end' }}>
                    <NavLink to="/" className="footer-logo">
                      <ScrollButton/>
                    </NavLink>
                    <img src={'/assets/img/ga_logo.jpeg'} style={{ height: '45px', width: "auto"}} />
                  </div>
                    
                    <div style={{ display: 'flex',flexDirection: "column", alignItems:'flex-start'}}>
                    <img src={'/assets/img/tech_logo.jpeg'} style={{ height: '45px', width: "auto" }} />
                    <p className="m-0 copy-text">
                      © 2021 BuyParts24. All rights reserved.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ends: .footer-bottom */}
        </footer>

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          buttonClasses="accept-btn"
          containerClasses="cookie-message"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          expires={150}
        >
          The cookie settings on this website are set to 'allow all cookies' to
          give you the very best experience. Please click Accept Cookies to
          continue to use the site
        </CookieConsent>
        {/* ends: .footer */}
        <LogIn />
      </Fragment>
    );
  }
}

export default withTranslation()(Footer);
