import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import AdvSearch from "../../content/element/MobileSearch/AdvanceSearch";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AdvSearchVIN from "../../content/element/MobileSearch/AdvanceVinSearch";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../constants";
import { setDefaults } from "react-i18next";
import { FaRegCommentDots } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Menu, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const MobileMenu = ({ menuList, activeCategory, handleDisplayOthersCategoryDiv, isMouseIn }) => {
  const [openSelectSearchOption, setOpenSelectSearchOption] = React.useState(false);
  const [searchByParts, setSearchByParts] = React.useState(false);
  const [searchByVin, setSearchByVin] = React.useState(false);
  const [menu, setMenu] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState("");

  const inHeaderCategory = [
    "Service Parts",
    "Suspension Parts",
    "Drivetrain System",
    "Engine Parts",
    "Batteries & Electricals",
    "Lubricants & Fluids",
    "Engine Cooling System",
    "Body & Chassis Parts",
    "Air Conditioning",
    "Steering Parts"
  ];
  const { allCategories, isAllCategoriesLoading } = useSelector(state => state.category);

  const handleMenuBar = (e) => {
    setMenu((menu) => !menu);
    setSearchByVin(false);
    setSearchByParts(false);
    setOpenSelectSearchOption(false);
    handleDisplayOthersCategoryDiv(e, false);
  };

  const handleSearchContainer = () => {
    setOpenSelectSearchOption((openSelectSearchOption) => !openSelectSearchOption);
    setMenu(false);
    setSearchByVin(false);
    setSearchByParts(false);
  };

  const handleMenuClick = () => { };

  const [leftHide, setLeftHide] = useState(false)
  const [rightHide, setRightHide] = useState(true)

  const slideLeft = () => {
    // setLeftHide(false);
    // setRightHide(true);
    const slider = document.getElementById("mobilesliders");
    slider.scrollLeft = slider.scrollLeft - 200
  }

  const slideRight = () => {
    setLeftHide(true);
    const slider = document.getElementById("mobilesliders");
    slider.scrollLeft = slider.scrollLeft + 200;
  }

  return (
    <>
      <div className="menubar-mobile-container">
        <div className="menubar-mobile">
          <div onClick={handleMenuBar} className="mobile-menu-title px-3 "
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              fontSize: "13px",
              fontWeight: "700",
              borderRight: "1px solid rgba(211, 208, 208, 0.747)",
            }}>
            <Menu style={{ fontSize: 20, margin: "auto 0" }} /> All
          </div>
          {leftHide &&
            <div style={{ cursor: "pointer" ,margin: "auto 0"}} onClick={slideLeft}>
              <KeyboardArrowLeft style={{ fontSize: 20, margin: "auto 0" }} />
            </div>}

          <div
            className="navbar-collapse pr-2 navbar-scroll"
            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
            id="mobilesliders">
            <ul className="navbar-nav mr-auto" >
              {inHeaderCategory.map((item, index) => {
                return (
                  <li
                    className={`nav-item px-3 ${activeCategory === item && "activecategory"}`}
                    key={index}
                    style={{
                      borderRight: "1px solid rgba(211, 208, 208, 0.747)",
                      fontSize: "13px",
                      fontWeight: "700",
                      paddingLeft: "15px",
                    }}
                    onMouseEnter={(e) => {
                      handleDisplayOthersCategoryDiv(e, true, item)
                      setMenu(false)
                    }}
                  >
                    <NavLink to='#'>
                      <span className={`navitem-css ${activeCategory === item && "activetextcolor"}`}
                        onClick={(e) => {
                          handleDisplayOthersCategoryDiv(e, true, item);
                          setMenu(false)
                        }}
                        onMouseEnter={(e) => {
                          isMouseIn = true
                        }}
                        onMouseOut={(e) => {
                          isMouseIn = false
                        }}
                        style={{ lineHeight: "1px" }}>
                        {item}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>

          {rightHide &&
            <div style={{ cursor: "pointer",margin: "auto 0" }} onClick={slideRight}>
              <KeyboardArrowRight style={{ fontSize: 20, margin: "auto 0" }} />
            </div>}
        </div>

        {/* menu list */}
        <div className={`menu-list ${menu && "active-menu-list"}`}>
          <ul className="menu-list-content" style={{ height: '75vh', overflow: 'auto' }}>
            {allCategories && allCategories?.length !== 0 && allCategories.map((list, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setActiveMenu(list?.name);
                    if (activeMenu == list?.name) setActiveMenu("");
                  }}
                >
                  <div>
                    <span style={{ marginRight: "5px" }}>{list.name}</span>
                    {activeMenu === list.name ? (
                      <ExpandLessIcon
                        style={{ fontSize: 18, margin: "auto 0" }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        style={{ fontSize: 18, margin: "auto 0" }}
                      />
                    )}
                  </div>
                  <ul
                    className={`subcategory-list ${activeMenu === list.name ? "active-subcatgory" : null
                      }`}
                  >
                    {list?.subCategories && list?.subCategories.length !== 0 &&
                      list?.subCategories.map((subCat, i) => {
                        return (
                          <li className="sub-catagory-list" key={i}>
                            <NavLink
                              to={`/spare-parts/${list?.name
                                .toLowerCase()
                                .replaceAll(" ", "-").replaceAll("/", "-")}/${subCat?.name.toLowerCase()
                                  .replaceAll(" ", "-").replaceAll("/", "-")}`}
                              onClick={() => {
                                setMenu(false);
                                setActiveMenu("");
                              }}
                            >
                              {subCat?.name}
                            </NavLink>
                          </li>
                        );
                      })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
