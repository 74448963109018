import React, {useState, useEffect} from "react";
import { ToWords } from "to-words";
import moment from "moment-timezone";
import _ from "lodash";
import "./style.css";
import { formatPhoneNumber, getDiscountAmount } from "../../../../utils";

const Index = ({ data, groupproducts: _groupproducts, discountPerUnit, returnProduct, initalDiscountPerUnit }) => {
  const [groupproducts, setGroupproducts] = React.useState([]);
  const [cancelledProducts, setCancelledProducts] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  React.useEffect(() => {
    let filteredProducts = [];
    let cancelledProducts = [];
    let allProducts = [];
    _groupproducts &&
      _groupproducts.length > 0 &&
      _groupproducts.forEach((p) => {
        p.data.forEach((item) => {
          allProducts.push(item);
          if (item.status !== "cancelled") {
            filteredProducts.push(item);
          } else {
            cancelledProducts.push(item);
          }
        });
      });
    setGroupproducts(filteredProducts);
    setCancelledProducts(cancelledProducts);
    setAllProducts(allProducts);
  }, [_groupproducts]);

  


  const getPriceComma = (str) => {
    return str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const toWords = new ToWords();

  let returnAmount = 0
    returnAmount = returnProduct.reduce((a, b) => {
      if (b.status === "quality_check_pass") {
        return a + (b.list?.price * b.quantity)
      } else {
        return a
      }
    }
    , 0) / 1.05
  
  let discountAmtOfReturn = returnProduct.map((o, i) => {
    let exclPrice = 0
    let discountAmount = 0
    if (o?.status === 'quality_check_pass') {
      exclPrice = (o?.list?.price / 1.05);
      discountAmount = getDiscountAmount(exclPrice, o?.quantity, o?.list, discountPerUnit, data?.coupon);
    }
    return discountAmount
  }).reduce((a, b) => {
    return a + b
  }, 0)
  // let afterDiscountOfReturn = returnAmount - (returnAmount * discountPerUnit);
  let afterDiscountOfReturn = returnAmount - discountAmtOfReturn;
  let vatOfReturn = afterDiscountOfReturn * 0.05;
  let netTotalofReturn = afterDiscountOfReturn + vatOfReturn;


  let total = groupproducts?.map((item, i) => {
    let exclPrice = (item?.delivery_option?.price / 1.05);
    let totalUnitPrice = (exclPrice * item?.quantity + item?.shipping_price_individual);
    let discountAmount = getDiscountAmount(exclPrice, item?.quantity, item?.delivery_option, discountPerUnit, data?.coupon);
    let taxAmount = ((totalUnitPrice - discountAmount) * 0.05);
    let netAmount = (totalUnitPrice - discountAmount + taxAmount);
    return netAmount;
  }).reduce((a, b) => a + b, 0);

  let initalorderamt = allProducts?.map((item, i) => {
    let exclPrice = (item?.delivery_option?.price / 1.05);
    let netAmount = (exclPrice * item?.quantity );
    return netAmount;
  }).reduce((a, b) => a + b, 0);

  let initalDiscountAmt = initalorderamt * initalDiscountPerUnit
  let initalTax = (initalorderamt - initalDiscountAmt) * 0.05
  let totalOrderAmt = initalorderamt - initalDiscountAmt + initalTax

  let remainingOrderAmt = (initalorderamt*1.05) - returnProduct.reduce((a, b) => {
    if (b.status === "quality_check_pass") {
      return a + (b.list?.price * b.quantity)
    } else {
      return a
    }
  }
    , 0) - cancelledProducts.reduce((a, b) => a + b.delivery_option.price * b.quantity, 0);

    
  let refundAmt = totalOrderAmt - remainingOrderAmt
	const showRefundAmount = returnProduct.some(item => item.status === "quality_check_pass")
  

  return (
    <>
      <div className="invoice-tax-header">
        <div className="company_logo">
          <img
            src="/assets/img/logo.svg"
            alt="buyParts"
            style={{ marginRight: "2rem", height: "50px" }}
          />
        </div>
      </div>

      <div className="tax-title">E-Invoice</div>

      <div className="users-details">
        <div className="left">
          <div className="tax-invoice-to">
            <div className="tax-info-detail">
              <div className="e-invoice-text">E-Invoice No.</div>
              <div>{data?._id.slice(0, 8)?.toUpperCase()}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">E-invoice Date</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">BuyParts24 Order No.</div>
              <div>{data?.order_id}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">BuyParts 24 Order Date</div>
              <div>
                {moment(data?.order_date)
                  .tz("Asia/Dubai")
                  .format("DD/MM/YYYY hh:mm A")}
              </div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Seller Tax Invoice No.</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Payment Terms</div>
              <div>Cash on delivery</div>
            </div>
          </div>

          <div className="deliver-to">
            <div className="tax-info-detail">
              <div className="e-invoice-text">Bill to:</div>
              <div>{data?.user?.name}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Vat Registration Number</div>
              <div>
                {data?.user?.details?.vat_registration_no
                  ? data?.user?.details?.vat_registration_no
                  : "n/a"}
              </div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Address</div>
              <div>{data?.user?.details?.emirate}</div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="tax-invoice-to">
            <div className="tax-info-detail">
              <div className="e-invoice-text">Seller No.</div>
              <div>
                {_groupproducts[0]?.sellerId.slice(0, 8)?.toUpperCase()}
              </div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Seller Name</div>
              <div>{_groupproducts[0]?.sellerName}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Seller Address</div>
              <div>{_groupproducts[0]?.data[0]?.seller?.details?.emirate}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Buyer No.</div>
              <div>{data?.user?._id?.slice(0, 8)?.toUpperCase()}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Buyer Name</div>
              <div>{data?.user?.details?.garage_name}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Buyer Address</div>
              <div>{data?.user?.details?.garage_address}</div>
            </div>
          </div>

          <div className="deliver-to">
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Ship to:</div>
              <div>{data?.user?.details?.garage_name}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Contact</div>
              <div>{formatPhoneNumber(data?.user?.phone)}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Address</div>
              <div>{data?.delivery_address?.address}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Postcode</div>
              <div>{data?.delivery_address?.postcode}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Location</div>
              <div>{data?.delivery_address?.location}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="item-table-container">
        <table className="invoice-tax-table">
          <tr style={{ border: "1px solid black", backgroundColor: "#bdbdbd" }}>
            <th>#</th>
            <th>Item</th>
            <th style={{ textAlign: "center" }}>Unit Price</th>
            <th style={{ textAlign: "center" }}>Qty</th>
            <th>Total Price</th>
            <th>Discount</th>
            <th style={{ textAlign: "center" }}>5% Tax</th>
            <th>Net Amt</th>
          </tr>
          {groupproducts &&
            groupproducts.length > 0 &&
            groupproducts.map((item, i) => {
              let exclPrice = (item?.delivery_option?.price / 1.05);
              let totalUnitPrice = (exclPrice * item?.quantity + item?.shipping_price_individual);
              let discountAmount = getDiscountAmount(exclPrice, item?.quantity, item?.delivery_option, discountPerUnit, data?.coupon);
              let taxAmount = ((totalUnitPrice-discountAmount) * 0.05);
              let netAmount = (totalUnitPrice-discountAmount+taxAmount);
              return (
                <tr>
                  <td style={{ paddingLeft: "5px" }}>{++i}</td>
                  <td>{item?.delivery_option?.partName}<br /><small><strong>{item?.delivery_option?.partSKU}</strong></small></td>
                  <td style={{ textAlign: "center" }}>
                    {exclPrice.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>{item?.quantity}</td>
                  <td style={{ textAlign: "center" }}>
                    {totalUnitPrice.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {discountAmount.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {taxAmount.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {netAmount.toFixed(2)}
                  </td>
                </tr>
              );
            })}

          <tr style={{ border: "1px solid black", fontWeight: "600" }}>
            <td colSpan="3">Total Invoice Value: </td>

            <td style={{ textAlign: "center" }}></td>
            <td></td>
            <td >
            </td>
            <td></td>

            <td style={{ textAlign: "center" }}>
              AED{" "}
              {getPriceComma(total.toFixed(2))}
            </td>
          </tr>

          <tr style={{ border: "1px solid black", fontWeight: "600" }}>
            <td colSpan="2">Amount in Words :</td>
            <td>
              {toWords.convert(total.toFixed(2)*1)}
            </td>
          </tr>
        </table>
      </div>

      {returnProduct?.length ? <div className="item-table-container">
        <div>
          <div style={{ fontWeight: "600", textAlign: "center", color:'black' }}> Return Sales </div>
        </div>
      <table className="invoice-tax-table">
        <thead>
          <tr style={{ border: "1px solid black", backgroundColor: "#BDBDBD" }}>
            <th style={{ textAlign: "center" }}>#</th>
            <th style={{ textAlign: "left" }}>Item</th>
              <th style={{ textAlign: "center" }}>Status</th>
            <th style={{ textAlign: "center" }}>Unit Price</th>
            <th style={{ textAlign: "center" }}>Qty</th>
              <th style={{ textAlign: "center" }}>Total Price</th>
            <th style={{ textAlign: "center" }}>Discount</th>
            <th style={{ textAlign: "center" }}>5% Tax</th>
            <th>Net Amt</th>
          </tr>
        </thead>
        <tbody>
          {returnProduct?.length > 0 && returnProduct.map((o, i) => {
            let exclPrice = 0
            let totalPrice = 0
            let discountAmount = 0
            let taxAmount = 0
            let netAmount = 0
            if(o?.status === 'quality_check_pass'){
            exclPrice = (o?.list?.price / 1.05);
            totalPrice = exclPrice * o?.quantity;
            discountAmount = getDiscountAmount(exclPrice, o?.quantity, o?.list, discountPerUnit, data?.coupon);
            taxAmount = ((totalPrice-discountAmount) * 0.05);
            netAmount = (totalPrice-discountAmount+taxAmount);
            }
            return(
            <tr style={{ border: "1px solid black", textAlign: "center" }}>
              <td style={{ paddingRight: "5px", textAlign: "center" }}>{++i}</td>
              <td style={{ textAlign: "left" }}>{o?.list?.partName}<br /><small><strong>{o?.list?.partSKU}</strong></small></td>
              <td style={{ textAlign: "center" }}>{o?.status.toUpperCase().replace("_", " ")}</td>
                <td style={{ textAlign: "center" }}>{exclPrice?.toFixed(2) }</td>
                <td style={{ textAlign: "center" }}>{o?.quantity}</td>
              <td style={{ textAlign: "center" }}>{totalPrice.toFixed(2)}</td>
                <td style={{ textAlign: "center" }}>{discountAmount.toFixed(2)}</td>
                <td style={{ textAlign: "center" }}>{taxAmount.toFixed(2)}</td>
                <td style={{ textAlign: "center" }}>{netAmount.toFixed(2)}</td>
            </tr>
          )}
          )}
            <tr style={{ border: "1px solid black", fontWeight: "600" }}>
              <td colSpan="3">Total Return Sales Value: </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td style={{ textAlign: "center" }}>
                AED{" "}
                {getPriceComma(netTotalofReturn.toFixed(2))}
              </td>
            </tr>

            <tr style={{ border: "1px solid black", fontWeight: "600" }}>
              <td colSpan="3">Net Total Amount: </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td style={{ textAlign: "center" }}>
                AED{" "}
                {getPriceComma((total - netTotalofReturn).toFixed(2))}
              </td>
            </tr>

            <tr style={{ border: "1px solid black", fontWeight: "600" }}>
              <td colSpan="3">Amount in Words : </td>

              <td style={{ textAlign: "center" }}>
                {toWords.convert((total - netTotalofReturn).toFixed(2) * 1)}
              </td>
            </tr>
        </tbody>
      </table>
      </div> : null}



      {showRefundAmount ? <div className="item-table-container">
        <div>
          <div style={{ fontWeight: "600", textAlign: "center", color: 'black' }}> Refundable Amount </div>
        </div>
        <table className="invoice-tax-table">
          <thead>
          </thead>
          <tbody>
            
            <tr style={{ border: "1px solid black", fontWeight: "600" }}>
              <td colSpan="3">Total Order Amount: </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td style={{ textAlign: "center" }}>
                AED{" "}
                {getPriceComma(totalOrderAmt.toFixed(2))}
              </td>
            </tr>

            <tr style={{ border: "1px solid black", fontWeight: "600" }}>
              <td colSpan="3">Remaining Order Amount: </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td style={{ textAlign: "center" }}>
                AED{" "}
                {getPriceComma((remainingOrderAmt).toFixed(2))}
              </td>
            </tr>
            <tr style={{ border: "1px solid black", fontWeight: "600" }}>
              <td colSpan="3">Refundable Amount: </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td style={{ textAlign: "center" }}>
                AED{" "}
                {getPriceComma((refundAmt).toFixed(2))}
              </td>
            </tr>

            <tr style={{ border: "1px solid black", fontWeight: "600" }}>
              <td colSpan="3">Amount in Words : </td>

              <td style={{ textAlign: "center" }}>
                {toWords.convert((refundAmt).toFixed(2) * 1)}
              </td>
            </tr>
          </tbody>
        </table>
      </div> : null}
      <div className="einvoice-note">
        <b>Note:</b>
        <ul>
          <li>
            This is an e-invoice from BuyParts24. The Tax invoice will be
            generated by the seller.{" "}
          </li>
          <li>
            It is system-generated document and does not require signature.
          </li>
          <li>
            BuyParts24 is not liable for payment made to any party based on this
            invoice. BuyParts24 accepts the payment through its authorized
            channels only.
          </li>
          <li>
            For any queries related to this invoice, please get in touch to our
            customer service.
          </li>
        </ul>
      </div>
    </>
  );
};

export default Index;
