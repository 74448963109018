import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router";

const noAction = (e) => e.preventDefault();

class AdvSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    
      error:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    noAction(e);
    this.setState({ error:false});
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit=(e)=>{

    e.preventDefault()
 
    const {search} = this.state
    if(!search){   this.setState({ error:true}); console.log("error"); return}
    // const {history} = this.props
   this.props.history.push(`/all-listings?search=${search}`)
  }

  render() {
    const { t } = this.props;
    const { search,error } = this.state;
    return (

      <Fragment>
        <div className=" only-mobile">
          <div style={{ padding: "0 19px" }}>
            <div className="row">
              <div className="col ">
                <div className="mobile-search">
                {/* <div className={error?"search_form search_form search_img search_form_error search_form_error search_img":"search_form search_form search_img"}> */}
                    <div className="input-search-mobile">
                      <input
                        className={error?"mobile-search-by-part-input_error": "mobile-search-by-part-input"}
                        type="text"
                        value={search || ""}
                        name="search"
                        onChange={this.handleChange}
                       
                        placeholder={"Find spare parts.."}
                      />
                      <button    onClick={(e)=>this.handleSubmit(e)}>
                        {" "}
                        <SearchIcon style={{ fontSize: 24, marginTop: 0 }} />
                      </button>
                    </div>
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(withRouter(AdvSearch));
