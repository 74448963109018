import React from 'react';
import PaymentSafetyBatchTooltip from './PaymentSafetyBatchTooltip'

export default function StepDetailText({ step }) {
    return (
        <>
            {(step === 2 && (
                <div className="please-select-div">
                    <span>Please select your address for the deliveries.</span>
                </div>
            )) ||
                (step === 3 && (
                    <div className="please-select-div">
                        <span>
                            Please select the shipping options available for each
                            vendor and product.
                        </span>
                    </div>
                )) ||
                (step === 4 && (
                    <div className="please-select-div payment-option-div">
                        <span>
                            Please choose the payment options to finalise your
                            order.
                        </span>
                    </div>
                )) ||
                (step === 5 && (
                    <div className="please-select-div">
                        <span>Please Review your products and details</span>
                    </div>
                ))}
        </>
    )
}
