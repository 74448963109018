import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import OnlinePayment from "./OnlinePayment";
import { popUp } from '../../../utils';
import { CircularProgress } from '@material-ui/core';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function PaymentModal({ isOpen, onClose, setPaymentSessionId }) {
    const [loading, setLoading] = useState(false)

    async function createSession() {
        try {
            const response = await window.NI.generateSessionId();
            setPaymentSessionId(response.session_id)
            onClose()
        } catch (err) {
            console.log(err);
            popUp("Your session has expire. Please try again.");
        } finally {
            setLoading(false)
        }
    }

    const payNow = () => {
        setLoading(true)
        createSession()
    }

    return (
        <>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen} fullWidth={true} scroll={"paper"}>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    Online Payment
                </DialogTitle>
                <DialogContent dividers>
                    {loading && (
                        <div style={{
                            padding: '0px 20px',
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <CircularProgress />
                        </div>
                    )}
                    <OnlinePayment setLoading={setLoading} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus disabled={loading} onClick={payNow} variant="contained" color="primary">
                        Check out
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
