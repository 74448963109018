import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment-timezone";
import AuthorListSearch from "../content/element/advance-search-author";
import { FormGroup, Label, Input } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { PageBanner } from "../content/element/page-banner";
import PreHeader from "../layout/pre-header";
import Header from "../layout/header";
import Footer from "../layout/footer";
import SellerListingGrid from "../content/element/card/seller-listing-grid";
import { CloudLightning } from "react-feather";
import { Range } from "rc-slider";
import { withTranslation } from "react-i18next";
import { baseUrl, categories, subCategories, topModals } from "../../constants";
import {
	Initialize,
	FilterListing,
	SetSearchQuery,
} from "../../Store/action/listingActions";
import Select from "react-select";

const noAction = (e) => e.preventDefault();

class SellerDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			seller: null,
			sellerListings: [],
			details: null,
			totalStar: 0,
			page: 1,
			perPage: 12,
			totalCount: 0,
			msg: "",
			msgFlag: false,
			category: "",
			subcategory: "",
			activeSubCategory: "",
			activeCategory: "",
			searchStatus: false,
			isClosedDay: false,
			price: [0, 20000],
			sellerImage: "",
		};
		this.sliderChange = this.sliderChange.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
		this.handleChangeSelect = this.handleChangeSelect.bind(this);
	}

	getReviews = (id) => {
		axios
			.get("/api/review/my-reviews-seller?user_id=" + id)
			.then((data) => {
				let ratings = data.data
					.map((d) => d.star)
					.filter((d) => d !== undefined)
					.reduce((total, c, _, { length }) => total + c / length, 0);
				this.setState({
					totalStar: ratings,
				});
			})
			.catch((err) => {
				this.setState({
					totalStar: 0,
				});
			});
	};

	sliderChange(e) {
		this.setState({ price: e });
	}

	resetFilter() {
		this.setState(
			{
				sellerListings: [],
				activeCategory: "",
				activeSubCategory: "",
				price: [0, 20000],
				page: 1,
				perPage: 12,
			},
			() => {
				this.fetchAPI();
			}
		);
	}

	componentDidMount() {
		this.setState({ searchQuery: this.props.list.searchQuery });
		this.fetchAPI();
	}

	fetchAPI = () => {
		const currentUser = JSON.parse(localStorage.getItem("login"));
		this.setState({ loading: true });
		let data = {
			price: this.state.price,
			category: this.state.activeCategory,
			subcategory: this.state.activeSubCategory,
		};
		axios
			.post(
				"/api/users/get-seller?slug=" +
				this.props.match.params.slug +
				"&user=" +
				currentUser._id +
				"&page=" +
				this.state.page +
				"&perPage=" +
				this.state.perPage,
				data
			)
			.then((res) => {
				const { seller, sellerListings, details, totalCount } = res.data;

				this.getReviews(seller?._id);
				this.setState({ seller, sellerListings, details, totalCount }, () => {
					this.setState({ loading: false });
				});
				this.setState({
					sellerImage: seller.avatar,
				});

				let now = moment().tz("Asia/Dubai");
				const currentTime = now.format("HH:mm");
				const currentDay = now.format("dddd");

				const sTime =
					details?.opening_hours[currentDay.toLowerCase()]?.start_time;
				const cTime =
					details?.opening_hours[currentDay.toLowerCase()]?.close_time;

				const isClosedDay =
					details.opening_hours[currentDay.toLowerCase()].clsoed === true;
				this.setState({ isClosedDay: isClosedDay });

				if (currentTime > sTime && currentTime < cTime) {
					this.setState({
						msg: "Currently Open",
						msgFlag: true,
					});
				} else {
					this.setState({
						msg: "Currently Closed",
						msgFlag: false,
					});
				}
			})
			.catch((err) => {
				this.setState({ loading: false });
			});
	};

	handleChangePagination = (e, value) => {
		this.setState({ page: value }, () => {
			this.fetchAPI();
		});
		window.scrollTo({
			top: 300,

			behavior: "smooth",
			/* you can also use 'auto' behaviour
		 in place of 'smooth' */
		});
	};

	searchField = (search, slug, searchStatus) => {
		// this.setState({ loading: true });
		const currentUser = JSON.parse(localStorage.getItem("login"));
		axios
			.post(
				"/api/users/get-seller?slug=" +
				slug +
				"&vinCode=" +
				search +
				"&user=" +
				currentUser._id
			)
			.then((res) => {
				const { seller, sellerListings, details, totalCount } = res.data;

				this.setState({
					sellerListings: [...sellerListings],
					searchstatus: searchStatus,
					totalCount,
				});
				// this.getReviews(seller?._id)
				// this.setState({ seller, sellerListings, details }, () => {
				//   this.setState({ loading: false });
				// });
			})
			.catch((err) => {
				// this.setState({ loading: false });
			});
	};

	handleChangeSelect(selectedOption, fieldAttr) {
		this.setState(
			{
				[fieldAttr.name]: selectedOption.value,
			},
			() => {
				this.fetchAPI();
			}
		);
	}

	render() {
		const { details } = this.state;
		const { t } = this.props;

		const sortedArrayCategories = categories.sort((a, b) =>
			a?.name.localeCompare(b.name)
		);
		const sortedArraySubCategories =
			this.state.activeCategory &&
			subCategories[this.state.activeCategory] &&
			subCategories[this.state.activeCategory].sort((a, b) =>
				a?.label.localeCompare(b.label)
			);

		return (
			<Fragment>
				{/* Header section start */}


				{/* Header section end */}

				{/* <PageBanner title="Seller Details" /> */}
				<section
					className="author-info-area section-padding-strict section-bg"
					style={{ padding: "3rem 0" }}
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div
									className="atbd_auhor_profile_area user-profile-area"
									style={{ marginBottom: 15 }}
								>
									<div className="atbd_author_avatar">
										{!this.state.seller ? (
											<Skeleton circle={true} width={120} height={120} />
										) : (
											<img
												src={
													this.state.sellerImage ||
													"/assets/img/user-default.png"
												}
												className="avatar-md-image"
												alt="AuthorImage"
												onError={() => {
													this.setState({
														sellerImage: "/assets/img/user-default.png",
													});
												}}
											/>
										)}

										{/*  */}
										<div className="atbd_auth_nd">
											<h2>
												{this.state.seller ? (
													this.state.seller.details.company_name
												) : (
													<Skeleton width={150} height={30} />
												)}
											</h2>

											{/* <span className="la la-map-marker"></span> */}
											<p>
												{/* <i
                          className="la la-map-marker"
                          style={{ marinLeft: "10px" }}
                        /> */}
												{this.state.seller ? (
													`Registered on ${moment(this.state.seller.date)
														.tz("Asia/Dubai")
														.format("DD/MM/YYYY")}`
												) : (
													<Skeleton width={150} height={30} />
												)}
											</p>
										</div>
									</div>
									{/*<!-- ends: .atbd_author_avatar -->*/}
									<div className="atbd_author_meta">
										<div className="atbd_listing_meta">
											{this.state.totalStar == 0 ||
												this.state.totalStar === null ? (
												<span>No Reviews Yet</span>
											) : (
												<span className="atbd_meta atbd_listing_rating">
													{parseFloat(this.state?.totalStar).toFixed(1)}{" "}
													<i className="la la-star"></i>
												</span>
											)}
										</div>
										<p className="meta-info">
											{this.state.seller ? (
												<>
													{this.state?.sellerListings?.length == 0 ? (
														<span>No Listings Yet</span>
													) : (
														<>
															<span>{this.state.sellerListings.length}</span>{" "}
															Listings
														</>
													)}
												</>
											) : (
												<Skeleton width={50} height={30} />
											)}
										</p>
									</div>
									{/*<!-- ends: .atbd_author_meta -->*/}
								</div>
								{/*<!-- ends: .atbd_auhor_profile_area -->*/}
							</div>
						</div>
						<div className="row" style={{ position: "relative" }}>
							{/* <!-- ends: .col-lg-12 --> */}
							<div
								className="col-lg-8 col-md-7 m-bottom-30"
								style={{ paddingRight: 0 }}
							>
								<div className="atbd_author_module">
									<div className="atbd_content_module">
										<div className="atbd_content_module__tittle_area">
											<div className="atbd_area_title">
												<h4>
													<span className="la la-user"></span>About Seller
												</h4>
											</div>
										</div>
										<div className="atbdb_content_module_contents">
											{details ? (
												<>
													{details?.description &&
														parse(`${details?.description}`)}
												</>
											) : (
												<span>Seller has not added any description.</span>
											)}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="atbd_author_listings_area m-bottom-30 d-flex">
											<h1>Seller Listings</h1>
											{this.state.sellerListings.length == 0 ? (
												""
											) : (
												<AuthorListSearch
													searchField={this.searchField}
													slug={this.props.match.params.slug}
												/>
											)}
										</div>
										<div className="">
											{this.state.sellerListings.length == 0 ? (
												<>
													<p style={{ textAlign: "center" }}>
														<img
															src="/assets/img/noproduct.png"
															style={{ width: "50%" }}
														/>
													</p>
													<p style={{ textAlign: "center" }}>
														The seller has not added any listings yet.
													</p>
												</>
											) : (
												<>
													<div className=" order-lg-0 order-0 pb-5 mt-lg-0">
														<div className="listings-sidebar">
															<div className="search-area default-ad-search pb-3">
																<div
																	className="row search-area-header s mt-3"
																	style={{ justifyContent: "space-between" }}
																>
																	<div className="filterBY-cat mt-3 col-md-4">
																		<FormGroup>
																			<Label for="location">Category</Label>
																			<Select
																				id="location"
																				className={`react-select`}
																				classNamePrefix="react-select"
																				name="activeCategory"
																				placeholder="Choose Category..."
																				options={
																					sortedArrayCategories &&
																					sortedArrayCategories.map((item) => {
																						return {
																							label: item.name,
																							value: item.value,
																						};
																					})
																				}
																				onChange={this.handleChangeSelect}
																			/>

																			{/* <select
                                        className="form-control"
                                        name="location"
                                        defaultValue="Choose Category"
                                        id="location"
                                        value={this.state.activeCategory}
                                        onChange={(e) =>
                                          this.setState({
                                            activeCategory: e.target.value
                                          })
                                        }
                                      >
                                        <option value="">
                                          Choose Category
                                        </option>
                                        {sortedArrayCategories &&
                                          sortedArrayCategories.map(
                                            (item, index) => {
                                              return (
                                                <option value={item.value}>
                                                  {item.name}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select> */}
																		</FormGroup>
																	</div>
																	<div className="filterBY-subcat mt-3 col-md-4">
																		<FormGroup>
																			<Label for="location">Sub Category</Label>
																			<Select
																				id="location"
																				className={`react-select`}
																				classNamePrefix="react-select"
																				name="activeSubCategory"
																				placeholder="Choose Subcategory..."
																				options={
																					sortedArraySubCategories &&
																					sortedArraySubCategories.map(
																						(item) => {
																							return {
																								label: item.label,
																								value: item.value,
																							};
																						}
																					)
																				}
																				onChange={this.handleChangeSelect}
																			/>
																			{/* <select
                                        className="form-control"
                                        name="location"
                                        defaultValue="Choose Category"
                                        id="location"
                                        value={this.state.activeSubCategory}
                                        onChange={(e) =>
                                          this.setState({
                                            activeSubCategory: e.target.value
                                          })
                                        }
                                      >
                                        <option value="">
                                          Choose Category
                                        </option>
                                        {sortedArraySubCategories &&
                                          sortedArraySubCategories.map(
                                            (item, index) => {
                                              return (
                                                <option value={item.value}>
                                                  {item.label}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select> */}
																		</FormGroup>
																	</div>
																	<div className="filterBy-price-div mt-3 col-md-4">
																		<Label for="location">Price Filter</Label>

																		<div className="range ml-2 mt-2">
																			<Range
																				style={{ width: 150, marginTop: 10 }}
																				min={0}
																				max={20000}
																				value={this.state.price}
																				marks
																				onAfterChange={this.fetchAPI}
																				onChange={this.sliderChange}
																			/>
																		</div>
																		<button
																			className="mt-3 btn-xs btn btn-primary"
																			onClick={(e) => this.resetFilter(e)}
																			style={{ fontSize: 10 }}
																		>
																			RESET FILTERS
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div
															className="d-flex"
															style={{
																flexWrap: "wrap",
																marginTop: "-15px",
																width: "100%",
															}}
														>
															<SellerListingGrid
																isLoading={this.state.loading}
																listings={this.state?.sellerListings}
															/>
														</div>
														<Pagination
															count={Math.ceil(
																this.state.totalCount / this.state.perPage
															)}
															color="primary"
															defaultPage={this.state.page}
															onChange={this.handleChangePagination}
														/>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
								{/*<!-- ends: .atbd_author_module -->*/}
							</div>
							{/*<!-- ends: .col-md-8 -->*/}
							<div
								className="col-lg-4 col-md-5 m-bottom-30 "
								style={{ zIndex: 0 }}
							>
								<div className="sticky-top" style={{ top: 111, zIndex: 99 }}>
									{/* <div className="widget atbd_widget widget-card ">
                    <div className="atbd_widget_title">
                      <h4>
                        <span className="la la-phone"></span>Contact Info
                      </h4>
                    </div>
                    
                    <div className="widget-body atbd_author_info_widget">
                      <div className="atbd_widget_contact_info">
                        {!this.state.seller ? (
                          <Skeleton count={4} height={30}></Skeleton>
                        ) : (
                          <ul>
                            <li>
                              <span className="la la-map-marker"></span>
                              <span className="atbd_info">
                                {this.state.seller.details.emirate}
                              </span>
                            </li>
                            <li>
                              <span className="la la-phone"></span>
                              <span className="atbd_info">
                                {formatPhoneNumberIntl(
                                  "+" + this.state.seller.phone
                                )}
                              </span>
                            </li>
                            <li>
                              <span className="la la-envelope"></span>
                              <span className="atbd_info">
                                {this.state.seller.email}
                              </span>
                            </li>
                          </ul>
                        )}
                      </div>
                      <div className="atbd_social_wrap">
                        {this.state?.details?.social_links?.map((link) => {
                          if (link.link) {
                            let className =
                              link.name === "facebook"
                                ? "fab fa-facebook-f"
                                : link.name === "twitter"
                                ? "fab fa-twitter"
                                : link.name === "google"
                                ? "fab fa-google-plus-g"
                                : link.name === "linkedin"
                                ? "fab fa-linkedin-in"
                                : "fab fa-dribbble";
                            return (
                              <p>
                                <a href={link.link} target="_blank">
                                  <span className={className}></span>
                                </a>
                              </p>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div> */}

									{/*<!-- ends: .widget -->*/}
									<div className="widget atbd_widget widget-card">
										<div
											className="atbd_widget_title"
											style={{ position: "relative" }}
										>
											<h4>
												<span className="la la-clock-o"></span>Business Hours
											</h4>
											<div
												style={{ position: "absolute", right: 30, top: "40%" }}
											>
												{details && (
													<h6
														className={
															this.state.msgFlag
																? "currentlyopen"
																: "currentlyclose"
														}
													>
														{this.state.isClosedDay
															? "Currently Closed"
															: this.state.msg}
													</h6>
												)}
											</div>
										</div>
										<div className="directory_open_hours">
											<ul>
												{details &&
													details.opening_hours &&
													Object.keys(details.opening_hours).map(
														(key, index) => {
															let timeClass = "atbd_open";

															if (
																key ===
																moment()
																	.tz("Asia/Dubai")
																	.format("dddd")
																	.toLowerCase()
															)
																timeClass = "atbd_open atbd_today";
															if (details.opening_hours[key].clsoed === true) {
																timeClass = "atbd_closed";
															}

															return (
																<>
																	{key.length == 0 ? (
																		<p>
																			This seller has not specified business
																			hours yet.
																		</p>
																	) : (
																		<li className={`${timeClass}`} key={index}>
																			<span className="day">
																				{key.charAt(0).toUpperCase() +
																					key.slice(1)}
																			</span>
																			<div className="atbd_open_close_time">
																				{details.opening_hours[key].clsoed ===
																					true && <span>Closed</span>}
																				{details.opening_hours[key].clsoed ===
																					false &&
																					details.opening_hours[key]
																						.start_time && (
																						<>
																							<span className="time">
																								{
																									details.opening_hours[key]
																										.start_time
																								}
																							</span>
																							-
																							<span className="time">
																								{
																									details.opening_hours[key]
																										.close_time
																								}
																							</span>
																						</>
																					)}
																			</div>
																		</li>
																	)}
																</>
															);
														}
													)}
											</ul>
										</div>
									</div>
								</div>
							</div>
							{/*<!-- ends: .col-lg-4 -->*/}
						</div>
					</div>
				</section>

			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		list: state.list,
	};
};
const mapDispatchToProp = (dispatch) => {
	return {
		initializeListing: (data) => dispatch(Initialize(data)),
		filterListing: (query) => dispatch(FilterListing(query)),
		setSearchQuery: (query) => dispatch(SetSearchQuery(query)),
	};
};
export default connect(mapStateToProps)(SellerDetails);
