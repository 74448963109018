import React from 'react'
import OrderListCard from '../../common/OrderListCard';

export default function Step3({ delivery_option, selectedAddress, defaultAddress, handleDeliveryOption, getCartLength, list }) {
    return (
        <div className="shipping-step">
            <div>
                <table id="directorist-checkout-table" className="table bg-transparent checkout-table">
                    <tbody className="bg-white">
                        <tr>
                            <td colSpan={2} style={{ backgroundColor: "#f7f7f7" }}>Name</td>
                            <td style={{ backgroundColor: "#f7f7f7", whiteSpace: "nowrap" }}>Shipping Options</td>
                            <td style={{ backgroundColor: "#f7f7f7" }}>Shipping Date</td>
                        </tr>
                        {delivery_option?.length > 0 &&
                            delivery_option.map((listing, i) => {
                                return (
                                    <OrderListCard
                                        key={i}
                                        shippingAddress={selectedAddress || defaultAddress}
                                        data={listing}
                                        getShippingPrice={() => { }}
                                        handleDeliveryOption={handleDeliveryOption}
                                        price={getCartLength(list?.itemsInCart, listing)}
                                    />
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
