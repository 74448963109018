import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LoginModal from "../../Login/LoginModal";

export default function NotLoggedInModal({ showModal, onClose, msg }) {
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setIsModalOpen(!isModalOpen);
  };

  const handelRegister = () => {
    history.push("/register");
  };
  const handleClose = () => {
    onClose(false);
  };

  const handleLogin = () => {
    handleClose();
    history.push("/");
  };

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Login as Buyer?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msg}
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ marginRight: "auto", marginLeft: 10 }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              toggle(e);
              handleClose();
            }}
            color="primary"
          >
            <a
              href=" "
              className="access-link"
              // data-toggle="modal"
              // data-target="#login_modal"
            >
              Login
            </a>
          </Button>
          <div onClick={handelRegister} className="go-to-register">
            Go To Register
          </div>
        </DialogActions>
      </Dialog>
      <LoginModal isModalOpen={isModalOpen} toggle={(e) => toggle(e)} />
    </div>
  );
}
