import React, { useState } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { popUp } from "../../../utils";
import Select from "react-select";
import TextField from '@material-ui/core/TextField';

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const UploadProduct = (props) => {
  const { remarkModal, handleRemarkModal,stockQuantity, data, setSelectedLog,warehouse,businessmodal } = props;
  const [remarks, setRemarks] = React.useState("");
  const [loading, setLoading] = React.useState("");
  const [shipdate,setShipdate]=React.useState('')
  const [selectedWarehoue,setSelectedWarehouse]=React.useState('')
  const [deliverydate,setDeliverydate]=React.useState('')
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  // const updatestock=()=>{
  //   const data1={
  //     quantity:stockQuantity.newQuantity
  //   }
  //    axios.patch(`/api/listing/inc-update-stock?pro_id=${stockQuantity.id}`,data1).
  //   then((res)=>{
  //     console.log(res)   
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }
  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
    ///.....
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
        const currentUser = JSON.parse(localStorage.getItem("login"));
        const updatedata= {
          id: data.id,
          status: data?.status,
          listing_id: data.productId,
          remarks: remarks,
          cancelledAmount:data?.cancelledAmount || 0,
          updatedBy: currentUser._id,
          onModel: 'users',
         }
         const updatedata1= {
          id: data.id,
          status: data?.status,
          listing_id: data.productId,
          remarks: remarks,
          cancelledAmount:data?.cancelledAmount || 0,
          updatedBy: currentUser._id,
          onModel: 'users',
          delivery_date:deliverydate,
          warehouse:selectedWarehoue,
       }
      if ((businessmodal === 'Consolidation' && data?.status === 'Preparing') ? (
          
          await axios.patch(`/api/order/update-order-by-product`, {
            id: data.id,
            status: data?.status,
            listing_id: data.productId,
            remarks: remarks,
            cancelledAmount:data?.cancelledAmount || 0,
            updatedBy: currentUser._id, 
            onModel: 'users',
            ship_date:shipdate,
            warehouse:selectedWarehoue,
            business_modal:businessmodal
          })
        ):(
          await axios.patch(`/api/order/update-order-by-product`,data?.status==='delayed'?updatedata1:updatedata)
        ))
        // (data.status==='cancelled') && updatestock()
        
        popUp("Status has been updated");
        setSelectedLog({
          id: data?.productDetails?._id,
          productName: data?.productDetails?.partName,
        });
        setRemarks("");
      } catch (error) {
        popUp("Failed to change the status, Server error");
      }
  

    handleRemarkModal(false);
    setLoading(false)
    // window.location.reload();
  };

  const statusOptionPure = warehouse && warehouse.map((stage) => {
    return {
      label: stage?.name,
      value: stage?._id,
    };
  });
  
  const handleDateChange=(e)=>{
    setShipdate(e.target.value)
  }

  const handledeliveryChange=(e)=>{
    setDeliverydate(e.target.value)
  }

  const handleWarehouseChange=(selectedOption)=>{
    setSelectedWarehouse(selectedOption.value)
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="upload-product-wrapper">
        <div className="container">
          <div style={{ padding: "5px 0" }}>
            <b>Order ID</b> : <span>{data?.id?.slice(0, 8).toUpperCase()}</span>
          </div>
          <div style={{ padding: "5px 0" }}>
            <b>Order Date</b> :{" "}
            <span>
              {moment(data?.orderDate).tz("Asia/Dubai").format("DD/MM/YYYY")}
            </span>
          </div>
          <div style={{ padding: "5px 0" }}>
            <b>Order Time</b> :{" "}
            <span>
              {moment(data?.orderDate).tz("Asia/Dubai").format("hh:mm A")}
            </span>
          </div>
          <div style={{ padding: "5px 0" }}>
            <b>Current Log</b> : <span>{data?.previousState}</span>
          </div>
          <div style={{ padding: "5px 0" }}>
            <b>Next Log</b> : <span>{data?.status}</span>
          </div>

          <div className="upload-product-content" style={{ padding: "5px 0" }}>
            <form onSubmit={handleSubmit}>
              <textarea
                rows="5"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Add a note to the buyer"
                style={{
                  border: "1px solid #ddd",
                  width: "100%",
                  outline: "none",
                }}
              />

               {(businessmodal && businessmodal==='Consolidation' && data?.status==='Preparing') && 
               <div>
                    <Select
                  className="react-select"
                  placeholder={'Please Select Warehouse'}
                  options={statusOptionPure}
                  menuPortalTarget={document.body}
                  option
                  required
                  styles={customStyles}
                  // styles={{ color: "black" }}
                  isOptionDisabled={(option) => option.isdisabled}
                  onChange={(selectedOption) => {
                      handleWarehouseChange(selectedOption)
                    }}
                />
    
                <TextField
                  id="date"
                  label="Ship Date"
                  type="date"
                  required
                  style={{width:'100%',marginTop:5}}
                  onChange={(e) =>handleDateChange(e)}
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            
              </div>}

              {(data?.status==='delayed') && 
               <div>
                    <Select
                  className="react-select"
                  placeholder={'Please Select Warehouse'}
                  options={statusOptionPure}
                  menuPortalTarget={document.body}
                  option
                  required
                  styles={customStyles}
                  // styles={{ color: "black" }}
                  isOptionDisabled={(option) => option.isdisabled}
                  onChange={(selectedOption) => {
                      handleWarehouseChange(selectedOption)
                    }}
                  
                />
    
                  <TextField
                  id="datetime-local"
                  label="Delivery Date and Time"
                  type="datetime-local"
                  required
                  style={{width:'100%',marginTop:5}}
                  onChange={(e) =>handledeliveryChange(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            
              </div>}    


              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  className="btn btn-color-blue file_upload_btn"
                  type="submit"
                  style={{ marginTop: 20, width: "48%" }}
                  disabled={loading}
                >
                  Send Note
                </button>
                <button
                  className="btn"
                  type="button"
                  onClick={() => handleRemarkModal(false)}
                  style={{
                    marginTop: 20,
                    width: "48%",
                    background: "#f5f7fc",
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      open={remarkModal}
      onClose={() => handleRemarkModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default UploadProduct;
