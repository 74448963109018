import React, { Fragment, Component } from "react";
// import Moment from 'react-moment';
import { Redirect } from "react-router-dom";
import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import { PageBanner } from "../content/element/page-banner";
import Footer from "../layout/footer";

import BuyerOrder from "../content/element/my-order/buyer-order";
import SellerOrder from "../content/element/my-order/seller-order";
import Pending from "../content/element/UserStatus/Pending";

import { connect } from "react-redux";

class MyOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { login } = this.props;

    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser && currentUser.status === "Pending") return <Pending />;

    return (
      <Fragment>


        {/* <Header className="menu--light" /> */}
        <PageBanner />

        {login?.role === "BUYER" ? (
          <BuyerOrder />
        ) : (
          login?.role === "SELLER" && <Redirect to="/my-sales" />
        )}


      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProp)(MyOrder);
