import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import equal from "fast-deep-equal";
import { withTranslation } from "react-i18next";
import axios from "axios";

import ListingCardGrid from "../content/element/card/card-listing-grid";
import { OptionSelection } from "../content/element/filter/selection";
import { numberWithCommas } from "../../utils";
import { ToastContainer, toast } from "react-toastify";
import { SetListings } from "../../Store/action/listingActions";
import Leaderboard from "../content/element/leaderboard";
import MpuLeaderboard from "../content/element/mpu-leaderboard";
import { SkeletonList } from "./skeleton-listing";
import { NotifyToastWithButtons } from "../common/ToastNotifier/ToastNotifier";

const businessModel = [{ _id: "Consolidation", name: "Consolidation" }, { _id: "Fulfilment", name: "Fulfilment" }]
class Listing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allCategories: null,
			allSubCategoriesBatteries: null,
			list: [],
			emiratesData: [],
			count: 0,
			perPage: 4,
			data: {},
			searchText: "",
			orderText: "",
			searchQuery: {
				string: "",
				priceRange: null,
				type: null,
				category: null,
				tireCats: null,
				tireSubCats: null,
				subCategory: null,
				make: null,
				vinCode: null,
				emirates: null,
				businessModel: null,
				// model: null,
				year: null,
				brand: null,
				sortBy: {},
			},
			pagemake: 0,
			pagemodel: 0,
			pagebrand: 0,
		};
		this.handleSliderChange = this.handleSliderChange.bind(this);
		// this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSelectionChange = this.handleSelectionChange.bind(this);
		this.handleSliderAfterChange = this.handleSliderAfterChange.bind(this);
		// this.searchAction = this.searchAction.bind(this);
		this.resetFilters = this.resetFilters.bind(this);
	}

	componentDidMount() {
		this.setState({
			searchQuery: {
				...this.props.list.searchQuery,
				type: this.props.type
			}
		});
		axios
			.get("/api/products/get-state")
			.then((res) => this.setState({ emiratesData: res?.data?.state }))
			.catch((err) =>
				NotifyToastWithButtons("There was a problem with server. Please try again.")
			);
		// axios
		// 	.get("/api/categories/all")
		// 	.then((res) =>
		// 		// console.log("this.props.allCategoriess", this.props.allCategories)
		// 		this.setState({ allCategories: res.data })
		// 	)

		axios.get('/api/subcategories/get-batteries-subcategories')
			.then((res) => this.setState({ allSubCategoriesBatteries: res?.data?.data }))

		if (this.props.list.tradesoftSKUs.length) {
			axios
				.post("/api/info/techalliance-skus", { skus: this.props.list.tradesoftSKUs })
				.then((res) => {
					let listings = this.props.list.listing || [];
					let techAllListings = res?.data?.listings?.map(l => l?.articleNumber)
					let emirate = this.props.login?.role === "BUYER" ? this.props.login?.details?.emirate : '';
					let user_id = this.props.login?.role === "BUYER" ? this.props.login?._id : '';
					axios.post(`/api/info/tradesoft-skus?emirate=${emirate}&user_id=${user_id}`, { skus: techAllListings }).then((res) => {
						let _listings = res.data?.map(b => {
							return {
								...b,
								date: new Date(),
								pic: b.imageArray[0],
								istecAll: false,
							}
						});

						let newlistings = [...listings, ..._listings];

						//unique listings by _id
						newlistings = newlistings.filter((item, index, self) =>
							index === self.findIndex((t) => (
								t._id === item._id
							))
						);

						this.props.setListings({ listings: newlistings });
					}
					)

				})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!equal(prevProps.list.searchQuery, this.props.list.searchQuery)) {
			this.setState({ allCategories: this.props.allCategories })

			this.setState({
				searchQuery: {
					...this.props.list.searchQuery,
					type: this.props.type
				}
			});
		}
	}

	// searchAction(e) {
	// 	e.preventDefault();
	// 	this.props.handleFilter(this.state.searchQuery);
	// }

	// handleSearchChange(e) {
	// 	e.preventDefault();
	// 	let { searchQuery } = this.state;
	// 	searchQuery.string = e.target.value;
	// 	this.setState({ searchQuery });
	// }

	handleSliderChange(values, name) {
		let { searchQuery } = this.state;
		searchQuery[name] = values;
		this.setState({ searchQuery });
	}

	handleSliderAfterChange() {
		const { searchQuery } = this.state;
		this.props.handleFilter(searchQuery);
	}

	handleSelectionChange(options, name) {
		let { searchQuery } = this.state;
		searchQuery[name] = options;
		this.setState({ searchQuery });
		this.props.handleFilter(searchQuery);
	}

	resetFilters(e) {
		e.preventDefault();
		const initQuery = {
			string: "",
			priceRange: null,
			type: this.props.type,
			emirates: null,
			vinCode: null,
			make: null,
			// model: null,
			subCategory: null,
			category: null,
			sortBy: {},
		};
		this.setState({ searchQuery: initQuery });
		this.props.handleFilter(initQuery);
	}

	handleSearchVin = (e) => {
		e.preventDefault();
		const searchText = this.state.searchText;
		const dataToSend = { ...this.state.searchQuery, vinCode: searchText };
		this.setState({ searchQuery: dataToSend });

		this.props.handleFilter(dataToSend);
	};

	setpagemake = (pageno) => {
		this.setState({ pagemake: pageno });
	};

	setpagemodel = (pageno) => {
		this.setState({ pagemodel: pageno });
	};
	setpagebrnd = (pageno) => {
		this.setState({ pagebrand: pageno });
	};

	render() {
		// sorting here
		const sort = [];
		Object.values(this.state.list).map((item) => {
			return sort.push(item);
		});

		const sorting = (e) => {
			e.preventDefault();

			let { searchQuery } = this.state;

			switch (e.target.getAttribute("href")) {
				case "lowest-price":
					searchQuery["sortBy"] = { price: 1 };
					this.setState({ orderText: "Lowest Price" });
					break;
				case "highest-price":
					searchQuery["sortBy"] = { price: -1 };
					this.setState({ orderText: "Highest Price" });
					break;
				case "new":
					searchQuery["sortBy"] = { date: -1 };
					this.setState({ orderText: "New To Old" });
					break;
				default:
					searchQuery["sortBy"] = { date: -1 };
					this.setState({ orderText: "New To Old" });
					break;
			}
			this.setState({ searchQuery });
			this.props.handleFilter(searchQuery);
		};
		// sorting end
		const { searchQuery, orderText } = this.state;
		const {
			maxPrice,
			minPrice,
			makeList,
			modelList,
			categoryList,
			subCategoryList,
			listing,
			brandList,
			sparePartSearchDetails,
		} = this.props.list;

		const { t } = this.props;
		let rowsPerPage = 10;
		const filteredMake = makeList?.slice(
			this.state.pagemake * rowsPerPage,
			this.state.pagemake * rowsPerPage + rowsPerPage
		);
		const totalpagemake = Math.ceil(makeList?.length / 10);

		const filteredModel = modelList?.slice(
			this.state.pagemodel * rowsPerPage,
			this.state.pagemodel * rowsPerPage + rowsPerPage
		);
		const totalpagemodel = Math.ceil(modelList?.length / 10);

		const filteredBrand = brandList?.slice(
			this.state.pagebrand * rowsPerPage,
			this.state.pagebrand * rowsPerPage + rowsPerPage
		);
		const totalpagebrand = Math.ceil(brandList?.length / 10);

		const getSearchProductOnlyCategory = () => {
			const categoryIds = listing?.map((list) => {
				return list.category;
			});

			//removing duplication from array
			const dataArrWithSet = new Set(categoryIds);
			const categoryIdArray = [...dataArrWithSet];

			//getting the name of that particular ids
			const categories = categoryIdArray?.map((categoryId) => {
				const finedCategory = this.props?.allCategories?.find(
					(category) => category?._id === categoryId
				);
				return finedCategory;
			}).filter(item => !!item);
			//returning the arrays of names category id
			return categories;
		};

		let top_leaderboard_category_banner = this.props?.list?.bannersList?.find((banner) => banner.category === "top_leaderboard_category_banner")

		let bottom_leaderboard_category_banner = this.props?.list?.bannersList?.find((banner) => banner.category === "bottom_leaderboard_category_banner")



		const getSearchProductOnlySubCategoryBattery = () => {
			const categoryIds = listing?.map((list) => {
				return list.subCategory;
			});

			//removing duplication from array
			const dataArrWithSet = new Set(categoryIds);
			const categoryIdArray = [...dataArrWithSet];

			//getting the name of that particular ids
			const categories = this.state.allSubCategoriesBatteries?.filter(item => !!item);
			//returning the arrays of names category id
			return categories;
		};

		let mpu_sidebar_category_banner = this.props?.list.bannersList?.filter((banner) => banner.category === "mpu_sidebar_category_banner")?.sort((a, b) => a.position - b.position)


		const getSearchTireOnlyCategory = () => {
			return categoryList;
		};

		const getSearchTireOnlySubCategory = () => {
			return subCategoryList;
		};
		console.log(this.state.searchQuery, "sdfsf")
		return (
			<Fragment>
				<section className="all-listing-wrapper">
					<div className="container-fluid">
						<div className="row">
							{!this.props.api && (
								<div className="col-lg-3 order-lg-0 order-0 mt-5 mt-lg-0">
									{!this.props.api && (
										<div className="listings-sidebar">
											<div className="search-area default-ad-search">
												<div className="search-area-header">
													{t("all_listings_filters")}
													<button
														className="btn btn-primary"
														onClick={this.resetFilters}
													>
														RESET FILTERS
													</button>
												</div>
												<div
													className="accordion"
													id="filterAccordion"
													role="tablist"
													aria-multiselectable="true"
												>


													<div className="card">
														<div
															className="card-header"
															role="tab"
															id="headingOne8"
														>
															<a
																data-toggle="collapse"
																data-parent="#filterAccordion"
																href="#collapseOne8"
																aria-expanded="true"
																aria-controls="collapseOne8"
																className="collapsed"
															>
																<span className="mb-0">
																	{t("Emirates")}
																</span>
																<i className="la la-angle-down"></i>
															</a>
														</div>
														<div
															id="collapseOne8"
															className="collapse"
															role="tabpanel"
															aria-labelledby="headingOne3"
															data-parent="#filterAccordion"
														>
															<div className="card-body">
																<div className="form-group p-bottom-10">
																	<OptionSelection
																		options={this.state.emiratesData}
																		name="emirates"
																		activeOptions={
																			searchQuery.emirates
																				? searchQuery.emirates
																				: []
																		}
																		onChange={this.handleSelectionChange}
																	/>
																</div>
																{/*<!-- ends: .form-group -->*/}
															</div>
														</div>
													</div>
													{this.props.type == null || this.props.type === 'batteries' && <div className="card">
														<div
															className="card-header"
															role="tab"
															id="headingOne6"
														>
															<a
																data-toggle="collapse"
																data-parent="#filterAccordion"
																href="#collapseOne6"
																aria-expanded="true"
																aria-controls="collapseOne6"
																className="collapsed"
															>
																{console.log("catt")}
																<span className="mb-0">Category</span>
																<i className="la la-angle-down"></i>
															</a>
														</div>
														<div
															id="collapseOne6"
															className="collapse"
															role="tabpanel"
															aria-labelledby="headingOne6"
															data-parent="#filterAccordion"
														>
															<div className="card-body">
																<div className="form-group p-bottom-10">
																	<OptionSelection
																		options={getSearchProductOnlyCategory()}
																		name="category"
																		activeOptions={
																			searchQuery.category
																				? searchQuery.category
																				: []
																		}
																		onChange={this.handleSelectionChange}
																	/>
																</div>
																{/*<!-- ends: .form-group -->*/}
															</div>
														</div>
													</div>}
													{console.log(this.props.type, "type")}
													{this.props.type === 'batteries' && <div className="card">
														<div
															className="card-header"
															role="tab"
															id="headingOne6"
														>
															<a
																data-toggle="collapse"
																data-parent="#filterAccordion"
																href="#collapseOne6fsfesf"
																aria-expanded="true"
																aria-controls="collapseOne6fsfesf"
																className="collapsed"
															>
																<span className="mb-0">Sub Category</span>
																<i className="la la-angle-down"></i>
															</a>
														</div>
														<div
															id="collapseOne6fsfesf"
															className="collapse"
															role="tabpanel"
															aria-labelledby="headingOne6"
															data-parent="#filterAccordion"
														>
															<div className="card-body">
																<div className="form-group p-bottom-10">
																	<OptionSelection
																		options={getSearchProductOnlySubCategoryBattery()}
																		name="subCategory"
																		activeOptions={
																			searchQuery.subCategory
																				? searchQuery.subCategory
																				: []
																		}
																		onChange={this.handleSelectionChange}
																	/>
																</div>
																{/*<!-- ends: .form-group -->*/}
															</div>
														</div>
													</div>}

													{this.props.type == 'tire' && <>
														<div className="card">
															<div
																className="card-header"
																role="tab"
																id="headingOne6"
															>
																<a
																	data-toggle="collapse"
																	data-parent="#filterAccordion"
																	href="#collapseOne7"
																	aria-expanded="true"
																	aria-controls="collapseOne7"
																	className="collapsed"
																>
																	<span className="mb-0">Category</span>
																	<i className="la la-angle-down"></i>
																</a>
															</div>
															<div
																id="collapseOne7"
																className="collapse"
																role="tabpanel"
																aria-labelledby="headingOne6"
																data-parent="#filterAccordion"
															>
																<div className="card-body">
																	<div className="form-group p-bottom-10">
																		<OptionSelection
																			options={getSearchTireOnlyCategory()}
																			name="tireCats"
																			activeOptions={
																				searchQuery.tireCats
																					? searchQuery.tireCats
																					: []
																			}
																			onChange={this.handleSelectionChange}
																		/>
																	</div>
																	{/*<!-- ends: .form-group -->*/}
																</div>
															</div>
														</div>

														<div className="card">
															<div
																className="card-header"
																role="tab"
																id="headingOne6"
															>
																<a
																	data-toggle="collapse"
																	data-parent="#filterAccordion"
																	href="#collapseOne11"
																	aria-expanded="true"
																	aria-controls="collapseOne11"
																	className="collapsed"
																>
																	<span className="mb-0">Sub Category</span>
																	<i className="la la-angle-down"></i>
																</a>
															</div>
															<div
																id="collapseOne11"
																className="collapse"
																role="tabpanel"
																aria-labelledby="headingOne6"
																data-parent="#filterAccordion"
															>
																<div className="card-body">
																	<div className="form-group p-bottom-10">
																		<OptionSelection
																			options={getSearchTireOnlySubCategory()}
																			name="tireSubCats"
																			activeOptions={
																				searchQuery.tireSubCats
																					? searchQuery.tireSubCats
																					: []
																			}
																			onChange={this.handleSelectionChange}
																		/>
																	</div>
																	{/*<!-- ends: .form-group -->*/}
																</div>
															</div>
														</div>
													</>}

													<div className="card">
														<div
															className="card-header"
															role="tab"
															id="headingOne1"
														>
															<a
																data-toggle="collapse"
																data-parent="#filterAccordion"
																href="#collapseOne1"
																aria-expanded="true"
																aria-controls="collapseOne1"
																className="collapsed"
															>
																<span className="mb-0">{t("price")}</span>
																<i className="la la-angle-down"></i>
															</a>
														</div>
														<div
															id="collapseOne1"
															className="collapse"
															role="tabpanel"
															aria-labelledby="headingOne1"
															data-parent="#filterAccordion"
														>
															<div className="card-body">
																<div className="form-group p-bottom-10">
																	<div className="price-range rs-primary">
																		<p className="d-flex justify-content-between">
																			<span className="amount">
																				{searchQuery.priceRange
																					? `${numberWithCommas(
																						searchQuery.priceRange[0]
																					)}${this.props.currency.currency
																					} - ${numberWithCommas(
																						searchQuery.priceRange[1]
																					)}${this.props.currency.currency
																					} `
																					: `${numberWithCommas(minPrice)}${this.props.currency.currency
																					}  - ${numberWithCommas(
																						maxPrice
																					)}${this.props.currency.currency
																					} `}
																			</span>
																		</p>
																		<Range
																			min={minPrice}
																			max={maxPrice}
																			defaultValue={[minPrice, maxPrice]}
																			value={
																				searchQuery.priceRange
																					? searchQuery.priceRange
																					: [minPrice, maxPrice]
																			}
																			onChange={(values) =>
																				this.handleSliderChange(
																					values,
																					"priceRange"
																				)
																			}
																			onAfterChange={
																				this.handleSliderAfterChange
																			}
																		/>
																	</div>
																	{/*<!-- ends: .price-range -->*/}
																</div>
																{/*<!-- ends: .form-group -->*/}
															</div>
														</div>
													</div>

													<div className="card">
														<div
															className="card-header"
															role="tab"
															id="headingOne3"
														>
															<a
																data-toggle="collapse"
																data-parent="#filterAccordion"
																href="#collapseOne3"
																aria-expanded="true"
																aria-controls="collapseOne3"
																className="collapsed"
															>
																<span className="mb-0">{t("make")}</span>
																<i className="la la-angle-down"></i>
															</a>
														</div>
														<div
															id="collapseOne3"
															className="collapse"
															role="tabpanel"
															aria-labelledby="headingOne3"
															data-parent="#filterAccordion"
														>
															<div className="card-body">
																<div className="form-group p-bottom-10">
																	<OptionSelection
																		options={filteredMake}
																		page={this.state.pagemake}
																		setpage={this.setpagemake}
																		totalpage={totalpagemake}
																		show={true}
																		name="make"
																		activeOptions={
																			searchQuery.make
																				? searchQuery.make
																				: []
																		}
																		onChange={this.handleSelectionChange}
																	/>
																</div>
																{/*<!-- ends: .form-group -->*/}
															</div>
														</div>
													</div>
													{/* 
                          <div className="card">
                            <div
                              className="card-header"
                              role="tab"
                              id="headingOne4"
                            >
                              <a
                                data-toggle="collapse"
                                data-parent="#filterAccordion"
                                href="#collapseOne4"
                                aria-expanded="true"
                                aria-controls="collapseOne4"
                                className="collapsed"
                              >
                                <span className="mb-0">{t("model")}</span>
                                <i className="la la-angle-down"></i>
                              </a>
                            </div>
                            <div
                              id="collapseOne4"
                              className="collapse"
                              role="tabpanel"
                              aria-labelledby="headingOne4"
                              data-parent="#filterAccordion"
                            >
                              <div className="card-body">
                                <div className="form-group p-bottom-10">
                                  <OptionSelection
                                    options={filteredModel}
                                    page={this.state.pagemodel}
                                    setpage={this.setpagemodel}
                                    totalpage={totalpagemodel}
                                    show={true}
                                    name="model"
                                    activeOptions={
                                      searchQuery.model
                                        ? searchQuery.model
                                        : []
                                    }
                                    onChange={this.handleSelectionChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}

													<div className="card">
														<div
															className="card-header"
															role="tab"
															id="headingOne5"
														>
															<a
																data-toggle="collapse"
																data-parent="#filterAccordion"
																href="#collapseOne5"
																aria-expanded="true"
																aria-controls="collapseOne5"
																className="collapsed"
															>
																<span className="mb-0">Part Brands</span>
																<i className="la la-angle-down"></i>
															</a>
														</div>

														<div
															id="collapseOne5"
															className="collapse"
															role="tabpanel"
															aria-labelledby="headingOne5"
															data-parent="#filterAccordion"
														>
															<div className="card-body">
																<div className="form-group p-bottom-10">
																	{/* send brandList in options if required all brands */}
																	<OptionSelection
																		options={filteredBrand}
																		page={this.state.pagebrand}
																		setpage={this.setpagebrnd}
																		totalpage={totalpagebrand}
																		show={true}
																		name="brand"
																		activeOptions={
																			searchQuery.brand
																				? searchQuery.brand
																				: []
																		}
																		onChange={this.handleSelectionChange}
																	/>
																</div>
																{/*<!-- ends: .form-group -->*/}
															</div>
														</div>

													</div>

													<div className="card">
														<div
															className="card-header"
															role="tab"
															id="headingOne6"
														>
															<a
																data-toggle="collapse"
																data-parent="#filterAccordion"
																href="#collapseOne6"
																aria-expanded="true"
																aria-controls="collapseOne6"
																className="collapsed"
															>
																<span className="mb-0">Business Model</span>
																<i className="la la-angle-down"></i>
															</a>
														</div>

														<div
															id="collapseOne6"
															className="collapse"
															role="tabpanel"
															aria-labelledby="headingOne6"
															data-parent="#filterAccordion"
														>
															<div className="card-body">
																<div className="form-group p-bottom-10">
																	{/* send brandList in options if required all brands */}
																	<OptionSelection
																		options={businessModel}
																		name="businessModel"
																		activeOptions={
																			searchQuery.businessModel
																				? searchQuery.businessModel
																				: []
																		}
																		onChange={this.handleSelectionChange}
																	/>
																</div>
																{/*<!-- ends: .form-group -->*/}
															</div>
														</div>

													</div>
												</div>
											</div>
										</div>
									)}
									{
										mpu_sidebar_category_banner?.length > 0 && <MpuLeaderboard banners={mpu_sidebar_category_banner} />
									}
								</div>
							)}
							{/* wiget */}
							<div
								className={
									this.props.api
										? `col-lg-12 order-lg-1 order-1`
										: `col-lg-9 order-lg-1 order-1`
								}
							>
								{
									top_leaderboard_category_banner && <Leaderboard banner={top_leaderboard_category_banner} />
								}
								<div className="row">
									<div className="col-lg-12">
										<div className="atbd_listing_action_toolbar mobile-view">
											<p
												style={{
													margin: 20,
													fontSize: 18,
													fontWeight: "bold",
												}}
											>
												Find Spare Parts for{" "}
												{this.props.api ? (
													<span
														style={{
															color: "#1e71b8",
															fontSize: "14px",
														}}
													>
														{sparePartSearchDetails?.make &&
															`Make:${sparePartSearchDetails?.make}`}{" "}
														{sparePartSearchDetails?.model &&
															`Model:${sparePartSearchDetails?.model}`}{" "}
														{sparePartSearchDetails?.engine &&
															`Engine:${sparePartSearchDetails?.engine}`}{" "}
													</span>
												) : (
													<span>
														{this.props.category || searchQuery.string}
													</span>
												)}
											</p>
											{!this.props.api && (
												<div className="dropdown dropdown-right">
													<a
														className="action-btn dropdown-toggle"
														href=" "
														role="button"
														id="dropdownMenuLink2"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														{t("all_listings_sort_by")}:{" "}
														<span className="sort-name">{orderText}</span>
														<span className="caret"></span>
													</a>
													<div
														className="dropdown-menu dropdown-menu-right"
														aria-labelledby="dropdownMenuLink2"
													>
														<a
															className="dropdown-item"
															onClick={sorting}
															href="lowest-price"
														>
															Lowest Price
														</a>
														<a
															className="dropdown-item"
															onClick={sorting}
															href="highest-price"
														>
															Highest Price
														</a>
														<a
															className="dropdown-item"
															onClick={sorting}
															href="new"
														>
															New to Old
														</a>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								<div
									className="row listing-5cards-wrapper"
									style={{ display: "block" }}
								>
									<ListingCardGrid
										size={3}
										api={this.props.api}
										handleFilter={this.props.handleFilter}
										_searchQuery={this.state.searchQuery}
									/>
									{
										bottom_leaderboard_category_banner && <Leaderboard banner={bottom_leaderboard_category_banner} />
									}
								</div>
							</div>
						</div>
					</div>
				</section>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		login: state.login,
		list: state.list,
		currency: state.currency,
		allCategories: state.category.allCategories,
	};
};


const mapDispatchToProp = (dispatch) => {
	return {
		setListings: (data) => dispatch(SetListings(data))
	};
};

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProp)
)(Listing);