import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch } from 'react-redux'
import Header from "../../layout/header";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import { PageBanner } from "../../content/element/page-banner";
import qs from "query-string";
import { popUp } from "../../../utils";
import { SetListings } from "../../../Store/action/listingActions";
import { useHistory } from "react-router";
const Index = () => {
    const [tireKeyword, setTireKeyword] = useState('')
    const [batteryKeyword, setBatteryKeyword] = useState('')
    const [batteryYear, setBatteryYear] = useState('')
    const [tireYear, setTireYear] = useState('')
    const [width, setWidth] = useState(null)
    const [rimSize, setRimSize] = useState(null)
    const [makeList, setMakeList] = useState([])
    const [modelList, setModelList] = useState([])
    const [tireBrandList, setTireBrandList] = useState([])
    const [batteriesBrandList, setBatteriesBrandList] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])
    const [seletedBrandBatteries, setSelectedBrandBatteries] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [height, setHeight] = useState(null)
    const [voltage, setVoltage] = useState(null)
    const [amp, setAmp] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const getMakes = async () => {
        try {
            const res = await axios
                .get("/api/listing/get-makes")
            const list = res.data.map((data) => ({
                label: data.name,
                value: data.id_car_make,
                _id: data._id,
            }));
            setMakeList(list)
        } catch (error) {
            console.log({ error })
        }
    }


    const getBrands = async () => {
        try {
            const res = await axios
                .get("/api/listing/get-tire-brands")
            const list = res.data.map((data) => ({
                label: data,
                value: data,
            }));
            setTireBrandList(list)
        } catch (error) {
            console.log({ error })
        }
    }

    const getBatteriesBrands = async () => {
        try {
            const res = await axios
                .get("/api/listing/get-batteries-brands")
            const list = res.data.map((data) => ({
                label: data,
                value: data,
            }));
            setBatteriesBrandList(list)
        } catch (error) {
            console.log({ error })
        }
    }

    const handleChangeBrand = (options) => {
        if (!options) {
            setMakes([])
            return;
        }
        const brands = options?.map((o) => {
            return o.value;
        });
        setSelectedBrand(brands)
    }

    const handleChangeBrandBatteries = (options) => {
        // if (!options) {
        //     setMakes([])
        //     return;
        // }
        const brands = options?.map((o) => {
            return o.value;
        });
        setSelectedBrandBatteries(brands)
    }

    function handleChangeMake(options) {
        if (!options) {
            setMakes([])
            return;
        }
        const make_ids = options.map((o) => {
            return o.value;
        });
        axios
            .post("api/listing/get-models", { makes: make_ids })
            .then((res) => {
                const list = res.data.map((data) => {
                    return { label: data.name, value: data.id_car_model, _id: data._id };
                });
                const makes = options.map((o) => o._id);
                setModelList(list)
                setMakes(makes)
            })
    }

    function handleChangeModel(options) {
        if (!options) {
            setModels([])
            return;
        }
        const models = options.map((o) => o._id);
        setModels(models)
    }


    const searchTires = async () => {
        try {
            setLoading(true)
            const currentUser = JSON.parse(localStorage.getItem("login"));
            let emirate = currentUser?.role === "BUYER" ? currentUser.details?.emirate : ''

            let user_id = currentUser?.role === "BUYER" ? currentUser._id : '';
            let URL = `/api/listing/get-tires?q=${tireKeyword}&user_id=${user_id}&emirate=${emirate}`;
            if (width) URL = `${URL}&width=${width}`;
            if (height) URL = `${URL}&height=${height}`;
            if (rimSize) URL = `${URL}&rimSize=${rimSize}`;
            if (tireYear) URL = `${URL}&tireYear=${tireYear}`;
            if (selectedBrand.length > 0) URL = `${URL}&brands=${selectedBrand}`;
            let res = await axios.get(URL)
            dispatch(SetListings(res.data))
            history.push('/all-listings?tire=true')
        } catch (error) {
            popUp('Tires not found.')
        } finally {
            setLoading(false)
        }
    }
    const searchBatteries = async () => {
        try {
            setLoading(true)
            const currentUser = JSON.parse(localStorage.getItem("login"));
            let emirate = currentUser?.role === "BUYER" ? currentUser.details?.emirate : ''
            let user_id = currentUser?.role === "BUYER" ? currentUser._id : '';
            let URL = `/api/listing/get-batteries?q=${batteryKeyword}&user_id=${user_id}&emirate=${emirate}`;
            if (voltage) URL = `${URL}&voltage=${voltage}`;
            if (amp) URL = `${URL}&amp=${amp}`;
            if (batteryYear) URL = `${URL}&batteryYear=${batteryYear}`;
            if (makes.length > 0) URL = `${URL}&makes=${makes}`;
            if (models.length > 0) URL = `${URL}&models=${models}`;
            if (seletedBrandBatteries.length > 0) URL = `${URL}&partBrand=${seletedBrandBatteries}`;
            let res = await axios.get(URL)
            dispatch(SetListings(res.data))
            history.push('/all-listings?batteries=true')
        } catch (error) {
            popUp('Tires not found.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getMakes()
        getBrands()
        getBatteriesBrands()
    }, [])

    return (
        <Fragment>
            {/* <LoadingOverlay active={loading} spinner text={`Loading...`}> */}
            <PageBanner title="Parts Search Vin" />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="searh-vin-page">
                            <section className="search-vin-col">

                                <h2>Tire</h2>
                                <p>
                                    Search tires accordingly.
                                </p>
                                <div className="vin-search-wrapper">
                                    <i className="la la-search"></i>
                                    <input
                                        type="text"
                                        name="search"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Tires..."
                                        onChange={(e) => setTireKeyword(e.target.value)}
                                        value={tireKeyword}
                                    />
                                </div>
                                <p className="ex-text">
                                    For example: tires
                                </p>

                                <div className="car-identified-wrap">
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Width:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="width"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="185"
                                                onChange={(e) => setWidth(e.target.value)}
                                                value={width}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Height:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="height"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="65"
                                                onChange={(e) => setHeight(e.target.value)}
                                                value={height}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            RIM Size:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="rimSize"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="15"
                                                onChange={(e) => setRimSize(e.target.value)}
                                                value={rimSize}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Brand:
                                        </label>
                                        <div className="col-sm-9">
                                            <Select
                                                className={`react-select`}
                                                classNamePrefix="react-select"
                                                placeholder="Choose Brands..."
                                                name="make"
                                                onChange={handleChangeBrand}
                                                options={tireBrandList}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                                <label className="control-label col-sm-3">
                                                    Year:
                                                </label>
                                                <div className="col-sm-9">
                                                <input
                                                    type="number"
                                                    name="year"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="15"
                                                    onChange={(e) => setTireYear(e.target.value)}
                                                    value={tireYear}
                                                />
                                                </div>
                                            </div> */}
                                    <button
                                        id="findTires"
                                        style={{ fontSize: "1rem" }}
                                        disabled={loading}
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        onClick={searchTires}
                                    >
                                        Find Tires
                                    </button>
                                </div>
                            </section>


                            {/* for batteries */}
                            <div className="separator-line">
                                <span>or</span>
                            </div>
                            <section className="search-vin-col">

                                <h2>Batteries</h2>
                                <p>
                                    Search Batteries accordingly.
                                </p>
                                <div className="vin-search-wrapper">
                                    <i className="la la-search"></i>
                                    <input
                                        type="text"
                                        name="search"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Batteries..."
                                        onChange={(e) => setBatteryKeyword(e.target.value)}
                                        value={batteryKeyword}
                                    />
                                </div>
                                <p className="ex-text">
                                    For example: batteries
                                </p>
                                <div className="car-identified-wrap">
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Brand:
                                        </label>
                                        <div className="col-sm-9">
                                            <Select
                                                className={`react-select`}
                                                classNamePrefix="react-select"
                                                placeholder="Choose Brands..."
                                                name="partBrand"
                                                onChange={handleChangeBrandBatteries}
                                                options={batteriesBrandList}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                            <label className="control-label col-sm-3">
                                                Make:
                                            </label>
                                            <div className="col-sm-9">
                                                <Select
                                                    className={`react-select`}
                                                    classNamePrefix="react-select"
                                                    placeholder="Choose Make..."
                                                    name="make"
                                                    onChange={handleChangeMake}
                                                    options={makeList}
                                                    isMulti={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="control-label col-sm-3">
                                                Model:
                                            </label>
                                            <div className="col-sm-9">
                                                <Select
                                                    className={`react-select`}
                                                    classNamePrefix="react-select"
                                                    placeholder="Choose model..."
                                                    name="model"
                                                    onChange={handleChangeModel}
                                                    options={modelList}
                                                    isMulti={true}
                                                />
                                            </div>
                                        </div> */}
                                    {/* <div className="form-group row">
                                                <label className="control-label col-sm-3">
                                                    Year:
                                                </label>
                                                <div className="col-sm-9">
                                                <input
                                                    type="number"
                                                    name="year"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="15"
                                                    onChange={(e) => setBatteryYear(e.target.value)}
                                                    value={batteryYear}
                                                />
                                                </div>
                                            </div> */}
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Voltage:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="voltage"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="15"
                                                onChange={(e) => setVoltage(e.target.value)}
                                                value={voltage}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Amp:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="amp"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="15"
                                                onChange={(e) => setAmp(e.target.value)}
                                                value={amp}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        id="findBatteries"
                                        style={{ fontSize: "1rem" }}
                                        disabled={loading}
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        onClick={searchBatteries}
                                    >
                                        Find Batteries
                                    </button>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>
            {/* </LoadingOverlay> */}
        </Fragment>

    )
}

export default Index