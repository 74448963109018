import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";

const index = ({ children }) => {
  return (
    <>
      {/* <PreHeader />
      <Header /> */}

      {children}
      {/* <Footer /> */}
    </>
  );
};

export default index;
