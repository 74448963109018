import React from 'react'

const MpuLeaderboard = ({ banners }) => {
  return (
    <>
      {
        banners.map((banner, index) => {
          return (
            <section key={index} className="top-M">
              <div className="container" style={{ marginBottom: "10px", marginTop: "10px", padding: '0px' }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="top-M-content d-flex" style={{ justifyContent: "center" }}>
                      <a href={banner?.banner_link}>
                        <img style={{ height: "280px", width: "336px", objectFit: 'cover' }} src={banner?.banner_upload} alt="banner" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        })
      }
    </>
  )
}

export default MpuLeaderboard