import React, { Fragment, Component } from "react";

import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>


        <div
          className="breadcrumb-wrapper cover innerpage-banner"
          style={{ backgroundImage: 'url("/assets/img/section-banner.jpg")' }}
        >
          <div className="inner-container">
            <h1>Terms and conditions</h1>
          </div>
        </div>
        <section>
          <div className="inner-container">
            <h3>WHO WE ARE</h3>
            <p>
              The website{" "}
              <Link to="https://www.buyparts24.com/">
                https://www.buyparts24.com/
              </Link>{" "}
              is operated by <strong>GA AUTO PLATFORMS DMCC</strong>, with
              e-market place service licence No. DMCC-808255 and general trading
              licence number DMCC-808254 whose registered office address is Unit
              No 2182 Business Centre, Level No1- Jewellery & Gemplex 3- Dubai,
              United Arab Emirates, of PO Box 294377 (
              <strong>“we”, “our” or “us”</strong>) so that we may: (1) help
              direct you to products and services which may be of interest to
              you; and (2) contact you with information and offers, suggestions
              and recommendations of products and services which may be of
              interest to you.
            </p>
            <p>
              Where we use <strong>“our site”</strong> in this privacy notice,
              we mean https://www.buyparts24.com/{" "}
            </p>
            <h3>OUR PRIVACY TERMS</h3>
            <ol type="a">
              <li>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on Sparepartsmarketplace Pty
                Ltd's website for personal, non-commercial transitory viewing
                only. This is the grant of a license, not a transfer of title,
                and under this license you may not:
                <ol type="i">
                  <li>modify or copy the materials;</li>
                  <li>
                    use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial);
                  </li>
                  <li>
                    attempt to decompile or reverse engineer any software
                    contained on Sparepartsmarketplace Pty Ltd's website;
                  </li>
                  <li>
                    remove any copyright or other proprietary notations from the
                    materials; or
                  </li>
                  <li>
                    transfer the materials to another person or "mirror" the
                    materials on any other server.
                  </li>
                </ol>
              </li>
              <li>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by
                Sparepartsmarketplace Pty Ltd at any time. Upon terminating your
                viewing of these materials or upon the termination of this
                license, you must destroy any downloaded materials in your
                possession whether in electronic or printed format.
              </li>
            </ol>
            <h3>3. Disclaimer</h3>
            <ol type="a">
              <li>
                The materials on Sparepartsmarketplace Pty Ltd's website are
                provided on an 'as is' basis. Sparepartsmarketplace Pty Ltd
                makes no warranties, expressed or implied, and hereby disclaims
                and negates all other warranties including, without limitation,
                implied warranties or conditions of merchantability, fitness for
                a particular purpose, or non-infringement of intellectual
                property or other violation of rights.
              </li>
              <li>
                Further, Sparepartsmarketplace Pty Ltd does not warrant or make
                any representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites linked to
                this site.
              </li>
            </ol>
            <h3>4. Limitations</h3>
            <p>
              In no event shall Sparepartsmarketplace Pty Ltd or its suppliers
              be liable for any damages (including, without limitation, damages
              for loss of data or profit, or due to business interruption)
              arising out of the use or inability to use the materials on
              Sparepartsmarketplace Pty Ltd's website, even if
              Sparepartsmarketplace Pty Ltd or a Sparepartsmarketplace Pty Ltd
              authorized representative has been notified orally or in writing
              of the possibility of such damage. Because some jurisdictions do
              not allow limitations on implied warranties, or limitations of
              liability for consequential or incidental damages, these
              limitations may not apply to you.
            </p>
            <h3>5. Accuracy of materials</h3>
            <p>
              The materials appearing on Sparepartsmarketplace Pty Ltd's website
              could include technical, typographical, or photographic errors.
              Sparepartsmarketplace Pty Ltd does not warrant that any of the
              materials on its website are accurate, complete or current.
              Sparepartsmarketplace Pty Ltd may make changes to the materials
              contained on its website at any time without notice. However
              Sparepartsmarketplace Pty Ltd does not make any commitment to
              update the materials.
            </p>
            <h3>6. Links</h3>
            <p>
              Sparepartsmarketplace Pty Ltd has not reviewed all of the sites
              linked to its website and is not responsible for the contents of
              any such linked site. The inclusion of any link does not imply
              endorsement by Sparepartsmarketplace Pty Ltd of the site. Use of
              any such linked website is at the user's own risk.
            </p>
            <h3>7. Modifications</h3>
            <p>
              Sparepartsmarketplace Pty Ltd may revise these terms of service
              for its website at any time without notice. By using this website
              you are agreeing to be bound by the then current version of these
              terms of service.
            </p>
            <h3>8. Governing Law</h3>
            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of Australia and you irrevocably submit
              to the exclusive jurisdiction of the courts in that State or
              location.
            </p>
          </div>
        </section>

      </Fragment>
    );
  }
}

export default Terms;
