import React from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import Rating from "@material-ui/lab/Rating";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import NoData from "../Nodata";
import Loader from "../Loader";
import CssTextField from "../SearchField";
import PageTitle from "../PageTitle";

const MyReviewCard = () => {
  const [reviews, setReviews] = React.useState([]);
  const [role, setRole] = React.useState(null);
  const [value, setValue] = React.useState(2);
  const [current, setCurrent] = React.useState();
  const [searchField, setSearchField] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  const fetchReviewData = async (id, role) => {
    setLoader(true);
    let url =
      role === "BUYER"
        ? `/api/review/my-reviews?user_id=${id}`
        : `/api/review/my-reviews-seller?user_id=${id}`;
    const res = await axios.get(url);
    if (res.data) {
      setReviews(res.data);
      setLoader(false);
    }
    setLoader(false);
    setRole(role);
  };

  React.useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    setCurrent(currentUser);
    currentUser._id && fetchReviewData(currentUser._id, currentUser.role);
  }, []);

  const columns = [
    {
      title: <span className="tryit">Products</span>,
      field: "",
      render: (r) => {
        let { star, seller, user, list } = r;
        let url =
          r?.list?.imageArray?.[0] ||
          process.env.PUBLIC_URL + "/assets/img/product-default.jpg";

        return (
          <div className="atbd_listing_image d-flex">
            <a style={{ margin: "auto 0" }}>
              <img
                src={url || "/assets/img/product-default.jpg"}
                alt="listingimage"
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "100%",
                  objectFit: "cover",
                }}
              />
            </a>
            <div className="m-3">
              <p style={{ margin: "auto 0" }}>{list?.partName}</p>
            </div>
          </div>
        );
      },
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title:
        current?.role == "SELLER" ? (
          <span className="tryit">Reviewer</span>
        ) : (
          <span className="tryit">Seller</span>
        ),
      field: "",
      render: (r) => {
        let { star, seller, user, list } = r;
        let url =
          r?.list?.imageArray?.[0] ||
          process.env.PUBLIC_URL + "/assets/img/product-default.jpg";

        return (
          <div>
            {current?.role == "SELLER" ? (
              <div className="d-flex">
                <img
                  src={user?.avatar || "/assets/img/user-default.png"}
                  style={{ height: 40, width: 40, borderRadius: "100%" }}
                />
                <p className="m-2">{user?.email}</p>
              </div>
            ) : (
              <div>
                <h6>{seller?.name}</h6>
              </div>
            )}
          </div>
        );
      },
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Reviews</span>,
      field: "",
      render: (r) => {
        let { star, seller, user, list } = r;
        let url =
          r?.list?.imageArray?.[0] ||
          process.env.PUBLIC_URL + "/assets/img/product-default.jpg";

        return (
          <div>
            <div style={{ lineHeight: "30px" }}>
              <Box component="fieldset" mb={3} borderColor="transparent">
                <Rating
                  name="read-only"
                  style={{ fontSize: 16, color: "#f3920" }}
                  value={star}
                  readOnly
                />
              </Box>
              <p style={{ marginTop: "-30px" }}>{r.comment}</p>
            </div>
          </div>
        );
      },
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
  ];
  const filteredOrders = reviews?.filter((item) => {
    return (
      item?.user?.email?.toLowerCase().includes(searchField?.toLowerCase()) ||
      item?.list?.partName?.toLowerCase().includes(searchField?.toLowerCase())
    );
  });

  if (loader) return <Loader />;

  return (
    <>
      <PageTitle title="My Reviews" />
      <div className="dashboard-search-box">
        <div className="ml-auto">
          <CssTextField
            id="standard-basic"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            label="Search by ID"
          />
        </div>
        <div>
          <i className="las la-search la-search search-icon-datatable"></i>
        </div>
      </div>
      {reviews && reviews.length > 0 ? (
        <div className="row">
          <div className="col">
            <div>
              <div className="order-list-wrapper">
                <MaterialTable
                  style={{ zIndex: 0 }}
                  title={null}
                  data={filteredOrders}
                  localization={{
                    body: {
                      emptyDataSourceMessage: (
                        <img
                          src="/assets/img/nodata.png"
                          className="datatableimage"
                        />
                      ),
                    },
                  }}
                  columns={columns}
                  options={{
                    search: false,
                    rowStyle: (x) => {
                      if (x.tableData.id % 2) {
                        return { backgroundColor: "#f2f2f2" };
                      }
                    },
                    paging: true,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [20, 50, 100],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoData
          text="You have not made any sale yet, so you can't have reviews. Once you
             will make your first sale, you will start collecting reviews from
             your customers on BuyParts24."
        />
      )}
    </>
  );
};

export default MyReviewCard;
