import React, { Component, Fragment } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Modal from "react-awesome-modal";
import UploadQuotationModal from "./upload";
import { connect } from "react-redux";
import { compose } from "redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import {
  UpdateQuotationItems,
  ClearQuotation,
} from "../../../../Store/action/quotationActions";
import ResponseQuotation from "../modal/response-quotation";
import { baseUrl } from "../../../../constants";
import MaterialTable from "material-table";
import moment from "moment-timezone";
import TextField from "@material-ui/core/TextField";
import Loader from "../Loader";
import { popUp, stringToUrl } from "../../../../utils";
import CssTextField from "../SearchField";
import PageTitle from "../PageTitle";
import { Button } from "@material-ui/core";
import EditResponseQuotation from "../modal/edit-response-quotation";

class SellerQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationExcels: [],
      lists: [],
      notes: "",
      modalQuotation: false,
      loadQuotationWithId: null,
      editmodalQuotation: false,
      editloadQuotationWithId: null,
      sendBuyer: "",
      quotationList: [],
      quotationListNotes: [],
      openSendQuotationModal: false,
      selectedTabs: "all",
      searchField: "",
      loader: false,
      byProductSearch: "",
      editfilename: '',
      editnotes: '',
      buyerId: null,

    };
    this.handleUploadQuotationModal =
      this.handleUploadQuotationModal.bind(this);
    this.getStatus = this.getStatus.bind(this);
  }

  componentDidUpdate() {
    // this.componentDidMount();
  }

  componentDidMount() {
    this.setState({ loader: true });

    this.refreshQuotationExcels();
    axios
      .get(`/api/products/get-seller-quotation/${this.props.login._id}`)
      .then((res) => {
        //  const trry=res?.data?.data.reduce((accumulator, currentValue)=> [...accumulator.listing,...currentValue.listing]
        //  )
        const trry = res?.data.data
          .map((item) => {
            return item.listing;
          })
          .reduce(
            (accumulator, currentValue) => [...accumulator, ...currentValue],
            []
          );
        this.setState({
          quotationList: trry,
          quotationListNotes: res?.data?.data,
        });
      })
      .catch((err) =>
        popUp("There was a problem with server. Please try again.")
      );
  }

  downloadRequest = (file_name) => {
    axios
      .get("/api/products/download-excel/" + file_name)
      .then((res) => { })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  };

  refreshQuotationExcels() {
    axios
      .get("/api/products/all?user_id=" + this.props.login._id)
      .then((res) => {
        const { data } = res.data;
        this.setState({ lists: data });
        this.setState({ loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
      });
    axios
      .get("/api/quotation/list-excel?buyer=" + this.props.login._id)
      .then((res) => {
        if (res.data.list) this.setState({ quotationExcels: res.data.list });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  closeQuotationModal = () => {
    this.setState({
      modalQuotation: false,
      loadQuotationWithId: null,
    });
  };
  editcloseQuotationModal = () => {
    this.setState({
      editmodalQuotation: false,
      editloadQuotationWithId: null,
    });
  };

  openQuotation = (index) => {
    const quotation = this.state.quotationExcels[index];
    this.setState({
      modalQuotation: true,
      loadQuotationWithId: quotation._id,
    });
  };

  handleUploadQuotationModal = (res) => {
    this.setState({ openSendQuotationModal: res });
  };

  getStatus(status) {
    if (status == "Active") {
      return "active";
    }
    if (status == "Pending") {
      return "pending";
    }
  }
  handleCancleRFQ(id) {
    let data = {
      _id: id
    }
    axios.patch(`/api/products/cancle-rfq-response`, data)
      .then((res) => {
        this.refreshQuotationExcels();
        popUp('Successfully cancelled your Quotation Response!')
        // setTimeout(() => {
        //   window.location.reload()
        // }, [1500])


      }).catch((err) => {
        popUp('Failed to cancelled your Quotation Response!')

      })
  }

  render() {
    const { lists } = this.state;

    const columns = [
      {
        title: <span className="tryit">Quotation Id</span>,
        field: "",
        render: (lists) => "Q-" + lists?._id.slice(0, 8).toUpperCase(),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Buyer Name</span>,
        field: "",
        render: (lists) => lists?.buyer.details.garage_name,
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },

      {
        title: <span className="tryit">Date/Time</span>,
        field: "",
        render: (lists) => {
          return (
            <span>
              {moment(lists?.date).tz("Asia/Dubai").format("DD/MM/YYYY")}
              <br />
              {moment(lists?.date).tz("Asia/Dubai").format("HH:ss:mm")}
            </span>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Status</span>,
        field: "",
        render: (lists) => {
          return (
            <div
              className={`${this.getStatus(
                lists?.status
              )}-quotation-status-one`}
            >
              {this.getStatus(lists?.status)}
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Quotation Request</span>,
        field: "",
        render: (lists) => (
          <div
            // onClick={() => this.downloadRequest(lists?.originalName)}
            style={{ cursor: "pointer" }}
          >
            <a href={`${baseUrl}/${lists?.originalName}`} download>
              <GetAppIcon style={{ color: "green", fontSize: 30 }} />
            </a>
          </div>
        ),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Quotation Response</span>,
        field: "",
        render: (lists) => (
          <div className="d-flex">
            {lists?.responseFileUrl ?
              <div
                // onClick={() => this.downloadRequest(lists?.responseFileUrl)}
                style={{ cursor: "pointer" }}
              >
                <a href={`${baseUrl}/${lists?.responseFileUrl}`} download>
                  <GetAppIcon style={{ color: "green", fontSize: 30 }} />
                </a>
              </div> : <button
                disabled={lists.status == "Active"}
                className="btn btn-primary btn-xs"
                onClick={(e) =>
                  this.setState({
                    modalQuotation: true,
                    sendBuyer: lists?._id,
                    buyerId: lists?.buyer?._id
                  })
                }
              >
                Send Quotations
              </button>}
          </div>
        ),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },

      {
        title: <span className="tryit">Action</span>,
        field: "",
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
        render: (lists) => (
          <>{
            lists?.responseFileUrl && <>

              <div className="primary-btn"
                onClick={(e) =>
                  this.setState({
                    editmodalQuotation: true,
                    sendBuyer: lists?._id,
                    editnotes: lists?.buyerNotes,
                    editloadQuotationWithId: lists?._id,
                    editfilename: lists?.responseFileUrl,
                    buyerId: lists?.buyer?._id
                  })
                }
              >Edit</div>
              <div
                className="primary-btn mt-2"
                style={{ backgroundColor: '#f39200' }}
                onClick={(e) => this.handleCancleRFQ(lists?._id)}
              >
                Cancel</div>
            </>
          }

          </>
        ),
      },
    ];

    const filteredOrders = this.state.lists.filter((item) => {
      return (
        item?.status
          .toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?._id
          .toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.buyer.email
          .toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.buyer.details.garage_name
          .toLowerCase()
          .includes(this.state?.searchField?.toLowerCase())
      );
    });

    const columns1 = [
      {
        title: <span className="tryit">RFQ ID</span>,
        field: "",
        render: (lists) => "RFQ-" + lists?._id.slice(0, 8).toUpperCase(),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Date</span>,
        field: "",
        render: (lists) => {
          return (
            <span>
              {moment(lists?.createdAt).tz("Asia/Dubai").format("DD/MM/YYYY")}
              <br />
              {moment(lists?.createdAt).tz("Asia/Dubai").format("HH:ss:mm")}
            </span>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Notes</span>,
        field: "",
        render: (lists) => lists?.notes,
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Status</span>,
        field: "",
        render: (lists) => {
          return (
            <div
              className={`${this.getStatus(
                lists?.status
              )}-quotation-status-one`}
            >
              {this.getStatus(lists?.status)}
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Action</span>,
        field: "",
        render: (lists) => (
          <NavLink to={`/rfq/view/${lists?._id}`}>
            <Button
              variant="contained"
              color="primary"
              style={{ color: "#fff", fontSize: 10 }}
            >
              View Order
            </Button>
          </NavLink>
        ),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },

      {
        title: <span className="tryit">Quotations</span>,
        field: "",
        render: (lists) => {
          return (
            <div>
              {lists?.originalName == null ? (
                <p style={{ fontStyle: "italic" }}>No Quotations Yet!</p>
              ) : (
                <div
                  // onClick={() => this.downloadRequest(lists?.originalName)}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    href={`${baseUrl}/${lists?.originalName}`}
                    download
                  >
                    <GetAppIcon style={{ color: "green", fontSize: 30 }} />
                  </a>
                </div>
              )}
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
    ];

    const byProductSearch = this.state.quotationListNotes.filter((item) => {
      return (
        item?._id
          .toLowerCase()
          .includes("RFQ-" + this.state.byProductSearch.toLowerCase()) ||
        item?.notes
          .toLowerCase()
          .includes(this.state.byProductSearch.toLowerCase())
      );
    });

    if (this.state.loader) return <Loader />;
    return (
      <Fragment>
        <section className="mt-2 mb-5">
          <div className="container">
            <PageTitle title="My Quotations" />
            <div className="row mt-2">
              <div className="col-lg-12">
                <div className="order-tab">
                  <ul className="nav nav-tabs" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className={`${this.state.selectedTabs == "all" && "active"
                          } nav-link`}
                        id="all-orders"
                        data-mdb-toggle="tab"
                        href="#all-orders"
                        role="tab"
                        aria-controls="all-orders"
                        aria-selected="true"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ selectedTabs: "all" });
                        }}
                      >
                        By Excel
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`${this.state.selectedTabs == "NOTES" && "active"
                          } nav-link`}
                        id="ex1-tab-2"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-2"
                        role="tab"
                        aria-controls="ex1-tabs-2"
                        aria-selected="false"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ selectedTabs: "NOTES" });
                        }}
                      >
                        By Products
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 seller-order-table-container">
                {this.state.selectedTabs == "all" && (
                  <>
                    {!this.state?.lists?.length ? (
                      <div style={{ marginTop: "20px" }}>
                        <p
                          className="empty-text"
                          style={{ width: "100%", color: "black" }}
                        >
                          You dont have any quotation yet. They will show here
                          as soon as you made your first quotation
                        </p>
                      </div>
                    ) : (
                      <>
                        {/* <div
                          className="mb-2"
                          style={{
                            marginRight: 2,
                            position: "relative",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <div className="ml-auto">
                            <CssTextField
                              id="standard-basic"
                              value={this.state.searchField}
                              onChange={(e) =>
                                this.setState({ searchField: e.target.value })
                              }
                              label="Search Field"
                            />
                          </div>
                          <div>
                            <i className="las la-search la-search search-icon-datatable"></i>
                          </div>
                        </div> */}

                        <MaterialTable
                          style={{ zIndex: 0 }}
                          title={null}
                          data={filteredOrders}
                          localization={{
                            body: {
                              emptyDataSourceMessage: (
                                <img
                                  src="/assets/img/nodata.png"
                                  className="datatableimage"
                                />
                              ),
                            },
                          }}
                          columns={columns}
                          isLoading={this.state.loader}
                          options={{
                            search: false,
                            rowStyle: (x) => {
                              if (x.tableData.id % 2) {
                                return { backgroundColor: "#f2f2f2" };
                              }
                            },
                            paging: true,
                            pageSize: 20,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [20, 50, 100],
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            {this.state.selectedTabs == "NOTES" && (
              <div id="ex1">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="mb-2"
                      style={{
                        marginRight: 2,
                        position: "relative",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div className="ml-auto">
                        <CssTextField
                          id="standard-basic"
                          value={this.state.byProductSearch}
                          onChange={(e) =>
                            this.setState({ byProductSearch: e.target.value })
                          }
                          label="Search By id, notes"
                        />
                      </div>
                      <div>
                        <i className="las la-search la-search search-icon-datatable"></i>
                      </div>
                    </div>
                    <MaterialTable
                      style={{ zIndex: 0 }}
                      title={null}
                      data={byProductSearch}
                      localization={{
                        body: {
                          emptyDataSourceMessage: (
                            <img
                              src="/assets/img/nodata.png"
                              className="datatableimage"
                            />
                          ),
                        },
                      }}
                      columns={columns1}
                      isLoading={this.state.loader}
                      options={{
                        search: false,
                        rowStyle: (x) => {
                          if (x.tableData.id % 2) {
                            return { backgroundColor: "#f2f2f2" };
                          }
                        },
                        paging: true,
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [20, 50, 100],
                      }}
                    />


                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        <Modal
          visible={this.state.modalQuotation}
          width="800"
          effect="fadeInUp"
        >
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-default mr-auto"
              style={{ height: 50, fontSize: 18, marginLeft: "-5px" }}
              onClick={this.closeQuotationModal}
            >
              X
            </button>
          </div>
          <ResponseQuotation
            closemodal={this.closeQuotationModal}
            loadWithId={this.state.loadQuotationWithId}
            onDataLoaded={() => this.setState({ loadQuotationWithId: null })}
            onFinished={() => {
              this.refreshQuotationExcels();
              this.closeQuotationModal();
            }}
            refresh={this.refreshQuotationExcels}
            _id={this.state.sendBuyer && this.state.sendBuyer}
            buyerId={this.state.buyerId && this.state.buyerId}
          />
        </Modal>

        <Modal
          visible={this.state.editmodalQuotation}
          width="800"
          effect="fadeInUp"
        >
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-default mr-auto"
              style={{ height: 50, fontSize: 18, marginLeft: "-5px" }}
              onClick={this.editcloseQuotationModal}
            >
              X
            </button>
          </div>
          <EditResponseQuotation
            closemodal={this.editcloseQuotationModal}
            loadWithId={this.state.editloadQuotationWithId}
            editfilename={this.state.editfilename}
            editnotes={this.state.editnotes}
            onDataLoaded={() => this.setState({ editloadQuotationWithId: null })}
            onFinished={() => {
              this.refreshQuotationExcels();
              this.editcloseQuotationModal();
            }}
            buyerId={this.state.buyerId}
            refresh={this.refreshQuotationExcels}
            _id={this.state.sendBuyer && this.state.sendBuyer}
          />
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    quotation: state.quotation,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    updateQuotationItems: (data) => dispatch(UpdateQuotationItems(data)),
    clearQuotation: (data) => dispatch(ClearQuotation(data)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(
  SellerQuotation
);
