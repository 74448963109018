import React from 'react';


function FormikValidationError(props){
    const {name, touched, error} = props;
    
    return touched[name] && !!error[name] ? 
    (<span className='text-danger'>
        {" "}
        <span className='ic-error'></span>{" "}
        {error[name] ? error[name] : ""}
    </span>) : <></>

}

export default FormikValidationError