import React,{useState, useEffect, useMemo} from "react";
import { ToWords } from "to-words";
import GetAppIcon from '@material-ui/icons/GetApp';
import { useParams, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import axios from "axios";
import _ from "lodash";
import "./style.css";
import { formatPhoneNumber, changeOrderToGroup, getDiscountAmount, calcOrderSummary } from "../../../utils";

const Index = ({ }) => {
  const [groupproducts, setGroupproducts] = React.useState([]);
  const [data, setData] = useState({});
  const [returnProduct, setReturnProduct] = useState([])
  const [allReturnProduct, setallReturnProduct] = useState([])
  const [allProducts, setAllProducts] = React.useState([]);
  const {order_id, seller_id} = useParams();

  useEffect(() => {
    if (data?._id) {
      let filteredProducts = [];
      let cancelledProducts = [];
      let allProducts = [];
      _groupproducts &&
      _groupproducts.length > 0 &&
      _groupproducts.forEach((p) => {
        p.data.forEach((item) => {
          allProducts.push(item);
          if (item.status !== "cancelled") {
            filteredProducts.push(item);
          } else {
            cancelledProducts.push(item);
          }
        });
      });
      setGroupproducts(filteredProducts);
      setAllProducts(allProducts);
    }
  }, [data]);
  let _groupproducts = changeOrderToGroup(data?.products || []);
  _groupproducts = _groupproducts?.filter(gp => gp.sellerId == seller_id) || []

  const getOrder = (id) => {
    axios.get(`/api/order/get-order/${id}`).then((res) => {
      setData(res?.data?.order);
    }).catch((err) => {
      console.log(err)
    })
  }
  const toWords = new ToWords();


  const {
    discount,
    discountPerUnit,
    actual_total_price,
    tax,
    shipping_price,
    actualSubTotal,
    totalOrderAmt,
    remainingOrderAmt,
    refundAmt,
    initalDiscountAmount,
    initalDiscountPerUnit
  } = useMemo(() => {
    if (data?._id) {
      return calcOrderSummary({ orderDetail:data, returnProduct: allReturnProduct, currentUser:{role:"BUYER"} })
    }
    return {}
  }, [data, allReturnProduct])

  let returnAmount = 0
  
  const getReturnProduct = (id) => {
    axios.get(`/api/order/return-sales?order_id=${id}`)
    .then((res) => {
      setallReturnProduct(res.data)
      setReturnProduct(res?.data?.filter(r => r?.list?.user == seller_id || r?.list?.user?._id == seller_id))
    }).catch((err) => console.log(err))
  }
  const getPriceComma = (str) => {
    return str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  //order summary
  returnAmount = returnProduct.reduce((a, b) => {
    if (b.status === "quality_check_pass") {
      return a + (b.list?.price * b.quantity)
    } else {
      return a
    }
  }
    , 0) / 1.05
  let discountAmtOfReturn = returnProduct.map((o, i) => {
    let exclPrice = 0
    let discountAmount = 0
    if (o?.status === 'quality_check_pass') {
      exclPrice = (o?.list?.price / 1.05);
      discountAmount = getDiscountAmount(exclPrice, o?.quantity, o?.list, discountPerUnit, data?.coupon);
    }
    return discountAmount
  }).reduce((a, b) => {
    return a + b
  }, 0)
  let afterDiscountOfReturn = returnAmount - discountAmtOfReturn;
  let vatOfReturn = afterDiscountOfReturn * 0.05;
  let netTotalofReturn = afterDiscountOfReturn + vatOfReturn;
  let total = groupproducts?.map((item, i) => {
    let exclPrice = (item?.delivery_option?.price / 1.05);
    let totalUnitPrice = (exclPrice * item?.quantity + item?.shipping_price_individual);
    let discountAmount = getDiscountAmount(exclPrice, item?.quantity, item?.delivery_option, discountPerUnit, data?.coupon);
    let taxAmount = ((totalUnitPrice - discountAmount) * 0.05);
    let netAmount = (totalUnitPrice - discountAmount + taxAmount);
    return netAmount;
  }).reduce((a, b) => a + b, 0);
	const showRefundAmount = returnProduct.some(item => item.status === "quality_check_pass")


  useEffect(() => {
    if (order_id) {
      getOrder(order_id);
      getReturnProduct(order_id);
    }
  }, [order_id]);
  return (
    <>
    <div className="container" style={{marginTop:'20px'}}>
      <div className="invoice-tax-header">
        <div className="company_logo">
          <img
            src="/assets/img/logo.svg"
            alt="buyParts"
            style={{ marginRight: "2rem", height: "50px" }}
          />
        </div>
          <div style={{ textAlign: "right" }}>
            <button
              className="btn primary-btn"
              style={{height: '50px'}}
              onClick={() => {}}
            >
              <span style={{ position: 'relative', top: "-2px" }}><GetAppIcon />Download PDF</span>
            </button>
        </div>
      </div>

      <div className="tax-title">E-Invoice</div>
      <div className="users-details">
        <div className="left">
          <div className="tax-invoice-to">
            <div className="tax-info-detail">
              <div className="e-invoice-text">E-Invoice No.</div>
              <div>{data?._id?.slice(0, 8)?.toUpperCase()}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">E-invoice Date</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">BuyParts24 Order No.</div>
              <div>{data?.order_id}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">BuyParts 24 Order Date</div>
              <div>
                {moment(data?.order_date)
                  .tz("Asia/Dubai")
                  .format("DD/MM/YYYY hh:mm A")}
              </div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Seller Tax Invoice No.</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Payment Terms</div>
              <div>Cash on delivery</div>
            </div>
          </div>

          <div className="deliver-to">
            <div className="tax-info-detail">
              <div className="e-invoice-text">Bill to:</div>
              <div>{data?.user?.name}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Vat Registration Number</div>
              <div>
                {data?.user?.details?.vat_registration_no
                  ? data?.user?.details?.vat_registration_no
                  : "n/a"}
              </div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Address</div>
              <div>{data?.user?.details?.emirate}</div>
            </div>
          </div>
        </div>
          <div className="right">
          <div className="tax-invoice-to">
            <div className="tax-info-detail">
              <div className="e-invoice-text">Seller No.</div>
              <div>
                {_groupproducts[0]?.sellerId.slice(0, 8)?.toUpperCase()}
              </div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Seller Name</div>
              <div>{_groupproducts[0]?.sellerName}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Seller Address</div>
              <div>{_groupproducts[0]?.data[0]?.seller?.details?.emirate}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Buyer No.</div>
              <div>{data?.user?._id?.slice(0, 8)?.toUpperCase()}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Buyer Name</div>
              <div>{data?.user?.name}</div>
            </div>
            <div className="tax-info-detail">
              <div className="e-invoice-text">Buyer Address</div>
              <div>{data?.user?.details?.garage_address}</div>
            </div>
          </div>

          <div className="deliver-to">
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Ship to:</div>
              <div>{data?.user?.name}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Contact</div>
              <div>{data?.user?.phone && formatPhoneNumber(data?.user?.phone)}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Address</div>
              <div>{data?.delivery_address?.address}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Postcode</div>
              <div>{data?.delivery_address?.postcode}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Location</div>
              <div>{data?.delivery_address?.location}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="item-table-container">
        <table className="invoice-tax-table">
          <tr style={{ border: "1px solid black", backgroundColor: "#bdbdbd" }}>
            <th>#</th>
            <th>Item</th>
            <th style={{ textAlign: "center" }}>Unit Price</th>
            <th style={{ textAlign: "center" }}>Qty</th>
            <th style={{ textAlign: "center" }}>Total Price</th>
              <th style={{ textAlign: "center" }}>Discount</th>
            <th style={{ textAlign: "center" }}>5% Tax</th>
              <th style={{ textAlign: "center" }} >Net Amt</th>
          </tr>
          {groupproducts &&
            groupproducts.length > 0 &&
            groupproducts.map((item, i) => {
              let exclPrice = (item?.delivery_option?.price / 1.05);
              let totalUnitPrice = (exclPrice * item?.quantity + item?.shipping_price_individual);
              let discountAmount = getDiscountAmount(exclPrice, item?.quantity, item?.delivery_option, discountPerUnit, data?.coupon);
              let taxAmount = ((totalUnitPrice - discountAmount) * 0.05);
              let netAmount = (totalUnitPrice - discountAmount + taxAmount);
              return (
                <tr>
                  <td style={{ paddingLeft: "5px" }}>{++i}</td>
                  <td>{item?.delivery_option?.partName}<br /><small><strong>{item?.delivery_option?.partSKU}</strong></small></td>
                  <td style={{ textAlign: "center" }}>
                    {exclPrice.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>{item?.quantity}</td>
                  <td style={{ textAlign: "center" }}>
                    {totalUnitPrice.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {discountAmount.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {taxAmount.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {netAmount.toFixed(2)}
                  </td>
                </tr>
              );
            })}
          <tr style={{ border: "1px solid black", fontWeight: "600" }}>
            <td colSpan="3">Total Invoice Value: </td>

            <td style={{ textAlign: "center" }}></td>
            <td></td>
            <td >
            </td>
            <td></td>

            <td style={{ textAlign: "center" }}>
              AED{" "}
              {total && getPriceComma(total?.toFixed(2))}
            </td>
          </tr>

          <tr style={{ border: "1px solid black", fontWeight: "600" }}>
            <td colSpan="2">Amount in Words :</td>
              <td colSpan="10">
              {total && toWords.convert(total?.toFixed(2)*1)}
            </td>
          </tr>
        </table>
      </div>

      {returnProduct?.length ? <div className="item-table-container">
        <div>
          <div style={{ fontWeight: "600", textAlign: "center", color:'black' }}> Return Sales </div>
        </div>
      <table className="invoice-tax-table">
        <thead>
          <tr style={{ border: "1px solid black", backgroundColor: "#BDBDBD" }}>
                <th >#</th>
                <th >Item</th>
                <th >Status</th>
                <th >Unit Price</th>
                <th >Qty</th>
                <th >Total Price</th>
                <th >Discount</th>
                <th >5% Tax</th>
                <th>Net Amt</th>
          </tr>
        </thead>
        <tbody>
              {returnProduct?.length > 0 && returnProduct.map((o, i) => {
                let exclPrice = 0
                let totalPrice = 0
                let discountAmount = 0
                let taxAmount = 0
                let netAmount = 0
                if (o?.status === 'quality_check_pass') {
                  exclPrice = (o?.list?.price / 1.05);
                  totalPrice = exclPrice * o?.quantity;
                  discountAmount = getDiscountAmount(exclPrice, o?.quantity, o?.list, discountPerUnit, data?.coupon);
                  taxAmount = ((totalPrice - discountAmount) * 0.05);
                  netAmount = (totalPrice - discountAmount + taxAmount);
                }
                return (
                  <tr style={{ border: "1px solid black", textAlign: "center" }}>
                    <td style={{ paddingRight: "5px", textAlign: "center" }}>{++i}</td>
                    <td style={{ textAlign: "left" }}>{o?.list?.partName}<br /><small><strong>{o?.list?.partSKU}</strong></small></td>
                    <td style={{ textAlign: "center" }}>{o?.status.toUpperCase().replace("_", " ")}</td>
                    <td style={{ textAlign: "center" }}>{exclPrice?.toFixed(2)}</td>
                    <td style={{ textAlign: "center" }}>{o?.quantity}</td>
                    <td style={{ textAlign: "center" }}>{totalPrice.toFixed(2)}</td>
                    <td style={{ textAlign: "center" }}>{discountAmount.toFixed(2)}</td>
                    <td style={{ textAlign: "center" }}>{taxAmount.toFixed(2)}</td>
                    <td style={{ textAlign: "center" }}>{netAmount.toFixed(2)}</td>
                  </tr>
                )
              }
              )}
              <tr style={{ border: "1px solid black", fontWeight: "600" }}>
                <td colSpan="3">Total Return Sales Value: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: "center" }}>
                  AED{" "}
                  {getPriceComma(netTotalofReturn.toFixed(2))}
                </td>
              </tr>

              {/* <tr style={{ border: "1px solid black", fontWeight: "600" }}>
                <td colSpan="3">Net Total Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: "center" }}>
                  AED{" "}
                  {getPriceComma((total - netTotalofReturn).toFixed(2))}
                </td>
              </tr> */}

              <tr style={{ border: "1px solid black", fontWeight: "600" }}>
                <td colSpan="2">Amount in Words : </td>

                <td colSpan="10"style={{ textAlign: "center" }}>
                  {toWords.convert(( netTotalofReturn).toFixed(2) * 1)}
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>: null}

        {showRefundAmount ? <div className="item-table-container">
          <div>
            <div style={{ fontWeight: "600", textAlign: "center", color: 'black' }}> Refundable Amount </div>
          </div>
          <table className="invoice-tax-table">
            <thead>
            </thead>
            <tbody>

              <tr style={{ border: "1px solid black", fontWeight: "600" }}>
                <td colSpan="3">Total Order Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: "center" }}>
                  AED{" "}
                  {getPriceComma(totalOrderAmt.toFixed(2))}
                </td>
              </tr>

              <tr style={{ border: "1px solid black", fontWeight: "600" }}>
                <td colSpan="3">Inital Discount Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: "center" }}>
                  AED{" "}
                  {getPriceComma(initalDiscountAmount.toFixed(2))}
                </td>
              </tr>

              <tr style={{ border: "1px solid black", fontWeight: "600" }}>
                <td colSpan="3">Remaining Order Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: "center" }}>
                  AED{" "}
                  {getPriceComma((remainingOrderAmt).toFixed(2))}
                </td>
              </tr>
              <tr style={{ border: "1px solid black", fontWeight: "600" }}>
                <td colSpan="3">Refundable Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: "center" }}>
                  AED{" "}
                  {getPriceComma((refundAmt).toFixed(2))}
                </td>
              </tr>

              <tr style={{ border: "1px solid black", fontWeight: "600" }}>
                <td colSpan="3">Amount in Words : </td>

                <td style={{ textAlign: "center" }}>
                  {toWords.convert((refundAmt).toFixed(2) * 1)}
                </td>
              </tr>
            </tbody>
          </table>
        </div> : null}
      <div className="einvoice-note">
        <b>Note:</b>
        <ul>
          <li>
            This is an e-invoice from BuyParts24. The Tax invoice will be
            generated by the seller.{" "}
          </li>
          <li>
            It is system-generated document and does not require signature.
          </li>
          <li>
            BuyParts24 is not liable for payment made to any party based on this
            invoice. BuyParts24 accepts the payment through its authorized
            channels only.
          </li>
          <li>
            For any queries related to this invoice, please get in touch to our
            customer service.
          </li>
        </ul>
      </div>
    </div>
    </>
  );
};

export default Index;
