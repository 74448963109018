import React from "react";

const index = ({ active, setActive }) => {
  return (
    <div className="benefits-buyer-container">
      <div
        className="benefits-buyer-image"
        onClick={() => setActive("PAYMENT")}
      >
        <img src="/assets/img/become-buyer/payment.png" alt="buyparts-access" />
      </div>

      <div className="benefits-buyer-text">Payment</div>
      {active === "PAYMENT" && <div className="benefits-seller-triangle" />}
    </div>
  );
};

export default index;
