import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Bar, Chart } from "react-chartjs-2";
Chart.defaults.global.legend = false;

const Bargraph = ({ data, totalOrders }) => {
  const [yearsOrders, setYearsOrders] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [thisWeekOrders, setThisWeekOrders] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [thisYearMonthsOrders, setThisYearMonthsOrders] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const noOfOrdersPerDayOnWeek = (orders) => {
    let counts = [0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      let index = moment(order.order_date).day();
      counts[index] += 1;
    }
    return counts;
  };
  const noOfOrdersPerMonth = (orders) => {
    let counts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      let index = moment(order.order_date).month();
      counts[index] += 1;
    }
    return counts;
  };
  const noOfOrdersPerYears = (orders) => {
    let counts = [0, 0, 0, 0, 0, 0];
    counts[0] = orders.lastYearOrders_4?.length;
    counts[1] = orders.lastYearOrders_3?.length;
    counts[2] = orders.lastYearOrders_2?.length;
    counts[3] = orders.lastYearOrders_1?.length;
    counts[4] = orders.lastYearOrders?.length;
    counts[5] = orders.thisYearOrders?.length;

    return counts;
  };
  useEffect(() => {
    setThisWeekOrders(
      noOfOrdersPerDayOnWeek(totalOrders?.thisWeekOrders || [])
    );
    setThisYearMonthsOrders(
      noOfOrdersPerMonth(totalOrders?.thisYearOrders || [])
    );
    setYearsOrders(noOfOrdersPerYears(totalOrders || []));
  }, [totalOrders]);

  return data == "month" ? (
    <div>
      <Bar
        data={{
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              data: thisYearMonthsOrders,
              backgroundColor: "#f49200",
              borderColor: ["white"],
            },
          ],
        }}
        height={285}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : data == "today" ? (
    <div>
      <Bar
        data={{
          labels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ],
          datasets: [
            {
              data: [15, 12, 13, 14, 25, 26, 8, 14, 11, 10, 11, 22],
              backgroundColor: "#f49200",
              borderColor: ["white"],
            },
            // {
            //   data: [11, 10, 9, 8, 16, 19, 5, 11, 6, 7, 8, 17],
            //   backgroundColor: "#F3ADAB",
            //   borderColor: ["white"],
            // },
          ],
        }}
        height={285}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : data == "week" ? (
    <div>
      <Bar
        data={{
          labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
          datasets: [
            {
              data: thisWeekOrders,
              backgroundColor: "#f49200",
              borderColor: ["white"],
            },
            // {
            //   data: [11, 10, 9, 8, 16, 19, 5, 11, 6, 7, 8, 17],
            //   backgroundColor: "#F3ADAB",
            //   borderColor: ["white"],
            // },
          ],
        }}
        height={285}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : data == "year" ? (
    <div>
      <Bar
        data={{
          labels: [
            moment().subtract(5, "years").year(),
            moment().subtract(4, "years").year(),
            moment().subtract(3, "years").year(),
            moment().subtract(2, "years").year(),
            moment().subtract(1, "years").year(),
            moment().subtract(0, "years").year(),
          ],
          datasets: [
            {
              data: yearsOrders,
              backgroundColor: "#f49200",
              borderColor: ["white"],
              label: "Confirmed",
            },
            // {
            //   data: yearsOrders?.cancelledcounts,
            //   backgroundColor: "#F3ADAB",
            //   borderColor: ["white"],
            //   label:"Cancelled"
            // },
          ],
        }}
        height={285}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  ) : null;
};

export default Bargraph;
