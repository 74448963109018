import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";

import { connect } from "react-redux";
import { compose } from "redux";
import { PageBanner } from "../content/element/page-banner";

import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import BuyerQuotation from "../content/element/quotation/buyer-quotation";
import SellerQuotations from "../content/element/quotation/seller-quotations";
import Pending from "../content/element/UserStatus/Pending";

class Quotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
    };
  }
  componentDidMount() { }

  render() {
    const { login } = this.props;

    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser && currentUser.status === "Pending") return <Pending />;

    return (
      <Fragment>

        {/* <Header className="menu--light" /> */}
        <PageBanner />
        <section className="dashboard-wrapper section-bg pt-2 pb-5">
          <div className="container">
            {login?.role === "BUYER" ? (
              <BuyerQuotation />
            ) : (
              login?.role === "SELLER" && <SellerQuotations />
            )}
          </div>
        </section>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(Quotation);
