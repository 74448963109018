const initState = {
  isDisagreed: false,
};

const AgreementReducer = (state = initState, action) => {
  const { data } = action;
  switch (action.type) {
    case "IS_POLICY_DISAGREED":
      return {
        isDisagreed: data,
      };
    default:
      return state;
  }
};
export default AgreementReducer;
