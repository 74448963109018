import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import moment from 'moment'

function UploadedInvoices({ orderDetail, currentUser, setShowModal, setPassId }) {
    const { seller_uploads } = orderDetail || {}
    console.log({ orderDetail });
    let uploads = []
    if (seller_uploads?.length > 0 && currentUser?.role === 'SELLER') {
        seller_uploads.forEach(data => {
            if (data.seller_id === currentUser._id) {
                uploads.push(data)
            }
        })
    }
    if (seller_uploads?.length > 0 && currentUser?.role === 'BUYER') {
        seller_uploads.forEach(data => {
            const total = orderDetail.products.filter(product => product.listing?.user === data.seller_id).length;
            const deliveredOrPending = orderDetail.products.filter(product => product.listing?.user === data.seller_id && (product.status === "delivered" || product.status === "cancelled")).length;
            console.log(total, deliveredOrPending)
            if (total === deliveredOrPending)
                uploads.push(data);
        })

        // let isAllDelivered = orderDetail.products.every(product => product.status === "delivered" || product.status === "cancelled");
        // if (isAllDelivered) {
        //     uploads = seller_uploads
        // }
    }

    {
        return uploads?.length > 0 ?
            <>
                <hr style={{ marginTop: "35px", marginBottom: "37px" }} />
                <div>

                    <h4>{currentUser?.role === 'BUYER' ? "Seller Invoices" : "My Invoices"}</h4>
                    <table className="table bg-transparent checkout-table order-details-table">
                        <tr>
                            <th style={{ textAlign: 'left' }}>
                                PartName
                            </th>
                            <th style={{ textAlign: 'left' }}>
                                PartSKU
                            </th>
                            <th style={{ textAlign: 'left' }}>
                                Date
                            </th>
                            <th style={{ textAlign: 'center' }}>
                                Actions
                            </th>
                        </tr>
                        {uploads?.map((data) => {
                            const placeholder = data.date ? `${moment(data.date).tz("Asia/Dubai").format("DD-MM-YYYY")}` : "INVOICE"

                            return <tr>

                                <td > {
                                    data?.list?.map((p) => {
                                        return (
                                            <div style={{ textAlign: 'left' }}>
                                                {p.partName}
                                            </div>
                                        )
                                    })
                                } </td>
                                <td> {
                                    data?.list?.map((p) => {
                                        return (
                                            <div style={{ textAlign: 'left' }}>
                                                {p.partSKU}
                                            </div>
                                        )
                                    })
                                }</td>
                                <td style={{ textAlign: 'left' }}>{placeholder}</td>
                                <td style={{ paddingLeft: 10, display: "flex", justifyContent: 'center' }}>
                                    <a href={data.file_url} download target="_blank">
                                        <div
                                            className="primary-btn"
                                            style={{ margin: 2 }}
                                        >
                                            <span style={{ position: 'relative', top: "-2px" }}><GetAppIcon /></span>
                                        </div>
                                    </a>
                                    {currentUser?.role === 'SELLER' ? <a href={'#'} onClick={() => {
                                        setPassId(data._id)
                                        setShowModal(true)
                                    }}>
                                        <div
                                            className="primary-btn"
                                            style={{ margin: 2, backgroundColor: '#f00909' }}
                                        >
                                            <span style={{ position: 'relative', top: "-2px" }}><DeleteForeverIcon /></span>
                                        </div>
                                    </a> : null}
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
            </> : null
    }
}

export default UploadedInvoices
