import React, { Component, Fragment } from "react";

export class ServiceDescription extends Component {
  render() {
    const { listing } = this.props;
    return (
      <Fragment>
        <div className="atbd_content_module atbd_listing_features">
          <div className="atbd_content_module__tittle_area">
            <div className="atbd_area_title">
              <h4>
                <span className="la la-list-alt"></span>Service Description
              </h4>
            </div>
          </div>

          <div className="atbdb_content_module_contents">
            {listing?.serviceDescription && (
              <div
                dangerouslySetInnerHTML={{
                  __html: listing.serviceDescription.replaceAll("\n", "<br/>"),
                }}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
