// import initState1 from '../../softdata.json';
const initState = {
  homeListing: [],
  listing: undefined,
  tradesoftSKUs: [],
  isInitalRender: false,
  sameNameItemList: [],
  totalCount: 0,
  maxPrice: 0,
  minPrice: 0,
  makeList: [],
  modelList: [],
  brandList: [],
  bannersList: [],
  categoryList: [],
  subCategoryList: [],
  isLoading: false,
  hasError: false,
  filterQuery: {},
  itemsInCart: JSON.parse(localStorage.getItem("itemsInCart")) || [],
  searchQuery: {
    string: "",
    priceRange: null,
    category: null,
    make: null,
    model: null,
    year: null,
    brand: null,
    sortBy: {},
  },
  count: localStorage.getItem("visitors_count") * 1 || 0,
  listing_count_pagination: 1,
  sparePartSearchDetails: {},
  loading_count: false,
  tradesoftSKUs: [],
  pathname: "",
  isBulkListLoading: false,
  isBulkUpload: false,
  bulkCartList: [],
  bulkCartListCount: 0,
  bulkUploadedList: [],
  unAvailableProducts: []
};

const listingReducer = (state = initState, action) => {
  switch (action.type) {
    case 'IMAGE_SEARCH_SKUS':
      return {
        ...state,
        isLoading: false,
        hasError: false,
        listing: action.listings
      }
    case "SET_FILTER": {
      return {
        ...state,
        filterQuery: action.filterQuery
      }
    }
    case "SET_PATHNAME": {
      return {
        ...state,
        pathname: action.pathname
      }
    }
    case "INITIALIZE_SUCCESS":
      return {
        ...state,
        searchQuery: { ...initState.searchQuery, ...action.searchQuery },
        listing: action.listings,
        filterQuery: action.filterQuery,
        totalCount: action.totalCount,
        isLoading: false,
        hasError: action.listings > 1 ? false : true
      };
    case "GET_FILTER":
      return {
        ...state,
        maxPrice: action.maxPrice,
        minPrice: action.minPrice,
        makeList: action.makeList,
        // modelList: action.modelList,
        brandList: action.brandList,
      };
    case "INITIALIZE_FAILED":
      return {
        ...initState,
      };
    case "GET_BANNER_LIST":
      return {
        ...state,
        bannersList: action.data,
      }
    case "SET_TRADESOFT_SKUS":
      return {
        ...state,
        tradesoftSKUs: action.data,
      }
    case "GET_HOME_LISTING_SUCCESS":
      return {
        ...state,
        homeListing: action.listing,
        isLoading: false,
      };
    case "GET_HOME_LISTING_FAILED":
      return {
        ...state,
        homeListing: [],
      };
    case "GET_MY_LISTING_BEGIN":
      return {
        ...state,
        listing: [],
        listing_count_pagination: 0,
        isLoading: true,
      };
    case "GET_MY_LISTING_SUCCESS":
      return {
        ...state,
        listing: action.listings?.listings,
        listing_count_pagination: action.listings?.total,
        isLoading: false,
      };
    case "GET_MY_LISTING_FAILED":
      return {
        ...initState,
      };
    case "ADD_TO_CART":
      const { itemsInCart } = state;
      itemsInCart.push(action.data);
      localStorage.itemsInCart = JSON.stringify(itemsInCart)
      // localStorage.setItem("itemsInCart", JSON.stringify(itemsInCart));
      return {
        ...state,
        itemsInCart,
      };
    case "UPDATE_CART":
      localStorage.itemsInCart = JSON.stringify(action.data)
      // localStorage.setItem("itemsInCart", JSON.stringify(action.data));
      return {
        ...state,
        itemsInCart: action.data,
      };
    case "CLEAR_CART":
      return {
        ...state,
        itemsInCart: [],
      };
    case "SET_VISIBILITY_SUCCESS":
      const { listing_id, visibility } = action;
      return {
        ...state,
        listing: state.listing.map((item) => {
          if (item?._id === listing_id) {
            return { ...item, hide: visibility };
          } else {
            return item;
          }
        }),
      };

    case "GET_MY_FAVORITES_SUCCESS":
      return {
        ...state,
        favoriteListing: action.listings,
        isLoading: false,
      };
    case "GET_MY_FAVORITES_FAILED":
      return {
        ...state,
        favoriteListing: [],
        isLoading: false,
      };
    case "GET_ALL_LISTING_SUCCESS":
      return {
        ...state,
        listing: action.listing,
        isLoading: false,
      };
    case "FILTER_LISTING_SUCCESS":
      return {
        ...state,
        listing: action.listings,
        totalCount: action.totalCount,
        isLoading: false,
      };
    case "FILTER_LISTING_FAILED":
      return {
        ...state,
        listing: [],
        isLoading: false,
      };
    case "SET_SIMILAR_LISTING":
      return {
        ...state,
        similarListing: action.data,
      };
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        searchQuery: action.data,
      };
    case "SET_LISTINGS":
      return {
        ...state,
        // listing: action.data,
        listing: action.data?.listings,
        maxPrice: action.data?.maxPrice,
        minPrice: action.data?.minPrice,
        makeList: action.data?.makeList,
        modelList: action.data?.modelList,
        brandList: action.data?.brandList,
        categoryList: action.data?.categoryList,
        subCategoryList: action.data?.subCategoryList,
        isLoading: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.data,
        hasError: false,
      };
    case "SAME_NAME_ITEM_LIST":
      return {
        ...state,
        sameNameItemList: action.data,
      };
    case "BULK_PRODUCT_DELETE":
      return {
        ...state,
        isLoading: true,
      };
    case "INCREASE_VISITORS_VISIT_COUNT":
      localStorage.setItem("visitors_count", action.count);
      return {
        ...state,
        count: action.count,
      };
    case "SET_SPAREPART_SEARCHDETAILS":
      return {
        ...state,
        sparePartSearchDetails: action.data,
      };
    case "LOADING_COUNT":
      return {
        ...state,
        loading_count: true,
      };
    case "GET_MY_LISTING_COUNT_SUCCESS":
      return {
        ...state,
        loading_count: false,
        listing_count_pagination: action.count,
        isInitalRender: action.isInitalRender,
      };
    case "GET_MY_LISTING_COUNT_FAILED":
      return {
        ...state,
        loading_count: false,
        listing_count_pagination: 1,
      };
    case "SET_BULK_UPLOAD_LIST":
      return {
        ...state,
        isBulkUpload: true,
        bulkUploadedList: action.data,
      };
    case "GET_BULK_CART_LIST_BEGIN":
      return {
        ...state,
        isBulkListLoading: true,
        bulkCartListCount: 0,
      };
    case "GET_BULK_CART_LIST_SUCCESS":
      return {
        ...state,
        bulkCartList: action.data?.products,
        unAvailableProducts: action.data?.unAvailableProducts,
        isBulkListLoading: false
      };
    case "GET_BULK_CART_LIST_ERROR":
      return {
        ...state,
        isBulkListLoading: false
      };
    case "SET_BULK_CART_LIST_COUNT":
      return {
        ...state,
        bulkCartListCount: action.data
      };
    case "UPDATE_BULK_CART":
      return {
        ...state,
        bulkCartList: action.data
      };
    case "CLEAR_BULK_CART":
      return {
        ...state,
        bulkCartList: [],
        bulkCartListCount: 0,
        bulkUploadedList:[],
        unAvailableProducts: [],
        isBulkUpload: false,
      };
    default:
      return state;
  }
};
export default listingReducer;
