import React from "react";

const Operations = ({ active, setActive }) => {
  return (
    <div className="benefits-seller-container">
      <div className="benefits-seller-image">
        <img
          src="/assets/img/become-seller/operations.png"
          alt="buyparts-operations"
          onClick={() => setActive("operations")}
        />
      </div>

      <div className="benefits-seller-text">Operations</div>
      {active === "operations" && <div className="benefits-seller-triangle" />}
    </div>
  );
};

export default Operations;
