import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import Header from "../layout/header";
import PreHeader from "../layout/pre-header";
import Footer from "../layout/footer";
import axios from "axios";
import { formatPhoneNumber, popUp } from "../../utils";
import ReactGA from 'react-ga4';


class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile_number: "",
      resonOfEnquiry: "",
      message: "",
      perferred_mobile_contact: "",
      perferred_email_contact: "",
      loading: false,
    };
    this.validator = new SimpleReactValidator();
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ loading: true });
      // let perferred_mode_of_contact = [];
      // if (this.state.perferred_email_contact) {
      //   perferred_mode_of_contact.push("Email");
      // }
      // if (this.state.perferred_mobile_contact) {
      //   perferred_mode_of_contact.push("Mobile");
      // }
      // const dataToSend = { ...this.state, perferred_mode_of_contact };
      axios
        .post("/api/enquiry", this.state)
        .then((res) => {
          if (res?.data?.message === "success") {
            this.validator.hideMessages();
            this.setState({
              name: "",
              email: "",
              mobile_number: "",
              resonOfEnquiry: "",
              message: "",
              perferred_mobile_contact: "",
              perferred_email_contact: "",
            });
            this.setState({ loading: false });
          }
          ReactGA.event({
            category: 'Contact',
            action: 'Message Sent'
          });
          popUp("Your Messages Sent Successfully !");
        })
        .catch((err) => {
          popUp("There was a problem with server. Please try again.");
          popUp("Message Sent Failed!");
          this.setState({ loading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    const { t } = this.props;
    const {
      name,
      email,
      mobile_number,
      resonOfEnquiry,
      message,
      perferred_mobile_contact,
      perferred_email_contact,
    } = this.state;
    return (
      <Fragment>
        <div
          className="breadcrumb-wrapper cover"
          style={{
            backgroundImage: 'url("/assets/img/section-banner.jpg")',
            height: 200,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: 'flex'
          }}
        >
          <div className="inner-container" style={{ alignSelf: 'center' }}>
            <div style={{ minWidth: "1024px" }}>
              <h1>Contact us</h1>
            </div>
          </div>
        </div>
        {/* <PageBanner title={t("contact_title")} /> */}
        <section
          className="contact-cards"
          style={{ paddingTop: 80, paddingBottom: 70 }}
        >
          <div className="inner-container">
            <div className="row">
              <div className="col-md-8">
                <form
                  className="contact-form"
                  onSubmit={(e) => this.handleSubmit(e)}
                >
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>{t("contact_form_your_name")} <span style={{ color: 'red' }}>*</span></label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.handleChange}
                      />
                      <div className="text-danger">
                        {this.validator.message("Name", name, `required`)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>{t("contact_form_your_email")} <span style={{ color: 'red' }}>*</span></label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                      <div className="text-danger">
                        {this.validator.message("Email", email, `required`)}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>{t("contact_form_your_mobile")} <span style={{ color: 'red' }}>*</span></label>
                      <input
                        className="form-control"
                        type="text"
                        value={mobile_number}
                        name="mobile_number"
                        onChange={this.handleChange}
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "Mobile No",
                          mobile_number,
                          `required`
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <label>{t("contact_form_contact_mode")}*</label>

                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobileMode"
                            value={perferred_mobile_contact}
                            name="perferred_mobile_contact"
                            onChange={(e) => {
                              this.handleChange({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked
                                }
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobileMode"
                          >
                            {t("contact_form_mobile_number")}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="emailMode"
                            name="perferred_email_contact"
                            value={perferred_email_contact}
                            onChange={(e) => {
                              this.handleChange({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked
                                }
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="emailMode"
                          >
                            {t("contact_form_email_address")}
                          </label>
                        </div>
                        <div className="text-danger">
                          {this.validator.message(
                            "Email or Contact No",
                            perferred_mobile_contact || perferred_email_contact,
                            `required`
                          )}
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>REASON OF ENQUIRY <span style={{ color: 'red' }}>*</span></label>
                        <select
                          className="form-control"
                          name="resonOfEnquiry"
                          value={resonOfEnquiry}
                          defaultValue={"General Enquiry"}
                          onChange={this.handleChange}
                        >
                          <option value="">Select</option>
                          <option value="General Enquiry">
                            General Enquiry
                          </option>
                          <option value="Complaint">Complaint</option>
                        </select>
                        <div className="text-danger">
                          {this.validator.message(
                            "Reason of Enquiry",
                            resonOfEnquiry,
                            `required`
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>{t("contact_form_your_message")} <span style={{ color: 'red' }}>*</span></label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="message"
                      value={message}
                      onChange={this.handleChange}
                    ></textarea>
                    <div className="text-danger">
                      {this.validator.message("Message", message, `required`)}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-3"></div>
                    <div className="col-md-12">
                      <button
                        disabled={this.state.loading}
                        className="btn btn-primary btn-md btn-block"
                        type="submit"
                        style={{
                          display: "block",
                          width: "100%",
                          backgroundColor: "#F39200",
                          borderColor: "#F39200",
                        }}
                      >
                        {this.state.loading && (
                          <i className="las la-spinner la-spin mr-2"></i>
                        )}
                        {t("contact_form_send")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-4">
                <ul style={{ listStyle: "none" }}>
                  <li style={{ paddingBottom: 20 }}>
                    <h3 className="mb-3 mt-2" style={{ color: "#1A0451" }}>
                      Get in touch
                    </h3>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: "#1A0451",
                        fontWeight: 500,
                      }}
                    >
                      Office: 800BUYPARTS
                    </p>
                    <p style={{
                      padding: 0,
                      margin: 0, color: "#1A0451", fontWeight: 500
                    }}>
                      Email:&nbsp;helpdesk@buyparts24.com
                    </p>
                    <p style={{ color: "#1A0451", fontWeight: 500 }}>
                      Whatsapp:&nbsp;{formatPhoneNumber(`+${process.env.REACT_APP_WHATSAPP_NUMBER}`)}
                    </p>
                  </li>
                  <li style={{ paddingBottom: 20 }}>
                    <h3 className="mb-3" style={{ color: "#1A0451" }}>
                      Opening Hours
                    </h3>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: "#1A0451",
                        fontWeight: 500,
                      }}
                    >
                      Monday to Saturday: 9am to 6pm
                    </p>
                    <p style={{ color: "#1A0451", fontWeight: 500 }}>
                      Excluding Public Holidays
                    </p>
                  </li>
                  <li style={{ paddingBottom: 20 }}>
                    <h3 className="mb-3" style={{ color: "#1A0451" }}>
                      Visit US
                    </h3>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: "#1A0451",
                        fontWeight: 500,
                      }}
                    >
                      BuyParts24
                    </p>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: "#1A0451",
                        fontWeight: 500,
                      }}
                    >
                      Dubai, United Arab Emirates
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

      </Fragment>
    );
  }
}

export default withTranslation()(ContactUs);
