import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { hostedAPI, outletRefID } from '../../constants';


const OnlinePayment = ({ setLoading, setIsButtonDisabled }) => {

    const style = {
        main: {
            padding: "15px"
        } /* the style for the main wrapper div around the card input*/,
        base: {} /* this is the default styles that comes with it */,
        input: {
            padding: "5px",
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "rgb(227, 230, 239)",
            color: "#000"
        } /* custom style options for the input fields */,
        invalid: {
            padding: "5px",
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#f00",
            color: "#f00"
        } /* custom input invalid styles */
    };

    const onSuccess = () => {
        console.log("success")
        setLoading(false)
        // setIsButtonDisabled(false)
    }
    const onFail = () => {
        console.log("fail")
        setLoading(false)
        // setIsButtonDisabled(true)
    }
    const apiKey = hostedAPI;
    const outletRef = outletRefID;
    useEffect(() => {
        setLoading(true)
        setIsButtonDisabled(true)
        window.NI.mountCardInput('mount-id'/* the mount id*/, {
            style, // Style configuration you can pass to customize the UI
            apiKey, // API Key for WEB SDK from the portal
            outletRef, // outlet reference from the portal
            onSuccess, // Success callback if apiKey validation succeeds
            onFail, // Fail callback if apiKey validation fails
            onChangeValidStatus: ({
                isCVVValid,
                isExpiryValid,
                isNameValid,
                isPanValid
            }) => {
                if (isCVVValid && isExpiryValid && isNameValid && isPanValid) {
                    setIsButtonDisabled(false)
                } else {
                    setIsButtonDisabled(true)
                }
                console.log({ isCVVValid, isExpiryValid, isNameValid, isPanValid });
            }
        });
    }, [window])

    return (
        <>
            <div id="mount-id" style={{ height: "225px" }}>
            </div>
        </>
    )
}





export default function PaymentModal() {
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    async function createSession() {
        try {
            const response = await window.NI.generateSessionId();
            if (window.opener != null && !window.opener.closed) {
                window.opener.isSessionExpired = false
                window.opener.paymentSessionId = response.session_id;
            }
        } catch (err) {
            console.log(err);
            window.opener.paymentSessionId = null
            window.opener.isSessionExpired = true
            // popUp("Your session has expire. Please try again.", 'error');
        } finally {
            setLoading(false)
            window.close();
        }
    }

    const payNow = () => {
        setLoading(true)
        createSession()
    }

    return (
        <>
            {loading && (
                <div style={{
                    padding: '0px 20px',
                    marginTop: '100px',
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && <img src="/assets/img/logo-footer.svg" alt="logoImage" width="200" style={{ marginTop: '20px', marginLeft: '150px' }} />}
            <OnlinePayment setLoading={setLoading} setIsButtonDisabled={setIsButtonDisabled} />
            {!loading && <Button autoFocus disabled={isButtonDisabled} onClick={payNow} variant="contained" color="primary" style={{ width: '453px', marginBottom: '2px', marginLeft: '25px', marginTop: '-35px' }}>
                Check out
            </Button>}
        </>
    );
}

