import React, { Fragment, useState } from "react";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import PreHeader from "../../layout/pre-header";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

const JSONInfo = () => {
    const [url, setUrl] = useState('')
    const [res, setRes] = useState(null)

    const getData = async url => {

        try {
            let res = await axios.get('/api/check/tradesoft?url=' + url, {
                headers: {
                    Authorization: "OEM-API-9A0257E0-1B38-4BC4-837E-C74F34EB9122"
                }
            })
            setRes(res.data)

        } catch (error) {
            setRes(null)
        }

    }

    const onSubmit = e => {
        e.preventDefault()
        if (url) {
            getData(url)
        }
    }
    return (
        <Fragment>


            <div className="directory_content_area">
                <div className="container" style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <div className="row">
                        <div className="col-lg-12  d-lg-block">
                            <div className="search-spare-part">
                                <form className="search_form" onSubmit={onSubmit}>
                                    <input
                                        type="url"
                                        value={url || ""}
                                        name="search"
                                        onChange={(e) => setUrl(e.target.value)}
                                        placeholder="https://example.com"
                                    />
                                    <div className="atbd_seach_button_wrapper">
                                        <button
                                            type="submit"
                                            className="btn btn-md btn-primary btn_search d-flex p-2"
                                            style={{
                                                backgroundColor: " #F39200",
                                                borderColor: "#F39200",
                                            }}
                                        >
                                            <i className="las la-search" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {res && <div className="col-lg-12  d-lg-block">
                            <pre>
                                <strong>
                                    {JSON.stringify(res, null, 2)}
                                </strong>
                            </pre>
                        </div>}

                        {!res && <div className="col-lg-12  d-lg-block" style={{ textAlign: 'center' }}>
                            <pre>
                                No results to show.
                            </pre>
                        </div>}


                    </div>
                </div>
            </div>

        </Fragment>
    );
};
export default JSONInfo;
