import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LoginModal from "../../Login/LoginModal";

export default function VisitorModal({ showModal, onClose, msg }) {
  const history = useHistory();
  const currentUser = JSON.parse(localStorage.getItem("login"));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handelRegister = () => {
    history.push("/register");
  };
  const handelBack = () => {
    history.push("/");
  };

  const toggle = (e) => {
    e.preventDefault();
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <Dialog
        open={showModal}
        hideBackdrop={false}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Login as Buyer?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {currentUser?.status === "Pending"
              ? "You have exceeded your visitor's limit ! Please wait until your account is approved."
              : msg}
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ marginRight: "auto", marginLeft: 10 }}
            onClick={handelBack}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              toggle(e);
              onClose(e);
            }}
            color="primary"
          >
            <a href=" " className="access-link">
              Login
            </a>
          </Button>
          <div onClick={handelRegister} className="go-to-register">
            Go To Register
          </div>
        </DialogActions>
      </Dialog>
      <LoginModal isModalOpen={isModalOpen} toggle={(e) => toggle(e)} />
    </div>
  );
}
