
import React, { Component, Fragment } from "react";

export class ListingFetures extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { listing } = this.props;
    let make = Array.isArray(listing.makes) ? listing.makes.map(m => m.name)?.join('/') : listing.makes
    return (
      <Fragment>
        <div className="atbd_content_module atbd_listing_features">
          <div className="atbd_content_module__tittle_area">
            <div className="atbd_area_title">
              <h4>
                <span className="la la-list-alt"></span>Details
              </h4>
            </div>
          </div>
          <div className="atbdb_content_module_contents">
            <ul className="atbd_custom_fields features-table">
              {/* <!--  get data from custom field--> */}
              <li>
                <div className="atbd_custom_field_title">
                  <p>Name: </p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.partName}</p>
                </div>
              </li>
              {this.props.login && <li>
                <div className="atbd_custom_field_title">
                  <p>Price:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>AED {listing?.price}</p>
                </div>
              </li>}
              <li>
                <div className="atbd_custom_field_title">
                  <p>Brand:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.partBrand}</p>
                </div>
              </li>
              <li>
                <div className="atbd_custom_field_title">
                  <p>PartSKU:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.partSKU}</p>
                </div>
              </li>

              {/*  */}
              {listing?.amp && <li>
                <div className="atbd_custom_field_title">
                  <p>Battery Amp:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.amp}</p>
                </div>
              </li>}{listing?.voltage && <li>
                <div className="atbd_custom_field_title">
                  <p>Voltage:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.voltage}</p>
                </div>
              </li>}


              {listing?.size && <li>
                <div className="atbd_custom_field_title">
                  <p>Size:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.size}</p>
                </div>
              </li>}
              {listing?.rimSize && <li>
                <div className="atbd_custom_field_title">
                  <p>Rim Size:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.rimSize}</p>
                </div>
              </li>}

              {listing?.heightDimension && <li>
                <div className="atbd_custom_field_title">
                  <p>Height:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.heightDimension}</p>
                </div>
              </li>}{listing?.widthDimension && <li>
                <div className="atbd_custom_field_title">
                  <p>Width:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.widthDimension}</p>
                </div>
              </li>}



              {listing?.utqg && <li>
                <div className="atbd_custom_field_title">
                  <p>UTQG:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.utqg}</p>
                </div>
              </li>}
              {listing?.makeYear && <li>
                <div className="atbd_custom_field_title">
                  <p>Make Year:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.makeYear}</p>
                </div>
              </li>}
              {/*  */}



              {make && <li>
                <div className="atbd_custom_field_title">
                  <p>Makes:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{make}</p>
                </div>
              </li>}
              <li className={listing?.category === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Category:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.category?.name}</p>
                </div>
              </li>
              <li className={listing?.subCategory === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Sub Category:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.subCategory?.name}</p>
                </div>
              </li>

              {listing?.subCategoryTire && <li className={listing?.subCategoryTire === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Sub Category Tire:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.subCategoryTire?.name}</p>
                </div>
              </li>}

              <li className={!listing?.countryOrigin ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Origin Of Country:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.countryOrigin}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

