import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import axios from 'axios'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import { popUp } from "../../../../utils";

export default function SearchImage({ showModal, onClose }) {
  const [image, setImage] = useState(null)
  const history = useHistory();

  const onChange = e => {
    setImage(e.target.files[0])
  }
 const searchByImage = async() => {
   let data = new FormData();
   data.append(`searchImage`, image);
   axios
     .post("/api/imageupload", data)
     .then((res) => {
       axios.post('/api/listing/recognize-details',{paths:res.data}).then(resp => {
        let vin = resp.data?.details?.vin
        if (vin) {
          history.push(`/parts-search-vin?vin=${vin}`)
        } else {
          popUp("Could not get any vin")
        }

       })       
     })
     .catch((error) => {      
       popUp("Could not get any vin")
       console.error(error);
     });
 }

  return (
    <div>
      <Dialog
        open={showModal}
        hideBackdrop={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Search VIN By Image"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Upload Image
            <input
              type="file"
              name="vin_by_image"
              onChange={onChange}
            />
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ marginRight: 'auto', marginLeft: 10 }} onClick={onClose} color="primary">
            Cancel
          </Button>

          <div onClick={searchByImage} className="go-to-register">
            Search
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
