import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function CouponConfirmModal({
  showModal,
  onClose,
  eventFunc,
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ position: "relative" }}>
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              left: 15,
              top: 0,
              fontSize: 22,
              cursor: "pointer",
            }}
          >
            x
          </div>
          <div
            style={{
              width: "100%",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <InfoOutlinedIcon
              style={{
                fontSize: 80,
                marginLeft: "auto",
                marginRight: "auto",
                color: "rgb(243, 146, 0)",
              }}
            />
            <h6>IMPORTANT NOTE</h6>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ color: "#686a6b" }}>
              -In case of cancellation or return of an item, if the order subtotal is less than the minimum cart value, the coupon will still be considered as used, the discounted amount will not be calculated and the prices will return to the original. The amount of refund will be less than the amount paid to cover the elimination of the coupon.
              <br />
              -In case of order cancellation, the coupon will be considered as used, and the amount paid will be refunded
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ paddingRight: "20%", paddingLeft: "20%", marginBottom: 10 }}
        >
          <Button
            variant="contained"
            style={{ color: "#fff", backgroundColor: "#1d71b8", width: 150 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              eventFunc();
            }}
            style={{
              backgroundColor: "rgb(243, 146, 0)",
              color: "#fff",
              width: 150,
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
