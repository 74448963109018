import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { NotifyToastWithButtons } from "../../common/ToastNotifier/ToastNotifier";
const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const validateEmail = (mail) => {
    const pattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!mail.match(pattern)) {
      setError({
        ...error,
        email: "Please enter a valid email",
      });
      setTimeout(function () {
        setError(null);
      }, 3000);
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      const resp = await axios.post("/api/newsletter", { email: email });
      setEmail("");
      NotifyToastWithButtons(resp.data?.message)
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="mt-4">
          <div className="">
            <form onSubmit={handleFormSubmit}>
              <div className="email-input">
                <input
                  type="email"
                  required
                  className="form-control"
                  value={email}
                  placeholder="Your email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Ok
              </button>
            </form>
            {error?.email && (
              <div style={{ color: "red", marginLeft: "2px" }}>
                {error.email}
              </div>
            )}
            <div>
              <p>
                By subscribing here you agree to receive our promotional offers.
                Please verify our{" "}
                <NavLink to="/privacy">
                  privacy policy terms and conditions
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="newsletter_img">
          {/* <img src="/assets/img/news-letter.jpg" /> */}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Newsletter);
