import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getImageNameFromFullPath } from '../../../utils'

class BrowseByCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isCatLoading: false,
      countsPerCategory: {},
      categories: [],
      sortedArrayCategories: [],
    }
  }

  componentDidMount() {
    // this.setState({ isCatLoading: true });
    // axios.get(`/api/categories/all`).then((res) => {
    // this.setState({ categories: this.props.allCategories });
    // let sortedArrayCategories = this.props.allCategories.sort((a, b) =>
    //   a?.name.localeCompare(b?.name)
    // );
    // this.setState({ sortedArrayCategories: sortedArrayCategories });
    // this.setState({ isCatLoading: false });
    // });
    // this.setState({ isLoading: true });
    // axios
    //   .get("/api/listing/get-count-by-category")
    //   .then((res) => {
    //     this.setState({ isLoading: false, countsPerCategory: res.data });
    //   })
    //   .catch((err) => {
    //     this.setState({ isLoading: false });
    //   });
  }

  render() {

    return (
      <Fragment>
        <div className='col-lg-12'>
          <div className='category-list mt-4'>
            {this.props.isAllCategoriesLoading
              ? Array.from(new Array(8)).map((index) => {
                <Box key={index} width={200} marginRight={0.5} my={5} mx={3} >
                  <Skeleton variant="rect" width={210} height={118} />
                  <Box pt={0.5}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                </Box>
              }
              )
              : this.props.allCategories.map((category, index) => {
                return (
                  <div
                    className="category-card-wrapper"
                    key={index}
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="category-card">
                      <NavLink
                        to={`/spare-parts/${category?.name
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        <img
                          src={
                            category?.imgUrl ||
                            "/assets/img/product-default.jpg"
                          }
                          alt={category?.name}
                          width="100%"
                        />
                      </NavLink>
                      <NavLink
                        to={`/spare-parts/${category?.name
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                        style={{
                          color: "#000",
                          display: "block",
                          paddingBottom: "10px",
                          fontSize: 12,
                          fontWeight: 500,
                          marginTop: 10
                        }}
                      >
                        {category?.name}
                      </NavLink>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    allCategories: state.category.allCategories,
    isAllCategoriesLoading: state.category.isAllCategoriesLoading,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {}
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(BrowseByCategory)
