import React, { useState } from "react";

import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import MinimizeIcon from "@material-ui/icons/Minimize";
import AddIcon from "@material-ui/icons/Add";
import Select from "react-select";
import axios from "axios";
import { getDiscountAmount, popUp } from "../../../../utils";
import { useEffect } from "react";

const ReturnProducts = ({
  isReturnModal,
  setIsReturnModal,
  productname,
  list,
  order_id,
  originalQuantity,
  handleCloseReturnModel,
  getReturnProduct,
  discountPerUnit,
  coupon,
}) => {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [reason, setreason] = useState("");

  useEffect(() => {
    if (isReturnModal) {
      setQuantity(1);
    }
  }, [isReturnModal]);

  const returnReasons = [
    "Excess quantity ordered",
    "Defective goods",
    "Goods shipped too late",
    "Product specifications are incorrect",
    "Wrong items shipped entirely",
    "Changed of mind",
    "Wrong product ordered",
    "Better product price found elsewhere",
  ].map((type) => {
    return { label: type, value: type };
  });
  const handleReturnReason = (selectedOption) => {
    setreason(selectedOption.value);
  };

  const handleSubmit = () => {
    if (loading) {
      return;
    }

    if (!reason) {
      alert("Please select your reason");
    } else {
      setLoading(true);
      let data = {
        list: { ...list?.delivery_option },
        reason: reason,
        order_id: order_id,
        quantity: quantity,
        assignedTo: list?.assignedTo,
      };
      axios
        .post("api/order/return-sales", data)
        .then((res) => {
          handleCloseReturnModel();
          getReturnProduct(order_id);
          popUp(
            "Your order has been returned to warehouse,please wait for the confirmation!");
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  const increamentQuantity = () => {
    if (quantity < originalQuantity) {
      setQuantity(quantity + 1);
    }
  };
  return (
    <Modal
      isOpen={isReturnModal}
      toggle={() => setIsReturnModal(false)}
      center
      style={{ maxWidth: "30rem" }}
    >
      <ModalHeader
        toggle={() => setIsReturnModal(false)}
        style={{ width: "100%" }}
      ></ModalHeader>
      <ModalBody>
        <div>
          <h3>
            Do your really want to Return the product <span>{productname}</span>
            ?
          </h3>
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 30 }}
            >
              <h6>Quantity you want to return:</h6>
              <div className="w-50 d-flex justify-content-around align-items-center p-1 ml-5">
                <button
                  disabled={quantity <= 1}
                  className="btn checkout-qty border"
                  onClick={() => setQuantity(quantity - 1)}
                >
                  <MinimizeIcon style={{ fontSize: 18, marginBottom: 10 }} />
                </button>
                <span
                  className="border h-100 w-500 justify-content-center d-flex align-items-center"
                  style={{ width: 300, paddingLeft: 30, paddingRight: 30 }}
                >
                  {quantity}
                </span>
                <button
                  className="btn checkout-qty border"
                  onClick={(e) => increamentQuantity()}
                  disabled={quantity >= originalQuantity}
                >
                  <AddIcon style={{ fontSize: 18, marginTop: "-3px" }} />
                </button>
              </div>
            </div>

            <div>
              <div>
                <label>Return Reason</label>
                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  name="reason"
                  placeholder="Choose Reason..."
                  options={returnReasons}
                  onChange={handleReturnReason}
                />
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <Button
                disabled={loading}
                onClick={handleSubmit}
                style={{ width: "100%", marginTop: 20 }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReturnProducts;
