import React from "react";

const index = ({ active, setActive }) => {
  return (
    <div className="benefits-buyer-container">
      <div className="benefits-buyer-image" onClick={() => setActive("SAVING")}>
        <img
          src="/assets/img/become-buyer/savings.png"
          alt="buyparts-savings"
        />
      </div>

      <div className="benefits-buyer-text">Savings</div>
      {active === "SAVING" && <div className="benefits-seller-triangle" />}
    </div>
  );
};

export default index;
