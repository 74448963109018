import React from "react";

const Benefits = ({ title, imgSrc, data }) => {
  return (
    <>
      <div style={{ marginBottom: "25px" }} className="mt-4">
        <div className="benefits-image-container">
          <img src={imgSrc} />
        </div>
        <div style={{ marginLeft: "10px", marginTop: "15px" }}>
          <div className="benefits-title-content">{title}</div>
          <div className="benefits-buyer-content" style={{ marginTop: "10px" }}>
            <ul style={{ paddingInlineStart: "21px" }}>
              {data.map((data, i) => {
                return <li key={i}>{data}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
