import React, { Component } from "react";
import axios from "axios";
import moment from "moment-timezone";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-awesome-modal";
import SellerList from "../modal/seller-list";

import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { popUp } from "../../../../utils";
import { baseUrl } from "../../../../constants";
// import Select from "react-select";

class ExcelQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: null,
      sellerId: null,
      seller: null,
      sellerList: null,
      quotationFile: null,
      quotationFileName: null,
      buyerFileName: null,
      sellerFileName: null,
      buyerNotes: null,
      modalSellerOpen: false,
      btndisable: false,
      quotationId: ""
    };

    this.validator = new SimpleReactValidator();
    this.handleGarageSelect = this.handleGarageSelect.bind(this);
    this.myRef = React.createRef();

  }

  reloadState() {
    this.setState({
      sellerId: null,
      seller: null,
      quotationFile: null,
      quotationFileName: null,
      buyerNotes: null,
      modalSellerOpen: false,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { loadWithId } = nextProps;

    if (loadWithId) {
      this.loadData(loadWithId);
    }

    return true;
  }

  onSellerSelected = (seller) => {
    this.setState({ modalSellerOpen: false });

    this.setState({
      sellerId: seller._id,
      seller,
    });
  };

  loadData = (id) => {
    axios
      .get("/api/quotation/get?quotationId=" + id)
      .then((res) => {
        if (this.props.onDataLoaded) this.props.onDataLoaded();

        const { seller, number, details, buyerNotes, responseFileUrl, originalName } = res.data;

        this.setState({
          number,
          seller,
          sellerId: seller._id,
          buyerNotes: buyerNotes,
          quotationFileName: originalName,
          quotationId: id,
          quotationFile: responseFileUrl,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.modalQuotation !== prevProps.modalQuotation) {
      this.reloadState();
      this.myRef.current.value = null;

    }

  }

  submit = () => {
    const { sellerId, quotationFile, buyerNotes } = this.state;
    const id = this.state.quotationId;
    // return;

    // if (!sellerId) {
    //   alert("you must select seller");
    //   return;
    // }



    if (!buyerNotes) {
      alert("please fill notes");
      return;
    }
    // if (quotationFile)
    //   if (quotationFile?.name?.split(".")[1]?.toLowerCase() !== "xlsx") {
    //     alert("Only extension with xlsx file type are supported");
    //     return;
    //   }
    const buyDate = moment.tz("Asia/Dubai").format("DD/MM/YYYY hh:mm:ss");
    var formData = new FormData();
    if (this.props.editQuotation) {
      formData.append("file", quotationFile);
    }
    else {
      formData.append("quotationFile", quotationFile);
    }
    // formData.append("seller", sellerId);
    formData.append("buyerNotes", buyerNotes);
    formData.append("date", buyDate);
    this.setState({ btndisable: true });

    if (this.props.editQuotation) {
      axios
        .patch(
          "/api/products/edit-upload-order?id=" + id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.reloadState();

          this.setState({ quotationFile: null })
          if (this.props.onFinished) this.props.onFinished();
          popUp("Update  Request");
          this.setState({
            quotationFile: "",
            sellerId: "",
            buyerNotes: "",
            buyDate: "",
            btndisable: false,
          });

        })
        .catch((err) => {
          if (this.props.onFinished) this.props.onFinished();

          popUp("There was a problem with server. Please try again.");
          this.setState({
            quotationFile: "",
            sellerId: "",
            buyerNotes: "",
            buyDate: "",
            btndisable: false,
          });
        });
    }
    else {
      if (!quotationFile) {
        alert("you must select quotation file");
        return;
      }

      axios
        .post(
          "/api/products/upload-order?user_id=" + this.props.login._id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.reloadState();

          this.setState({ quotationFile: null })
          if (this.props.onFinished) this.props.onFinished();
          popUp("Successfully Sent Request");
          this.setState({
            quotationFile: "",
            sellerId: "",
            buyerNotes: "",
            buyDate: "",
            btndisable: false,
          });
          this.myRef.current.value = null;
        })
        .catch((err) => {
          if (this.props.onFinished) this.props.onFinished();

          popUp("There was a problem with server. Please try again.");
          this.setState({
            quotationFile: "",
            sellerId: "",
            buyerNotes: "",
            buyDate: "",
            btndisable: false,
          });
        });
    }
  };

  componentDidMount() {
    axios
      .get("/api/users/get-seller-list?status=Approved")
      .then((res) => {
        this.setState({
          sellerList: res.data,
          sellerId: res.data.find(
            (s) => s._id === process.env.REACT_APP_QUOTATION_SELLER_ID
          )?._id,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  handleGarageSelect(selectedOption) {
    /** selectedOption in object with key lable and value
     * this is the way of react select
     */
    // this.setState({ sellerId: selectedOption.value });
  }

  render() {
    return (
      <div className="p-3">
        <div className="d-flex mb-2 justify-content-between">
          <h4>
            {this.props.editQuotation
              ? "View Quotation Request by Excel file"
              : "New Quotation Request by Excel file"}
          </h4>
          <a
            href="/assets/buyparts24_rfq_template.xlsx"
            className="btn btn-default btn-xs"
          >
            Download Excel Template
          </a>
        </div>
        <div className="row">
          {/* <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="sellerName" className="form-label">
                Seller
              </label>
              <br/>

              <Select
                id="garage-type"
                className={`react-select`}
                classNamePrefix="react-select"
                placeholder="Choose Garage Type..."
                name="garage_type"
                onChange={this.handleGarageSelect}
                options={this.state.sellerList?.map((sellerName) => {
                  return { label: sellerName?.name, value: sellerName?._id };
                })}

              />
              <div className="text-danger">
                {this.validator.message(
                  "garage_type",
                  this.state.garage_type,
                  `required`
                )}
              </div>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="quotationFile" className="form-label">
                Quotation File
              </label>
              {this.state.quotationFileName && (
                <p className="text-ellipse">
                  <a href={`${baseUrl}/${this.state.quotationFileName}`} _target="blank">
                    {this.state.quotationFileName}
                  </a>
                </p>
              )}
              <input
                type="file"
                className="form-control"
                id="quotationFile"
                accept=".xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  this.setState({ quotationFile: e.target.files[0] });
                  this.setState({ btndisable: false });
                }}
                ref={this.myRef}
              // value={this.state.quotationFile}
              />

              {/* <input type="file"
                className="form-control"
                id="quotationFile"
                accept=".xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  this.setState({ quotationFile: e.target.files[0] });
                  this.setState({ quotationFileName: e.target.files[0].name });
                  this.setState({ btndisable: false });
                }}
                style={{ visibility: "hidden" }} />
              <label for="quotationFile">Select file</label>
              {this.state.quotationFileName ? <span className="ml-3">{this.state.quotationFileName}</span> : <span className="ml-3">No files Choosen</span>} */}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="buyerNotes" className="form-label">
                Buyer Notes
              </label>
              <textarea
                value={this.state.buyerNotes || ""}
                className="form-control"
                rows="3"
                onChange={(e) => {
                  this.setState({ buyerNotes: e.target.value });
                  this.setState({ btndisable: false });
                }}
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <button
              disabled={this.state.btndisable}
              className="btn btn-primary btn-xs"
              onClick={this.submit}
            >
              {this.props.editQuotation ? "Update Request" : "Submit Request"}
            </button>
          </div>
        </div>
        <Modal
          visible={this.state.modalSellerOpen}
          width="800"
          effect="fadeInUp"
        >
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-default"
              onClick={(e) => this.setState({ modalSellerOpen: false, quotationFile: null })}
            >
              X
            </button>
          </div>
          {/* <SellerList onItemSelected={this.onSellerSelected} /> */}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

ExcelQuotation.propTypes = {
  onFinished: PropTypes.func,
};

ExcelQuotation.propTypes = {
  loadWithId: PropTypes.string,
};

ExcelQuotation.propTypes = {
  onDataLoaded: PropTypes.func,
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(
  ExcelQuotation
);
