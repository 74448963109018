import axios from "axios";
import { useHistory } from "react-router";
import { popUp } from "../../utils";

export const GetHomeListing = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      data: true,
    });
    axios
      .get("/api/listing/get-home")
      .then((res) => {
        const listing = res.data;
        dispatch({
          type: "GET_HOME_LISTING_SUCCESS",
          listing,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        dispatch({
          type: "GET_HOME_LISTING_FAILED",
        });
      });
  };
};

export const updatestock = (data) => {
  return async (dispatch) => {
    try {
      await axios.post("/api/listing/update-stock-quantity", data);
    } catch (err) { }
  };
};

export const setFilters = (filterQuery) => (dispatch) => {
  dispatch({
    type: "SET_FILTER",
    filterQuery,
  });
};
export const setPathname = (pathname) => (dispatch) => {
  dispatch({
    type: "SET_PATHNAME",
    pathname,
  });
};

export const Initialize = (filterQuery, count) => {
  const currentUser = JSON.parse(localStorage.getItem("login"));

  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      data: true,
    });

    if ((!currentUser || currentUser?.status === "Pending") && count >= 3) {
    } else {
      let emirate = currentUser?.role === "BUYER" ? currentUser.details?.emirate : ''
      let user_id = currentUser?.role === "BUYER" ? currentUser._id : ''

      let URL = `/api/listing/initialize?emirate=${emirate}&user_id=${user_id}`;

      if (filterQuery && Object.keys(filterQuery).length > 0) {
        Object.keys(filterQuery).map(key => {
          if (filterQuery[key] !== null && filterQuery[key] !== undefined) {
            if (key === 'category') {
              URL = `${URL}&category=${filterQuery.category._id}`;
            }
            else if (key === 'subcategory') {
              URL = `${URL}&subcategory=${filterQuery.subcategory._id}`;
            }
            else
              URL = `${URL}&${key}=${filterQuery[key]}`;
          }
        })
      }

      axios
        .get(URL)
        .then((res) => {
          const { searchQuery, listings, findQuery, totalCount } = res.data;

          let bp24Listing =
            listings?.map((value) => ({
              ...value,
              istecAll: false,
            })) || [];

          //sorting by most match
          let sortedListings = [];
          let searchQueryWords = searchQuery?.string?.split(" ");

          if (searchQueryWords?.length) {
            searchQueryWords =
              searchQueryWords?.map((s) => s.trim()?.toLowerCase()) || [];
            let newListings = [];
            let allWords = []; //array of arrays
            let newListingsTec = [];

            bp24Listing.forEach((l) => {
              let byDesc =
                l?.description
                  ?.replace(/<\/?[^>]+(>|$)/g, "")
                  ?.split(" ")
                  .map((value) => value.toLowerCase()) || [];
              let byAdNotes =
                l?.description
                  ?.replace(/<\/?[^>]+(>|$)/g, "")
                  ?.split(" ")
                  .map((value) => value.toLowerCase()) || [];
              let bySku = l?.partSKU
                ?.split(" ")
                .map((value) => value.toLowerCase());
              let _bySKU = l?.partSKU
                ?.split("-")
                .map((value) => value.toLowerCase());
              let byMake = [];
              l?.makes?.forEach((value) => {
                byMake.push(
                  ...value?.name?.split(" ").map((value) => value.toLowerCase())
                );
              });
              let byName = l?.partName
                ?.split(" ")
                .map((value) => value.toLowerCase());
              allWords.push([
                ...new Set([
                  ...byDesc,
                  ...byAdNotes,
                  ...bySku,
                  ...byName,
                  ..._bySKU,
                  ...byMake,
                ]),
              ]);
            });

            allWords.forEach((a, i) => {
              let matchedValue = 0;
              a.forEach((word) => {
                if (searchQueryWords.includes(word?.trim()?.toLowerCase())) {
                  matchedValue += 4;
                } else if (
                  searchQueryWords.some(
                    (w) => word?.trim()?.toLowerCase().search(w) > -1
                  )
                ) {
                  matchedValue += 1;
                }
              });
              newListings.push({ matchedValue, ...bp24Listing[i] });
            });
            newListings = newListings.sort(
              (prev, next) => next.matchedValue - prev.matchedValue
            );

            sortedListings = [...newListings, ...newListingsTec];
          } else {
            sortedListings = [...bp24Listing];
          }

          dispatch({
            type: "INITIALIZE_SUCCESS",
            searchQuery,
            filterQuery, //used for next page api call
            listings: sortedListings,
            totalCount,
          });

          axios
            .post(`${process.env.REACT_APP_PRICE_SERVER_URL}/listing-filters`, {
              ...filterQuery,
              findQuery,
            })
            .then((res) => {
              const { maxPrice, minPrice, makeList, brandList } = res.data;
              let newBrandList = brandList.map((b) => {
                return { name: b, _id: b };
              });
              dispatch({
                type: "GET_FILTER",
                maxPrice,
                minPrice,
                makeList,
                brandList: newBrandList,
              });
            });
          // axios.post(`${process.env.REACT_APP_PRICE_SERVER_URL}/save-keyword`,{search:filterQuery.search})//to save search keywords

          if (!currentUser || currentUser?.status === "Pending") {
            dispatch({
              type: "INCREASE_VISITORS_VISIT_COUNT",
              count: count + 1,
            });
          }
        })
        .catch((err) => {
          console.log({ err });
          popUp("There was a problem with server. Please try again.");
          dispatch({
            type: "INITIALIZE_FAILED",
          });
        });
    }
  };
};

export const visitor = (count) => {
  const currentUser = JSON.parse(localStorage.getItem("login"));

  return (dispatch, getState) => {
    if (!currentUser || currentUser?.status === "Pending") {
      dispatch({
        type: "INCREASE_VISITORS_VISIT_COUNT",
        count: count + 1,
      });
    }
  };
};

export const visitor1 = (count) => {
  const currentUser = JSON.parse(localStorage.getItem("login"));

  return (dispatch, getState) => {
    if (!currentUser || currentUser?.status === "Pending") {
      dispatch({
        type: "INCREASE_VISITORS_VISIT_COUNT",
        count: count,
      });
    }
  };
};

export const GetMyListings = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      data: true,
    });
    const login = getState().login;
    axios
      .get(`/api/listing/get-my-listing?user_id=${login._id}`)
      .then((res) => {
        const { listings } = res.data;
        dispatch({
          type: "GET_MY_LISTING_SUCCESS",
          listings,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        dispatch({
          type: "GET_MY_LISTING_FAILED",
        });
      });
  };
};

export const GetMyListingsInPagination = (page, size, filters) => {
  return (dispatch, getState) => {
    const login = getState().login;
    dispatch({ type: "GET_MY_LISTING_BEGIN" })
    let URL = `/api/listing/all?page=${page}&size=${size}&user_id=${login._id}`;

    Object.keys(filters).map(key => {
      if (filters[key] !== undefined && filters[key] !== '') {
        if (key === 'max_price') URL = `${URL}&${key}=${(filters[key]).toString().replace(/\+/g, '%2B')}`;
        else URL = `${URL}&${key}=${filters[key]}`;
      }
    });
    axios
      .get(URL)
      .then((res) => {
        const listings = res.data;
        dispatch({
          type: "GET_MY_LISTING_SUCCESS",
          listings,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        dispatch({
          type: "GET_MY_LISTING_FAILED",
        });
      });
  };
};
export const GetListingsCount = (filters, isInitalRender) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING_COUNT",
    });
    const login = getState().login;
    axios
      .get(`/api/listing/all?count=${true}&user_id=${login._id}`, {
        filters,
      })
      .then((res) => {
        const count = res.data;

        dispatch({
          type: "GET_MY_LISTING_COUNT_SUCCESS",
          count,
          isInitalRender,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        dispatch({
          type: "GET_MY_LISTING_COUNT_FAILED",
        });
      });
  };
};

// const getListingsCount = (filters) => {
//   return async (dispatch, getState) => {
//     try {
//       const login = getState().login;
//       const resp = await axios.post(
//         `/api/listing/all?count=${true}&user_id=${login._id}`,
//         {
//           filters,
//         }
//       );
//       dispatch(getListingsCountSuccess(resp.data));
//     } catch (err) {
//       dispatch(getListingsErr(err));
//     }
//   };
// };

export const AddToCart = (data) => {
  const currentUser = JSON.parse(localStorage.getItem("login"));
  return (dispatch, getState) => {
    if (currentUser && currentUser.role === "BUYER") {
      const { isBulkUpload } = getState().list;

      if (isBulkUpload) {
        popUp("You cannot add items in your cart while your bulk order is still on process", "error");
        return;
      }

      if (currentUser && currentUser.status === "Pending") {
        popUp(
          "Your account is in pending state. Please wait for admin to approves your account and then u can purchase the products"
        );
      } else {
        axios
          .get(
            `/api/listing/check-buyability?emirate=${currentUser?.details?.emirate}&list=${data}`
          )
          .then((res) => {
            const { canbuy } = res.data;
            if (canbuy) {
              dispatch({
                type: "ADD_TO_CART",
                data,
              });
              popUp(
                `A product has been added to the cart.`,
                "View Cart",
                "/checkout"
              );
            } else {
              popUp("This seller do not sell in your emirate.");
            }
          })
          .catch((err) => {
            popUp("Cannot add item in the cart. Please try again.");
          });
      }
    } else {
      popUp(
        "Sorry! your accout type is seller. Please login as buyer to purchase the products in BuyParts 24"
      );
    }
  };
};

export const UpdateCart = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_CART",
      data,
    });
  };
};

export const SetVisibility = (id, visibility) => {
  return (dispatch, getState) => {
    axios
      .get(`/api/listing/set-visibility?id=${id}&visibility=${!visibility}`)
      .then((res) => {
        dispatch({
          type: "SET_VISIBILITY_SUCCESS",
          listing_id: id,
          visibility: !visibility,
        });
        popUp("Successfully updated.");
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        dispatch({
          type: "SET_VISIBILITY_FAILED",
        });
      });
  };
};

export const handleBulkDelete = (id, action) => {
  return (dispatch, getState) => {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    axios
      .delete(`/api/listing/delete-bulk-products?action=${action}`, {
        data: { id, deletedBy: currentUser._id },
      })
      .then((res) => {
        dispatch({
          type: "BULK_PRODUCT_DELETE",
        });
        dispatch(GetMyListingsInPagination(1, 18, {}));
        popUp(`Items ${action}ed from your listing successfully!`);
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  };
};
export const GetMyFavorites = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      data: true,
    });
    const login = getState().login;
    axios
      .get(`/api/listing/get-my-favorites?user_id=${login._id}`)
      .then((res) => {
        const { listings } = res.data;
        dispatch({
          type: "GET_MY_FAVORITES_SUCCESS",
          listings,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        dispatch({
          type: "GET_MY_FAVORITES_FAILED",
        });
      });
  };
};

export const FilterListing = (query) => {
  console.log("Final", query)
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      data: true,
    });
    const currentUser = JSON.parse(localStorage.getItem("login"));
    let emirate = currentUser?.role === "BUYER" ? currentUser.details?.emirate : ''
    let user = currentUser?.role === "BUYER" ? currentUser._id : '';
    let URL = `/api/listing/search?emirate=${emirate}&user=${user}`;
    if (Object.keys(query).length > 0) {
      const { string, emirates, priceRange, category, subCategory, make, model, year, brand, sortBy, type, businessModel, page, perPage } = query;
      URL = `${URL}&page=${page || 1}&perPage=${perPage || 10}`;
      if (string) URL = `${URL}&string=${string}`;
      if (emirates && emirates.length > 0) {
        let options = [];
        emirates.map(emirate => options.push(emirate.name));
        URL = `${URL}&emirates=${options}`;
      }
      if (priceRange) URL = `${URL}&priceRange=${priceRange}`;
      if (category) {
        let options = [];
        category.map(m => options.push(m));
        URL = `${URL}&category=${options}`;
      }
      if (subCategory) {
        let options = [];
        subCategory.map(m => options.push(m));
        URL = `${URL}&subCategory=${options}`;
      }
      if (make) {
        console.log(make, "teste")
        let options = [];
        make.map(m => options.push(m._id ?? m));
        URL = `${URL}&make=${options}`;
      }
      if (model) {
        let options = [];
        model.map(m => options.push(m._id));
        URL = `${URL}&model=${options}`;
      }
      if (brand) {
        let options = [];
        brand.map(m => options.push(m.name));
        URL = `${URL}&brand=${options}`;
      }
      if (year) URL = `${URL}&year=${year}`;
      if (sortBy && Object.keys(sortBy).length > 0) {
        let sort = '';
        Object.keys(sortBy).map(key => {
          if (sortBy[key] === 1) sort += key;
          else sort += `-${key}`;
        })
        URL = `${URL}&sortBy=${sort}`;
      }
      if (type) URL = `${URL}&type=${type}`;
      if (businessModel) {
        let options = [];
        businessModel.map(bM => options.push(bM._id));
        URL = `${URL}&businessModel=${options}`;
      }
    }

    axios
      .get(URL)
      .then((res) => {
        const { listings, totalCount } = res.data;
        dispatch({
          type: "FILTER_LISTING_SUCCESS",
          listings,
          totalCount,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        dispatch({
          type: "FILTER_LISTING_FAILED",
        });
      });
  };
};

export const GetAllListing = () => {
  return (dispatch, getState) => {
    axios
      .get("/api/listing/get-all")
      .then((res) => {
        const listing = res.data;
        dispatch({
          type: "GET_ALL_LISTING_SUCCESS",
          listing,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
        dispatch({
          type: "GET_ALL_LISTING_FAILED",
        });
      });
  };
};

export const SetFavouriteListing = (e, listing_id) => {
  e.preventDefault();
  return (dispatch, getState) => {
    const login = getState().login;
    if (!login) return;
    axios
      .post("/api/listing/set-favourite", {
        listing_id: listing_id,
        user_id: login._id,
      })
      .then((res) => {
        dispatch({
          type: "SET_FAVOURITE_LISTING_SUCCESS",
          data: res.data,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  };
};

export const getBannerList = () => {
  return (dispatch, getState) => {
    axios
      .get("/api/banner/")
      .then((res) => {
        dispatch({
          type: "GET_BANNER_LIST",
          data: res.data?.banner,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const SetLoading = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_LOADING",
      data,
    });
  };
};

export const SetSimilarListing = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_SIMILAR_LISTING",
      data,
    });
    return Promise.resolve();
  };
};

export const SetSearchQuery = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_SEARCH_QUERY",
      data,
    });
    return Promise.resolve();
  };
};

export const SetListings = (data) => {
  return (dispatch, getState) => {
    let newBrandList = data?.brandList?.map((b) => {
      return { name: b, _id: b };
    });
    let newData = {
      ...data,
      brandList: newBrandList || [],
    };
    dispatch({
      type: "SET_LISTINGS",
      data: newData,
    });
    // return Promise.resolve();
  };
};

export const SetTradesoftSKUs = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_TRADESOFT_SKUS",
      data,
    });
    return Promise.resolve();
  };
};

export const getSameNameItem = (name, type, seller) => async (dispatch) => {
  try {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    let emirate =
      currentUser?.role === "BUYER" ? currentUser.details?.emirate : "";
    let res = await axios.get(
      `/api/listing/get-by-same-name?partName=${name}&type=${type}&seller=${seller}&emirate=${emirate}`
    );
    dispatch({ type: "SAME_NAME_ITEM_LIST", data: res.data });
  } catch (err) {
    popUp("There was a problem with server. Please try again.");
  }
};

export const VinSearchDetails = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_SPAREPART_SEARCHDETAILS",
      data,
    });
    return Promise.resolve();
  };
};

export const updateBulkCart = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_BULK_UPLOAD_LIST",
      data,
    });
    return Promise.resolve();
  };
}

export const getBulkCartListing = () => {
  return (dispatch, getState) => {
    const { bulkUploadedList } = getState().list;
    const { details } = getState().login;
    dispatch({
      type: "GET_BULK_CART_LIST_BEGIN",
    });

    const result = Object.values(
      bulkUploadedList.reduce((acc, obj) => ({ ...acc, [obj?.partSKU]: obj }), {})
    );
    axios
      .post("/api/listing/bulk-cart-listings", {
        listings: result,
        emirate: details?.emirate
      })
      .then((res) => {
        dispatch({
          type: "GET_BULK_CART_LIST_SUCCESS",
          data: res.data,
        });

        //to get total order items

        const totalOrders = res.data?.products && res.data?.products?.length !==0 &&
          res.data?.products?.reduce((a, b) => {
            a = a + b?.orderQty;
            return a;
          }, 0);

        dispatch({
          type: "SET_BULK_CART_LIST_COUNT",
          data: totalOrders,
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_BULK_CART_LIST_ERROR",
        });
        popUp("There was a problem with server. Please try again.", "error");
      });
  };
}

export const updateBulkCartListing = (data, type) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_BULK_CART",
      data
    });
    let list = getState().list
    let totalCount = 0;

    switch (type) {
      case "increment":
        totalCount = list?.bulkCartListCount + 1;
        break;
      case "decrement":
        totalCount = list?.bulkCartListCount - 1;
        break;
      default:
        totalCount = data && data?.length !== 0 ?
          data.reduce((a, b) => {
            a = a + b?.orderQty;
            return a;
          }, 0) : 0;
    }

    dispatch({
      type: "SET_BULK_CART_LIST_COUNT",
      data: totalCount,
    });
    return Promise.resolve();
  };
}
