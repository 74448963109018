import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";

const noAction = (e) => e.preventDefault();

class AdvSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    noAction(e);
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { t } = this.props;
    const { vin } = this.state;
    return (
      <Fragment>
        <div className=" only-mobile">
          <div style={{ padding: "0 19px" }}>
            <div className="row">
              <div className="col ">
                <div className="mobile-search">
                  <form>
                    <div className="input-search-mobile">
                      <input
                        type="text"
                        value={vin || ""}
                        name="vin"
                        onChange={this.handleChange}
                        placeholder={t("Find parts by VIN")}
                        className="mobile-search-by-vin-input"
                      />
                      <button type="submit">
                        {" "}
                        <SearchIcon style={{ fontSize: 24, marginTop: 0 }} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(AdvSearch);
