import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { popUp } from '../../utils';


const ThreeDsPayment = () => {

    const threeDs = async () => {
        const resp  = await window.NI.handlePaymentResponse(window.paymentRes, {
            mountId: '3ds_iframe',
            style: { width: 500, height: 500 }
        });
        const { status, error } = resp
        if (
            status === window.NI.paymentStates.AUTHORISED ||
            status === window.NI.paymentStates.CAPTURED
        ) {
            // Same as before this signals successful payment
            window.opener.isPaymentSuccess = true
            console.log('success')
            window.close()
        } else if (
            status === window.NI.paymentStates.FAILED ||
            // A new state to look out for is 3DS Challenge failure
            status === window.NI.paymentStates.THREE_DS_FAILURE
        ) {
            // payment failure signal
            window.opener.isPaymentSuccess = false
            console.log('failed')
            window.close()

        }
    }
    
    useEffect(() => {
        threeDs()
      
    }, [window])

    return (
        <>
            <div id="3ds_iframe" style={{ height: "225px" }}>
            </div>
        </>
    )
}





export default function ThreeDsPaymentModal() {
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    // async function createSession() {
    //     try {
    //         const response = await window.NI.generateSessionId();
    //         if (window.opener != null && !window.opener.closed) {
    //             window.opener.isSessionExpired = false
    //             window.opener.paymentSessionId = response.session_id;
    //         }
    //     } catch (err) {
    //         console.log(err);
    //         window.opener.paymentSessionId = null
    //         window.opener.isSessionExpired = true
    //         // popUp("Your session has expire. Please try again.", 'error');
    //     } finally {
    //         setLoading(false)
    //         window.close();
    //     }
    // }


    return (
        <>            
             <img src="/assets/img/logo-footer.svg" alt="logoImage" width="200" style={{marginTop:'20px', marginLeft:'150px'}}/>
            <ThreeDsPayment />
        </>
    );
}

