import React from "react";

const CardOfWhatWeDo = ({ imgSrc, info }) => {
  return (
    <div className="what-we-do-item">
      <div className="d-flex align-items-center mb-4">
        <img
          src={imgSrc || "/assets/img/product-default.jpg"}
          alt="what_we_do"
          className="what-we-do-card"
        />
        <h4
          className="ml-3"
          style={{
            color: "white",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "1.5rem",
          }}
        >
          {info}
        </h4>
      </div>
    </div>
  );
};

export default CardOfWhatWeDo;
