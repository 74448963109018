import moment from "moment";
import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
	chipStyle: {
		backgroundColor: "#f7af30",
	},
	imgStyle: {
		position: "absolute",
		height: (props) => `${props.height || "90px"} !important`,
		bottom: (props) => props.bottom || "-22px",
		right: (props) => props.right || "-95px",
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},
}));

const IsExpress = (props) => {
	const { business_model } = props;
	const classes = useStyles(props);
	let after = moment().tz("Asia/Dubai").format("HH:mm:ss");
	let checkExpressCutoff = moment(after, "hh:mm:ss").isBefore(
		moment("18:00:00", "hh:mm:ss")
	);
	return (
		<>
			{/* {checkExpressCutoff && business_model === "Fulfilment" && (
				<img
					className={classes.imgStyle}
					// src="/assets/img/express1.png"
					alt="express"
					style={{
						objectFit: "contain",
						objectPosition: "center",
						width: "50px",
					}}
				/>
			)} */}
		</>
	);
};

export default IsExpress;
