import { popUp } from "../../utils";

export const NewQuotation = (data) => {
  const currentUser = JSON.parse(localStorage.getItem("login"));
  return (dispatch, getState) => {
    if (currentUser && currentUser.status === "Pending") {
      popUp(
        "Your account is in pending state. Please wait for admin to approves your account and then u can purchase the products"
      );
    } else {
      dispatch({
        type: "NEW_QUOTATION",
        data,
      });
    }
  };
};

export const UpdateQuotationItems = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_QUOTATION_ITEMS",
      data,
    });
  };
};

export const ClearQuotation = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_QUOTATION",
      data,
    });
  };
};
