import React from "react";

const SoloutionCard = ({ title, data, imgSrc }) => {
  return (
    <>
      <div className="d-flex mt-3">
        <div className="solution-card">
          <img src={imgSrc} alt="solution_icon" />
        </div>
        <div className="ml-4 about-us-solution">
          <h3 className="section-inner-title " style={{ fontWeight: "700" }}>
            {title}
          </h3>

          <ul className="mt-2">
            {data.map((data, i) => {
              return (
                <>
                  <li key={i}>
                    <img
                      src="./assets/img/tick.png"
                      alt="tick_icon"
                      className="mr-2"
                      width="16px"
                    />
                    {data}
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SoloutionCard;
