import React from 'react'
import { useSelector } from 'react-redux';
import ListCard from './ListCard';
import UnAvailableProductsList from './UnAvailableProductsList';

export default function BulkOrderStep1({ listings, getCartLength, list, updatestockflag, qtyDecrement, qtyIncrement, handleConfirm, coupon, discountPerUnit, changeCartQty }) {
    const { unAvailableProducts } = useSelector(state => state.list);

    return (
        <div className="checkout-table table-responsive order-review-step">
            {
                listings?.map((data, index) => {
                    if (!data?.data?.length) return <></>;

                    //sorting the data with order quantity greater than actual quantity
                    data?.data?.sort((a, b) => a?.listings[0]?.quantity - a?.listings[0]?.orderQty);

                    return (
                        <table
                            id="directorist-checkout-table"
                            className="table bg-transparent checkout-table"
                            key={index}
                        >
                            <ListCard
                                data={data}
                                list={list}
                                getCartLength={getCartLength} 
                                updatestockflag={updatestockflag} 
                                qtyIncrement={qtyIncrement}
                                qtyDecrement={qtyDecrement}
                                handleConfirm={handleConfirm}
                                coupon={coupon}
                                discountPerUnit={discountPerUnit}
                                changeCartQty={changeCartQty}
                            />
                        </table>
                    );
                })}
                {
                    unAvailableProducts?.length !==0 &&
                    <UnAvailableProductsList/>
                }
        </div>
    )
}
