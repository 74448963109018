import React, { Component } from "react";
import equal from "fast-deep-equal";
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
export class OptionSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOptions: this.props.activeOptions,
    };
    this.handleClickOption = this.handleClickOption.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      !equal(this.state.currentOptions, this.props.activeOptions) ||
      !equal(prevProps.options, this.props.options)
    ) {
      this.setState({ currentOptions: this.props.activeOptions });
    }
  }

  handleClickOption(e, option) {
    e.preventDefault();
    let { currentOptions } = this.state;

    if(this.props.name !== "businessModel") {
      if (currentOptions.some((e) => e?._id === option?._id))
        currentOptions = currentOptions.filter((e) => e?._id !== option?._id);
      else currentOptions.push(option);
    } else {
      currentOptions.pop();
      currentOptions.push(option);
    }

    this.setState({ currentOptions });
    this.props.onChange(currentOptions, this.props.name);
  }

  render() {
    let { options,setpage,page,totalpage,show } = this.props;
    const { currentOptions } = this.state;
    return (
      <div className="atbd_selection__group">
        {options &&
          options.map((option, index) => {
            return (
              <div
                key={index}
                className={
                  currentOptions.some((e) => e?._id === option?._id)
                    ? "atbd_selection__item active"
                    : "atbd_selection__item"
                }
              >
                <span onClick={(e) => this.handleClickOption(e, option)}>
                  {option?.name}
                </span>
              </div>
            );
          })}
          <div style={{display:'block',position:'absolute',bottom:'-25px',width:'100%'}} >
           {show==true &&<div style={{width:'100%',justifyContent:'space-between'}} className="d-flex">
              {(totalpage-1)!==page && <Button size="small" color="primary"onClick={(e)=>setpage(page+1)}>Next<NavigateNextIcon style={{marginBottom:5}} /></Button>}
              {page>0 &&<Button size="small" color="primary" onClick={(e)=>setpage(page-1)}><ArrowBackIosIcon style={{fontSize:14,marginBottom:5}}/>Prev</Button>}
            </div>}
          </div>

      </div>
    );
  }
}

export class ThumbOptionSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOptions: [],
    };
    this.handleClickOption = this.handleClickOption.bind(this);
  }

  handleClickOption(e, option) {
    e.preventDefault();
    let { activeOptions, name } = this.props;
    if (activeOptions.includes(option))
      activeOptions = activeOptions.filter((e) => e !== option);
    else activeOptions.push(option);
    this.props.onChange(activeOptions, name);
  }

  render() {
    const { options, activeOptions } = this.props;
    return (
      <div className="atbd_selection__group">
        {options &&
          options.map((option, index) => {
            return (
              <div
                key={index}
                className={
                  activeOptions.includes(option.value)
                    ? "atbd_selection__thumb_item active"
                    : "atbd_selection__thumb_item"
                }
                onClick={(e) => this.handleClickOption(e, option.value)}
              >
                <img
                  src={`/assets/img/car-types/${option.value}.png`}
                  alt="cartype"
                />
                <span>{option.label}</span>
              </div>
            );
          })}
      </div>
    );
  }
}
